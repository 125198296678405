import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import { setPricing } from "../../../../actions/onboardingSeller";
import { toast } from "react-toastify";
import { queryClient } from "../../../App";
import { useMutation, useQuery } from "react-query";
import { getStripeProducts } from "../../../../actions/stripeProduct";
import { StripeProduct } from "types/stripeProduct";
import { Interval } from 'types/stripeProduct';
import { formatValue } from "../../pricing/Pricing";

interface Props {
	seller: any;
	rqKeyOnboardingSellers: string;
}

const PricingPlan: React.FC<Props> = ({ seller, rqKeyOnboardingSellers }) => {
	const [modal, setModal] = useState<boolean>(false);
	const [modal1, setModal1] = useState<boolean>(false);
	const [modal2, setModal2] = useState<boolean>(false);

	const [plan, setPlan] = useState<StripeProduct>();
	const [interval, setInterval] = useState<Interval>('month');

	const plans = useQuery(`stripe-products`, getStripeProducts, { refetchOnWindowFocus: false });
	const currentPlan = usePricingPlan(seller.stripePriceId);

	useEffect(() => {
		if (!currentPlan) {
			return;
		}

		setPlan(plans.data?.find(p => p.stripeId === currentPlan.stripeId));
		setInterval(currentPlan.interval);
	}, [currentPlan, plans.data]);

	const getPrice = useCallback(() => {
		return plan?.prices.find(pr => pr.interval === interval);
	}, [plan, interval]);

	const totalPrice = useMemo(() => getPrice()?.price, [getPrice]);

	const [otherCompliments, setOtherCompliments] = useState<string>(
		seller?.otherCompliments || ""
	);
	const [pricingData, setPricingData] = useState<any>({});

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			{seller.contractStatus === "Na" ? (
				<button
					onClick={() => setModal(true)}
					style={{ zIndex: "100" }}
					className="sel-set-actions-modal"
				>
					Select plan
				</button>
			) : (
				<button
					className="border0 no-shadow transparent email-text"
					onClick={() => {
						if (seller.paymentStatus !== "Accepted") {
							setModal(true);
						} else {
							setModal2(true);
						}
					}}
				>
					<span className="sel-orange-text">{currentPlan?.name}</span>
					<b>{currentPlan?.price}</b>
					<span>{`${currentPlan?.interval}ly`}</span>
				</button>
			)}

			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<>
						<div
							style={{ width: "480px" }}
							className="table-modal-form-cont p4"
						>
							<div className="table-modal-title-box width100 m0">
								<div className="mapping-modal-title-text">
									Setup a Pricing Plan
								</div>
							</div>
							<div className="table-modal-border"></div>
							<div
								style={{ textAlign: "start" }}
								className="sel-modal-text in-column align-start"
							>
								<div className="in-row width100 align-center justify-between">
									<div className="in-column width100 align-start justify-start">
										<span>Zeoos Plan:</span>
										<select
											className="main-select-bi width90 mwidth150"
											value={plan?.stripeId}
											onChange={e => setPlan(plans.data?.find(p => p.stripeId === e.target.value))}
										>
											<option value=''>Select option</option>
											{plans.data?.map((plan, index: number) => (
												<option value={plan.stripeId} key={index}>
													{plan.name}
												</option>
											))}
										</select>
									</div>
									<div className="in-column width100 align-start justify-start">
										<span>Subscription:</span>
										<div className="in-row align-center">
											<span
												className={`mr2 ${interval !== "month"
													? "sel-pla-modal-grey"
													: "sel-pla-modal-green"
													}`}
											>
												Monthly
											</span>
											<AntSwitch
												checked={interval !== "month"}
												onChange={e => setInterval(e.target.checked ? 'year' : 'month')}
												name="plan"
												required
											/>
											<span
												className={`ml2 ${interval === "month"
													? "sel-pla-modal-grey"
													: "sel-pla-modal-green"
													}`}
											>
												Yearly
											</span>
										</div>
									</div>
								</div>
								{totalPrice && (
									<div className="sel-plan-modal-greybg">
										<span className="">
											Total pricing (€): <strong>{totalPrice} / {interval}</strong>
										</span>
									</div>
								)}
								<div className="in-column width100 align-start justify-start">
									<span>Other complements:</span>
									<textarea
										className="sel-pricing-modal-area"
										value={otherCompliments}
										onChange={(e) => setOtherCompliments(e.target.value)}
									/>
								</div>
							</div>
							<div className="table-modal-border"></div>
							<div className="table-modal-form-button-box width100 m0">
								<button
									className="sel-modal-form-button-cancel"
									onClick={onModalClose}
								>
									Cancel
								</button>
								<button
									onClick={() => {
										setPricingData({ ...seller, stripePriceId: getPrice()?.stripeId });
										setModal(false);
										setModal1(true);
									}}
									className="table-modal-form-button"
								>
									SET PRICING
								</button>
							</div>
						</div>
					</>
				</Modal>
			)}

			{modal1 && (
				<ConfirmPaymentModal
					modal={modal1}
					setModal={setModal1}
					data={pricingData}
					rqKeyOnboardingSellers={rqKeyOnboardingSellers}
				/>
			)}

			{modal2 && (
				<ConfirmedModal modal={modal2} setModal={setModal2} seller={seller} />
			)}
		</>
	);
};

interface IConfirmPaymentModal {
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
	data: any;
	rqKeyOnboardingSellers: string;
}

const ConfirmPaymentModal: React.FC<IConfirmPaymentModal> = ({
	modal,
	setModal,
	data,
	rqKeyOnboardingSellers,
}) => {
	const refetchPlansConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyOnboardingSellers);
			toast.success(
				"Pricing plan has been selected. Please wait confirmation email"
			);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const createMutation = useMutation(
		() => setPricing(data),
		refetchPlansConfig
	);

	const setPrice = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			e.stopPropagation();

			await createMutation.mutateAsync();
			setModal(false);
		},
		[setModal, createMutation]
	);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<>
				<div style={{ maxWidth: "460px" }} className="table-modal-form-cont p4">
					<div className="updateForm__container">
						<div className="vendor-mapping-modal-text">
							After configuring the price of the plan, the seller will receive
							an email to accept the terms and conditions and to make the
							payment.
							<br />
							<br />
							<strong>Are you sure about this seller's plan and price?</strong>
						</div>
						<div className="table-modal-border"></div>
						<div className="table-modal-form-button-box width100 m0">
							<button
								className="sel-modal-form-button-cancel"
								onClick={onModalClose}
							>
								Cancel
							</button>
							<button className="table-modal-form-button" onClick={setPrice}>
								Confirm price
							</button>
						</div>
					</div>
				</div>
			</>
		</Modal>
	);
};

interface IConfirmedModal {
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
	seller: any;
}

const ConfirmedModal: React.FC<IConfirmedModal> = ({
	modal,
	setModal,
	seller,
}) => {
	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<>
				<div style={{ maxWidth: "480px" }} className="table-modal-form-cont p4">
					<div className="table-modal-title-box width100 m0">
						<div className="mapping-modal-title-text">Setup a Pricing Plan</div>
					</div>
					<div className="table-modal-border"></div>
					<div
						style={{ textAlign: "start" }}
						className="sel-modal-text in-column align-start"
					>
						<div className="sel-plan-modal-greenbg">
							<b style={{ color: "#299C00" }} className="">
								The contract has already been accepted by the seller.
							</b>
						</div>
						<div className="mt4">
							Zeoos Plan: <strong className="">{seller.zeoosPlan}</strong>
						</div>
						<div className="mt4">
							Total pricing (€):{" "}
							<strong className="">{seller.totalPricing}</strong>(
							{seller.subscription})
						</div>
						{seller.otherCompliments && (
							<div className="mt4">
								Other complements:
								<br />
								<strong className="">{seller.otherCompliments}</strong>
							</div>
						)}
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box justify-center width100 m0">
						<button className="table-modal-form-button" onClick={onModalClose}>
							CLOSE
						</button>
					</div>
				</div>
			</>
		</Modal>
	);
};

function usePricingPlan(stripePriceId: string) {
	const plans = useQuery(`stripe-products`, getStripeProducts, { refetchOnWindowFocus: false });
	const [data, setData] = useState<{ stripeId: string, name: string, interval: Interval, price: string; }>();

	useEffect(() => {
		if (!plans.data) {
			return;
		}

		for (const p of plans.data) {
			const price = p.prices.find(pr => pr.stripeId === stripePriceId);

			if (!price) {
				continue;
			}

			setData({
				name: p.name,
				interval: price.interval,
				price: `${formatValue(price.price)} ${price.currency}`,
				stripeId: p.stripeId,
			});

			break;
		}
	}, [plans.data, stripePriceId]);

	return data;
}

export default PricingPlan;
