import React, { useContext } from "react";
import "./marketplaces.css";
import * as _ from "lodash";
import { IMarketplace, IPaginatedRequest } from "../../../interfaces";
import { CountriesContext } from "../../context";
import { ICountryM } from "../country-management/CountryManagement";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../users-management/UsersManagement";
import FilterSelect from "../../ui/select/FilterSelect";

export const types = ["Webstore", "Marketplace"];

interface Props {
	setOpenCreate: (openCreate: boolean) => void;
	marketplaces: any;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
}

export const PlatformFilter: React.FC<Props> = ({
	setOpenCreate,
	marketplaces,
	setCurrentConfig,
	currentConfig,
}) => {
	const countries = useContext(CountriesContext) as ICountryM[];

	return (
		<div className="marketplace-heading-and-create-box">
			<div className="mplc-filter-cont">
				<div className="mplc-filter">
					<div className="filter-text">Filter by:</div>
					<FilterSelect
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
							setCurrentConfig((conf: IPaginatedRequest) => ({
								...conf,
								platformName: e.target.value,
							}))
						}
						value={currentConfig?.platformName}
						data={[
							"All platforms",
							..._.uniq(
								_.map(
									marketplaces.filter(
										(x: IMarketplace) =>
											x.country !== "Portugal" && x.zeoosName !== "Worten ES"
									),
									(item: any) => item.zeoosName
								)
							),
						]}
					/>
					<FilterSelect
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
							setCurrentConfig((conf: IPaginatedRequest) => ({
								...conf,
								type: e.target.value,
							}))
						}
						value={currentConfig?.type}
						data={["All types", ..._.uniq(_.map(types, (item: any) => item))]}
					/>
					<FilterSelect
						onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
							setCurrentConfig((conf: IPaginatedRequest) => ({
								...conf,
								country: e.target.value,
							}))
						}
						value={currentConfig?.country}
						data={[
							"All countries",
							..._.uniq(
								_.map(
									countries.filter((x: ICountryM) => x.name !== "Portugal"),
									(item: any) => item.name
								)
							),
						]}
					/>
				</div>
				<RoleRequired role={Role.admin}>
					<div className="create-button-box">
						<button
							onClick={() => {
								setOpenCreate(true);
							}}
							className="create-btn-lbox"
						>
							+ ADD platform
						</button>
					</div>
				</RoleRequired>
			</div>
		</div>
	);
};
