import React from 'react'
import { NavLink, withRouter, RouteComponentProps  } from 'react-router-dom';
import { useSingleOrder } from '../../../../../../hooks/useOrders';
import SingleOrderProducts from '../SingleOrderProducts';
import SingleOrderPurchaseInfo from './SingleOrderPurchaseInfo';
import Preloader from '../../../../../ui/preloader/Preloader';

type TParams = {
    order: string;
    shipping: string;
};

const SingleOrderPurchase: React.FC<RouteComponentProps<TParams>> = (props) => {
    const order = useSingleOrder(props.match.params.order);
    const shipping = props.match.params.shipping;

    return (
        <div className="main-body">
            <div className="main-container">
            {!order.isLoading ? (   
            <div className="table-main-cont">
                <NavLink to={`../${shipping}`}>
                    <div
                    className="single-billing-nav pointer" style={{paddingTop:"20px"}}
                    >
                        <img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
                        <div className="table-subtext underline pl2">
                            back to Delivery 
                            {` ${order.data.order.shipping_list.find((d: any) => d._id === shipping).reference}`}
                        </div>
                    </div>
                </NavLink>
                <div className="single-order-cont">
                    <SingleOrderPurchaseInfo order={order.data.order} shipping={shipping} />
                    <SingleOrderProducts order={order.data.order} shipping={shipping} purchase={true} />
                </div>
            </div>
            ) : (
                <div className="acompan-preloader">
                  <Preloader />
                </div>
            )}
            </div>
        </div>
    );

}

export default withRouter(SingleOrderPurchase);
