import React, { useState } from "react";
import "./single-billing.css";
import * as _ from "lodash";
import { getBilling, getSingleBillingCSV } from "../../../../actions/billings";
import { SingleBillingTable } from "./SingleBillingTable";
import { useQuery } from "react-query";
import Preloader from "../../../ui/preloader/Preloader";
import FilterSelect from "../../../ui/select/FilterSelect";
import { toast } from "react-toastify";

export interface IBillLineIds {
	product_description: string;
	quantity_sold: number;
	subtotal: number;
}

export interface IBillings {
	bill_id: number;
	bill_line_ids: IBillLineIds[];
	bill_name: string;
	country: string;
	invoice_date: string;
	partner_name: string;
	platform_name: any;
	related_sale_order: string;
	shipment_id: number;
	total_open: number;
	total_paid: number;
	payment_state: string;
}

interface Props {
	billingsId: number;
	setBillingsWindow: (billingsWindow: boolean) => void;
}

export const SingleBilling: React.FC<Props> = ({
	setBillingsWindow,
	billingsId,
}) => {
	const [platformName, setPlatformName] = useState<string>("All platforms");
	const [country, setCountry] = useState<string>("All countries");

	const { isLoading, data: billings } = useQuery(
		`billings?id=${billingsId}`,
		() => getBilling(billingsId),
		{ refetchOnWindowFocus: false }
	);

	if (isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont">
					<div
						className="single-billing-nav pointer"
						onClick={() => setBillingsWindow(false)}
					>
						<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
						<div className="table-subtext underline pl2">Billing Manager</div>
					</div>
					<div className="sb-filter-box">
						<div className="sb-filter-cont">
							<div className="sb-filter">
								<div className="in-row align-center">
									<div className="filter-text">Filter by:</div>
									<FilterSelect
										onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
											setPlatformName(e.target.value)
										}
										value={platformName}
										data={[
											"All platforms",
											..._.uniq(
												_.map(billings, (item: any) => item.platform_name)
											),
										]}
									/>

									<FilterSelect
										onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
											setCountry(e.target.value)
										}
										value={country}
										data={[
											"All countries",
											..._.uniq(
												_.map(billings, (item: IBillings) => item.country)
											),
										]}
									/>
								</div>
								<div
									className="export-btn pointer"
									onClick={() => {
										getSingleBillingCSV(billingsId).catch(console.error);
										toast.success("You will receive an email with the file");
									}}
								>
									Export
								</div>
							</div>
						</div>
					</div>
					<SingleBillingTable
						billings={billings}
						country={country}
						platformName={platformName}
					/>
				</div>
			</div>
		</div>
	);
};
