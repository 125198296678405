import React, { useCallback, useState } from "react";
import useUser from "../../../hooks/useUser";
import { IMarketplace, IPaginatedRequest } from "../../../interfaces";
import Paginator from "../../ui/pagination";
import { getLogoSvg } from "./MarketplacesList";
import CountryFlag from "../../ui/country-flag/CountryFlag";
import { CreateEditMarketplaceForm } from "./CreateEditMplc";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../users-management/UsersManagement";
import ConditionalLink from "../../utils/conditional-link";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";

interface Props {
	marketplaces: any;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
	loadMarketplaces: boolean;
}

export const PlatformTable: React.FC<Props> = ({
	marketplaces,
	setCurrentConfig,
	currentConfig,
	loadMarketplaces,
}) => {
	const { user } = useUser();

	const [marketplace, setMarketplace] = useState<IMarketplace>();
	const [editModal, setEditModal] = useState<boolean>(false);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	return (
		<>
			<div className="table-main-title-cont">
				<div className="mplc-main-title-search-value-cont">
					<div className="search-box">
						<div className="search_form">
							<div className="table-search-box">
								<form
									className="table-search-form"
									onSubmit={(e) => e.preventDefault()}
								>
									<input
										className="table-search-input"
										placeholder="Search marketplace"
										onChange={(e) =>
											setCurrentConfig((conf: IPaginatedRequest) => ({
												...conf,
												search: e.target.value,
											}))
										}
										value={currentConfig.search}
									/>
									<img
										className="table-search-image"
										src="/icons/search.svg"
										width="25px;"
										alt=""
									/>
								</form>
							</div>
						</div>
						<div className="table-value">
							<span className="table-main-title-text">
								{loadMarketplaces ? (
									<CircularPreloader />
								) : (
									<span className="table-main-title-text">
										{
											marketplaces?.data.filter(
												(x: IMarketplace) =>
													x.country !== "Portugal" &&
													x.zeoosName !== "Worten ES"
											).length
										}{" "}
										marketplaces
									</span>
								)}
							</span>
						</div>
					</div>
					<div className="marketplace-pagination">
						<Paginator
							data={!loadMarketplaces ? marketplaces : {}}
							refetch={onChangePage as any}
						/>
					</div>
				</div>
			</div>
			<div style={{ height: "max-content" }} className="table-overflow">
				{loadMarketplaces ? (
					<></>
				) : (
					<table
						style={{
							position: "sticky",
							zIndex: 101,
							top: "2px",
							width: "100%",
						}}
					>
						<thead>
							<tr
								style={{ marginTop: "-5px", width: "100%" }}
								className="table-results-title"
							>
								<th className="marketplace-actions">Platform</th>
								<th className="marketplace-pais">Country</th>
								<th className="marketplace-title">Platform Name</th>
								<th className="marketplace-sell">Type</th>
								<RoleRequired role={Role.admin}>
									<th className="marketplace-actions">Actions</th>
								</RoleRequired>
							</tr>
						</thead>
					</table>
				)}
				<table className="table-results">
					<tbody>
						{marketplaces?.data
							.filter(
								(x: IMarketplace) =>
									x.country !== "Portugal" && x.zeoosName !== "Worten ES"
							)
							.map((item: IMarketplace, index: number) => (
								<tr className="table-result-box minheight100" key={index}>
									<ConditionalLink
										condition={user.role === Role.admin}
										to={"/platform/" + item.zeoosName}
										className="width100 align-center"
									>
										<td className="marketplace-actions">
											<img
												src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getLogoSvg(
													item
												)}.svg`}
												alt=""
												width="80px"
											/>
										</td>
										<td className="marketplace-pais">
											<div className="marketplace-text">
												<CountryFlag
													country={item.country}
													className="table-search-image"
													width="35px"
												/>
											</div>
										</td>
										<td className="marketplace-title">
											<h3 className="marketplace-text">{item.zeoosName}</h3>
										</td>
										<td className="marketplace-sell">
											<span className="marketplace-text">{item.type}</span>
										</td>
										<RoleRequired role={Role.admin}>
											<td className="marketplace-actions">
												<button
													onClick={(e) => {
														e.preventDefault();
														setMarketplace(item);
														setEditModal(true);
													}}
													className="marketplace-table-edit-button"
												>
													Edit
												</button>
											</td>
										</RoleRequired>
									</ConditionalLink>
								</tr>
							))}
					</tbody>
				</table>
				{editModal && (
					<CreateEditMarketplaceForm
						marketplace={marketplace}
						setModal={setEditModal}
						modal={editModal}
						action={"edit"}
					/>
				)}
			</div>
		</>
	);
};
