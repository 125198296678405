import React, { memo, useState } from "react";
import "./rates.css";
import * as _ from "lodash";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import { IPaginatedRequest } from "../../../../interfaces";
import queryString from "query-string";
import MappingsNav from "../../../ui/mappings-nav/MappingsNav";
import useCountries from "../../../../hooks/useCountries";
import { ICountryM } from "../../country-management/CountryManagement";
import PRatesTable from "./PRatesTable";
import { getPaginatedCategories } from "../../../../actions/categoryField";
import Preloader from "../../../ui/preloader/Preloader";
import { ICategoryField } from "types";
import FilterSelect from "../../../ui/select/FilterSelect";

const PlatformRates: React.FC = () => {
	const countries = useCountries() as ICountryM[];

	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
		name: "All categories",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
	const [filterCategories, setFilterCategories] = useState<string[]>([]);
	const [filterCountry, setFilterCountry] = useState<string>("All countries");

	const { isLoading, data: categories } = useQuery(
		`paginated-category-fields?${queryString.stringify(debouncedConfig)}`,
		async () => {
			const categories = await getPaginatedCategories(currentConfig);
			setFilterCategories((prev) =>
				!!prev.length
					? prev
					: [
						"All categories",
						..._.uniq(
							_.map(categories?.data, (item: ICategoryField) => item.name)
						),
					]
			);
			return categories;
		}
	);

	const filteredByCountry = () => {
		return filterCountry === "All countries"
			? countries
			: countries.filter((c: ICountryM) => c.name === filterCountry);
	};

	if (isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont p0">
					<div className="table-value-search-cont">
						<MappingsNav />
					</div>
					<div style={{ marginTop: "4px" }} className="rates-title-border"></div>
					<div className="table-title-cont">
						<div className="mplc-filter-cont width100">
							<div className="mplc-filter">
								<div className="filter-text">Filter by:</div>
								<FilterSelect
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
										setCurrentConfig((conf: IPaginatedRequest) => ({
											...conf,
											name: e.target.value,
										}))
									}
									value={currentConfig.name}
									data={filterCategories}
								/>
								<FilterSelect
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
										setFilterCountry(e.target.value)
									}
									value={filterCountry}
									data={[
										"All countries",
										..._.uniq(_.map(countries, (item: ICountryM) => item.name)),
									]}
								/>
							</div>
						</div>
					</div>
					<PRatesTable
						countries={filteredByCountry()}
						categories={categories}
						setCurrentConfig={setCurrentConfig}
						currentConfig={currentConfig}
					/>
				</div>
			</div>
		</div>
	);
};

export default memo(PlatformRates);
