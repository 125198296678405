import React, { useRef, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Modal } from "../../../ui/modal/Modal";
import { Ticket } from "types";
import { TicketStatus } from "types";
import { createTicketMessage, updateTicket } from "../../../../actions/ticket";
import { toast } from "react-toastify";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { useTicket, useTicketMessages } from "../../../../hooks/useTickets";
import { DateTime } from "luxon";
import useUser from "../../../../hooks/useUser";
import { TicketMessage } from "types";
import { Role } from "../../users-management/UsersManagement";
import { queryClient } from "../../../App";
import useChatScroll from "../../../../hooks/useChatScroll";

const SingleTicket: React.FC<RouteComponentProps<{ id: string }>> = ({
	match,
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const ticket = useTicket(match.params.id);
	const messages = useTicketMessages(match.params.id);
	const { user } = useUser();

	const ref = useRef<HTMLInputElement>(null);
	const refScroll = useChatScroll(messages);
	const [message, setMessage] = useState({
		file: undefined as undefined | File,
		text: "",
	});

	if (ticket.isLoading) {
		return <></>;
	}

	return (
		<>
			<div className="main-body">
				<div className="main-container">
					<div className="table-main-cont">
						<Link to="/tickets" className="single-billing-nav pointer">
							<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
							<div className="email-text underline pl2">back to Tickets</div>
						</Link>

						<div className="table-title-cont p0 mb4">
							<div
								style={{ padding: "20px" }}
								className="mplc-filter-cont align-start width100"
							>
								<div className="in-column align-start justify-center">
									<div className="in-row align-center mb2 justify-start">
										<h2 className="s-ticket-title mr2">
											Ticket {ticket.data?._id}
										</h2>
										<div
											className={`ticket-status-${ticket.data?.status.toLowerCase()}`}
										>
											{ticket.data?.status}
										</div>
									</div>
									<div className="in-row align-center email-text justify-start">
										<span className="mr4">Reason: {ticket.data?.reason}</span>
										<span className="mr4">
											{/* @ts-ignore */}
											From: {ticket.data?.initiator.username}
										</span>
										<span className="mr4">
											Opened on:{" "}
											{DateTime.fromISO(ticket.data?.created!).toFormat(
												"LLL dd, yyyy"
											)}
										</span>
									</div>
								</div>

								<RoleRequired role={Role.admin}>
									<button
										onClick={() => setOpen(true)}
										className="ticket-modal-add-btn m0"
									>
										{ticket.data?.status === TicketStatus.OPEN
											? "Close"
											: "Re-open"}
									</button>
								</RoleRequired>
							</div>
						</div>

						<div
							ref={refScroll}
							style={{ padding: "20px" }}
							className="mplc-filter-cont width100 s-ticket-chat-cont"
						>
							{messages.data?.data.map((message) => {
								return (
									<div
										className={
											isMine(user, message)
												? "ticket-sender-cont"
												: "ticket-recipient-cont"
										}
									>
										<div className="ticket-recipient-box">
											<div className="in-row align-center mb1 mt1 justify-start">
												<img
													className="user-avatar"
													/* @ts-ignore */
													src={message.sender.profileImage}
													alt=""
												/>
												<b className="ml2">
													{/* @ts-ignore */}
													{message.sender._id === user._id
														? "You"
														: /* @ts-ignore */
														  message.sender.username}
													wrote at{" "}
													{DateTime.fromISO(ticket.data?.created!).toFormat(
														"LLL dd, yyyy, HH:mm"
													)}
													:
												</b>
											</div>

											<div className="in-row align-center mt1 mb1 justify-start">
												{message.text}
											</div>

											{message.file && (
												<a
													href={message.file}
													target="_blank"
													rel="noreferrer"
													className="in-row align-center mt1 mb1 justify-start"
												>
													<img src="/icons/file-download.svg" alt="" />
													<span style={{ color: "#55718A" }} className="ml2">
														{message.fileName}
													</span>
												</a>
											)}
										</div>
									</div>
								);
							})}
						</div>

						{ticket.data?.status === TicketStatus.OPEN ? (
							<form
								style={{ padding: "20px" }}
								className="mplc-filter-cont pb4 align-start width100"
								onSubmit={async (e) => {
									e.preventDefault();

									try {
										await createTicketMessage(
											ticket.data?._id!,
											message as any
										);

										messages.invalidate();
										queryClient.invalidateQueries("/ticket-notifications");

										setMessage({ file: undefined, text: "" });
									} catch (error) {
										console.error(error);
										toast.error("Something went wrong...");
									}
								}}
							>
								<textarea
									placeholder="Write new message"
									className="single-ticket-textarea"
									required
									value={message.text}
									onChange={(e) =>
										setMessage((d) => ({ ...d, text: e.target.value }))
									}
								/>

								<div
									style={{ width: "200px" }}
									className="in-column ml2 mr2 align-between justify-between"
								>
									<button type="submit" className="s-ticket-send">
										Send message
									</button>

									<button
										type="button"
										className={`s-ticket-add`}
										onClick={() => ref.current?.click()}
									>
										{message.file ? "File added" : "Add a file"}
									</button>
									<input
										onChange={(e) =>
											setMessage((d) => ({ ...d, file: e.target.files?.[0] }))
										}
										ref={ref}
										style={{ display: "none" }}
										type="file"
									/>
								</div>
							</form>
						) : (
							<div
								style={{ padding: "20px" }}
								className="mplc-filter-cont pb4 align-center justify-center red width100"
							>
								The ticket was closed, so you cannot send a message. If you want
								to send a message, you need to re-open the ticket.
							</div>
						)}
					</div>
				</div>
			</div>

			<RoleRequired role={Role.admin}>
				<TicketStatusModal
					setOpen={setOpen}
					open={open}
					onSuccess={ticket.invalidate}
					ticket={ticket.data!}
				/>
			</RoleRequired>
		</>
	);
};

export const TicketStatusModal: React.FC<{
	open: boolean;
	setOpen: (s: boolean) => void;
	onSuccess: () => void;
	ticket: Ticket;
}> = ({ open, setOpen, onSuccess, ticket }) => {
	if (!open) {
		return <></>;
	}

	return (
		<Modal onModalClose={() => {}} isOpened={open}>
			<div
				style={{ minWidth: "360px", width: "360px", padding: "30px" }}
				className="table-modal-form-cont p4"
			>
				<div className="updateForm__container">
					<div className="vendor-mapping-modal-text mb4">
						Are you sure you want to{" "}
						{ticket.status === TicketStatus.OPEN ? "close" : "re-open"} this
						ticket?
					</div>

					<div className="table-modal-border"></div>

					<div className="table-modal-form-button-box width100 m0">
						<button
							style={{
								textDecoration: "none",
								color: "#55718A",
								textTransform: "initial",
							}}
							className="table-modal-form-button-cancel bold"
							onClick={() => setOpen(false)}
						>
							Cancel
						</button>

						<button
							style={{ padding: "10px 20px !imtoptant" }}
							className="tax-title-button bold pointer"
							onClick={async () => {
								try {
									await updateTicket(ticket._id, {
										status:
											ticket.status === TicketStatus.OPEN
												? TicketStatus.CLOSED
												: TicketStatus.OPEN,
									});

									queryClient.invalidateQueries("/ticket-notifications");

									onSuccess();
									setOpen(false);
								} catch (error) {
									console.error(error);
									toast.error("Something went wrong...");
								}
							}}
						>
							Yes
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

function isMine(user: any, message: TicketMessage): Boolean {
	return (
		// @ts-ignore
		(user.role === Role.admin && message.sender.role === Role.admin) ||
		// @ts-ignore
		(user.role !== Role.admin && message.sender.role !== Role.admin)
	);
}

export default withRouter(SingleTicket);
