import axios from "axios";
import { IPaginatedRequest } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export const getPaginatedOnboardingSellers = async (
  pagination: IPaginatedRequest
) => {
  const res = await axios.get(
    `/onboarding-sellers?${queryString.stringify(pagination)}`,
    getHeaders()
  );

  return res.data;
};

export async function createOnBoardingSeller(body: any) {
  await axios.post(`/onboarding-seller`, body, getHeaders());
}

export async function editOnBoardingSeller(body: any) {
  await axios.put(`/onboarding-seller/${body._id}`, body, getHeaders());
}

export async function deleteOnBoardingSeller(id: string) {
  await axios.delete(`/onboarding-seller/${id}`, getHeaders());
}

export async function setPricing(body: any) {
  await axios.put(`/onboarding-seller/pricing/${body._id}`, body, getHeaders());
}

export async function getTokenValidity(token: string) {
  const res = await axios.get(`/onboarding-seller/token-validity/${token}`);

  return res.data as string;
}

export async function createPassword(token: string, password: string) {
  await axios.post(`/onboarding-seller/create-password/${token}`, { password });
}

export async function readContract() {
  await axios.post(`/onboarding-seller/read-contract`, {}, getHeaders());
}

export async function updateBillingData(data: any) {
  await axios.post(`/onboarding-seller/billing-data`, data, getHeaders());
}

export async function subscribe(data: any) {
  const res = await axios.post(
    `/onboarding-seller/subscribe`,
    data,
    getHeaders()
  );
  return res.data;
}

export async function confirmSubscription3DS() {
  await axios.post(
    `/onboarding-seller/subscribe/confirm-3ds`,
    {},
    getHeaders()
  );
}

export async function confirmBankInfo(data: any) {
  await axios.post(`/onboarding-seller/confirm-bank-info`, data, getHeaders());
}

export async function getPricesTemplate() {
  const res = await axios.get(`/onboarding-seller/prices/template`, {
    ...getHeaders(),
    responseType: "blob",
  });
  return res.data as Buffer;
}

export async function downloadPriceAndStockSpreadsheet() {
  const url = window.URL.createObjectURL(
    new Blob([await getPricesTemplate()], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "ZeoosPricingTemplate.xlsx");
  document.body.appendChild(link);
  link.click();
}

export async function uploadPriceAndStockSpreadsheet(file: File) {
  const formData = new FormData();
  formData.append("file", file);

  const res = await axios.post(
    `/onboarding-seller/prices/template`,
    formData,
    getHeaders()
  );

  return res.data;
}
