import React, { useCallback, useState } from "react";
import { updateVendor } from "../../../actions/vendor";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
import { Modal } from "../../ui/modal/Modal";

interface Props {
  mappedVendor: any;
  handleAccordClick: any;
  onSuccess: () => void;
}

export const VendorActivity: React.FC<Props> = ({
  mappedVendor,
  onSuccess,
  handleAccordClick,
}) => {
  const [state, setState] = useState({
    checkedC: mappedVendor.isActive,
  });
  const [modal, setModal] = useState<boolean>(false);

  const onModalClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setModal(false);
    },
    [setModal]
  );

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleAccordClick("");
    setState({ ...state, [event.target.name]: event.target.checked });
    setModal(true);
  };

  const handleSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    mappedVendor.isActive = state.checkedC;
    await updateVendor(mappedVendor);
    onSuccess();
    setModal(false);
  };

  // const onToggle = useCallback(
  //   (e: React.MouseEvent) => {
  //     setModal(true);
  //     e.stopPropagation();
  //   },
  //   [setModal]
  // );

  return (
    <>
      <td className="users-status">
        <AntSwitch
          checked={mappedVendor.isActive}
          // onChange={handleChange}
          readOnly
          // onClick={onToggle}
          name="checkedC"
          required
        />
        <span className="email-text ml1">
          {mappedVendor.isActive ? "Active Seller" : "Inactive Seller"}
        </span>
      </td>
      {/* {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <div className="table-modal-form-cont p4">
            <div className="updateForm__container">
              <div className="vendor-mapping-modal-text">
                Are you sure you want to{" "}
                {mappedVendor.isActive ? "disable" : "enable"} <br />{" "}
                <strong>{mappedVendor.name}</strong>?
              </div>
              <div className="table-modal-border"></div>
              <div className="table-modal-form-button-box width100 m0">
                <button
                  className="table-modal-form-button-cancel"
                  onClick={onModalClose}
                >
                  Cancel
                </button>
                <div
                  className="table-modal-form-button pointer"
                  onClick={handleSubmit}
                >
                  SET
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )} */}
    </>
  );
};
