import React, { useDeferredValue, useMemo, useState } from "react";
import "./notifications.css";
import Paginator from "../../ui/pagination";
import { NavLink } from "react-router-dom";
import { Modal } from "components";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import { CreateNotification, Notification } from "types/notification";
import useUserGroups from "../../../hooks/useUserGroups";
import useVendors from "../../../hooks/useVendors";
import { createNotification, readAllNotifcations, readNotification } from "../../../actions/notifications";
import { toast } from "react-toastify";
import RoleRequired from "../../utils/role-required/RoleRequired";
import useNotifications from "../../../hooks/useNotifications";
import { DateTime } from "luxon";
import isURL from "validator/lib/isURL";
import { useClickOutside } from "@mantine/hooks";
import DatePicker from "react-datepicker";
import { ExampleCustomInput } from "../business-intelligence/Datepickers";
import "react-datepicker/dist/react-datepicker.css";
import { Role } from "../users-management/UsersManagement";
import { queryClient } from "../../App";

enum Type {
    ORDERS = "ORDERS",
    PRICE_AND_STOCK_UPDATES = "PRICE_AND_STOCK_UPDATES",
    INTEGRATED_PRODUCT = "INTEGRATED_PRODUCT",
    IMPORTED_PRODUCT = "IMPORTED_PRODUCT",
    BUYBOX = "BUYBOX",
    RANKING_ZEOOS = "RANKING_ZEOOS",
    DEFAULT_NOTIFICATION = "DEFAULT_NOTIFICATION",
}

export default function Notifications() {
    const [config, setConfig] = useState({
        seller: '',
        perPage: 50,
        page: 1,
        wasRead: undefined as undefined | false,
        search: '',
    });
    const debouncedConfig = useDeferredValue(config);
    const notifications = useNotifications(debouncedConfig);

    const sellers = useVendors();

    const [modal, setModal] = useState<boolean>(false);

    const [open, setOpen] = useState<boolean>(false);
    const dropdownRef = useClickOutside(() => {
        if (open) {
            setOpen(false);
        }
    });

    return (
        <>
            <div className="main-body">
                <div className="main-container">
                    <div className="table-main-cont">
                        <div className="table-title-cont p0 mt4 mb4">
                            <div className="mplc-filter-cont width100">
                                <span style={{ fontWeight: "14px" }} className="ml4">Notifications {notifications.data?.total}</span>

                                <RoleRequired role={Role.admin}>
                                    <div className="in-row align-center">
                                        <button
                                            onClick={() => {
                                                setModal(true);
                                            }}
                                            className="tax-title-button">
                                            <span style={{ textTransform: "initial" }} className="pim-avaliacoes-title-button-text">
                                                Create Notification
                                            </span>
                                        </button>

                                        <select
                                            style={{ minWidth: "200px" }}
                                            className="main-select-bi ml4"
                                            value={config.seller}
                                            onChange={e => setConfig(c => ({ ...c, seller: e.target.value }))}
                                        >
                                            <option className="compa-option" value="">
                                                All Selers
                                            </option>

                                            {sellers.data?.map((seller: any) => (
                                                <option className="compa-option" value={seller._id}>
                                                    {seller.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </RoleRequired>
                            </div>
                        </div>

                        <div className="table-main-title-cont">
                            <div className="table-main-title-search-value-cont">
                                <div className="in-row align-center ml2">
                                    <button style={config.wasRead === undefined ? { background: '#FCE8A4' } : {}} onClick={() => setConfig(c => ({ ...c, wasRead: undefined }))} className="not-table-btn">All events</button>
                                    <button style={config.wasRead === false ? { background: '#FCE8A4' } : {}} onClick={() => setConfig(c => ({ ...c, wasRead: false }))} className="not-table-btn">Unread</button>
                                    <div className="app-drop-down-container ml2" ref={dropdownRef}>
                                        <button onClick={() => setOpen(p => !p)}><img className="pointer not-dotts-btn ml2" src="/icons/menu-settings-dots.svg" alt="" /></button>
                                        {open && (
                                            <ul>
                                                <li
                                                    className="in-row align-center not-menuitem-cont"
                                                    onClick={async () => {
                                                        try {
                                                            await readAllNotifcations();
                                                            notifications.invalidate();
                                                            queryClient.invalidateQueries('unread-notifications');
                                                        } catch (error) {
                                                            console.error(error);
                                                        }
                                                    }}
                                                >
                                                    <img src="/icons/not-check-dotts.svg" alt="" />
                                                    <span className="not-menuitem-text">Mark all as read</span>
                                                </li>

                                                <NavLink to="/notifications-settings">
                                                    <li className="in-row align-center not-menuitem-cont">
                                                        <img src="/icons/not-set-dotts.svg" alt="" />
                                                        <span className="not-menuitem-text">Notifications configuration</span>
                                                    </li>
                                                </NavLink>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                <div className="search-box">
                                    <div className="search_form">
                                        <div className="table-search-box">
                                            <form
                                                className="table-search-form"
                                                onSubmit={(e) => e.preventDefault()}
                                            >
                                                <input
                                                    className="table-search-input"
                                                    placeholder="Search notification"
                                                    onChange={e => setConfig(c => ({ ...c, search: e.target.value }))}
                                                    value={config.search}
                                                />
                                                <img
                                                    className="table-search-image"
                                                    src="/icons/search.svg"
                                                    width="25px;"
                                                    alt=""
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="marketplace-pagination">
                                <Paginator
                                    data={notifications.isLoading ? {} : notifications.data}
                                    // @ts-ignore
                                    refetch={(config: any) => {
                                        setConfig(c => {
                                            return {
                                                ...c,
                                                ...config,
                                            };
                                        });
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            style={{ height: "max-content", marginBottom: "20px" }}
                            className="table-overflow"
                        >
                            <table
                                style={{
                                    zIndex: 101,

                                    width: "100%",
                                }}
                            >
                                <tr
                                    style={{ marginTop: "-5px", width: "100%" }}
                                    className="table-results-title"
                                >
                                    <th className="not-notifications">Notification</th>
                                    {/* <th className="not-pl-mkpl">Platform / Marketplace</th> */}
                                </tr>
                            </table>

                            <table style={{ marginTop: "-5px" }} className="table-results">
                                <tbody>
                                    {!notifications.isLoading && (
                                        <>
                                            {notifications.data?.total ? (
                                                notifications.data?.data.map(n =>
                                                    <NotificationItem
                                                        notification={n}
                                                        key={n._id}
                                                        onRead={() => notifications.invalidate()}
                                                    />
                                                )
                                            ) : (
                                                <tr className="table-result-box in-column justify-center border0 m0 height100px width100">
                                                    <img src="/icons/not-alert-table.svg" alt="" />
                                                    <span>You have no notifications alerts.</span>
                                                </tr>
                                            )}
                                        </>
                                    )}

                                    {/* <tr style={{ background: "#FFEDED", color: "#5A5A5A" }} className="table-result-box pl4 justify-between border0 m0 height100px width100">
                                        <span>Are you sure you want to <b>remove</b> this notification?</span>
                                        <div className="in-row align-center mr4">
                                            <button className="not-cancel-btn">Cancel</button>
                                            <button className="not-delete-btn">Delete</button>
                                        </div>
                                    </tr>
                                    <tr style={{ background: "#FFEDED", color: "#5A5A5A" }} className="table-result-box pl4 justify-between border0 m0 height100px width100">
                                        <span>Are you sure you want to <b>disable product import</b> this notifications?</span>
                                        <div className="in-row align-center mr4">
                                            <button className="not-cancel-btn">Cancel</button>
                                            <button className="not-delete-btn">Delete</button>
                                        </div>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <RoleRequired role={Role.admin}>
                <CreateNotificationModal open={modal} setOpen={setModal} onSuccess={notifications.invalidate} />
            </RoleRequired>
        </>
    );
}

const STEPS = ['Recipient', 'Alert types', 'Message'];

const DEFAULT_DATA = {
    seller: '',
    userGroup: '',
    alertType: {
        platform: true,
        email: false,
        topbar: false,
        push: false,
        sms: false,
        popup: false,
    },
    isClosable: false,
    title: '',
    lineText: '',
    data: '',
    url: '',
    relevantUntil: Date.now(),
};

const CreateNotificationModal: React.FC<{ open: boolean, setOpen: (s: boolean) => void; onSuccess: () => {}; }> = ({ open, setOpen, onSuccess }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [value, setValue] = React.useState('female');

    const [data, setData] = useState<CreateNotification>(DEFAULT_DATA);

    const [recipient, setRecipient] = useState<'all' | 'group' | 'seller'>('all');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const groups = useUserGroups();
    const sellers = useVendors();


    if (!open) {
        return <></>;
    }

    return (
        <Modal onModalClose={() => { }} isOpened={open}>
            <form
                style={{ width: "460px" }}
                className="table-modal-cont"
                onSubmit={async (e) => {
                    e.preventDefault();

                    try {
                        await createNotification(data);
                        toast.success('Notification successfully created!');

                        setOpen(false);
                        onSuccess();

                        setData(DEFAULT_DATA);
                        setActiveStep(0);
                    } catch (error) {
                        console.error(error);
                        toast.error('Something went wrong...');
                    }
                }}>
                <div className="table-modal-title-box mb2">
                    <div>
                        <div className="table-modal-title-pricing">
                            <div style={{ fontSize: "21px" }}>
                                Create notification
                            </div>
                        </div>
                    </div>
                </div>

                <Box sx={{ width: '100%' }}>
                    <Stepper className="not-stepper-cont" activeStep={activeStep}>
                        {STEPS.map(label => {
                            const stepProps: { completed?: boolean; } = {};
                            const labelProps: {
                                optional?: React.ReactNode;
                            } = {};

                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel className="not-step-title-cont" {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === STEPS.length ? (
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button
                                className="table-modal-form-button"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Finish
                            </Button>
                        </Box>
                    ) : (
                        <React.Fragment>
                            {activeStep === 0 && <div id="step1" className="not-step-cont">
                                <FormControl>
                                    <FormLabel className="not-radio-modal-title bold" id="demo-controlled-radio-buttons-group">Select who will receive the notification:</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            className="not-radio-modal-title p0 m0"
                                            value="All sellers and users"
                                            control={
                                                <Radio
                                                    onChange={e => {
                                                        setData(d => ({ ...d, userGroup: '', seller: '' }));
                                                        setRecipient('all');
                                                    }}
                                                    checked={recipient === 'all'}
                                                />
                                            }
                                            label="All sellers and users"
                                        />

                                        <FormControlLabel
                                            className="not-radio-modal-title p0 m0"
                                            value="Partner group"
                                            control={
                                                <Radio
                                                    onChange={e => {
                                                        setData(d => ({ ...d, seller: '' }));
                                                        setRecipient('group');
                                                    }}
                                                    checked={recipient === 'group'}
                                                />
                                            }
                                            label="Partner group"
                                        />

                                        <FormControlLabel
                                            className="not-radio-modal-title p0 m0"
                                            value="Specific seller"
                                            control={
                                                <Radio
                                                    onChange={e => {
                                                        setData(d => ({ ...d, userGroup: '' }));
                                                        setRecipient('seller');
                                                    }}
                                                    checked={recipient === 'seller'}
                                                />
                                            }
                                            label="Specific seller"
                                        />
                                    </RadioGroup>
                                </FormControl>

                                {recipient === 'group' && (
                                    <div className="with100 align-start justify-center in-column">
                                        <span className="bold">Partner Group:</span>
                                        <select
                                            style={{ width: "300px" }}
                                            className="main-select-bi"
                                            value={data.userGroup}
                                            onChange={e => setData(d => ({ ...d, userGroup: e.target.value }))}
                                        >
                                            <option className="compa-option" value="">
                                                Select group
                                            </option>

                                            {groups.data?.map(g => (
                                                <option className="compa-option" value={g._id}>
                                                    {g.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                {recipient === 'seller' && (
                                    <div className="with100 align-start justify-center in-column">
                                        <span className="bold">Select sellers:</span>
                                        <select
                                            style={{ width: "300px" }}
                                            className="main-select-bi"
                                            value={data.seller}
                                            onChange={e => setData(d => ({ ...d, seller: e.target.value }))}
                                        >
                                            <option className="compa-option" value="">
                                                Select seller
                                            </option>

                                            {sellers.data?.map((s: any) => (
                                                <option className="compa-option" value={s._id}>
                                                    {s.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>}

                            {activeStep === 1 && <div id="step2" className="not-step-cont">
                                <div className="with100 align-start justify-center in-column">
                                    <span className="bold">Select notification views:</span>
                                    <div className="not-checkbox-cont">
                                        <FormControlLabel className="not-checkbox-box" control={<Checkbox onChange={e => setData(d => ({ ...d, alertType: { ...d.alertType, platform: e.target.checked } }))} checked={data.alertType.platform} />} label="Platform" />
                                        <FormControlLabel className="not-checkbox-box" control={<Checkbox onChange={e => setData(d => ({ ...d, alertType: { ...d.alertType, push: e.target.checked } }))} checked={data.alertType.push} />} label="Push" />
                                        <FormControlLabel className="not-checkbox-box" control={<Checkbox onChange={e => setData(d => ({ ...d, alertType: { ...d.alertType, email: e.target.checked } }))} checked={data.alertType.email} />} label="Email" />
                                        <FormControlLabel className="not-checkbox-box" control={<Checkbox onChange={e => setData(d => ({ ...d, alertType: { ...d.alertType, sms: e.target.checked } }))} checked={data.alertType.sms} />} label="SMS" />
                                        <FormControlLabel className="not-checkbox-box" control={<Checkbox onChange={e => setData(d => ({ ...d, alertType: { ...d.alertType, topbar: e.target.checked } }))} checked={data.alertType.topbar} />} label="Topbar" />
                                        <FormControlLabel className="not-checkbox-box" control={<Checkbox onChange={e => setData(d => ({ ...d, alertType: { ...d.alertType, popup: e.target.checked } }))} checked={data.alertType.popup} />} label="Pop-up" />
                                    </div>

                                    {data.alertType.topbar && (
                                        <>
                                            <FormControlLabel className="not-radio-modal-title p0 m0" value="Closable" control={<Radio checked={data.isClosable} onChange={() => setData(d => ({ ...d, isClosable: true }))} />} label="Closable" />
                                            <FormControlLabel className="not-radio-modal-title p0 m0" value="Fixed" control={<Radio checked={!data.isClosable} onChange={() => setData(d => ({ ...d, isClosable: false }))} />} label="Fixed" />

                                            <div style={{ width: 200 }}>
                                                <DatePicker
                                                    showTimeInput
                                                    selected={data.relevantUntil as any}
                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                    onChange={(date: any) => {
                                                        setData(p => ({ ...p, relevantUntil: Date.parse(date) }));
                                                    }}
                                                    customInput={<ExampleCustomInput />}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>}

                            {activeStep === 2 && <div id="step3" className="not-step-cont">
                                <div className="width100 align-start justify-center in-column">
                                    <span className="bold">Notification line text (for platform notification, push, SMS, topbar):</span>
                                    <input
                                        style={{ minWidth: "300px", width: "100%", height: "60px" }}
                                        className="logistic-modal-input"
                                        value={data.lineText}
                                        onChange={e => setData(d => ({ ...d, lineText: e.target.value }))}
                                        required
                                    />
                                </div>
                                <div className="width100 align-start justify-center in-column">
                                    <span className="bold">Title (for pop-up and email subject):</span>
                                    <input
                                        style={{ minWidth: "300px", width: "100%" }}
                                        className="logistic-modal-input"
                                        value={data.title}
                                        onChange={e => setData(d => ({ ...d, title: e.target.value }))}
                                        required
                                    />
                                </div>
                                <div className="width100 align-start justify-center in-column">
                                    <span className="bold">Notification details text:</span>
                                    <input
                                        style={{ minWidth: "300px", width: "100%", height: "60px" }}
                                        className="logistic-modal-input"
                                        value={data.data}
                                        onChange={e => setData(d => ({ ...d, data: e.target.value }))}
                                        required
                                    />
                                </div>
                                <div className="width100 align-start justify-center in-column">
                                    <span className="bold">Notification URL:</span>
                                    <input
                                        style={{ minWidth: "300px", width: "100%" }}
                                        className="logistic-modal-input"
                                        value={data.url}
                                        onChange={e => setData(d => ({ ...d, url: e.target.value }))}
                                    />
                                </div>
                            </div>}

                            <div className="table-modal-border"></div>

                            <Box sx={{ display: 'flex', width: "100%", flexDirection: 'row', pt: 2 }}>
                                <button
                                    onClick={() => setOpen(false)}
                                    style={{ textDecoration: "none", color: "#55718A" }}
                                    className="table-modal-form-button-cancel bold"
                                >
                                    Cancel
                                </button>
                                <div className="width100 in-row justify-end align-center">
                                    <Button style={{ textDecoration: "none", color: "#55718A", textTransform: "initial" }}
                                        className="table-modal-form-button-cancel bold"
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>

                                    {activeStep === STEPS.length - 1 ? (
                                        <Button className="table-modal-form-button" type="submit">
                                            Finish
                                        </Button>
                                    ) : (
                                        <Button className="table-modal-form-button" onClick={handleNext}>
                                            Next
                                        </Button>
                                    )}
                                </div>
                            </Box>
                        </React.Fragment>
                    )}
                </Box>
            </form>
        </Modal>
    );
};

const NotificationItem: React.FC<{ notification: Notification; onRead: () => {}; }> = ({ notification, onRead }) => {
    const [open, setOpen] = useState<boolean>(false);
    const dropdownRef = useClickOutside(() => {
        if (open) {
            setOpen(false);
        }
    });

    const inner = useMemo(
        () => (
            <>
                <img src={getNotificationImage(notification.type)} alt="" />

                <div className="not-table-cont">
                    <span className="">{notification.title}</span>
                    <span className="">{notification.data}</span>
                    <span style={{ fontSize: "12px" }} className="opacity5 bold">{DateTime.fromISO(notification.created as any).toFormat("dd/LL/yyyy 'at' HH:mm")}</span>

                    <RoleRequired role={Role.admin}>
                        {/* @ts-ignore */}
                        {notification.user.username && <span style={{ fontSize: "12px" }} className="opacity5 bold">Seller name: {notification.user.username}</span>}
                    </RoleRequired>
                </div>
            </>
        ), [notification]
    );

    return (
        <tr className="table-result-box border0 m0 height100px width100">
            <td className="not-notifications">
                {notification.url && isURL(notification.url) ? (
                    <a href={notification.url} rel="noreferrer" target="_blank">
                        {inner}
                    </a>
                ) : inner}
            </td>

            <td className="not-pl-mkpl justify-end">
                <div className="app-drop-down-container" ref={dropdownRef}>
                    <button onClick={(e) => setOpen(p => !p)}><img className="pointer not-dotts-btn ml2" src="/icons/menu-settings-dots.svg" alt="" /></button>
                    {open && (
                        <ul style={{ marginLeft: "-150px" }}>
                            <li onClick={async () => {
                                try {
                                    await readNotification(notification._id);
                                    onRead();
                                } catch (error) {
                                    console.error();
                                }
                            }} className="in-row align-center not-menuitem-cont">
                                <img src="/icons/not-check-dotts.svg" alt="" />
                                <span className="not-menuitem-text">Mark as read</span>
                            </li>

                            <NavLink to="/notifications-settings">
                                <li className="in-row align-center not-menuitem-cont">
                                    <img src="/icons/not-set-dotts.svg" alt="" />
                                    <span className="not-menuitem-text">Disable this type of notification</span>
                                </li>
                            </NavLink>
                        </ul>
                    )}
                </div>

                <div style={(notification.wasRead ? { background: 'transparent' } : {})} className="not-orange-dott ml4"></div>
            </td>
        </tr>
    );
};

export function getNotificationImage(type: Type) {
    switch (type) {
        case Type.BUYBOX: {
            return "/icons/not-buybox-grey.svg";
        }

        case Type.IMPORTED_PRODUCT: {
            return "/icons/not-imported.svg";
        }

        case Type.INTEGRATED_PRODUCT: {
            return "/icons/not-integrated.svg";
        }

        case Type.ORDERS: {
            return "/icons/not-order.svg";
        }

        case Type.PRICE_AND_STOCK_UPDATES: {
            return "/icons/not-price.svg";
        }

        case Type.RANKING_ZEOOS: {
            return "/icons/nor-rank-grey.svg";
        }


        case Type.DEFAULT_NOTIFICATION:
        default: {
            return "/icons/not-zeoos.svg";
        }

    }
}
