import React from "react";
import { NavLink } from "react-router-dom";

export default function MappingsNav() {
	return (
		<div className="table-title-nav-cont">
			<NavLink
				style={{
					padding: "10px 10px 24px",
				}}
				to="/freight"
				className="table-tab-mapping"
				activeClassName="table-tab-mapping-active"
			>
				Freight Mapping
			</NavLink>
			<NavLink
				style={{ padding: "10px 10px 24px" }}
				to="/tax"
				className="table-tab-mapping"
				activeClassName="table-tab-mapping-active"
			>
				Tax Mapping
			</NavLink>
			<NavLink
				style={{ padding: "10px 10px 24px" }}
				to="/platformRates"
				className="table-tab-mapping"
				activeClassName="table-tab-mapping-active"
			>
				Platform Rates
			</NavLink>
			{/* <NavLink to="/vendor" className="table-tab-mapping">
                      Vendor Mapping
                    </NavLink> */}
		</div>
	);
}
