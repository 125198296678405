import React, { memo, useCallback, useMemo, useState } from "react";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import CreateEditWarehouse from "./CreateEditWarehouse";
import {
	ILogisticServices,
	ILogisticWarehouse,
} from "types";
import ServicesTable from "./ServicesTable";
import { Modal } from "../../../ui/modal/Modal";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import * as LogisticController from "../../../../actions/logistic";
import Preloader from "../../../ui/preloader/Preloader";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";

interface Props {
	warehouseId: string;
	setWarehouseId: (warehouse: React.SetStateAction<string>) => void;
}

const SingleWarehouse: React.FC<Props> = ({ warehouseId, setWarehouseId }) => {
	const [over, setOver] = useState<boolean>(false);
	const [editModal, setEditModal] = useState<boolean>(false);
	const [addModal, setAddModal] = useState<boolean>(false);
	const [serviceType, setServiceType] = useState<string>("");

	const rqKeyWarehouse = useMemo(() => {
		return `/logistic-warehouse/${warehouseId}`;
	}, [warehouseId]);

	const { isLoading, data: warehouse } = useQuery(rqKeyWarehouse, () =>
		LogisticController.getWarehouse(warehouseId)
	);

	if (isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont">
					<div
						className="s-warehouse-nav pointer"
						onClick={() => setWarehouseId("")}
					>
						<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
						<div className="table-subtext underline pl2">Back to Logistic</div>
					</div>
					<div className="s-warehouse-info mb3">
						<div className="in-row align-center justify-start">
							<img
								className="mr6"
								height="60px"
								src={warehouse.logisticPartner.logo}
								alt=""
							/>
							<div>
								<div className="uppercase-text ft2">
									{warehouse.logisticPartner.partnerName}
								</div>
								<div className="bold ft3">{warehouse.warehouseName}</div>
								<div className="in-row align-center justify-start mt1">
									<CountryFlag
										country={warehouse.country}
										className="table-search-image"
										width="32px"
									/>
									<span className="ft2">{warehouse.country}</span>
								</div>
							</div>
						</div>
						<img
							onMouseOver={() => setOver(true)}
							onMouseOut={() => setOver(false)}
							onClick={() => setEditModal(true)}
							className="pointer"
							width="20px"
							height="20px"
							src={
								!over ? "/icons/edit-modal.svg" : "/icons/edit-modal-hover.svg"
							}
							alt=""
						/>
					</div>
					<ServicesTable
						title={"Fulfilment costs"}
						btnText={"fulfilment cost"}
						array={warehouse.fulfillmentCosts}
						handleClick={(e: React.MouseEvent) => {
							e.preventDefault();

							setServiceType("fulfilment");
							setAddModal(true);
						}}
					/>
					<ServicesTable
						title={"Other services"}
						btnText={"other service"}
						array={warehouse.otherServices}
						handleClick={(e: React.MouseEvent) => {
							e.preventDefault();

							setServiceType("other");
							setAddModal(true);
						}}
					/>
				</div>
			</div>
			{editModal && (
				<CreateEditWarehouse
					warehouse={warehouse}
					modal={editModal}
					setModal={setEditModal}
				/>
			)}
			{addModal && (
				<AddServiceModal
					modal={addModal}
					setModal={setAddModal}
					warehouse={warehouse}
					serviceType={serviceType}
					rqKeyWarehouse={rqKeyWarehouse}
				/>
			)}
		</div>
	);
};

interface IAddServiceProps {
	modal: boolean;
	setModal: (value: React.SetStateAction<boolean>) => void;
	warehouse: ILogisticWarehouse;
	serviceType: string;
	rqKeyWarehouse: string;
}

const AddServiceModal: React.FC<IAddServiceProps> = ({
	modal,
	setModal,
	warehouse,
	serviceType,
	rqKeyWarehouse,
}) => {
	const [service, setService] = useState<string>("");
	const [amount, setAmount] = useState<string>("");
	const [unit, setUnit] = useState<string>("SKU");
	const [note, setNote] = useState<string>("");

	const refetchWarehouseConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyWarehouse);
			toast.success(`The service has successfully added!`);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const editMutation = useMutation(
		(data: ILogisticWarehouse) => LogisticController.updateWarehouse(data),
		refetchWarehouseConfig
	);

	const newService = useMemo(
		() => ({
			service,
			amount,
			unit,
			note,
		}),
		[service, amount, unit, note]
	) as ILogisticServices;

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			if (serviceType === "fulfilment") {
				await editMutation.mutateAsync({
					...warehouse,
					fulfillmentCosts: [...warehouse.fulfillmentCosts, newService],
				});
			} else {
				await editMutation.mutateAsync({
					...warehouse,
					otherServices: [...warehouse.otherServices, newService],
				});
			}

			setModal(false);
		},
		[setModal, editMutation, newService, serviceType, warehouse]
	);

	const onModalClose = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			setModal(false);
		},
		[setModal]
	);

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="service-modal-cont">
				<div className="table-modal-title-box mt3">
					<div className="warehouse-modal-title-text">New Service</div>
				</div>
				<div className="table-modal-border"></div>
				<form className="table-modal-form-cont text-start" onSubmit={onSubmit}>
					<div className="width100 in-column align-start">
						<div className="width100">
							<div>Service Name:</div>
							<input
								className="logistic-modal-input"
								value={service}
								onChange={(e) => setService(e.target.value)}
								required
							/>
						</div>
						<div className="width100 in-row align-center justify-between">
							<div className="width30">
								<div>Amount (€):</div>
								<input
									className="logistic-modal-input"
									type="number"
									step="0.01"
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									required
								/>
							</div>
							<div className="width70">
								<div>Fee unit:</div>
								<select
									className="warehouse-modal-select"
									value={unit}
									onChange={(e) => {
										setUnit(e.target.value);
									}}
								>
									{["SKU", "EAN", "GTIN", "UPC"].map((u: string, index: number) => (
										<option key={index}>{u}</option>
									))}
								</select>
							</div>
						</div>
						<div className="width100">
							<div>Note:</div>
							<textarea
								className="service-modal-textarea"
								value={note}
								onChange={(e) => setNote(e.target.value)}
							/>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button className="table-modal-form-button" type="submit">
							Create
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(SingleWarehouse);
