import React, { useState, useEffect, memo } from "react";
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
import Login from "./routes/login/Login";
import Esqueci from "./routes/login/Esqueci";
import Reset from "./routes/login/Reset";
import {
	createPermission,
	PermissionScope,
	PermissionType,
} from "../actions/user";
import { CountriesContext } from "./context";
import SingleMarketplace from "./routes/single-marketplace/SingleMarketplace";
import AccessDenied from "./routes/accessdenied/AccessDenied";
import WrapBI from "./routes/business-intelligence/BusinessIntelligence";
import UsersManagement, {
	Role,
} from "./routes/users-management/UsersManagement";
import Reviews from "./routes/reviews/Reviews";
import Tax from "./routes/mappings/tax/Tax";
import Freight from "./routes/mappings/freight/Freight";
import PlatformRates from "./routes/mappings/platform-rates/PlatformRates";
import Billing from "./routes/billing/Billing";
import Translator from "./routes/translator/Translator";
import Profile from "./routes/profile/Profile";
import Gestao from "./routes/tables/gestao/Gestao";
import Comparador from "./routes/tables/comparador/Comparador";
import Acompanhamento from "./routes/tables/acompanhamento/Acompanhamento";
import PermissionRoute from "./utils/permission-route/PermissionRoute";
import CssBaseline from "@mui/material/CssBaseline";
import ToastMessage from "./ui/toast-message/ToastMessage";
import PanelBar from "./ui/panel-bar/PanelBar";
import "./app.css";
import Pim from "./routes/pim/Pim";
import { Requests } from "./ui/header/requests/Requests";
import { MarketplacesList } from "./routes/marketplaces/MarketplacesList";
import Preloader from "./ui/preloader/Preloader";
import VendorsManagement from "./routes/vendors-management/VendorsManagement";
import PimCreateScheme from "./routes/pim/scheme/CreateScheme";
import PimScheme from "./routes/pim/scheme/EditScheme";
import {
	CountryManagement,
	ICountryM,
} from "./routes/country-management/CountryManagement";
import { getCountries } from "../actions/countryManagement";
import { FileManagement } from "./routes/file-management/FileManagement";
import Vendors from "./routes/vendors/Vendors";
import JobsPanel from "./routes/sync/jobs-panel/JobsPanel";
import Queue from "./routes/sync/queue/Queue";
import SingleOrder from "./routes/tables/acompanhamento/singleorder/SingleOrder";
import BatchList from "./routes/sync/batch-list/BatchList";
import SingleBatch from "./routes/sync/batch-list/single-batch/SingleBatch";
import { PimMain } from "./routes/pim/PimMain";
import { QueryClient } from "react-query";
// import DigitalAssets from "./routes/digital-assets/DigitalAssets";
import Intelligence from "./routes/intelligence/Intelligence";
import PasswordManagement from "./routes/password-management/PasswordManagement";
import MFALogin from "./routes/login/MFALogin";
import PIMTranslation from "./routes/pim-translation/PIMTranslation";
import { RequestsNoties } from "./routes/requests-notifications/RequestsNoties";
import HistoryLog from "./routes/history-log/HistoryLog";
import SingleOrderView from "./routes/tables/acompanhamento/singleorder/singleorderview/SingleOrderView";
import SingleOrderPurchase from "./routes/tables/acompanhamento/singleorder/singleorderpurchase/SingleOrderPurchase";
import ProductAttrubutes from "./routes/single-marketplace/ProductAttributes";
import StatusOrder from "./routes/single-marketplace/StatusOrder";
import MatchFields from "./routes/single-marketplace/MatchFields";
import BatchProducts from "./routes/tables/gestao/BatchProducts";
import Rules from "./routes/single-marketplace/Rules";
import Pricing from "./routes/pricing/Pricing";
import PricingEvent from "./routes/pricing/planned-events-and-standing-edit/PricingEvent";
import SellerProduct from "./routes/vendors-management/seller-products/SellerProduct";
import Logistic from "./routes/logistic/Logistic";
import IntelligenceProduct from "./routes/intelligence/product/IntelligenceProduct";
import IntelligenceOverview from "./routes/intelligence/overview/IntelligenceOverview";
import IntelligenceSettings from "./routes/intelligence/settings/IntelligenceSettings";
import IntelligenceSettingsMatch from "./routes/intelligence/settings/IntelligenceSettingsMatch";
import IntelligenceSettingsSingle from "./routes/intelligence/settings/IntelligenceSettingsSingle";
import SetupAccount from "./routes/login/SetupAccount";
import axios from "axios";
import Onboarding from "./routes/onboarding/Onboarding";
import SellersOnboarding from "./routes/vendors-management/onboarding/Onboarding";
import useUser from "../hooks/useUser";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import UserPreview from "./routes/user-preview";
import Notifications from "./routes/notifications/Notifications";
import SingleNotification from "./routes/notifications/single-notification/SingleNotification";
import NotificationsSettings from "./routes/notifications/notifications-settings/NotificationsSettings";
import Tickets from "./routes/tickets/Tickets";
import SingleTicket from "./routes/tickets/single-ticket/SingleTicket";
import NotificationAlerts from "./ui/notification-alerts";
import SellerInfo from "./routes/seller-info/SellerInfo";

axios.defaults.baseURL =
	process.env.REACT_APP_API_URL || "https://api.zeoos.com/api";

console.debug(axios.defaults.baseURL);
export const queryClient = new QueryClient();

const navOpenAtom = atomWithStorage('navOpenAtom', true);

export default memo(function App() {
	const [loading, setLoading] = useState(true);

	const [countries, setCountries] = useState<ICountryM[]>([]);
	const [open, setOpen] = useAtom(navOpenAtom);

	const history = useHistory();

	const { user, isLoading } = useUser();

	useEffect(() => {
		async function initializeState() {
			try {
				// const { user } = await refreshTokens();
				// setUser(user);
				setCountries(await getCountries());
			} catch (error) {
				console.error(error);
			}
			setLoading(false);
		}

		initializeState();
	}, [history, user]);

	return (
		<>
			{(loading || isLoading) ? (
				<div className="center-loader">
					<Preloader />
				</div>
			) : (
				<BrowserRouter>
					<CountriesContext.Provider value={countries}>
						<ToastMessage />
						{user ? (
							<div className="root">
								{user.isOnboardingFinished && (user?.seller ? user?.seller?.onBoardingPriceStock : true) ? (
									<>
										<CssBaseline />
										<NotificationAlerts />

										<PanelBar setOpen={setOpen} open={open} />

										<main className={open ? "content-shift" : "content"}>
											<div className="drawer-header" />
											<Switch>
												<Route path="/login" exact>
													<Login />
												</Route>

												{/* Platform Routes */}
												<PermissionRoute
													path="/platforms"
													roles={[Role.sellerAdmin, Role.admin]}
													exact
												>
													<MarketplacesList />
												</PermissionRoute>
												<PermissionRoute
													path="/platform/:zeoosName"
													permission={createPermission(
														PermissionScope.platforms,
														PermissionType.read
													)}
													exact
												>
													<SingleMarketplace />
												</PermissionRoute>

												{/* Pim Routes */}
												<PermissionRoute
													path="/productInfo/:sku"
													permission={createPermission(
														PermissionScope.pim,
														PermissionType.read
													)}
													exact
												>
													<Pim />
												</PermissionRoute>
												<PermissionRoute
													path="/products"
													exact
													permission={createPermission(
														PermissionScope.pim,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
												>
													<PimMain />
												</PermissionRoute>
												{/* <PermissionRoute
                        path="/pim-management"
                        permission={createPermission(
                          PermissionScope.pim,
                          PermissionType.read
                        )}
                        exact
                      >
                        <PimManagement />
                      </PermissionRoute> */}
												<PermissionRoute
													path="/pim/scheme"
													permission={createPermission(
														PermissionScope.pim,
														PermissionType.read
													)}
													exact
												>
													<PimCreateScheme />
												</PermissionRoute>
												<PermissionRoute
													path="/pim/scheme/:name"
													permission={createPermission(
														PermissionScope.pim,
														PermissionType.read
													)}
													exact
												>
													<PimScheme />
												</PermissionRoute>

												{/* Digital Assets Routes */}
												{/* <PermissionRoute
                        path="/digital-assets"
                        permission={createPermission(
                          PermissionScope.digital_assets,
                          PermissionType.read
                        )}
                        exact
                      >
                        <DigitalAssets />
                      </PermissionRoute> */}

												{/* Password Management */}
												<PermissionRoute path="/password-management" exact>
													<PasswordManagement />
												</PermissionRoute>

												{/* BI Routes */}
												<PermissionRoute
													path={["/", "/dashboard"]}
													permission={createPermission(
														PermissionScope.dashboard,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
													exact
												>
													<WrapBI />
												</PermissionRoute>
												{/* Notifications */}
												<Route
													path={["/notifications"]}
													exact
												>
													<Notifications />
												</Route>
												{/* Seller Info */}
										<Route
											path={["/seller-information"]}
											exact
										>
											<SellerInfo />
										</Route>
												<Route
													path={["/single-notification"]}
													exact
												>
													<SingleNotification />
												</Route>
												<Route
													path={["/notifications-settings"]}
													exact
												>
													<NotificationsSettings />
												</Route>
												{/* Tickets */}
												<Route
													path={["/tickets"]}
													exact
												>
													<Tickets />
												</Route>
												<Route
													path={"/ticket/:id"}
													exact
												>
													<SingleTicket />
												</Route>

												{/* Settings Routes */}
												<PermissionRoute
													path="/users-management"
													// permission={createPermission(
													// 	PermissionScope.settings,
													// 	PermissionType.read
													// )}
													roles={[Role.admin]}
													exact
												>
													<UsersManagement />
												</PermissionRoute>
												<PermissionRoute
													path="/history-log"
													// permission={createPermission(
													// 	PermissionScope.settings,
													// 	PermissionType.read
													// )}
													roles={[Role.admin]}
													exact
												>
													<HistoryLog />
												</PermissionRoute>
												<PermissionRoute
													path="/reviews"
													permission={createPermission(
														PermissionScope.settings,
														PermissionType.read
													)}
													exact
												>
													<Reviews />
												</PermissionRoute>
												<PermissionRoute
													path="/pim-translation"
													permission={createPermission(
														PermissionScope.settings,
														PermissionType.read
													)}
													exact
												>
													<PIMTranslation />
												</PermissionRoute>
												<PermissionRoute
													path="/translator"
													permission={createPermission(
														PermissionScope.settings,
														PermissionType.read
													)}
													exact
												>
													<Translator />
												</PermissionRoute>
												<PermissionRoute
													path="/requests"
													permission={createPermission(
														PermissionScope.settings,
														PermissionType.read
													)}
													exact
												>
													<Requests />
												</PermissionRoute>
												<PermissionRoute
													path="/requests-noties"
													permission={createPermission(
														PermissionScope.settings,
														PermissionType.read
													)}
													exact
												>
													<RequestsNoties />
												</PermissionRoute>
												<PermissionRoute
													path="/country-management"
													permission={createPermission(
														PermissionScope.settings,
														PermissionType.read
													)}
													exact
												>
													<CountryManagement />
												</PermissionRoute>
												<PermissionRoute
													path="/file-management"
													permission={createPermission(
														PermissionScope.settings,
														PermissionType.read
													)}
													exact
												>
													<FileManagement />
												</PermissionRoute>

												<PermissionRoute path="/profile" exact>
													<Profile />
												</PermissionRoute>

												{/* Inventory Routes */}
												<PermissionRoute
													path="/offers"
													permission={createPermission(
														PermissionScope.inventory,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
													exact
												>
													<Gestao />
												</PermissionRoute>

												<PermissionRoute path="/comparador" exact>
													<Comparador />
												</PermissionRoute>

												{/* Order Routes */}
												<PermissionRoute
													path="/orders"
													permission={createPermission(
														PermissionScope.orders,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
													exact
												>
													<Acompanhamento />
												</PermissionRoute>
												{/* SingleOrder Routes */}
												<PermissionRoute
													path="/orders/:id"
													permission={createPermission(
														PermissionScope.orders,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
													exact
												>
													<SingleOrder />
												</PermissionRoute>
												{/* SingleOrderView Routes */}
												<PermissionRoute
													path="/orders/:order/:shipping"
													permission={createPermission(
														PermissionScope.orders,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
													exact
												>
													<SingleOrderView />
												</PermissionRoute>
												{/* SingleOrderView Routes */}
												<PermissionRoute
													path="/orders/:order/:shipping/purchase"
													permission={createPermission(
														PermissionScope.orders,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
													exact
												>
													<SingleOrderPurchase />
												</PermissionRoute>
												<PermissionRoute
													path="/pricing"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<Pricing />
												</PermissionRoute>
												<PermissionRoute
													path="/pricing-event/:id"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<PricingEvent />
												</PermissionRoute>

												{/* Mappings Routes */}
												<PermissionRoute
													path="/tax"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<Tax />
												</PermissionRoute>
												<PermissionRoute
													path="/freight"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<Freight />
												</PermissionRoute>
												<PermissionRoute
													path="/platformRates"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<PlatformRates />
												</PermissionRoute>
												<PermissionRoute
													path="/vendor"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<Vendors />
												</PermissionRoute>
												<PermissionRoute
													path="/product-attributes/:zeoosName"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<ProductAttrubutes />
												</PermissionRoute>
												<PermissionRoute
													path="/status-order/:zeoosName"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<StatusOrder />
												</PermissionRoute>
												<PermissionRoute
													path="/match-fields/:categoryId/:zeoosName"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<MatchFields />
												</PermissionRoute>
												<PermissionRoute
													path="/rules/:zeoosName"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<Rules />
												</PermissionRoute>
												<PermissionRoute
													path="/batch-products"
													permission={createPermission(
														PermissionScope.mappings,
														PermissionType.read
													)}
													exact
												>
													<BatchProducts />
												</PermissionRoute>
												{/* Syncs Routes */}
												<PermissionRoute
													path="/jobs-panel"
													permission={createPermission(
														PermissionScope.syncs,
														PermissionType.read
													)}
													exact
												>
													<JobsPanel />
												</PermissionRoute>
												<PermissionRoute
													path="/queue"
													permission={createPermission(
														PermissionScope.syncs,
														PermissionType.read
													)}
													exact
												>
													<Queue />
												</PermissionRoute>

												{/* Syncs Routes */}
												<PermissionRoute
													path="/jobs-panel"
													permission={createPermission(
														PermissionScope.syncs,
														PermissionType.read
													)}
													exact
												>
													<JobsPanel />
												</PermissionRoute>
												<PermissionRoute
													path="/queue"
													permission={createPermission(
														PermissionScope.syncs,
														PermissionType.read
													)}
													exact
												>
													<Queue />
												</PermissionRoute>
												<PermissionRoute
													path="/batch-list"
													permission={createPermission(
														PermissionScope.syncs,
														PermissionType.read
													)}
													exact
												>
													<BatchList />
												</PermissionRoute>
												<PermissionRoute
													path="/batch/:id"
													permission={createPermission(
														PermissionScope.syncs,
														PermissionType.read
													)}
													exact
												>
													<SingleBatch />
												</PermissionRoute>

												{/* Logistic Routes */}
												<PermissionRoute
													path="/logistic"
													permission={createPermission(
														PermissionScope.logistic,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
													exact
												>
													<Logistic />
												</PermissionRoute>

												{/* Billing Routes */}
												<PermissionRoute
													path="/payouts"
													permission={createPermission(
														PermissionScope.billings,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
													exact
												>
													<Billing />
												</PermissionRoute>

												{/* Intelligence Routes */}
												<PermissionRoute
													path="/intelligence"
													permission={createPermission(
														PermissionScope.intelligence,
														PermissionType.read
													)}
													exact
												>
													<Intelligence />
												</PermissionRoute>
												<PermissionRoute
													path="/intelligence/products"
													roles={[Role.admin]}
													exact
												>
													<IntelligenceProduct />
												</PermissionRoute>
												<PermissionRoute
													path="/intelligence/overview"
													roles={[Role.admin]}
													exact
												>
													<IntelligenceOverview />
												</PermissionRoute>
												<PermissionRoute
													path="/intelligence/settings"
													roles={[Role.admin]}
													exact
												>
													<IntelligenceSettings />
												</PermissionRoute>
												<PermissionRoute
													path="/intelligence/robot/:id"
													roles={[Role.admin]}
													exact
												>
													<IntelligenceSettingsSingle />
												</PermissionRoute>
												<PermissionRoute
													path="/intelligence/settings-match"
													roles={[Role.admin]}
													exact
												>
													<IntelligenceSettingsMatch />
												</PermissionRoute>

												{/* Vendors Routes */}
												<PermissionRoute
													path="/sellers"
													roles={[Role.admin, Role.sellerAdmin]}
													exact
												>
													<VendorsManagement />
												</PermissionRoute>
												<PermissionRoute
													path="/sellers-onboarding"
													roles={[Role.admin, Role.sellerAdmin]}
													exact
												>
													<SellersOnboarding />
												</PermissionRoute>
												<PermissionRoute
													path="/sellers/:id/products"
													permission={createPermission(
														PermissionScope.vendors,
														PermissionType.read
													)}
													exact
												>
													<SellerProduct />
												</PermissionRoute>

												<Route path="/user/preview" exact>
													<UserPreview />
												</Route>

												<Route
													path="/permission-denied"
													exact
													component={AccessDenied}
												/>
											</Switch>

										</main>
									</>
								) : (
									<Onboarding />
								)}
							</div>
						) : (
							<Switch>
								<Route path="/login/esqueci" exact>
									<Esqueci />
								</Route>
								<Route path="/login/resetpass/:token" exact>
									<Reset />
								</Route>
								<Route path="/login/mfa" exact>
									<MFALogin />
								</Route>

								<Route path="/setup/:token" exact>
									<SetupAccount />
								</Route>

								<Route path="/user/preview" exact>
									<UserPreview />
								</Route>

								<Route path="*">
									<Login />
								</Route>
							</Switch>
						)}
					</CountriesContext.Provider>
				</BrowserRouter>
			)}
		</>
	);
});
