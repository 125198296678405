import React, { useState } from "react";
import "./users-management.css";
import * as UserController from "../../../actions/user";
import { Modal } from "../../ui/modal/Modal";
import { toast } from "react-toastify";
import { queryClient } from "../../App";
import { useMutation } from "react-query";
import { IUser } from "../../../interfaces";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
// import { AutocompleteVendors } from "./AutocompleteVendors";
// import useUserGroups from "../../../hooks/useUserGroups";
// import useRoles from "../../../hooks/useRoles";
// import RoleRequired from "../../utils/role-required/RoleRequired";
// import { Select } from "@mui/material";
// import { Role } from "./UsersManagement";
// import { PermissionSelect } from "./CreateUser";

interface Props {
	mappedUser: IUser;
	vendorOptions: any;
	setModal: (modal: boolean) => void;
	modal: boolean;
	rqKey: string;
}

// Todo: combine EditUser and CreateUser into CreateEditUser for easier maintenance
export const EditUser: React.FC<Props> = ({
	mappedUser,
	// vendorOptions,
	setModal,
	modal,
	rqKey,
}) => {
	// const roles = useRoles();

	const [email, setEmail] = useState<string>(mappedUser.email);
	const [username, setUsername] = useState<string>(mappedUser.username);
	const [password, setPassword] = useState<string>("");
	const [hide, setHide] = useState(false);
	const [state, setState] = useState({
		checkedC: mappedUser.isActive,
	});
	// const [role, setRole] = useState<Role>(mappedUser.role);
	// const [vendorPermissions, setVendorPermissions] = useState<
	// 	IVendorPermission[]
	// >(mappedUser.vendorPermissions);
	// const [permissions, setPermissions] = useState<UserController.TPermission[]>(
	// 	mappedUser.permissions || []
	// );
	// const userGroups = useUserGroups();
	// const [userGroup, setUserGroup] = useState(mappedUser.group);

	const actionUserConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKey);
			toast.success(`The action is successfully done!`);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const deleteMutation = useMutation(
		() => UserController.deleteUser(mappedUser.username),
		actionUserConfig
	);

	const editMutation = useMutation(
		(toUpdate: IUser) =>
			UserController.updateUser(mappedUser._id, toUpdate),
		actionUserConfig
	);

	const onSubmitHandler = async (e: React.MouseEvent) => {
		e.preventDefault();

		const toUpdate = {
			...mappedUser,
			email,
			isActive: state.checkedC,
			username,
			// role,
			// vendorPermissions,
			// group: userGroup,
			// permissions: permissions.filter((p) =>
			// 	Object.values(UserController.PermissionType).includes(p.type)
			// ),
		} as IUser;

		if (password.length >= 8) {
			toUpdate.password = password;
		} else if (password.length > 0) {
			return toast.error("Password length should be more than 8 characters");
		}

		await editMutation.mutateAsync(toUpdate);
		setModal(false);
	};

	const deleteUserHandler = async (e: React.MouseEvent) => {
		e.preventDefault();

		if (window.confirm("Are you sure?")) {
			await deleteMutation.mutateAsync();
			setModal(false);
		}
	};

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};
	const toggleHide = () => {
		setHide(!hide);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<form className="formUser">
				<div className="formEdit__container-user">
					<div className="user-title-sticky">
						<div className="table-modal-title-box m0">
							<div className="mapping-modal-title-text">Edit User</div>
						</div>
						<div className="table-modal-border"></div>
					</div>
					<span>Email:</span>
					<input
						type="text"
						className="formEdit__input"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
					<div className="in-row align-start width100">
						<div className="mr2 width100">
							<span>Username:</span>
							<input
								type="text"
								className="formEdit__input"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								required
							/>
						</div>

						<div style={{ marginTop: "auto" }} className="width100">
							<div className="width100">
								<span
									onClick={toggleHide}
									style={{
										display: hide ? "none" : "block",
									}}
									className="underline pointer width100 mb4"
								>
									Create New Password
								</span>
							</div>
							<div
								className="width100"
								style={{
									display: hide ? "inline" : "none",
								}}
							>
								<span className="width100">Password:</span>
								<input
									type="password"
									className="formEdit__input width100"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
						</div>
					</div>

					{/* <div className="in-row align-start width100 justify-between mb4">
						<div className="mr2 width50">
							<span>Role</span>
							<select
								id="field-3"
								name="field-3"
								required
								className="main-select-bi width100"
								onChange={(e) => setRole(e.target.value as Role)}
								value={role}
							>
								{Object.keys(roles).map((key: string, index: number) => (
									<option
										className="option-field-user"
										value={roles[key]}
										key={index}
									>
										{roles[key]}
									</option>
								))}
							</select>
						</div>

						<RoleRequired role={roles.admin}>
							{[roles.sellerAdmin, roles.sellerUser].includes(role) && (
								<div className="width100">
									<span>User Group</span>
									<Select
										required
										onChange={(e) => setUserGroup(e.target.value)}
										value={userGroup}
									>
										{userGroups?.data?.map((ug) => (
											<option
												className="option-field-user"
												value={ug._id}
												key={ug._id}
											>
												{ug.name}
											</option>
										))}
									</Select>
								</div>
							)}
						</RoleRequired>

						{role === roles.user && (
							<div className="width50">
								<span>Vendor Permission:</span>
								<AutocompleteVendors
									vendorOptions={vendorOptions}
									setVendorPermissions={setVendorPermissions}
									vendorPermissions={vendorPermissions}
								/>
							</div>
						)}
					</div>
					<PermissionSelect
						role={role}
						permissions={permissions}
						setPermissions={setPermissions}
					/> */}

					<div className="in-row align-center width100 justify-start mt4">
						<div className="in-row align-center">
							<div className="switch-width">
								<AntSwitch
									checked={state.checkedC}
									onChange={handleChange}
									name="checkedC"
									required
								/>
							</div>
							<span className="email-text ml4">Active</span>
						</div>
						<button
							onClick={deleteUserHandler}
							className="userm-modal-button-cancel"
						>
							<img
								className="mr1"
								src="/icons/trash-bin.svg"
								alt=""
								width="18px;"
							></img>
							delete
						</button>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button
							onClick={onSubmitHandler}
							className="table-modal-form-button"
						>
							Save
						</button>
					</div>
					<div className="edit-buttons"></div>
				</div>
			</form>
		</Modal>
	);
};
