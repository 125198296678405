import React from "react";
import { NavLink } from "react-router-dom";

export default function InventoryNav() {
	return (
		<>
			<div className="in-row width100 align-center p1 mt2">
				<div className="table-title-nav-cont">
					<NavLink
						to="/inventory"
						className="int-tab-sync"
						activeClassName="int-tab-sync-active"
					>
						Offers
					</NavLink>
				</div>
			</div>
		</>
	);
}
