import React, { useState, useMemo } from "react";
import { Modal } from "../../../ui/modal/Modal";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import useUser from "../../../../hooks/useUser";
import { IProductGestao } from "../../../../interfaces";
import {
	allMarketplaces,
	getSellersMarketplace,
	integratedMarketplaces,
	isSelling,
	rankingZeoosWinner,
	stockTotal,
} from "./TableGestao";
import { convertPrice } from "./UpdateGestao";
import { Role } from "../../users-management/UsersManagement";

interface Props {
	product: IProductGestao;
	setModal1: (data: boolean) => void;
	setProduct: (item: any) => void;
	setMarketplace: (marketplace: any) => void;
	setSelectedWinner: (winner: any) => void;
	featured: number;
  setSelectedSeller: (seller: number) => void;
  setModalUpdate: (data: boolean) => void;
}

const GestaoGridModal: React.FC<Props> = ({
	product,
	setModal1,
	setProduct,
	setMarketplace,
	setSelectedWinner,
	featured,
  setSelectedSeller,
  setModalUpdate
}) => {
	const { user } = useUser();
	const [modal, setModal] = useState<boolean>(false);
	const [value, setValue] = React.useState("0");

	const isWortenUser = useMemo(
		() => user?.group?._id === "630c87437a3c0a000381f941",
		[user]
	);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const buyboxMarketplaces = (item: IProductGestao) => {
		let list: any[] = [];
		Object.keys(item?.marketplaces || {}).forEach((zeoosName: string) => {
			if (item?.marketplaces?.[zeoosName]?.buybox?.ranking === 1) {
				list.push({
					...item?.marketplaces?.[zeoosName],
					zeoosName,
				});
			}
		});
		return list;
	};

	const isAdmin = user.role === Role.admin ? true : false;
  const seller = isAdmin ? undefined : user.seller?.id; 
	const integrated = integratedMarketplaces(product);
	const buybox = buyboxMarketplaces(product);
	const all = allMarketplaces(product);

	return (
		<>
			<div
				onClick={() => {
					setModal(true);
				}}
				className="gestao-grid-modal-btn"
			>
				{integrated.length - featured <= 0
					? "no more platforms"
					: `+ ${integrated.length - featured} platform(s)`}
			</div>
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<>
						<div
							style={{ maxWidth: "460px" }}
							className="table-modal-form-cont p4"
						>
							<div className="table-modal-title-box width100 pb1 m0">
								<div className="inv-title-modal1-text">{product.name}</div>
							</div>
							<div className="in-row align-center justify-start width100">
								<div
									style={{ width: "120px", height: "120px" }}
									className="int-set-match-logo"
								>
									<img
										width="120px"
										height="120px"
										className="pb1"
										src={product.image}
										alt=""
									/>
								</div>
								<div className="ml4">
									<div className="in-row align-center ustify-start width100 gestao-title-text mb1">
										<b className="pr2">SKU: </b>
										{product.sku}
									</div>
									<div className="in-row align-center ustify-start width100 gestao-title-text mb1">
										<b className="pr2">EAN: </b>
										<div className="gestao-ean-text">{product.barcode}</div>
									</div>
									<div className="in-row align-center ustify-start width100 gestao-title-text mb1">
										<b className="pr2">Stock: </b>
										<div className="gestao-ean-text">{stockTotal(product)}</div>
									</div>
								</div>
							</div>
							<TabContext value={value}>
								<TabList
									className="inv-mod-tabs"
									style={{ borderBottom: "1px solid #E1E1E1" }}
									onChange={handleChange}
									aria-label="lab API tabs example"
								>
									<Tab
										style={{ maxWidth: "100px !important" }}
										className="ges-tab-sync ges-tab-sync-active ml1"
										label={`Integrated (${integrated.length})`}
										value="0"
									/>
									{/* <Tab className="ges-tab-sync ges-tab-sync-active ml1"
                                    label={`Buybox (${buybox.length})`} value="1" /> */}
									<Tab
										className="ges-tab-sync ges-tab-sync-active ml1"
										label={`All (${all.length})`}
										value="2"
									/>
								</TabList>
								<TabPanel className="m0 p0" value="0">
									<table
										style={{ fontSize: "14px" }}
										className="mapping-modal-table gestao-title-text"
									>
										<thead>
											<tr className="mapping-modal-results-title justify-between m0 pt2 pb2">
												<th
													style={{ flex: "0 1 35%", width: "120px" }}
													className="inv-modal1-table-seller"
												>
													Platforms
												</th>
												{isAdmin && (
													<th className="inv-modal1-table-column">Sellers</th>
												)}
												<th
													style={{ flex: "0 1 30%", width: "120px" }}
													className="inv-modal1-table-column"
												>
													Price
												</th>
												{!isAdmin && (
													<th className="inv-modal1-table-column">Ranking</th>
												)}
											</tr>
										</thead>
										<tbody>
											{integrated.map((m: any, index: number) => {
												const winner = rankingZeoosWinner(m, seller);
												const opacity = !isSelling(m) ? "opacity5" : "";
                        const deactivated = m.available === false ? true : false;
												return (
													<tr
														className="mapping-modal-result-box justify-between pt1 pb1 pointer mass-import-option"
														key={index}
														onClick={() => {
                              if (winner?.price) {
																setModal1(true);
															} else {
																setModalUpdate(true);
																setSelectedSeller(seller);
															}
															setProduct(product);
															setMarketplace(m);
															setSelectedWinner(winner);
														}}
													>
														<td
															style={{ flex: "0 1 35%", width: "120px" }}
															className="inv-modal1-table-seller"
															title={m.zeoosName}
														>
															{m.zeoosName}
														</td>
														{isAdmin && (
															<td className="inv-modal1-table-column">
																{getSellersMarketplace(m)}
															</td>
														)}
														<td
															style={{ flex: "0 1 30%", width: "120px" }}
															className={`inv-modal1-table-column text-end ${opacity}`}
														>
															{winner?.price 
                                ? <div className="blue">add</div>
                                : opacity
                                  ? (deactivated ? "deactivated" : "not selling")
                                  : `${convertPrice(winner?.price?.pvpFinal || 0)} €`
                              }
															{isAdmin && m.buybox?.ranking === 1 && (
																<img
																	className="ml2"
																	src="/icons/inv-modal1-rank.svg"
																	alt=""
																/>
															)}
														</td>
														{!isAdmin && (
															<td className="inv-modal1-table-column">
																<div className="gestao-cost-text align-center justify-start">
																	{winner.ranking === 1 ? (
																		<img
																			className="ml2"
																			src="/icons/ranking-modal-yellow.svg"
																			alt=""
																			title="1º on Zeoos"
																		/>
																	) : winner?.ranking ? (
																		`${winner.ranking}º`
																	) : (
																		""
																	)}
																	{m.buybox?.ranking === 1 ? (
																		<img
																			className="ml2"
																			src="/icons/inv-modal1-rank.svg"
																			alt=""
																		/>
																	) : (
																		""
																	)}
																</div>
															</td>
														)}
													</tr>
												);
											})}
										</tbody>
									</table>
								</TabPanel>
								<TabPanel className="m0 p0" value="1">
									<table
										style={{ fontSize: "14px" }}
										className="mapping-modal-table gestao-title-text"
									>
										<thead>
											<tr className="mapping-modal-results-title justify-between m0 pt2 pb2">
												<th
													style={{ flex: "0 1 35%", width: "120px" }}
													className="inv-modal1-table-seller"
												>
													Platforms
												</th>
												{isAdmin && (
													<th className="inv-modal1-table-column">Sellers</th>
												)}
												<th
													style={{ flex: "0 1 30%", width: "120px" }}
													className="inv-modal1-table-column"
												>
													Price
												</th>
												{!isAdmin && (
													<th className="inv-modal1-table-column">Ranking</th>
												)}
											</tr>
										</thead>
										<tbody>
											{buybox.map((m: any, index: number) => {
												const winner = rankingZeoosWinner(m, seller);
												return (
													<tr
														className="mapping-modal-result-box justify-between pt1 pb1 pointer mass-import-option"
														key={index}
														onClick={() => {
															setModal1(true);
															setProduct(product);
															setMarketplace(m);
															setSelectedWinner(winner);
														}}
													>
														<td
															style={{ flex: "0 1 35%", width: "120px" }}
															className="inv-modal1-table-seller"
															title={m.zeoosName}
														>
															{m.zeoosName}
														</td>
														{isAdmin && (
															<td className="inv-modal1-table-column">
																{getSellersMarketplace(m)}
															</td>
														)}
														<td
															style={{ flex: "0 1 30%", width: "120px" }}
															className="inv-modal1-table-column text-end"
														>
															{`${convertPrice(
																winner?.price?.pvpFinal || 0
															)} €`}
															{isAdmin && m.buybox?.ranking === 1 && (
																<img
																	className="ml2"
																	src="/icons/inv-modal1-rank.svg"
																	alt=""
																/>
															)}
														</td>
														{!isAdmin && (
															<td className="inv-modal1-table-column">
																<div className="gestao-cost-text align-center justify-start">
																	{winner.ranking === 1 ? (
																		<img
																			className="ml2"
																			src="/icons/ranking-modal-yellow.svg"
																			alt=""
																			title="1º on Zeoos"
																		/>
																	) : winner?.ranking ? (
																		`${winner.ranking}º`
																	) : (
																		""
																	)}
																	{m.buybox?.ranking === 1 ? (
																		<img
																			className="ml2"
																			src="/icons/inv-modal1-rank.svg"
																			alt=""
																		/>
																	) : (
																		""
																	)}
																</div>
															</td>
														)}
													</tr>
												);
											})}
										</tbody>
									</table>
								</TabPanel>
								<TabPanel className="m0 p0" value="2">
									<table
										style={{ fontSize: "14px" }}
										className="mapping-modal-table gestao-title-text"
									>
										<thead>
											<tr className="mapping-modal-results-title justify-between m0 pt2 pb2">
												<th
													style={{ flex: "0 1 35%", width: "120px" }}
													className="inv-modal1-table-seller"
												>
													Platforms
												</th>
												{isAdmin && (
													<th className="inv-modal1-table-column">Sellers</th>
												)}
												<th
													style={{ flex: "0 1 30%", width: "120px" }}
													className="inv-modal1-table-column"
												>
													Price
												</th>
												{!isAdmin && (
													<th className="inv-modal1-table-column">Ranking</th>
												)}
											</tr>
										</thead>
										<tbody>
											{all.map((m: any, index: number) => {
												const winner = rankingZeoosWinner(m, seller);
												const cssIntegrated = m.integrated
													? ""
													: "not-integrated";
												const opacity =
													!isSelling(m) || !m.integrated ? "opacity5" : "";
                        const deactivated = m.available === false ? true : false;
												return (
													<tr
														className={`mapping-modal-result-box justify-between pt1 pb1 pointer platform-list ${cssIntegrated}`}
														key={index}
														onClick={() => {
															setModal1(true);
															setProduct(product);
															setMarketplace(m);
															setSelectedWinner(winner);
														}}
													>
														<td
															style={{ flex: "0 1 35%", width: "120px" }}
															className="inv-modal1-table-seller"
															title={m.zeoosName}
														>
															{m.zeoosName}
														</td>
														{isAdmin && (
															<td className="inv-modal1-table-column">
																{getSellersMarketplace(m)}
															</td>
														)}
														<td
															style={{ flex: "0 1 30%", width: "120px" }}
															className={`inv-modal1-table-column text-end ${opacity}`}
														>
															{!cssIntegrated
																? isSelling(m)
																	? `${convertPrice(
																			winner?.price?.pvpFinal || 0
																	  )} €`
																	: (deactivated ? "deactivated" : "not selling")
																: "not integrated"}
															{m.buybox?.ranking === 1 ? (
																<img
																	className="ml2"
																	src="/icons/inv-modal1-rank.svg"
																	alt=""
																	title="Buybox"
																/>
															) : (
																""
															)}
														</td>
														{!isAdmin && (
															<td className="inv-modal1-table-column">
																<div className="gestao-cost-text align-center justify-start">
																	{winner.ranking === 1 ? (
																		!cssIntegrated ? (
																			<img
																				className="ml2"
																				src="/icons/ranking-modal-yellow.svg"
																				alt=""
																				title="1º on Zeoos"
																			/>
																		) : (
																			""
																		)
																	) : winner?.ranking ? (
																		`${winner.ranking}º`
																	) : (
																		""
																	)}
																	{m.buybox?.ranking === 1 ? (
																		<img
																			className="ml2"
																			src="/icons/inv-modal1-rank.svg"
																			alt=""
																		/>
																	) : (
																		""
																	)}
																</div>
															</td>
														)}
													</tr>
												);
											})}
										</tbody>
									</table>
								</TabPanel>
							</TabContext>
							<div className="in-row width100 align-center justify-center">
								<div onClick={onModalClose} className="grid-modal-close-btn">
									Close
								</div>
							</div>
						</div>
					</>
				</Modal>
			)}
		</>
	);
};

export default GestaoGridModal;
