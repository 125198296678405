import React, { useState, useEffect } from "react";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getCookie, setCookie } from '../../../utils/cookie';
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            width: 80,
            display: 'block'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

const Language: React.FC<any> = () => {
    const [lg, setLg] = useState('');

    const classes = useStyles();

    const handleChangelg = (event: React.ChangeEvent<{ value: unknown }>) => {
        setLg(event.target.value as string);
        setCookie('lang', event.target.value as string, 365);
    };

    useEffect(() => {
        setLg(getCookie('lang') || 'PT');
    }, []);

    return (
        <>
            <FormControl variant="outlined" className={classes.formControl}>
                <Select style={{ width:"80px"}}
                    value={lg}
                    onChange={handleChangelg}
                >
                    <MenuItem className="login-lg-text" value='PT'><strong>PT</strong> - Portugal</MenuItem>
                    <MenuItem className="login-lg-text" value='EN'><strong>EN</strong> - English</MenuItem>
                    <MenuItem className="login-lg-text" value='ES'><strong>ES</strong> - Español</MenuItem>
                    <MenuItem className="login-lg-text" value='FR'><strong>FR</strong> - Français</MenuItem>
                    <MenuItem className="login-lg-text" value='IT'><strong>IT</strong> - Italy</MenuItem>
                    <MenuItem className="login-lg-text" value='DE'><strong>DE</strong> - Deustch</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};

export default Language;
