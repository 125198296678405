import React, { useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";
import { changeRequestStatus } from "../../../actions/notifications";
import { IPaginatedUserRequest, IRequest, IUser } from "../../../interfaces";
import useUser from "../../../hooks/useUser";
import { Modal } from "../../ui/modal/Modal";
import Paginator from "../../ui/pagination";
import { HistoryUser } from "../users-management/HistoryUser";

interface Props {
  requests: IPaginatedUserRequest;
  refetch: any;
  setSelectedIds: (selectedIds: any) => void;
  selectedIds: string[];
}

export const RequestsNotiesTable: React.FC<Props> = ({
  requests,
  refetch,
  setSelectedIds,
  selectedIds,
}) => {
  const [modalType, setModalType] = useState<boolean>(false);
  const [historyModal, setHistoryModal] = useState<boolean>(false);
  const [request, setRequest] = useState<IRequest | any>({});

  const handleSelectAll = useCallback(() => {
    const ids = requests.data?.map((u) => u._id);
    setSelectedIds((prev: string[]) => (!prev.length ? ids : []));
  }, [requests.data, setSelectedIds]);

  const handleSelectOne = useCallback(
    (id: string) => () => {
      setSelectedIds((prev: string[]) => {
        if (prev.includes(id)) {
          return prev.filter((x: string) => x !== id);
        }
        return [...prev, id];
      });
    },
    [setSelectedIds]
  );

  const dateFormat = (date: Date) => {
    const formatedDate = new Date(date)
      .toLocaleString("en-GB")
      .slice(0, -3)
      .split(",");

    return (
      <>
        {formatedDate.map((el: string, index: number) => (
          <span key={index}>{el}</span>
        ))}
      </>
    );
  };

  const renderStatusStyle = (status: string) => {
    switch (status) {
      case "Rejected":
        return "reject-color";
      case "Accepted":
        return "green";
      case "In Process":
        return "grey";
    }
  };

  return (
    <>
      <div className="table-main-cont">
        <div className="table-main-title-cont">
          <div className="table-main-title-search-value-cont">
            <div></div>
            <div className="table-value">
              <span className="table-main-title-text ml5">
                {requests?.total} Requests {"&"} Notifications
              </span>
            </div>
          </div>
          <div className="marketplace-pagination">
            <Paginator data={requests} refetch={refetch} />
          </div>
        </div>
        <div className="table-border-bottom"></div>
        <div className="table-overflow table-gestao-height">
          <table
            style={{
              position: "sticky",
              zIndex: 101,
              top: "2px",
              width: "100%",
            }}
          >
            <thead>
              <tr
                style={{ marginTop: "-5px", width: "100%" }}
                className="table-results-title"
              >
                <th className="rew-img">
                  <img
                    className="pointer"
                    src={
                      selectedIds.length
                        ? "/icons/carbon_checkbox.svg"
                        : "/icons/check-box.svg"
                    }
                    alt=""
                    onClick={handleSelectAll}
                  />
                </th>
                <th className="rew-lgs">Notification Type</th>
                <th className="rew-mins">Seller</th>
                <th className="rew-lgs">User</th>
                <th className="rew-mins">Status</th>
                <th className="rew-mins">Last Update</th>
                <th className="rew-mins">History</th>
              </tr>
            </thead>
          </table>
          <table className="table-results mb2" style={{ minHeight: "auto" }}>
            <tbody>
              {requests?.data?.map((request: IRequest) => (
                <tr
                  className="table-result-box height100px ft2"
                  key={request._id}
                >
                  <td
                    className="rew-img"
                    onClick={handleSelectOne(request._id)}
                  >
                    <img
                      className="pointer"
                      src={
                        !selectedIds.includes(request._id)
                          ? "/icons/check-box.svg"
                          : "/icons/check-box-checked.svg"
                      }
                      alt=""
                    />
                  </td>
                  <td
                    className="rew-lgs in-row"
                    onClick={() => {
                      setRequest(request);
                      setModalType(true);
                    }}
                  >
                    <img
                      className="mr1"
                      src="/icons/PricingProposed.svg"
                      alt=""
                    />
                    <div className="underline pointer">
                      {request?.type} {request._id.slice(-4)}
                    </div>
                  </td>
                  <td className="rew-mins">vendor</td>
                  <td className="rew-lgs">
                    <div className="table-user">
                      <img
                        className="table-avatar"
                        src={
                          !request?.user?.profileImage
                            ? "/icons/image-bot.svg"
                            : request?.user?.profileImage
                        }
                        alt=""
                      />
                      <div>{request?.user?.username}</div>
                    </div>
                  </td>
                  <td
                    className={`${renderStatusStyle(
                      request?.status
                    )} rew-mins bold`}
                  >
                    {request?.status}
                  </td>
                  <td className="rew-mins table-br">
                    {dateFormat(request?.created)}
                  </td>
                  <td
                    className="rew-mins underline pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setRequest(request);
                      setHistoryModal(true);
                    }}
                  >
                    Show History
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {modalType && (
        <TypeModal
          modal={modalType}
          setModal={setModalType}
          request={request}
        />
      )}
      {historyModal && (
        <HistoryUser
          userHistory={request?.user?.history || []}
          setModal={setHistoryModal}
          modal={historyModal}
        />
      )}
    </>
  );
};

interface TypeModalProps {
  modal: boolean;
  setModal: (modal: boolean) => void;
  request: IRequest;
}

const TypeModal: React.FC<TypeModalProps> = ({ modal, setModal, request }) => {
  const { user } = useUser();
  const [status, setStatus] = useState<string>("");

  const onChangeStatusHandler = async (e: React.MouseEvent) => {
    e.preventDefault();

    request.status = status;

    await changeRequestStatus(request);
    setModal(false);
    toast.success("Status changed successfully!");
  };

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };
  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
      <div className="table-modal-cont">
        <div className="table-modal-title-box">
          <div>
            <div className="table-modal-title-pricing">
              <img className="mr1" src="/icons/PricingProposed.svg" alt="" />
              <div>
                {request?.type} {request._id.slice(-4)}
              </div>
            </div>
          </div>
        </div>
        <div className="table-modal-border"></div>
        <form className="table-modal-form-cont">
          {/* <SystemMsgType /> */}
          <PricingPropType
            request={request}
            user={user}
            statusForBtn={status}
            setStatus={setStatus}
          />
          <div className="table-modal-border"></div>
          <div className="table-modal-form-button-box">
            <button
              onClick={onModalClose}
              className="table-modal-form-button-cancel"
            >
              Cancel
            </button>
            <button
              className="table-modal-form-button"
              onClick={
                request?.status === "In Process"
                  ? onChangeStatusHandler
                  : onModalClose
              }
            >
              {request?.status !== "In Process" ? "CLOSE" : "SAVE"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

interface SystemMsgProps {
  request: IRequest;
}

// eslint-disable-next-line
const SystemMsgType: React.FC<SystemMsgProps> = ({ request }) => {
  return (
    <div className="table-req-modal-cont">
      <div className="in-row">
        <div className="table-req-modal-elems">
          <div className="mb3 bold">Status:</div>
          <div className="mb3 bold">User:</div>
        </div>
        <div className="table-req-modal-elems">
          <div className="in-row mb3">
            <div className="table-req-status-btn mr2">status1</div>
            <div className="table-req-status-btn mr2">status1</div>
            <div className="table-req-status-btn mr2">status1</div>
          </div>
          <div className="table-user mb3">
            <img className="table-avatar" src="/icons/admin_gv.svg" alt="" />
            <div>GV</div>
          </div>
        </div>
      </div>
      <div className="table-req-cont-msg">
        <div className="bold">Message:</div>
        <div>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Atque vero
          blanditiis omnis maxime hic eveniet laboriosam voluptatibus totam
          velit illum labore itaque, architecto cum. Dolor facilis dolore sed
          placeat nobis.
        </div>
      </div>
      <div className="table-req-modal-date ft1">
        <div className="mr1">Last Update:</div>
        <div className="mr1">20/08/2022 - 13:45</div>
        <div className="mr1">|</div>
        <div className="pointer underline">Show History</div>
      </div>
    </div>
  );
};

interface PricingProps {
  request: IRequest;
  user: IUser;
  statusForBtn: string;
  setStatus: (status: string) => void;
}

const PricingPropType: React.FC<PricingProps> = ({
  request,
  user,
  statusForBtn,
  setStatus,
}) => {
  const renderBtns = (status: string) => {
    switch (status) {
      case "Rejected":
        return (
          <div className="table-req-status-reject mr2 in-row">
            <img
              className="mr1"
              width="20px"
              height="20px"
              src="/icons/reject-icon-active.svg"
              alt=""
            />
            <div>Rejected</div>
          </div>
        );

      case "Accepted":
        return (
          <div className="table-req-status-accept mr2 in-row">
            <img
              className="mr1"
              width="20px"
              height="20px"
              src="/icons/accept-icon-active.svg"
              alt=""
            />
            <div>Accepted</div>
          </div>
        );

      default:
        return (
          <>
            <div
              className={`${statusForBtn !== "Rejected"
                ? "table-req-status-btn"
                : "table-req-status-reject"
                } mr2 in-row`}
              onClick={() => setStatus("Rejected")}
            >
              <img
                className="mr1"
                width="20px"
                height="20px"
                src="/icons/reject-icon.svg"
                alt=""
              />
              <div>Reject</div>
            </div>
            <div
              className={`${statusForBtn !== "Accepted"
                ? "table-req-status-btn"
                : "table-req-status-accept"
                } mr2 in-row`}
              onClick={() => setStatus("Accepted")}
            >
              <img
                className="mr1"
                width="20px"
                height="20px"
                src="/icons/accept-icon.svg"
                alt=""
              />
              <div>Accept</div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="table-req-modal-cont">
      <div className="in-row">
        <div className="mr4">
          <img width="80px" height="80px" src="" alt="" />
        </div>
        <div className="table-req-modal-elems">
          <div className="mb1 bold">Seller:</div>
          <div className="mb1 bold">User:</div>
        </div>
        <div className="table-req-modal-elems">
          <div className="mb1">{request?.content?.seller}</div>
          <div className="mb1">{request?.user?.username}</div>
        </div>
      </div>
      <div className="table-modal-border"></div>
      <div className="in-row table-req-modal-bg">
        <div className="mr4">
          <img
            width="80px"
            height="80px"
            src={request?.content?.image}
            alt=""
          />
        </div>
        <div>
          <div>{request?.content?.title}</div>
          <div className="in-row justify-start">
            <div className="table-req-modal-elems">
              <div className="mb1 bold">SKU:</div>
              <div className="mb1 bold">Platform:</div>
              <div className="mb1 bold">Standart Pricing:</div>
              <div className="mb6 bold">Main Pricing:</div>
              <div className="mb5 bold">Pricing Proposed:</div>
            </div>
            <div className="table-req-modal-elems">
              <div className="mb1">{request?.content?.sku}</div>
              <div className="mb1">{request?.content?.zeoosName}</div>
              <div className="mb1">{request?.content?.priceStandard} €</div>
              <div className="mb6">{request?.content?.compareAtPrice} €</div>
              <div className="mb5">{request?.content?.price} €</div>
            </div>
          </div>
          <div className="in-row align-center justify-center width80 mb4">
            {renderBtns(request?.status)}
          </div>
        </div>
      </div>
      {request?.status !== "In Process" && (
        <div className="table-req-modal-date ft1 mt6">
          <div className="mr1">
            {user?.username} {request?.status.toLowerCase()}{" "}
            {request?.type.toLowerCase()}:
          </div>
          <div>
            {new Date(request?.updated)
              .toLocaleString("en-GB")
              .slice(0, -3)
              .split(",")}
          </div>
        </div>
      )}
    </div>
  );
};
