import React from 'react'
import { NavLink, withRouter, RouteComponentProps  } from 'react-router-dom';
import { useSingleOrder } from '../../../../../../hooks/useOrders';
import Preloader from '../../../../../ui/preloader/Preloader';
import SingleOrderAdresses from '../SingleOrderAdresses';
import SingleOrderProducts from '../SingleOrderProducts';
import SingleOrderStepperInfo from './SingleOrderStepperInfo';
import SingleOrderViewStepper from './SingleOrderViewStepper';

type TParams = {
    order: string;
    shipping: string;
};

const SingleOrderView: React.FC<RouteComponentProps<TParams>> = (props) => {
    const order = useSingleOrder(props.match.params.order);
    const shipping = props.match.params.shipping;

    return (
        <div className="main-body">
            <div className="main-container">
            {!order.isLoading ? (
                <div className="table-main-cont">
                    <NavLink to={`/orders/${order.data.order._id}`}>
                        <div
                        className="single-billing-nav pointer" style={{paddingTop:"20px"}}
                        >
                            <img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
                            <div className="table-subtext underline pl2">back to Order View { order.data.order.order_id }</div>
                        </div>
                    </NavLink>
                    <SingleOrderViewStepper 
                        order={ order.data.order } 
                        shipping={ shipping } 
                        steps={ order.data.steps }
                        actions={ order.data.actions }
                        />
                    
                    <SingleOrderStepperInfo order={ order.data.order } shipping={ shipping } />
                    
                    <div className="single-order-cont">
                        <SingleOrderProducts order={ order.data.order } shipping={ shipping } />
                        <SingleOrderAdresses order={ order.data.order } />
                    </div>
                </div>            
            ) : (
                <div className="acompan-preloader">
                  <Preloader />
                </div>
            )}
            </div>
        </div>
    );

}

export default withRouter(SingleOrderView);