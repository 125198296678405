import React, { useEffect } from "react";
import "./modal.css";
import ReactDOM from "react-dom";

// TODO: fix any
interface Props {
  children: any;
  onModalClose: any;
  isOpened: boolean;
  bodyClassName?: string;
}

export const Modal: React.FC<Props> = ({
  children,
  onModalClose,
  isOpened,
  bodyClassName,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [isOpened]);

  return ReactDOM.createPortal(
    <div className="modal" onClick={onModalClose}>
      <div
        className={`modal__body ${bodyClassName}`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* <div className="modal__close-button" onClick={onModalClose}>
          ×
        </div> */}
        {children}
      </div>
    </div>,
    document.getElementById("portal") as any
  );
};
