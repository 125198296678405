import { Checkbox } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { getMarketplaceProductImage } from "../../../../actions/pim";
import { updateSellerProduct } from "../../../../actions/vendor";
import useCountries from "../../../../hooks/useCountries";
import {
	IVendorProducts,
	IVendor,
	IPaginatedRequest,
} from "../../../../interfaces";
import { queryClient } from "../../../App";
import { Modal } from "../../../ui/modal/Modal";
import Paginator from "../../../ui/pagination";
import { ITaxMapping } from "../../tax/Tax";
import queryString from "query-string";

interface IPaginatedVendorProducts {
	data: IVendorProducts[];
	lastPage: number;
	page: number;
	perPage: number;
	total: number;
}
interface Props {
	vendor: IVendor;
	products: IPaginatedVendorProducts;
	taxes: ITaxMapping[];
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
}

interface IEditProduct {
	product?: IVendorProducts;
	country?: string;
}

interface IUpdate {
	sku: string;
	country: string;
	seller: number;
	deliveryType: string;
	values: {
		cost: number;
		markup: number;
	};
}

const formatNumber = (number: number) => {
	return new Intl.NumberFormat("de", {
		style: "currency",
		currency: "EUR",
	}).format(!number ? 0 : number);
};

const SellerProductContryPrice: React.FC<Props> = ({
	vendor,
	products,
	taxes,
	setCurrentConfig,
	currentConfig,
}) => {
	const [modal, setModal] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<IEditProduct>({});
	const [newCost, setNewCost] = useState(0);
	const [newMarkup, setNewMarkup] = useState(0);
	const [newPvp, setNewPvp] = useState<number|undefined>(undefined);
	const [newFreight, setNewFreight] = useState<number|undefined>(undefined);
	const countries = useCountries();

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	const getCountryValue = (
		field: string,
		product?: IVendorProducts,
		country?: string
	) => {
		const prod = products?.data.find((p: any) => p._id === product?._id);
		if (prod) {
			const countryProd = prod.countries?.find((c: any) => c.name === country);
			if (countryProd) {
				return countryProd[field];
			}
		}

		return 0;
	};

	const getCountryTax = (product?: IVendorProducts, country?: string) => {
		const tax = taxes.find(
			(t: any) => t._id === product?.info?.details.taxCategory
		);
		return tax?.countries.find((c: any) => c.name === country)?.vat;
	};

	const getZeoosRate = (product?: IVendorProducts, country?: string) => {
		const rate = vendor?.categories.find(
			(c: any) => c.scheme === product?.scheme?.name
		);
		return (
			rate?.countries.find(
				(c: any) =>
					c.name === country && c.deliveryType === product?.deliveryType
			)?.zeoosRate || 0
		);
	};

	const key = useMemo(
		() =>
			`paginatedVendorProducts/${vendor.id}?${queryString.stringify(
				currentConfig
			)}`,
		[vendor.id, currentConfig]
	);

	const editMutation = useMutation((update) => updateSellerProduct(update), {
		onMutate: async (update: IUpdate) => {
			await queryClient.cancelQueries({
				queryKey: [key],
			});

			const previousData = queryClient.getQueryData([key]);

			queryClient.setQueryData([key], (old: any) => {
				const updatedData = old.data.map((x: IVendorProducts) => {
					if (x.sku === update.sku) {
						const countriesArr = x.countries.map((c: any) => {
							if (c.name === update.country) {
								return {
									...c,
									cost: update.values.cost,
									markup: update.values.markup,
								};
							}
							return c;
						});
						return { ...x, countries: countriesArr };
					} else {
						return x;
					}
				});

				return { ...old, data: updatedData };
			});

			return { previousData };
		},

		onError: (err, previousData) => {
			queryClient.setQueryData([key], previousData);
			toast.info("No changes were made in Product!");
		},

		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [key] });
			toast.success("Product updated successfully!");
		},
	});

	const onHandleSubmit = async (e: React.MouseEvent) => {
		e.preventDefault();
		const btn = e.target as HTMLButtonElement;
		btn.disabled = true;
		const update = {
			sku: selectedProduct?.product?.sku,
			country: selectedProduct.country,
			seller: vendor.id,
			deliveryType: selectedProduct?.product?.deliveryType,
			values: {
				cost: newCost,
				markup: newMarkup,
				vp: newPvp,
				freight: newFreight
			},
		} as IUpdate;

		await editMutation.mutateAsync(update);
		btn.disabled = false;
		setModal(false);
	};

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	return (
		<>
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont">
					<div className="table-search-box">
						<form
							className="table-search-form ml2"
							onSubmit={(e) => e.preventDefault()}
						>
							<input
								className="table-search-input"
								placeholder="Search product by SKU"
								onChange={(e) =>
									setCurrentConfig((conf: IPaginatedRequest) => ({
										...conf,
										search: e.target.value,
									}))
								}
								value={currentConfig.search}
							/>
							<img
								className="table-search-image"
								src="/icons/search.svg"
								width="25px;"
								alt=""
							/>
						</form>
					</div>
					<div className="table-main-title-text">
						{products?.total} products
					</div>
				</div>
				<div className="marketplace-pagination">
					{<Paginator data={products} refetch={onChangePage as any} />}
				</div>
			</div>
			<div className="table-border-bottom"></div>
			<div style={{ height: "max-content" }} className="table-overflow">
				<table style={{ position: "sticky", zIndex: 101, top: "2px" }}>
					<thead>
						<tr
							style={{ marginTop: "-5px", width: "100%" }}
							className="table-results-title"
						>
							<th className="gestao-sticky-block admin-titlebg title-border">
								<th className="ml3 mr3">
									<Checkbox />
								</th>
								<th className="gestao-image-link"></th>
								<th className="gestao-image"></th>
								<th>
									<th className="gestao-sku align-start">SKU</th>
									<th className="gestao-ean p0">EAN</th>
								</th>
								<th className="gestao-title">Title</th>
								<th className="gestao-total align-start">Delivery Type</th>
								<th className="table-border-right"></th>
							</th>
							{countries.map((country: any, i: number) => (
								<React.Fragment key={i}>
									<th className="table-border-right"></th>
									<th className="gestao-vinuus pt1">
										<div className="in-row align-center">
											<img
												width="28px"
												className="mr1"
												alt=""
												src={country.iconUrl}
											/>
											{country.name}
										</div>
										<div className="in-row width100 height100 gestao-sort">
											<div className="gestao-cost justify-center">Purchase</div>
											<div className="gestao-stock justify-center">Zeoos</div>
										</div>
									</th>
								</React.Fragment>
							))}
						</tr>
					</thead>
					<tbody></tbody>
				</table>
				<div>
					<table className="table-results p0">
						<thead></thead>
						<tbody>
							{products?.data?.map((product: any, index: number) => (
								<tr className="table-result-box" key={index}>
									<td className="gestao-sticky-block">
										<td className="ml3 mr3">
											<Checkbox />
										</td>
										<td className="gestao-image-link"></td>
										<td className="gestao-image">
											<img
												className="pointer"
												src={
													product.info?.details?.individual1 ||
													product.info?.details?.main_image ||
													product.info?.details?.pack
												}
												alt=""
												width={60}
											/>
										</td>
										<td>
											<td className="gestao-sku align-start">
												<div className="gestao-sku-text">{product.sku}</div>
											</td>
											<td className="gestao-ean p0">
												<div className="gestao-ean-text">
													{product.info?.details?.ean}
												</div>
											</td>
										</td>
										<td className="gestao-title">
											<div className="gestao-title-text">
												{product.info?.details?.title}
											</div>
										</td>
										<td className="gestao-total align-start">
											<div className="gestao-title-text">
												{product.deliveryType}
											</div>
										</td>
										<td className="table-border-right"></td>
									</td>
									{countries.map((country: any, i: number) => (
										<React.Fragment key={i}>
											<td className="table-border-right"></td>
											<td
												style={{ border: "none !important" }}
												onClick={(e) => {
													setSelectedProduct({
														product: product,
														country: country.name,
													});
													setNewCost(
														getCountryValue("cost", product, country.name)
													);
													setNewMarkup(
														getCountryValue("markup", product, country.name)
													);
													setNewPvp(
														getCountryValue("pvp", product, country.name)
													);
													setNewFreight(
														getCountryValue("freight", product, country.name)
													);
													e.stopPropagation();
													e.preventDefault();
													setModal(true);
												}}
												className="gestao-vinuus pointer"
											>
												<div className="in-row width100 height100">
													<div className="gestao-cost">
														<div className="gestao-cost-text">
															{formatNumber(
																getCountryValue("cost", product, country.name) +
																	getCountryValue(
																		"markup",
																		product,
																		country.name
																	)
															)}
														</div>
													</div>
													<div className="gestao-stock">
														<div className="gestao-stock-text">
															{getZeoosRate(product, country.name)}%
														</div>
													</div>
												</div>
											</td>
										</React.Fragment>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<form className="table-modal-form-cont p4">
						<div className="updateForm__container">
							<div className="table-modal-title-box m0">
								<h3 className="mapping-modal-title-text">
									Set Country Price Point
								</h3>
							</div>
							<div className="table-modal-border"></div>
							<div className="maxwidth420">
								<div className="in-row align-start justify-start">
									<img
										width="60px"
										// src={vendor?.vendorIcon}
										src={getMarketplaceProductImage(
											selectedProduct.product?.info?.details
										)}
										alt=""
									/>
									<div className="in-column justify-start align-start ml4">
										<span className="mapping-modal-text text-start">
											<b> Product: </b>
											{selectedProduct.product?.info?.details.title}
										</span>
										<span className="mapping-modal-text">
											<b> SKU: </b>
											{selectedProduct.product?.sku}
										</span>
										<span className="mapping-modal-text">
											<b> Vendor: </b>
											{vendor?.displayName || vendor?.name}
										</span>
										<span className="mapping-modal-text">
											<b> Delivery Type: </b>
											{selectedProduct.product?.deliveryType}
										</span>
									</div>
								</div>
								<table className="mapping-modal-table">
									<thead>
										<tr className="mapping-modal-results-title">
											<th className="sellers-cat-rate">
												<div className="in-row align-center">
													<img
														width="28px"
														className="mr1"
														alt=""
														src={
															countries.find(
																(c: any) => c.name === selectedProduct.country
															)?.iconUrl
														}
													/>
													{`${selectedProduct.country} Price`}
												</div>
											</th>
											<th className="sellers-cat-rate in-column justify-end align-end">
												Value
											</th>
										</tr>
									</thead>
									<tbody>
										<tr className="mapping-modal-result-box justify-between p1 align-start">
											<td className="sellers-cat-rate">Country Tax:</td>
											<td className="sellers-cat-rate in-column justify-end align-end">
												<div className="">
													{getCountryTax(
														selectedProduct.product,
														selectedProduct.country
													)}
													%
												</div>
											</td>
										</tr>
										<tr className="mapping-modal-result-box justify-between p1 align-start">
											<td className="sellers-cat-rate">Zeoos Rate:</td>
											<td className="sellers-cat-rate in-column justify-end align-end">
												<div className="">
													{getZeoosRate(
														selectedProduct.product,
														selectedProduct.country
													)}
													% (
													{formatNumber(
														((newMarkup + newCost) / 100) *
															getZeoosRate(
																selectedProduct.product,
																selectedProduct.country
															)
													)}
													)
												</div>
											</td>
										</tr>
										<tr
											style={{ background: "#F8F8F8" }}
											className="mapping-modal-result-box justify-between p1 align-start"
										>
											<td className="sellers-cat-rate">Purchase Price:</td>
											<td className="sellers-cat-rate in-column justify-end align-end">
												<strong>{formatNumber(newMarkup + newCost)}</strong>
											</td>
										</tr>
										<tr className="mapping-modal-result-box justify-between p1 align-start">
											<td className="sellers-cat-rate pl2">Markup:</td>
											<td className="sellers-cat-rate in-column justify-end align-end">
												<input
													type="number"
													style={{ textAlign: "right" }}
													className="mapping-modal-table-input mapping-modal-text mapping-modal-widthx2 p1"
													value={newMarkup}
													min={0}
													step="0.01"
													onChange={(e) => {
														setNewMarkup(Number(e.target.value));
													}}
												/>
											</td>
										</tr>
										<tr className="mapping-modal-result-box justify-between p1 align-start">
											<td className="sellers-cat-rate pl2">Cost:</td>
											<td className="sellers-cat-rate in-column justify-end align-end">
												<input
													type="number"
													style={{ textAlign: "right" }}
													className="mapping-modal-table-input mapping-modal-text mapping-modal-widthx2 p1"
													value={newCost}
													min={0}
													step="0.01"
													onChange={(e) => {
														setNewCost(Number(e.target.value));
													}}
												/>
											</td>
										</tr>
										{vendor.pricingType === 'pvpAndCost' && 
										<>
										<tr className="mapping-modal-result-box justify-between p1 align-start">
											<td className="sellers-cat-rate pl2">Pvp:</td>
											<td className="sellers-cat-rate in-column justify-end align-end">
												<input
													type="number"
													style={{ textAlign: "right" }}
													className="mapping-modal-table-input mapping-modal-text mapping-modal-widthx2 p1"
													value={newPvp}
													min={0}
													step="0.01"
													onChange={(e) => {
														setNewPvp(Number(e.target.value));
													}}
												/>
											</td>
										</tr>
										<tr className="mapping-modal-result-box justify-between p1 align-start">
											<td className="sellers-cat-rate pl2">Freight:</td>
											<td className="sellers-cat-rate in-column justify-end align-end">
												<input
													type="number"
													style={{ textAlign: "right" }}
													className="mapping-modal-table-input mapping-modal-text mapping-modal-widthx2 p1"
													value={newFreight}
													min={0}
													step="0.01"
													onChange={(e) => {
														setNewFreight(Number(e.target.value));
													}}
												/>
											</td>
										</tr>
										</>
										}
									</tbody>
								</table>
								<div className="sellers-cat-type-add"></div>
							</div>
							<div className="table-modal-form-button-box width100">
								<button
									className="table-modal-form-button-cancel"
									onClick={onModalClose}
								>
									Cancel
								</button>
								<button
									className="table-modal-form-button"
									type="submit"
									onClick={onHandleSubmit}
								>
									Save
								</button>
							</div>
						</div>
					</form>
				</Modal>
			)}
		</>
	);
};

export default SellerProductContryPrice;
