import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { exportPriceStockData } from "../../../actions/pim";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Role } from "../../routes/users-management/UsersManagement";

interface Props {
	products: any;
	selectedProducts: string[];
	setSelectedProducts: React.Dispatch<React.SetStateAction<string[]>>;
	batch: boolean;
	setBatch: React.Dispatch<React.SetStateAction<boolean>>;
	productsFrom: string;
	setOpenWindow?: React.Dispatch<React.SetStateAction<boolean>>;
}

const BatchDropDown: React.FC<Props> = ({
	products,
	selectedProducts,
	setSelectedProducts,
	batch,
	setBatch,
	productsFrom,
	setOpenWindow,
}) => {
	return (
		<AnimatePresence>
			{!!selectedProducts.length && (
				<div style={{ position: "sticky", top: "60px", zIndex: "101"}} className="width100 animation-block">
					<motion.div
						initial={{ opacity: 0, height: 0 }}
						animate={{ opacity: 1, height: "auto" }}
						exit={{ opacity: 0, height: 0 }}
						style={{ overflow: "hidden" }}
						transition={{ duration: 0.5 }}
					>
						<div
							style={{ display: batch ? "none" : "flex" }}
							className="inv-dropdown-cont justify-between"
						>
							<div className="in-row align-center">
								{batch ? (
									<div className="inv-dropdown-text">
										<span>Select products to create the Upload Batch.</span>
										<b>{selectedProducts.length} selected products.</b>
									</div>
								) : (
									<div className="inv-dropdown-text bold">
										{selectedProducts.length} selected products.
									</div>
								)}
								<div
									className="pointer fw1 ft1 ml1"
									onClick={() =>
										setSelectedProducts(
											products?.all?.map((x: any) => x.sku) || []
										)
									}
								>
									Select all
								</div>
								<div className="pointer fw1 ft1 ml1">|</div>
								<div
									className="pointer fw1 ft1 ml1"
									onClick={() => setSelectedProducts([])}
								>
									Clear
								</div>
							</div>
							<div className="in-row">
								<div
									className="req-popup-button in-row align-center"
									onClick={() =>
										exportPriceStockData(selectedProducts).then(() =>
											toast.success(
												"Success! Check your mailbox, it should be there in seconds."
											)
										)
									}
								>
									<div>Export</div>
								</div>
								<RoleRequired role={Role.admin}>
									{productsFrom === "inventory" && (
										<div
											className="req-popup-button in-row align-center ml3"
											onClick={() => setOpenWindow?.(true)}
										>
											<div>Active/Deactive on Platforms</div>
										</div>
									)}
									<div className="req-popup-button in-row align-center ml3">
										<div onClick={() => setBatch(true)}>Add to batch</div>
									</div>
								</RoleRequired>
							</div>
						</div>
						<div
							style={{ display: batch ? "flex" : "none" }}
							className="inv-dropdown-cont justify-between"
						>
							<div className="in-row align-center">
								<div className="inv-dropdown-text">
									Select products to create the Upload Batch.{" "}
									<b>{selectedProducts.length} selected products.</b>
								</div>
								<div
									className="pointer underline fw1 ft1 ml1"
									onClick={() => setSelectedProducts([])}
								>
									Clear
								</div>
							</div>
							<div className="in-row align-center">
								<div
									className="inv-dropdown-cancel"
									onClick={() => setBatch(false)}
								>
									Cancel
								</div>
								<div className="in-row align-center ml3">
									<NavLink
										className="inv-dropdown-upload"
										to={`/batch-products?from=${productsFrom}`}
									>
										<img src="/icons/upload-batch.svg" alt="" />
										Upload batch
									</NavLink>
								</div>
							</div>
						</div>
					</motion.div>
				</div>
			)}
		</AnimatePresence>
	);
};

export default BatchDropDown;
