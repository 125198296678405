import React, { FormEvent, useCallback, useMemo, useState } from "react";
import "./users-management.css";
import * as _ from "lodash";
import { Role, roleOptions } from "./UsersManagement";
import { IPaginatedRequest, IRoleOptions } from "../../../interfaces";
import useUserGroups from "../../../hooks/useUserGroups";
import useActiveVendors from "../../../hooks/useActiveVendors";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Box } from "@mui/material";
import { Modal } from "../../ui/modal/Modal";
import { AutocompleteVendors } from "./AutocompleteVendors";
import { UserGroup } from "../../../actions/userGroup";
import { UserGroupController } from "../../../actions";
import { toast } from "react-toastify";
import FilterSelect from "../../ui/select/FilterSelect";

interface Props {
	setModal: (modal: boolean) => void;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	vendorOptions: any;
	currentConfig: IPaginatedRequest;
}

export const UMFilter: React.FC<Props> = ({
	setModal,
	setCurrentConfig,
	vendorOptions,
	currentConfig,
}) => {
	return (
		<div className="table-title-cont">
			<div className="mplc-filter-cont width100">
				<div className="mplc-filter">
					<RoleRequired role={Role.admin}>
						<>
							<div className="filter-text">Filter by:</div>
							<FilterSelect
								onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
									setCurrentConfig((conf: IPaginatedRequest) => ({
										...conf,
										role:
											roleOptions
												?.find((x: IRoleOptions) => x?.label === e.target.value)
												?.value.toUpperCase() || "All roles",
									}))
								}
								value={
									roleOptions?.find(
										(x: IRoleOptions) => x.value === currentConfig.role
									)?.label
								}
								data={[
									"All roles",
									..._.uniq(_.map(roleOptions, (item: any) => item.label)),
								]}
							/>
							<FilterSelect
								onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
									setCurrentConfig((conf: IPaginatedRequest) => ({
										...conf,
										vendor:
											vendorOptions.find((x: any) => x.name === e.target.value)
												?.id || "All vendors",
									}))
								}
								value={
									vendorOptions.find((x: any) => x.id === currentConfig.vendor)
										?.name
								}
								data={[
									"All vendors",
									..._.uniq(_.map(vendorOptions, (item: any) => item.name)),
								]}
							/>
						</>
					</RoleRequired>
				</div>
				<Box
					sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
				>
					<RoleRequired role={Role.admin}>
						<UserGroups />
					</RoleRequired>

					<div
						onClick={() => {
							setModal(true);
						}}
						className="tax-title-button pointer mr2"
					>
						<img src="/icons/pim-plus.svg" alt="" />
						<div className="pim-avaliacoes-title-button-text">Create User</div>
					</div>
				</Box>
			</div>
		</div>
	);
};

const UserGroups: React.FC = () => {
	const [modal, setModal] = useState(false);
	const [userGroup, setUserGroup] = useState<UserGroup | {}>();
	const userGroups = useUserGroups();
	const vendors = useActiveVendors();

	if (userGroups.isLoading || vendors.isLoading) {
		return <></>;
	}

	return (
		<>
			<div
				onClick={() => {
					setModal(true);
				}}
				className="tax-title-button pointer mr2"
			>
				<div className="pim-avaliacoes-title-button-text">View User Groups</div>
			</div>

			{modal && (
				<Modal
					onModalClose={(e: React.MouseEvent) => {
						e.stopPropagation();
						setModal(false);
					}}
					isOpened={modal}
				>
					<div className="formUser">
						<div className="formEdit__container">
							<div className="user-title-sticky">
								<div className="space-between width100 in-row">
									<div className="table-modal-title-box m0">
										<div className="mapping-modal-title-text">User Groups</div>
									</div>
									<div
										className="tax-title-button pointer"
										style={{ width: 320 }}
										onClick={() => {
											setModal(false);
											setUserGroup({});
										}}
									>
										<img src="/icons/pim-plus.svg" alt="" />
										<div className="pim-avaliacoes-title-button-text">
											Create User Group
										</div>
									</div>
								</div>
								<div className="table-modal-border"></div>
							</div>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								{userGroups.data?.map((ug) => (
									<Box
										key={ug._id}
										sx={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "space-between",
										}}
									>
										<span className="email-text mr-4">{ug.name}</span>
										<AutocompleteVendors
											vendorPermissions={ug.vendorPermissions}
											readOnly={true}
											vendorOptions={vendors.data!}
										/>
										<span
											className="users-edit pointer"
											onClick={() => {
												setModal(false);
												setUserGroup(ug);
											}}
										>
											Edit
										</span>
									</Box>
								))}
							</Box>
						</div>
					</div>
				</Modal>
			)}

			{!!userGroup && (
				<CreateEditUserGroup
					userGroup={userGroup as UserGroup}
					reset={() => {
						setModal(true);
						setUserGroup(undefined);
					}}
				/>
			)}
		</>
	);
};

const CreateEditUserGroup: React.FC<{
	userGroup?: UserGroup;
	reset: () => void;
}> = ({ userGroup, reset }) => {
	const [name, setName] = useState(userGroup?.name || "");
	const [vendorPermissions, setVendorPermissions] = useState(
		userGroup?.vendorPermissions || []
	);
	const vendors = useActiveVendors();
	const { invalidate } = useUserGroups();

	const toCreate = useMemo(() => !userGroup?._id, [userGroup?._id]);

	const handleSubmit = useCallback(
		async (e: FormEvent) => {
			e.preventDefault();

			const image = vendors?.data.find(
				(v: any) => v.id === vendorPermissions[0].id
			)?.vendorIcon;

			try {
				if (userGroup?._id) {
					await UserGroupController.update(userGroup._id, {
						name,
						vendorPermissions,
						image,
					});
				} else {
					await UserGroupController.create({ name, vendorPermissions, image });
				}

				invalidate();
			} catch (error) {
				console.error(error);
				toast.error("Something went wrong");
			}
		},
		[name, vendorPermissions, invalidate, userGroup?._id, vendors?.data]
	);

	if (vendors.isLoading) {
		return <></>;
	}

	return (
		<Modal
			onModalClose={(e: any) => {
				e.stopPropagation();
				reset();
			}}
			isOpened={!!userGroup?.name}
		>
			<form
				id="email-form"
				name="email-form"
				className="formUser"
				onSubmit={handleSubmit}
			>
				<div className="formEdit__container">
					<div className="user-title-sticky">
						<div className="table-modal-title-box m0">
							<div className="mapping-modal-title-text">
								{toCreate ? "Create" : "Update"} User Group
							</div>
						</div>
						<div className="table-modal-border"></div>

						<div className="width100">
							<span>Name:</span>
							<input
								type="text"
								className="formEdit__input"
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
							/>
						</div>

						<div className="ml2 width100">
							<span>Vendor Permission:</span>
							<AutocompleteVendors
								vendorOptions={vendors.data!}
								setVendorPermissions={setVendorPermissions}
								vendorPermissions={vendorPermissions}
							/>
						</div>

						<div className="table-modal-form-button-box width100 mt2">
							<button
								className="table-modal-form-button-cancel"
								onClick={reset}
							>
								Cancel
							</button>
							<button
								className="table-modal-form-button"
								type="submit"
								value="Create"
							>
								{toCreate ? "Create" : "Update"}
							</button>
						</div>
					</div>
				</div>
			</form>
		</Modal>
	);
};
