import React, { useCallback, useDeferredValue, useEffect, useMemo, useState } from "react";
import IntelligenceNav from "../../../ui/int-nav/IntelligenceNav";
import IntelligenceSetNav from "../../../ui/int-nav/IntellienceSetNav";
import { useQuery } from "react-query";
import { getCategories, getMarketplaces, updateCategories } from "../../../../actions/intelligence";
import { MarketplaceImage } from "../../billing/single-billing/SingleBillingTable";
import { Category } from "types/intelligence";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";

export default function IntelligenceSettingsMatch() {
    const [zeoosName, setZeoosName] = useState<any>("");
    const debounced = useDeferredValue(zeoosName);

    const key = useMemo(() => `/intelligence/categories?${debounced}`, [debounced]);
    const marketplaces = useQuery(`/intelligence/marketplaces`, () => getMarketplaces());
    const _categories = useQuery(key, () => getCategories(debounced), { refetchOnWindowFocus: false });

    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        setCategories(_categories.data || []);
    }, [_categories.data]);

    const handleSave = useCallback(async (e: React.MouseEvent) => {
        e.preventDefault();

        try {
            await updateCategories(categories);
            queryClient.invalidateQueries(key);

            toast.success('Categories updated!');
        } catch (error) {
            console.error(error);

            toast.error('Something went wrong...');
        }
    }, [categories, key]);

    return (
        <div className="main-body">
            <div className="main-container">
                <div className="table-main-cont p0">
                    <IntelligenceNav />
                </div>
                <div style={{ paddingBottom: "200px" }} className="table-main-cont">
                    <div style={{ marginBottom: "20px", padding: "0 20px" }} className="table-main-title-cont">
                        <div className="in-column align-center justify-start">
                            <IntelligenceSetNav />
                        </div>
                    </div>
                    <div className="table-main-title-cont">
                        <div className="table-main-title-search-value-cont">
                            <select
                                id="compa-channels"
                                name="compa-channels"
                                required
                                className="compa-select"
                                value={zeoosName}
                                onChange={e => setZeoosName(e.target.value)}
                            >

                                <option className="compa-option" value={''}>
                                    Select marketplace
                                </option>

                                {marketplaces.data?.map(m => (
                                    <option className="compa-option" value={m} key={m}>
                                        {m}
                                    </option>
                                ))}
                            </select>
                            <span style={{ color: "#E80000" }} className="ml2 int-text bold">{categories.filter(c => !c.internalName).length} category combinations left</span>
                        </div>
                        <div className="marketplace-pagination">
                            {/* <div className="search-box">
                                <div className="search_form">
                                    <div className="table-search-box">
                                        <form
                                            className="table-search-form"
                                            onSubmit={(e) => e.preventDefault()}
                                        >
                                            <input
                                                className="table-search-input"
                                                placeholder="Filter items"
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            <img
                                                className="table-search-image"
                                                src="/icons/search.svg"
                                                width="25px;"
                                                alt=""
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                            <div className="tax-title-button pointer mr2" onClick={handleSave}>
                                <div
                                    className="pim-avaliacoes-title-button-text p0"
                                >
                                    Salvar
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom" />
                    <div style={{ height: "max-content" }} className="table-overflow">
                        <div className="int-set-match-main-cont">
                            <div className="int-set-match-logo">
                                {zeoosName && <MarketplaceImage width="100px" height="100px" zeoosName={zeoosName} className="p2" />}
                            </div>
                            <div className="int-set-match-content">
                                <div className="int-set-match-box">
                                    <span className="int-text bold width100">Found Categories</span>
                                    <div className="int-set-match-arrow-box" />
                                    <span className="int-text bold width100">Zeoos Categories</span>
                                </div>

                                {categories.map(c => (
                                    <div key={c.id} className="int-set-match-box">
                                        <input className="int-product-input" placeholder={c.externalName} />

                                        <div className="int-set-match-arrow-box">
                                            <img src="/icons/int-set-match-arrow.svg" alt="" />
                                        </div>

                                        <input
                                            className="int-product-input"
                                            value={c.internalName}
                                            onChange={e => {
                                                setCategories(prev => prev.map(category => {
                                                    if (category.id === c.id) {
                                                        category.internalName = e.target.value;
                                                    }

                                                    return category;
                                                }));
                                            }}
                                        />
                                    </div>
                                ))}

                            </div>
                            <div className="border-bottom width100" />
                            <div className="width100 align-center in-row justify-end mr4 mt2 mb4">
                                <div className="tax-title-button pointer">
                                    <div className="pim-avaliacoes-title-button-text p0">Salvar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
