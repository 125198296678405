import React, { useContext, useState } from "react";
import { CountriesContext } from "../../context";
import { Modal } from "../../ui/modal/Modal";
import { ICountryM } from "../country-management/CountryManagement";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { IVendor, IVendorContacts } from "../../../interfaces";

interface Props {
	onSuccess: (vendor: IVendor) => void;
	sendRequest: (vendor: Partial<IVendor>) => Promise<IVendor>;
	modal: boolean;
	setModal: (modal: boolean) => void;
	action: string;
	vendor?: IVendor;
}

export const CreateEditVendor: React.FC<Props> = ({
	modal,
	setModal,
	action,
	vendor,
	onSuccess,
	sendRequest,
}) => {
	const countries = useContext(CountriesContext) as ICountryM[];

	const [name, setName] = useState<string>(vendor?.name || "");
	const [displayName, setDisplayName] = useState<string>(
		vendor?.displayName || ""
	);
	const [country, setCountry] = useState<string>(vendor?.country || "");
	const [phone, setPhone] = useState<string>(vendor?.phone || "");
	const [contacts, setContacts] = useState<IVendorContacts[]>(
		vendor?.contacts || []
	);
	const [zeoosClient, setZeoosClient] = useState<boolean>(
		vendor?.zeoosClient === true
	);

	const onSubmitHandler = async (e: React.MouseEvent) => {
		e.preventDefault();
		try {
			const vendorFields = vendor || ({} as any);
			vendorFields.name = name;
			vendorFields.displayName = displayName;
			vendorFields.phone = phone;
			vendorFields.country = country;
			vendorFields.zeoosClient = zeoosClient;
			vendorFields.contacts = contacts;
			vendorFields.file =
				acceptedFiles.length > 0 ? acceptedFiles[0] : vendor?.vendorIcon;

			const newVendor = await sendRequest(vendorFields);
			onSuccess(newVendor);
			toast.success(
				`The vendor is successfully ${
					action === "edit" ? "updated" : "created"
				}!`
			);
		} catch (error) {
			console.error(error);
			toast.error("You should fill the fields!");
		}
	};

	const removeContact = (e: React.MouseEvent, contactId: string) => {
		e.preventDefault();
		setContacts(contacts.filter((contact: any) => contact._id !== contactId));
	};

	const addContact = (e: React.MouseEvent) => {
		e.preventDefault();
		setContacts((prevState: any) => [
			...prevState,
			{
				contactName: "",
				contactEmail: "",
			},
		]);
	};

	const handleUpdateContact = (e: any, id: string, key: string) => {
		setContacts((prevState: any) =>
			prevState.map((contact: any) => {
				if (contact._id === id) {
					contact[key] = e.target.value;
				}

				return contact;
			})
		);
	};

	const createCountriesArray = (array: ICountryM[]) => {
		return [
			"Select country",
			..._.uniq(_.map(array, (item: any) => item.name)),
		];
	};

	const { getRootProps, getInputProps, acceptedFiles } = useDropzone({});

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<>
				<div className="table-modal-form-cont p4">
					<div className="table-modal-title-box m0">
						<div className="mapping-modal-title-text">
							{action === "create" ? "New Seller" : "Edit seller"}
						</div>
					</div>
					<form className="table-modal-form-cont">
						<div className="table-modal-border"></div>
						<div className="table-vendormm-title-cont">
							<div className="table-vendormm-title-image">
								<div
									className="table-vendormm-title-imagebg"
									{...getRootProps()}
								>
									{/* @ts-ignore */}
									{action !== "view" && <input {...getInputProps()} />}
									{vendor?.vendorIcon && !acceptedFiles.length && (
										<img
											src={vendor?.vendorIcon}
											width="210px"
											height="160px"
											alt=""
										/>
									)}
									{!vendor?.vendorIcon && (
										<div>
											<h2 style={{ color: "#000" }}>
												{acceptedFiles.length > 0
													? `Click 'Apply' to finish upload ${acceptedFiles[0].name}`
													: "Drop the file here"}
											</h2>
											{!acceptedFiles.length && (
												<div className="table-vendormm-title-image-desc">
													<div className="table-vendormm-title-image-text">
														Add 480 x 360 image |&nbsp;
													</div>
												</div>
											)}
										</div>
									)}
									{vendor?.vendorIcon && acceptedFiles.length ? (
										<h2 style={{ color: "#000" }}>
											Click 'Apply' to finish upload {acceptedFiles[0].name}
										</h2>
									) : (
										<></>
									)}
								</div>
							</div>
							<div className="table-vendormm-title-box">
								<div className="tax-modal-cat width100">
									<div className="mapping-modal-text">Vendor Name:</div>
									<input
										className="tax-modal-input width100"
										readOnly={action === "view" ? true : false}
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
								<div className="tax-modal-cat width100">
									<div className="mapping-modal-text">Display Name:</div>
									<input
										className="tax-modal-input width100"
										readOnly={action === "view" ? true : false}
										value={displayName}
										onChange={(e) => setDisplayName(e.target.value)}
									/>
								</div>
								<div className="width100 align-center in-row mt2 mb2">
									<div className="tax-modal-cat width100">
										<div className="mapping-modal-text">Country:</div>
										<div className="">
											<select
												disabled={action === "view" ? true : false}
												className="main-select-bi mwidth150 m0"
												value={country}
												onChange={(e) => setCountry(e.target.value)}
											>
												{createCountriesArray(countries).map(
													(country: string, index: number) => (
														<option className="acompan-option" key={index}>
															{country}
														</option>
													)
												)}
											</select>
										</div>
									</div>
									<div className="tax-modal-cat width100 ml1">
										<div className="mapping-modal-text">Phone:</div>
										<input
											readOnly={action === "view" ? true : false}
											className="tax-modal-input width100"
											value={phone}
											onChange={(e) => setPhone(e.target.value)}
										/>
									</div>
								</div>
								<div className="tax-modal-cat-zeoos-client">
									<div className="zeoos-client-label">Zeoos client:</div>
									<input
										disabled={action === "view" ? true : false}
										type="checkbox"
										defaultChecked={zeoosClient}
										onChange={() => setZeoosClient(!zeoosClient)}
									></input>
								</div>
							</div>
						</div>
						{contacts.map((contact: any, index: number) => (
							<div className="mb4" key={index}>
								<div className="table-modal-border"></div>
								<div className="table-vendormm-box">
									<div className="table-vendormm-bold-text">
										Contact {index + 1}
									</div>
									<div
										className="vendormm-remove-button"
										onClick={(e) => removeContact(e, contact._id)}
									>
										Remove contact
									</div>
								</div>
								<div className="width100 in-row mt2 mb2">
									<div className="tax-modal-cat width50 mr1">
										<div className="mapping-modal-text">Contact Name:</div>
										<input
											readOnly={action === "view" ? true : false}
											className="tax-modal-input width100"
											value={contact.contactName}
											onChange={(e) =>
												handleUpdateContact(e, contact._id, "contactName")
											}
										/>
									</div>
									<div className="tax-modal-cat width100 ml1">
										<div className="mapping-modal-text">E-mail:</div>
										<input
											readOnly={action === "view" ? true : false}
											className="tax-modal-input width100"
											value={contact.contactEmail}
											onChange={(e) =>
												handleUpdateContact(e, contact._id, "contactEmail")
											}
										/>
									</div>
								</div>
							</div>
						))}
						{action !== "view" ? (
							<div className="vendormm-add-button" onClick={addContact}>
								Add new contact
							</div>
						) : (
							""
						)}
						<div className="table-modal-border"></div>
						<div className="table-modal-form-button-box width100">
							<button
								className="table-modal-form-button-cancel"
								onClick={onModalClose}
							>
								Cancel
							</button>
							{action !== "view" ? (
								<button
									className="table-modal-form-button"
									onClick={onSubmitHandler}
								>
									{action === "create" ? "create new vendor" : "Edit"}
								</button>
							) : (
								<></>
							)}
						</div>
					</form>
				</div>
			</>
		</Modal>
	);
};
