import React, { useContext, useEffect, useState } from "react";
import "./acompanhamento.css";
import "../tables.css";
import { ICountryM } from "../../country-management/CountryManagement";
import { CountriesContext } from "../../../context";
import { Paginator } from "../../../ui/pagination/Paginator";
// import RoleRequired from "../../../utils/role-required/RoleRequired";
// import { Role } from "../../users-management/UsersManagement";
import { NavLink } from "react-router-dom";
import SellerInvoice from "./SellerInvoice";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { Role } from "../../users-management/UsersManagement";

interface Props {
	orders: any;
}

export function renderUnifiedOrderStatus(order: any) {
	const pickStatus = (statuses: string[]) => {
		for (const status of statuses) {
			if (status !== "") {
				return status;
			}
		}
		return null;
	};
	const status: any = pickStatus([
		order.status,
		order.sale_lengow_status,
		order.sale_shopify_status,
	]);
	const statusToName = (status: string) => {
		return status
			.split("_")
			.map((s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
			.join(" ");
	};
	return <div className={status}>{statusToName(status)}</div>;
}

export const TableAcompanhamento: React.FC<Props> = ({ orders }) => {
	const countries = useContext(CountriesContext) as ICountryM[];

	const [search, setSearch] = useState<any>("");
	const [paginatedData, setPaginatedData] = useState<any>(orders.slice(0, 50));
	const [limit, setLimit] = useState<number>(50);
	const [results, setResults] = useState<any>(orders);

	useEffect(() => {
		const results = orders
			// eslint-disable-next-line
			.filter((item: any) => {
				if (search === "") {
					return item;
				} else if (
					Object.keys(item).filter((x: any) =>
						`${item[x]}`
							.toLowerCase()
							.includes(search.toLowerCase().replace(",", "."))
					)?.length > 0
				) {
					return item;
				}
			});
		setResults(results);
		setPaginatedData(
			results
				.sort(
					(a: any, b: any) =>
						new Date(b.sale_date).valueOf() - new Date(a.sale_date).valueOf()
				)
				.slice(0, limit)
		);
		// eslint-disable-next-line
	}, [search, orders]);

	return (
		<div className="table-main-cont">
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont">
					<div className="table-search-box ml2">
						<form
							className="table-search-form"
							onSubmit={(e) => e.preventDefault()}
						>
							<input
								className="table-search-input"
								placeholder="Search order"
								onChange={(e) => setSearch(e.target.value)}
							/>
							<img
								className="table-search-image"
								src="/icons/search.svg"
								width="25px;"
								alt=""
							/>
						</form>
					</div>
					<div className="table-value">
						<span className="table-main-title-text">
							{results.length} pedidos
						</span>
					</div>
				</div>
				<div className="marketplace-pagination">
					<Paginator
						data={search !== "" ? results : orders}
						rowsPerPage={limit}
						setPaginatedData={setPaginatedData}
						setLimit={setLimit}
						limit={limit}
					/>
				</div>
			</div>
			<div className="table-border-bottom"></div>
			<div style={{ height: "max-content" }} className="table-overflow">
				<table
					style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
				>
					<tr
						style={{ marginTop: "-5px", width: "100%" }}
						className="table-results-title"
					>
						<th className="acompan-status">Status</th>
						<th className="acompan-order">Order ID</th>
						<th className="acompan-country">Platform</th>
						<th className="acompan-channel">Platform Name</th>
						<th className="acompan-date">Date</th>
						<th className="acompan-name">Customer Name</th>
						<th className="acompan-country">Country</th>
						<th className="acompan-amount">Amount</th>
						{/* <RoleRequired role={Role.admin}>
							<th className="acompan-invoice">Seller<br />Invoice</th>
						</RoleRequired> */}
					</tr>
				</table>
				<table className="table-results">
					{/* <thead>
						<tr className="table-results-title">
							<th className="acompan-status">Status</th>
							<th className="acompan-order">Order ID</th>
							<th className="acompan-country">Platform</th>
							<th className="acompan-channel">Platform Name</th>
							<th className="acompan-date">Date</th>
							<th className="acompan-name">Customer Name</th>
							<th className="acompan-country">Country</th>
							<th className="acompan-amount">Amount</th>
						</tr>
					</thead> */}
					<tbody>
						{paginatedData.map((item: any, index: number) => (
							<tr className="table-result-box" key={index}>
								<NavLink
									className="width100 in-row align-center"
									to={`/orders/${item._id}`}
								>
									<td className="acompan-status">
										{renderUnifiedOrderStatus(item)}
									</td>
									<td className="acompan-order">
										<div
											className="acompan-order-text"
											style={{ textDecoration: "none" }}
										>
											{item.order_id}
										</div>
									</td>
									<td className="acompan-country">
										<img
											src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${item.marketplace?.toLowerCase()}.svg`}
											alt=""
											width="60px"
										/>
									</td>
									<td className="acompan-channel">
										<div className="acompan-channel-text">{item.zeoosName}</div>
									</td>
									<td className="acompan-date">
										<div className="acompan-date-text">
											{item.date.slice(0, 10).replaceAll("-", "/")}
											{/* {moment(item.sale_date).format("MMM Do YY")} */}
										</div>
									</td>
									<td className="acompan-name">
										<div className="acompan-name-text">
											{item.customer.name}
										</div>
									</td>
									<td className="acompan-country">
										{countries.find(
											(x: any) => item.customer?.country === x.name
										) ? (
											<img
												className="table-search-image"
												src={
													countries.find(
														(x: any) => item.customer?.country === x.name
													)?.iconUrl
												}
												width="32px;"
												alt=""
											/>
										) : (
											<span>{item.country}</span>
										)}
									</td>
									<td className="acompan-amount">
										<div className="acompan-amount-text">
											{item.price.toLocaleString("de-DE", {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}{" "}
											€
										</div>
									</td>
									{/* <td className="acompan-mail">
									<img
										className="table-search-image pointer"
										src="/icons/acompan-mail.svg"
										width="32px;"
										alt=""
									/>
								</td> */}
								</NavLink>
								{/* <RoleRequired role={Role.admin}>
									<td className="acompan-invoice">
										<SellerInvoice order={item} />
									</td>
								</RoleRequired> */}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{/* <div>
				<PaginationComponent
					data={results}
					rowsPerPage={rowsPerPage}
					setPaginatedData={setPaginatedData}
				/>
			</div> */}
		</div>
	);
};
