import React, { useContext, useState } from "react";
import { withRouter } from "react-router";
import { loginWithMFACode } from "../../../actions/user";
import useUser from "../../../hooks/useUser";
import "./login.css";
import { toast } from "react-toastify";
import LogoutWrap from "../../utils/logout-wrap/LogoutWrap";

const MFALogin: React.FC<any> = ({ history }) => {
  const { setUser } = useUser();
  const [verifyCode, setVerifyCode] = useState("");

  const handleMFALogin = async (e: any) => {
    e.preventDefault();

    try {
      const res = await loginWithMFACode({ code: verifyCode });
      setUser(res.data.user);
      history.push("/");
      toast.success(res.message);
    } catch (error: any) {
      history.push("/login");
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="contrato-bg">
       <div style={{ margin: "auto" }} className="in-row width100 pt4 align-center justify-between">
                    <img src="/icons/worten-logo.svg" className="ml4" width="194px;" alt="" />
                    {/* <div className="in-row mob-column align-center">
                        <span className="powered-text">Powered by</span>
                        <img width="96px" height="28px" src="/icons/zeoos-logo.svg" alt="" />
                    </div> */}
                    <LogoutWrap>
                        <button className="logout-btn-onboarding mr4"><div>Logout</div></button>
                    </LogoutWrap>
                </div>
    <div className="s_main-login">          
      <div className="login-box">
        <div className="in-column">
          <form className="login-form-block" onSubmit={handleMFALogin}>
            <div className="login-title-box">
              <div className="login-title-text">MFA Login</div>
            </div>
            <div className="email_text_box">
              <label className="email-text-login">Verification Code:</label>
            </div>
            <div className="login-input">
              <img
                className="image-profile"
                src="/icons/verification-code.svg"
                alt="settings"
                width="30px"
                height="30px"
              />
              <input
                value={verifyCode}
                onChange={(e) => setVerifyCode(e.target.value)}
                className="email-field"
                name="verifyCode"
                id="verifyCode"
                required
              />
            </div>
            <div className="button_box">
              <button onClick={handleMFALogin} className={`log-in-login`}>
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
};

export default withRouter(MFALogin);
