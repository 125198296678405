import React, { memo, useCallback, useState } from "react";
import Paginator from "../../../ui/pagination";
import * as _ from "lodash";
import { IPaginatedRequest } from "../../../../interfaces";
import { ICountryM } from "../../country-management/CountryManagement";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import { ICategoryField } from "types";
import { motion, AnimatePresence } from "framer-motion";
import "../freight/freight.css";
import PRatesModal from "./PRatesModal";

export interface IRate {
	country: string;
	rate: number;
}

interface IPaginatedCategories {
	data: ICategoryField[];
	perPage: number;
	page: number;
	total: number;
	lastPage: number;
}

interface IGroupObj {
	label: string;
	data: ICategoryField[];
}

interface Props {
	categories: IPaginatedCategories;
	countries: ICountryM[];
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
}

const PRatesTable: React.FC<Props> = ({
	categories,
	countries,
	setCurrentConfig,
	currentConfig,
}) => {
	const [modalPR, setModalPR] = useState<boolean>(false);
	const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
	const [selectedCategory, setSelectedCategory] = useState<ICategoryField>(
		categories?.data[0]
	);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	const groupByPlatformName = () => {
		const group = _.groupBy(categories.data, "marketplaceName");
		return Object.keys(group).map((x: string) => ({
			label: x,
			data: group[x],
		}));
	};

	return (
		<div className="table-main-cont">
			<div style={{ padding: "0 40px" }} className="width100">
				<div className="table-main-title-cont">
					<div className="table-main-title-search-value-cont">
						<div className="search-box">
							<div className="search_form">
								<div className="table-search-box">
									<form
										className="table-search-form"
										onSubmit={(e) => e.preventDefault()}
									>
										<input
											className="table-search-input"
											placeholder="Search platform"
											onChange={(e) =>
												setCurrentConfig((conf: IPaginatedRequest) => ({
													...conf,
													search: e.target.value,
												}))
											}
											value={currentConfig.search}
										/>
										<img
											className="table-search-image"
											src="/icons/search.svg"
											width="25px;"
											alt=""
										/>
									</form>
								</div>
							</div>
							<div className="table-main-title-text pl2">
								<span className="table-main-title-text">
									{groupByPlatformName().length} platforms
								</span>
							</div>
						</div>
					</div>
					<div className="marketplace-pagination">
						<Paginator data={categories} refetch={onChangePage as any} />
					</div>
				</div>
				<div
					style={{ height: "max-content", minHeight: "80vh" }}
					className="table-overflow"
				>
					<table
						style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
					>
						<thead>
							<tr
								style={{ marginTop: "-5px", width: "100%" }}
								className="table-results-title"
							>
								<th className="gestao-sticky-block admin-titlebg">
									<th className="rates-icon"></th>
									<th className="rates-platform">Platform</th>
									<th className="rates-category">Category</th>
									<th className="table-border-right"></th>
								</th>
								<th className="table-border-right"></th>
								{countries.map((c: ICountryM) => (
									<th className="rates-column" key={c._id}>
										<CountryFlag country={c.name} width="28px;" />
										{c.name}
									</th>
								))}
							</tr>
						</thead>
						<tbody></tbody>
					</table>
					<table className="table-results">
						<thead></thead>
						<tbody>
							{groupByPlatformName().map((obj: IGroupObj, index: number) => (
								<React.Fragment key={index}>
									<tr className="rates-result-box rates-body">
										<td className="rates-sticky-block">
											<td
												className="rates-icon pointer"
												onClick={(e: React.MouseEvent) => {
													e.preventDefault();
													e.stopPropagation();

													setSelectedPlatforms((prev: string[]) =>
														prev.includes(obj.label)
															? prev.filter((p: string) => p !== obj.label)
															: [...prev, obj.label]
													);
												}}
											>
												<img
													src={
														selectedPlatforms.includes(obj.label)
															? "/icons/arr-dropdown-close.svg"
															: "/icons/arr-dropdown-open.svg"
													}
													alt=""
													width="30px"
													height="30px"
												/>
											</td>
											<td className="rates-platform">
												<img
													src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${obj.label}.svg`}
													alt=""
													width="70px"
													height="70px"
													className="rates-img-border p1"
												/>
												<span className="ml3 capital">{obj.label}</span>
											</td>
											<td className="rates-category">
												<span>
													{obj.data.length > 1
														? `${obj.data.length} categories`
														: obj.data[0].name}
												</span>
											</td>
											<td className="table-border-right"></td>
										</td>
										<td className="table-border-right"></td>
										{countries.map((c: ICountryM) => {
											const ratesRange = obj.data.map((cat: ICategoryField) => ({
												min: _.minBy(cat.rates, "rate")?.rate,
												max: _.maxBy(cat.rates, "rate")?.rate,
											}));

											const minRate = _.minBy(ratesRange, "min")?.min;
											const maxRate = _.maxBy(ratesRange, "max")?.max;

											return (
												<td key={c._id} className="rates-column">
													{!minRate && !maxRate
														? "-"
														: `${minRate}% - ${maxRate}%`}
												</td>
											);
										})}
									</tr>
									<AnimatePresence>
										{selectedPlatforms.includes(obj.label) && (
											<motion.div
												initial={{ opacity: 0, height: 0 }}
												animate={{ opacity: 1, height: "auto" }}
												exit={{ opacity: 0, height: 0 }}
												style={{ overflow: "hidden", width: "100%" }}
												transition={{ duration: 0.5 }}
											>
												{obj.data.map((x: ICategoryField) => (
													<tr
														className="rates-result-box rates-body"
														style={{ height: "60px", width: "100%" }}
														key={x._id}
													>
														<td className="rates-sticky-block">

															<span
																className="rates-text pl4 capital pointer"
																onClick={() => {
																	setSelectedCategory(
																		(prev: ICategoryField) =>
																			categories.data?.find(
																				(cat) => cat.name === x.name
																			) || prev
																	);
																	setModalPR(true);
																}}
															>
																{x.name}
															</span>

														</td>
														<td className="table-border-right"></td>
														<td
															className="in-row align-center width100 zIndex10"
															style={{ height: "60px" }}
														>
															{countries.map((c: ICountryM) => {
																const rates = obj.data.find(
																	(cat: ICategoryField) => cat.name === x.name
																)?.rates;

																const value = rates?.find(
																	(rate: IRate) => rate.country === c.name
																)?.rate;

																return (
																	<td key={c._id} className="rates-column">
																		{!value ? "-" : `${value}%`}
																	</td>
																);
															})}
														</td>
													</tr>
												))}
											</motion.div>
										)}
									</AnimatePresence>
								</React.Fragment>
							))}
						</tbody>
					</table>
				</div>
				{modalPR && (
					<PRatesModal
						modal={modalPR}
						setModal={setModalPR}
						category={
							!selectedCategory.rates.length
								? {
									...selectedCategory,
									rates: countries.map((c: ICountryM) => ({
										country: c.name,
										rate: 0,
									})),
								}
								: selectedCategory
						}
						countries={countries}
					/>
				)}
			</div>
		</div>
	);
};

export default memo(PRatesTable);
