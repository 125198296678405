import React, { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import { getMarketplaceProductImage } from "../../../../actions/pim";
import { TooltipText } from "./Tooltip";
import { IProductGestao, IPaginatedRequest } from "../../../../interfaces";
import FilterSelect from "../../../ui/select/FilterSelect";
import * as _ from "lodash";
import Paginator from "../../../ui/pagination";
import { useAtom } from "jotai";
import { ProductResponse, selectedProductsAtom } from "./Gestao";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import queryString from "query-string";
import { getPaginatedInventoryProducts } from "../../../../actions/marketplace";
import { useMemo } from "react";
import useActiveVendors from "../../../../hooks/useActiveVendors";

interface Props {
	pathname: string;
}

const StepProducts: React.FC<Props> = ({ pathname }) => {
	const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
	const [currentConfig, setCurrentConfig] = useState<
		IPaginatedRequest<{ skus: string[]; }>
	>({
		perPage: 50,
		page: 1,
		skus: selectedProducts,
		country: "All Countries",
		vendorName: "All Sellers",
		brand: "All Brands",
		category: "All Categories",
		platformName: "All Marketplaces",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
	const { isLoading, data: products } = useQuery(
		`stepProducts?${queryString.stringify(debouncedConfig)}`,
		() =>
			getPaginatedInventoryProducts(debouncedConfig) as never as ProductResponse
	);

	const vendors = useActiveVendors();
	const vendorOptions = useMemo(() => {
		return ["All Sellers", ..._.uniq(_.map(vendors.data, (x) => x.name))];
	}, [vendors.data]);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	return (
		<div
			style={{ background: "#E8EDF2", marginTop: "0", paddingTop: "20px" }}
			className="product-cont"
		>
			<h1 className="product-h">Edit batch products</h1>
			<p className="product-p">
				{selectedProducts?.length} selected products{" | "}
				<NavLink className="batch-link-text" to={`/${pathname}`}>
					Add more products
				</NavLink>
			</p>
			<div className="product-content">
				<div className="table-main-title-cont">
					<div className="table-main-title-search-value-cont">
						<div className="table-search-box ml2">
							<form
								className="table-search-form"
								onSubmit={(e) => e.preventDefault()}
							>
								<input
									className="table-search-input"
									placeholder="Search product"
									onChange={(e) =>
										setCurrentConfig((conf: IPaginatedRequest) => ({
											...conf,
											search: e.target.value,
										}))
									}
									value={currentConfig.search}
								/>
								<img
									className="table-search-image"
									src="/icons/search.svg"
									width="25px;"
									alt=""
								/>
							</form>
						</div>
						<div className="table-value">
							<span className="table-main-title-text">
								{selectedProducts?.length} products
							</span>
						</div>
					</div>
					<div className="in-row">
						<FilterSelect
							onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
								setCurrentConfig((conf: IPaginatedRequest) => ({
									...conf,
									vendorName: e.target.value,
								}))
							}
							value={currentConfig.vendorName}
							data={vendorOptions}
						/>
						<div className="marketplace-pagination">
							<Paginator
								data={!isLoading ? products : {}}
								refetch={onChangePage as any}
							/>
						</div>
					</div>
				</div>
				<div className="table-border-bottom"></div>
				<div style={{ height: "max-content" }} className="table-overflow">
					<table
						style={{
							position: "sticky",
							zIndex: 101,
							top: "2px",
							width: "100%",
						}}
					>
						<thead>
							<tr
								style={{ marginTop: "-5px", width: "100%" }}
								className="table-results-title"
							>
								<th className="gestao-image-link mr4"></th>
								<th className="gestao-image-link"></th>
								<th className="gestao-image"></th>
								<th className="gestao-sku">SKU</th>
								<th className="gestao-ean">EAN</th>
								<th className="gestao-uni">Title</th>
								<th className="gestao-uni">Producer</th>
								<th className="gestao-ean"></th>
							</tr>
						</thead>
					</table>
					<table className="table-results">
						<tbody>
							{products?.data.map((product: IProductGestao) => (
								<tr
									className="table-result-box height100px initial"
									key={product._id}
								>
									<td
										className="gestao-image-link pointer g-delete-icon"
										onClick={() => {
											setSelectedProducts((prev) =>
												prev?.filter((sku) => sku !== product.sku)
											);

											setCurrentConfig((prev) => ({
												...prev,
												skus: prev.skus?.filter((sku) => sku !== product.sku),
											}));
										}}
									>
										<img
											className="mr4 ml2"
											width="16px"
											height="16px"
											src="/icons/delete-x.svg"
											alt=""
										/>
									</td>
									<td className="gestao-image-link">
										<img
											src={
												!product.components?.length ||
													product.components?.length > 1
													? `/icons/group-p.svg`
													: `/icons/arrow-return-right.svg`
											}
											alt=""
										/>
									</td>
									<td className="gestao-image">
										<img
											className="gestao-table-image"
											src={getMarketplaceProductImage(product)}
											alt=""
										/>
									</td>
									<td className="gestao-sku">
										<div className="gestao-sku-text">
											<TooltipText
												text={product.sku}
												items={7}
												separator={""}
											/>
										</div>
									</td>
									<td className="gestao-ean">
										<div className="gestao-ean-text">{product.barcode}</div>
									</td>
									<td className="gestao-uni">
										<div className="gestao-title-text">
											<TooltipText
												text={product.name}
												items={20}
												separator={" "}
											/>
										</div>
									</td>
									<td className="gestao-uni">
										<div className="gestao-title-text">{product.brand}</div>
									</td>
									<td className="gestao-ean">
										{
											<a
												className="underline in-row align-center gestao-title-text"
												target={"_blank"}
												style={{ color: "#2B5EC5" }}
												rel="noreferrer"
												href={`${process.env.REACT_APP_PREVIEW_URL}/pt/${product.sku}`}
											>
												<img
													className="mr2"
													src="/icons/preview-link-blue.svg"
													alt=""
												/>
												Preview
											</a>
										}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<NavLink className="product-main-btn mb4" to={`/${pathname}`}>
					add more products
				</NavLink>
			</div>
		</div>
	);
};

export default StepProducts;
