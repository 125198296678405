import React, { useState } from "react";
import { Modal } from "../../ui/modal/Modal";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { createOdooProduct, getProductBySku } from "../../../actions/pim";
import { useHistory } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

export enum IdCode {
	BARCODE = "EAN",
	GTIN = "GTIN",
	UPC = "UPC",
}

export interface ICreateProductModal {
	modal: boolean;
	setModal: (modal: boolean) => void;
	schemes: any;
	productInfo?: any;
	selectData?: any;
	sendRequest: (data: any) => Promise<void>;
}

const operationRoutes = [
	{
		label: "Manufacture",
		value: "Manufacture",
	},
	{
		label: "StockaBee: Deliver in 1 step (ship)",
		value: "StockaBee: Deliver in 1 step (ship)",
	},
	{
		label: "Byrd Germany: Deliver in 1 step (ship)",
		value: "Byrd Germany: Deliver in 1 step (ship)",
	},
	{
		label: "Buy",
		value: "Buy",
	},
	{
		label: "Dropship",
		value: "Dropship",
	},
];

export const CreateProductModal: React.FC<ICreateProductModal> = ({
	modal,
	setModal,
	schemes,
	sendRequest,
	productInfo,
	selectData,
}) => {
	const history = useHistory();
	const { vendors, categories, taxes } = selectData;

	const [title, setTitle] = useState<string>(productInfo?.title || "");
	const [weight, setWeight] = useState<string>(productInfo?.weight || "");
	const [productType, setProductType] = useState<any>(
		productInfo?.productType || schemes[0]?._id
	);
	const [isMix, setIsMix] = useState<boolean>(productInfo?.isMix || false);
	const [isVariant, setIsVariant] = useState<boolean>(
		productInfo?.variantOfSku ? true : false
	);
	const [variantOfSku, setVariantOfSku] = useState<any>(
		productInfo?.variantOfSku ? { sku: productInfo.variantOfSku } : {}
	);
	const [components, setComponents] = useState<any>(
		productInfo?.components?.map((x: any) => ({
			sku: x,
		})) || []
	);
	const [quantities, setQuantities] = useState<any>([]);
	const [producer, setProducer] = useState<any>({});
	const [iva, setIva] = useState<any>("");
	const [price, setPrice] = useState<any>("");
	const [category, setCategory] = useState<any>("");
	const [operationRoute, setOperationRoute] = useState(
		operationRoutes[0].value
	);
	const [brand, setBrand] = useState<any>("");
	const [idCode, setIdCode] = useState<string>("EAN");
	const [code, setCode] = useState<string>(productInfo?.ean || "");

	const taxesPT = taxes
		.filter((t: any) => t.name)
		.map((t: any) => {
			const vat = t.countries.filter((t: any) => t.name === "Portugal");
			return {
				id: t._id,
				name: t.name,
				vat: vat[0]?.vat,
			};
		});

	const validate = () => {
		if (!title || !weight || !producer || !iva || !category || !brand || !code) {
			return false;
		}

		if (!isMix && !isVariant && !price) {
			return false;
		}

		if (isMix && components.length < 2) {
			return false;
		}

		if (isVariant && (!components[0]?.sku || !quantities[0])) {
			return false;
		}

		return true;
	};

	const handleSubmit = async (e: any) => {
		e.target.disabled = true;
		e.preventDefault();
		let sku;

		const vendor = vendors.filter((v: any) => v.id === producer.id)?.[0];
		const taxCategory = taxes.find((t: any) => t._id === iva);
		const ivaValue = taxesPT.find((t: any) => t.id === iva);

		try {
			const odooProduct = (
				await createOdooProduct({
					product_name: title,
					product_barcode: code,
					idCode,
					weight,
					number_of_bom_lines: components.length,
					bom_lines_skus: components.map((x: any) => Number(x.sku)),
					bom_lines_skus_qtys: quantities.map((x: any) => Number(x)),
					vendor_name: vendor?.name,
					seller_id: producer.id,
					iva: ivaValue.vat,
					price_without_discount: price || 0,
					brand,
					parent_category: category.length === 2 ? category[0] : null,
					product_category: category.length === 2 ? category[1] : category[0],
					operationRoute,
					productType
				})
			).data.product as any;

			sku =
				odooProduct["new product sku"] ||
				odooProduct["SKU of existing product"];

			if (odooProduct.code === 200) {
				await sendRequest({
					productType,
					details: {
						title,
						ean: code,
						idCode,
						weight,
						numberBottles: quantities.length
							? quantities.reduce(
								(acc: string, cur: string) => Number(acc) + Number(cur)
							)
							: 1,
						sku,
						producer: producer.name,
						brand,
						taxCategory: taxCategory._id,
					},
					isVariant,
					variantOfSku: variantOfSku?.sku,
					isMix,
					components: components.map((x: any) => x.sku),
					sku,
					seller_id: producer.id,
					brand,
				});
			} else if (odooProduct.code === 400) {
				e.target.disabled = false;
				return toast.error(odooProduct.message, { autoClose: 6000 });
			}
		} catch (error) {
			console.error(error);
			e.target.disabled = false;
			return toast.error("Something went wrong");
		}

		toast.success("Success! Product info populated");
		setModal(false);
		if (sku) history.push(`/productInfo/${sku}`);
	};

	const setVariant = (sku?: string) => {
		setComponents(sku ? [{ sku: sku }] : []);
		setVariantOfSku(sku ? { sku: sku } : {});
	};

	const setVariantQty = (qty: string) => {
		setQuantities(qty ? [qty] : []);
	};

	const addComponent = (sku: string, qty: string, name: string) => {
		if (sku && qty) {
			setComponents([...components, { sku, name }]);
			setQuantities([...quantities, qty]);
		}
	};

	const removeComponent = (index: number) => {
		let newComponents = components;
		let newQuantities = quantities;
		newComponents.splice(index, 1);
		newQuantities.splice(index, 1);
		setComponents([...newComponents]);
		setQuantities([...newQuantities]);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<>
						<div className="pim-modal-form-container">
							<div className="pim-modal-form-content">
								<div className="pim-modal-form-sku">
									<div className="pim-modal-title-sku">Create Product</div>
								</div>
								<div className="table-modal-border"></div>

								<div className="in-row align-center width100 mb2">
									<div className="">
										<span className="mr2">Scheme:</span>
										<Select
											className="scheme"
											value={productType || ""}
											onChange={(e) => setProductType(e.target.value)}
										>
											{schemes.map((scheme: any, index: number) => (
												<MenuItem value={scheme._id} key={index}>
													{scheme.name}
												</MenuItem>
											))}
										</Select>
									</div>

									{categories.length && (
										<>
											<div className="">
												<span className="mr2 ml2">Category:</span>
												<Select
													className="producer"
													value={category || ""}
													onChange={(e) => {
														setCategory(e.target.value);
													}}
												>
													{categories.map((cat: any, index: number) => (
														<MenuItem value={cat} key={index}>
															{`${cat[0]}${cat[1] ? ` / ${cat[1]}` : ""}`}
														</MenuItem>
													))}
												</Select>
											</div>
										</>
									)}
								</div>

								<div className="width100 mb2">
									<span className="mr2 ml2">Title:</span>
									<TextField
										value={title}
										type="text"
										className="product-title"
										onChange={(e) => setTitle(e.target.value)}
									/>
								</div>

								<div className="width100 mb2">
									<span className="mr2 ml2">Operation routes:</span>
									<Select
										className="producer"
										style={{ width: 400 }}
										value={operationRoute}
										onChange={(e) => {
											setOperationRoute(e.target.value);
										}}
									>
										{operationRoutes.map((route, index: number) => (
											<MenuItem value={route.value} key={index}>
												{route.label}
											</MenuItem>
										))}
									</Select>
								</div>

								<div className="in-row align-center width100 mb2">
									{vendors.length && (
										<>
											<span className="mr2">Producer:</span>
											<div className="">
												<Select
													className="producer"
													value={producer.id || ""}
													onChange={(e) => {
														const vendor = vendors.find(
															(v: any) => v.id === e.target.value
														);

														setProducer({
															id: vendor.id,
															name: vendor.displayName || vendor.name,
														});
													}}
												>
													{vendors.map((vendor: any, index: number) => (
														<MenuItem value={vendor.id} key={index}>
															{vendor.displayName || vendor.name}
														</MenuItem>
													))}
												</Select>
											</div>

											<span className="mr2 ml2">Brand:</span>
											<TextField
												value={brand}
												type="text"
												className="product-title"
												onChange={(e) => setBrand(e.target.value)}
											/>
										</>
									)}
								</div>

								<div className="in-row align-center width100 mb2">
									<div>
										<span className="mr2">Weight:</span>
										<TextField
											type="number"
											className="product-weight"
											value={weight || ""}
											onChange={(e) => setWeight(e.target.value)}
										/>
									</div>

									<div>
										<span className="mr2 ml2">Tax:</span>
										<Select
											className="product-weight"
											value={iva || ""}
											onChange={(e) => setIva(e.target.value)}
										>
											{taxesPT.map((tax: any, index: number) => (
												<MenuItem value={tax.id} key={index}>
													{tax.name}
												</MenuItem>
											))}
										</Select>
									</div>

									<div className={isMix || isVariant ? "disabled" : ""}>
										<span className="mr2 ml2">Cost:</span>
										<TextField
											type="number"
											value={price || ""}
											disabled={isMix || isVariant}
											className="product-weight"
											onChange={(e) => setPrice(e.target.value)}
										/>
									</div>
								</div>

								<div className="width100 in-row align-center">
									<div className="pim-modal-form-mixed">
										<div className="pim-modal-variant-checkbox">
											<label
												className={`pim-modal-title-mixed ${isVariant ? "disabled" : ""
													}`}
											>
												<Checkbox
													disabled={isVariant}
													className="checkbox-input"
													color="primary"
													onChange={(e) => {
														if (e.target.checked) setPrice(0);
														setIsMix(e.target.checked);
														setVariantOfSku({});
														setComponents([]);
														setQuantities([]);
													}}
												/>
												Is mix?
											</label>
										</div>
									</div>
									<div className="pim-modal-form-variant">
										<div className="pim-modal-variant-checkbox">
											<label
												className={`pim-modal-title-variant ${isMix ? "disabled" : ""
													}`}
											>
												<Checkbox
													disabled={isMix}
													color="primary"
													className="checkbox-input"
													onChange={(e) => {
														if (e.target.checked) setPrice(0);
														setIsVariant(e.target.checked);
														setVariantOfSku({});
														setComponents([]);
														setQuantities([]);
													}}
												/>
												Is variant?
											</label>
										</div>
									</div>
								</div>

								<div className="width100 in-row align-center justify-start">
									<div>
										<span className="mr2 ml2">Id.Code:</span>
										<Select
											className="product-weight"
											value={idCode}
											onChange={(e) => setIdCode(e.target.value)}
										>
											{Object.values(IdCode).map(
												(idCode: string, index: number) => (
													<MenuItem value={idCode} key={index}>
														{idCode}
													</MenuItem>
												)
											)}
										</Select>
									</div>
									<TextField
										type="text"
										className=""
										value={code}
										onChange={(e) => setCode(e.target.value)}
									/>
								</div>

								{isVariant && (
									<ComponentsVariant
										setVariant={setVariant}
										setVariantQty={setVariantQty}
									/>
								)}

								{isMix && (
									<ComponentsMix
										components={components}
										quantities={quantities}
										addComponent={addComponent}
										removeComponent={removeComponent}
									/>
								)}

								<div className="table-modal-border"></div>
								<div className="table-modal-form-button-box width100 m0">
									<button
										className="table-modal-form-button-cancel"
										onClick={() => setModal(false)}
									>
										Cancel
									</button>
									<button
										className="table-modal-form-button"
										disabled={!validate()}
										onClick={handleSubmit}
									>
										Create
									</button>
								</div>
							</div>
						</div>
					</>
				</Modal>
			)}
		</>
	);
};

interface IComponentsVariant {
	info?: any;
	setVariant: (sku: string) => void;
	setVariantQty: (qty: string) => void;
}

export const ComponentsVariant: React.FC<IComponentsVariant> = ({
	setVariant,
	setVariantQty,
	info,
}) => {
	const [parentName, setParentName] = useState<string>("");
	const [parent, setParent] = useState<string>(info?.parent?.sku);

	return (
		<div className="in-column align-start width100 mb2 components">
			<span className="mr2">Variant of SKU:</span>
			<div className="component-line">
				<TextField
					type="number"
					placeholder="SKU"
					size="small"
					className="component-sku"
					value={parent}
					title="Parent SKU"
					onChange={(e) => {
						setVariant(e.target.value);
						setParent(e.target.value);
					}}
					onBlur={async (e) => {
						let result = false;
						let msg = "";

						if (e.target.value) {
							const find = await getProductBySku(e.target.value);

							if (
								Object.keys(find.data).length === 0 ||
								!find.data.productInfo?.details?.title
							) {
								msg = "SKU not found!";
							} else {
								if (
									!find.data.productInfo.isMix &&
									!find.data.productInfo.isVariant &&
									!find.data.productInfo.variantOfSku &&
									!find.data.productInfo.components.length
								) {
									setParentName(find.data.productInfo.details.title);
									result = true;
								} else {
									msg = "SKU is not a single product!";
								}
							}

							if (!result) {
								toast.error(msg);
								setVariant("");
								setParentName("");
								setParent("");
								e.target.value = "";
								e.target.focus();
							}
						} else {
							setParentName("");
						}
					}}
				/>

				{!info && (
					<TextField
						type="number"
						placeholder="Qty"
						size="small"
						className="component-qty"
						title="Quantity"
						onChange={(e) => setVariantQty(e.target.value)}
					/>
				)}

				<div className="component-title" title={parentName}>
					{parentName}
				</div>
			</div>
		</div>
	);
};

interface IComponentsMix {
	components: any[];
	quantities: any[];
	addComponent: (sku: string, qty: string, name: string) => void;
	removeComponent: (index: number) => void;
}

export const ComponentsMix: React.FC<IComponentsMix> = ({
	components,
	quantities,
	addComponent,
	removeComponent,
}) => {
	const [sku_add, setSku_add] = useState("");
	const [qty_add, setQty_add] = useState("");
	return (
		<div className="in-column align-start width100 mb2 components">
			<span className="mr2">SKU Components:</span>
			<div>
				<TextField
					type="number"
					placeholder="SKU"
					size="small"
					className="component-sku"
					value={sku_add}
					onChange={(e) => setSku_add(e.target.value)}
				/>

				<TextField
					type="number"
					placeholder="Qty"
					size="small"
					className="component-qty"
					value={qty_add}
					onChange={(e) =>
						setQty_add(Math.abs(Number(e.target.value)).toString())
					}
				/>
				<img
					src="/icons/add-circle-pim-ficha.svg"
					className="searchsku"
					alt=""
					width="22"
					onClick={async (e) => {
						let msg = "";
						let result = false;

						if (sku_add && qty_add) {
							const find = await getProductBySku(sku_add);

							if (
								Object.keys(find.data).length !== 0 &&
								find.data.productInfo?.details?.title
							) {
								if (
									!find.data.productInfo.isMix &&
									!find.data.productInfo.isVariant &&
									!find.data.productInfo.variantOfSku &&
									!find.data.productInfo.components.length
								) {
									addComponent(
										sku_add,
										qty_add,
										find.data.productInfo.details.title
									);
									setSku_add("");
									setQty_add("");
									result = true;
								} else {
									msg = `SKU ${sku_add} is not a single product!`;
								}
							} else {
								msg = `SKU ${sku_add} not found!`;
							}

							if (!result) {
								toast.error(msg);
							}
						}
					}}
				/>
			</div>
			{components.map((c: any, i: number) => {
				return (
					<div className="component-line mix" key={i}>
						<div>SKU: {c.sku}</div>
						<div>Qty: {quantities[i]}</div>
						<img
							src="/icons/grey-trash-can.svg"
							className="searchsku"
							alt=""
							title="Delete"
							width="20"
							onClick={(e) => removeComponent(i)}
						/>
						<div className="component-title ml2" title={c.name}>
							{c.name}
						</div>
					</div>
				);
			})}
		</div>
	);
};
