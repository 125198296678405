import React, { useMemo, useState } from "react";
import "./users-management.css";
import { getPaginatedUsers } from "../../../actions/user";
import { getVendorsActive } from "../../../actions/vendor";
import Preloader from "../../ui/preloader/Preloader";
import { CreateUserForm } from "./CreateUser";
import { UMFilter } from "./UMFilter";
import { useDebouncedValue } from "@mantine/hooks";
import { IPaginatedRequest, IRoleOptions } from "../../../interfaces";
import { useQuery } from "react-query";
import { UMTable } from "./UMTable";
import useUserGroups from "../../../hooks/useUserGroups";
import useUser from "../../../hooks/useUser";
import queryString from "query-string";
import SettingsNav from "../../ui/settings-nav/SettingsNav";

export enum Role {
  readonly = "READONLY",
  user = "USER",
  admin = "ADMIN",
  permissionBased = "PERMISSION_BASED",
  sellerAdmin = "SELLER_ADMIN",
  sellerUser = "SELLER_USER",
}

export const roleOptions = [
  {
    value: "USER",
    label: "vendor",
    bgColor: "#FFE7FF",
  },
  {
    value: "ADMIN",
    label: "admin",
    bgColor: "#FFE6B5",
  },
  {
    value: "PERMISSION_BASED",
    label: "permission based",
    bgColor: "#E1E1E1",
  },
  {
    value: "SELLER_ADMIN",
    label: "seller manager",
    bgColor: "#EAFFAF",
  },
  {
    value: "SELLER_USER",
    label: "seller user",
    bgColor: "#DBFBFF",
  },
] as IRoleOptions[];

export default function UsersManagement() {
  const { user } = useUser();
  const [modal, setModal] = useState<boolean>(false);
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
    role: "All roles",
    vendor: "All vendors",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const userGroups = useUserGroups();

  const rqKey = useMemo(() => {
    return `users?${queryString.stringify(debouncedConfig)}`;
  }, [debouncedConfig]);

  const { isLoading: loadUsers, data: users } = useQuery(rqKey, () =>
    getPaginatedUsers(debouncedConfig)
  );

  const { isLoading: loadVendors, data: vendorOptions } = useQuery(
    ["vendorOptions", user.role === Role.admin],
    () => getVendorsActive()
  );

  if (loadVendors || userGroups.isLoading) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  return (
    <>
      <div className="main-body">
        <div className="main-container">
          <div className="table-main-cont p0">
            <SettingsNav />
            <UMFilter
              setModal={setModal}
              setCurrentConfig={setCurrentConfig}
              currentConfig={currentConfig}
              vendorOptions={vendorOptions}
            />
            <UMTable
              users={users}
              loadUsers={loadUsers}
              vendorOptions={vendorOptions}
              setCurrentConfig={setCurrentConfig}
              currentConfig={currentConfig}
              rqKey={rqKey}
            />
          </div>
        </div>
      </div>

      {modal && (
        <CreateUserForm
          onModalClose={(e: React.MouseEvent) => {
            e.stopPropagation();
            setModal(false);
          }}
          mappedUser={users}
          modal={modal}
          vendorOptions={vendorOptions}
          setModal={setModal}
          rqKey={rqKey}
        />
      )}
    </>
  );
}
