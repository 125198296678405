import React, { useCallback, useMemo, useState } from "react";
import SellersNav from "../../../ui/sellers-nav/SellersNav";
import OnboardingTable from "./OnboardingTable";
import { useDebouncedValue } from "@mantine/hooks";
import Preloader from "../../../ui/preloader/Preloader";
import queryString from "query-string";
import { IPaginatedRequest } from "../../../../interfaces";
import { useQuery } from "react-query";
import { getPaginatedOnboardingSellers } from "../../../../actions/onboardingSeller";
import Paginator from "../../../ui/pagination";
import SellerCreateEditModal from "../SellerCreateEditModal";

const SellersOnboarding = () => {
	const [modalNewOnboarding, setModalNewOnboarding] = useState<boolean>(false);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
	});
	const [sellerId, setSellerId] = useState<string>("");

	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const rqKeyOnboardingSellers = useMemo(() => {
		return `/onboarding-sellers?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const { isLoading: loadOnboardingSellers, data: onboardingSellers } =
		useQuery(rqKeyOnboardingSellers, () =>
			getPaginatedOnboardingSellers(debouncedConfig)
		);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	if (loadOnboardingSellers) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<>
			{modalNewOnboarding ? (
				<SellerCreateEditModal
					rqKey={rqKeyOnboardingSellers}
					setModal={setModalNewOnboarding}
					seller={onboardingSellers?.data.find(
						(seller: any) => seller._id === sellerId
					)}
					label={
						sellerId === "" ? "New Onboarding Seller" : "Edit Onboarding Seller"
					}
					tab="onboarding"
				/>
			) : (
				<div className="main-body">
					<div className="main-container">
						<div className="s-users-management-container">
							<div className="table-main-cont">
								<div className="table-title-cont p0 mt4 mb4">
									<div className="mplc-filter-cont width100">
										<SellersNav />
										<div className="tax-title-button pointer mr2">
											<div
												style={{ textTransform: "none" }}
												className="pim-avaliacoes-title-button-text"
												onClick={() => {
													setSellerId("");
													setModalNewOnboarding(true);
												}}
											>
												Start a new onboarding
											</div>
										</div>
									</div>
								</div>
								<div className="table-main-title-cont">
									<div className="table-main-title-search-value-cont">
										<div className="search-box">
											<div className="search_form">
												<div className="table-search-box">
													<form
														className="table-search-form"
														onSubmit={(e) => e.preventDefault()}
													>
														<input
															className="table-search-input"
															placeholder="Search seller"
															onChange={(e) =>
																setCurrentConfig((conf: IPaginatedRequest) => ({
																	...conf,
																	search: e.target.value,
																}))
															}
															value={currentConfig.search}
														/>
														<img
															className="table-search-image"
															src="/icons/search.svg"
															width="25px;"
															alt=""
														/>
													</form>
												</div>
											</div>
											<div className="table-value">
												<span className="table-main-title-text">
													{onboardingSellers?.total}{" "}
													{onboardingSellers?.total > 1 ? "sellers" : "seller"}
												</span>
											</div>
										</div>
									</div>
									<div className="marketplace-pagination">
										<Paginator
											data={onboardingSellers}
											refetch={onChangePage as any}
										/>
									</div>
								</div>
								<OnboardingTable
									onboardingSellers={onboardingSellers}
									setModalNewOnboarding={setModalNewOnboarding}
									setSellerId={setSellerId}
									rqKeyOnboardingSellers={rqKeyOnboardingSellers}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SellersOnboarding;
