import React from "react";
import LogoutWrap from "../../../utils/logout-wrap/LogoutWrap";
import Avatar from "@mui/material/Avatar";
import useUser from "../../../../hooks/useUser";
import { SellerUploadFile } from "../Uploading";

export default function ChoosePlatforms() {
    const { user } = useUser();

    return (
        <div className="contrato-bg">
            <div className="uploading-header">
                <div className="uploading-header-cont">
                    <img className="ml4" src="/icons/worten.svg" alt="" />
                    <div className="in-row align-center mr4">
                        <div className="in-row align-center justify-between">
                            <Avatar
                                alt={user.username}
                                className="profile-bg-image user-avatar"
                                src={
                                    user.profileImage === undefined ? "df" : user.profileImage
                                }
                                sx={{ width: 40, height: 40, borderRadius: "50%" }}
                            />
                            <div className="hide-mob">
                            <div className="profile-box-header pointer">
                                Hey, {user.username}
                            </div>
                            </div>
                        </div>
                        <div>
                            <LogoutWrap>
                                <button className="log-in-out-btn-header">
                                    <div>Logout</div>
                                </button>
                            </LogoutWrap>
                        </div>
                    </div>
                </div>
            </div>
            <div className="in-column justify-center align-center width100">
            <div className="upload-completed">
                <img src="/icons/green-circle.svg" alt="" />
                <span className="successfull-text">Your onboarding process has been successfully completed.</span>
            </div>
            <div className="choose-platforms-cont">
                <div className="upload-cont">
                    <span className="policy-text">9 available platforms:</span>
                    <div className="upload-platform-box">
                        <img className="mb2" width="80px" height="80px" src="/icons/upload-test.png" alt="" />
                        <div className="upload-platform-countries">
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                        </div>
                    </div>
                    <div className="upload-platform-box">
                        <img width="80px" height="80px" src="/icons/upload-test.png" alt="" />
                        <div className="upload-platform-countries">
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                        </div>
                    </div>
                    <div className="upload-platform-box">
                        <img width="80px" height="80px" src="/icons/upload-test.png" alt="" />
                        <div className="upload-platform-countries">
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                            <span className="upload-platform-country">amazon ES</span>
                        </div>
                    </div>
                </div>
                <div className="upload-cont">
                    <span className="policy-text">Set the stock and price per country for your products.</span>
                    <div className="mt4">
                        <SellerUploadFile />
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}
