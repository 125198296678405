import React, { useDeferredValue, useState } from "react";
import IntelligenceNav from "../../../ui/int-nav/IntelligenceNav";
import IntelligenceFilter from "../product/IntelligenceFilter";
import IntelligenceSetNav from "../../../ui/int-nav/IntellienceSetNav";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import IntelligenceSettingsActions from "./IntelligenceSettingActions";
import IntellligenceSettingsActiveProducts from "./IntellligenceSettingsActiveProducts";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import { getRobots } from "../../../../actions/intelligence";
import { ClientRobot } from "types/intelligence";
import { getImageURL } from "../../../../actions/vendor";
import { atomWithHash } from "jotai/utils";
import { useAtom } from "jotai";

export const searchAtom = atomWithHash('search', '');

export default function IntelligenceSettings() {
    const [search, setSearch] = useAtom(searchAtom);
    const debounced = useDeferredValue(search);

    const clientRobots = useQuery(`/intelligence/overview?${debounced}`, () => getRobots(debounced), { refetchOnWindowFocus: false });

    return (
        <div className="main-body">
            <div className="main-container">
                <div className="table-main-cont p0">
                    <IntelligenceNav />
                </div>
                <div className="table-main-cont">
                    <div style={{ marginBottom: "20px", padding: "0 20px" }} className="table-main-title-cont">
                        <div className="in-column align-center justify-start">
                            <IntelligenceSetNav />
                        </div>
                        <IntelligenceFilter />
                    </div>
                    <div className="table-main-title-cont">
                        <div className="table-main-title-search-value-cont">
                            <div className="search-box">
                                <div className="search_form">
                                    <div className="table-search-box">
                                        <div className="table-search-form">
                                            <input
                                                className="table-search-input"
                                                placeholder="Search seller"
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                            <img
                                                className="table-search-image"
                                                src="/icons/search.svg"
                                                width="25px;"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="table-value">
                                    {clientRobots.data && (
                                        <span className="table-main-title-text">
                                            {clientRobots.data.length} sellers
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="marketplace-pagination">
                            {/* <div className="int-set-list-title-buttons-cont">
                                <div className="int-set-list-title-button">
                                    Sellers
                                </div>
                                <div className="int-set-list-title-button">
                                    Marketplaces
                                </div>
                            </div> */}
                            {/* Paginator */}
                        </div>
                    </div>
                    <div
                        style={{ height: "max-content", marginBottom: "20px" }}
                        className="table-overflow"
                    >
                        <table
                            style={{

                                zIndex: 101,

                                width: "100%",
                            }}
                        >
                            <tr
                                style={{ marginTop: "-5px", width: "100%" }}
                                className="table-results-title"
                            >
                                <th className="vendors-accordion"></th>
                                <th className="int-set-list-table-seller">Seller</th>
                            </tr>
                        </table>
                        <table style={{ marginTop: "-5px" }} className="table-results">
                            <tbody>
                                {clientRobots.data?.map((item, index) => (
                                    <TableItem key={index} clientRobot={item} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const TableItem: React.FC<{ clientRobot: ClientRobot; }> = ({ clientRobot }) => {
    return (
        <Accordion
            className="m0 p0 width100"
        >
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="width100 p0 m0div"

            >
                <tr className="table-result-box border0 m0 height100px width100">
                    <td className="vendors-accordion">
                        <img
                            src="/icons/vendors-arrow-down.svg"
                            alt=""
                        />
                    </td>
                    <td className="">
                        <img style={{ width: "110px", height: "80px" }} src={getImageURL(clientRobot.sellerId)} alt="" />
                    </td>
                    <td className="int-text ml2">
                        {clientRobot.seller}
                    </td>
                </tr>
            </AccordionSummary>
            <AccordionDetails className="width100 p0 m0div">
                {clientRobot.marketplaces?.map((m, index) => (
                    <Accordion
                        className="m0 p0 width100"
                        key={m.zeoosName}
                        expanded={!!m.processes.length}
                    >
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            className="width100 p0 m0div"
                        >
                            <table className="table-results height30 mheightauto">
                                {index === 0 && (
                                    <thead>
                                        <tr className="table-result-box int-text border0 width100 m0 height30 width100">
                                            <th className="int-set-list-table-platform ml4">Platform Robot</th>
                                            <th className="int-set-list-table-active">Active products</th>
                                            <th className="int-set-list-table-inactive">Inactive products</th>
                                            <th className="int-set-list-table-inactive">Products not found</th>
                                            <th className="int-set-list-table-rounds">Rounds</th>
                                            <th className="int-set-list-table-inactive">Actions</th>
                                        </tr>
                                    </thead>
                                )}
                                <tbody>
                                    <tr style={{ height: "50px", fontWeight: "normal" }} className="table-results-title">
                                        <td className="int-set-list-table-platform ml4">
                                            <img src="/icons/vendors-arrow-down.svg" alt="" />
                                            <span className="int-text ml2">{m.zeoosName}</span>
                                        </td>
                                        <td className="int-set-list-table-active">
                                            {m.totalProducts} active products
                                        </td>
                                        <td className="int-set-list-table-inactive">{m.processes[0] ? m.totalProducts - m.processes[0].totalProducts : 0}</td>
                                        <td className="int-set-list-table-inactive">0</td>
                                        <td className="int-set-list-table-rounds">
                                            <img src="/icons/int-robot.svg" alt="" />
                                            <b className="ml2">{m.processes.length}</b>
                                        </td>
                                        <td className="int-set-list-table-inactive">
                                            <IntelligenceSettingsActions zeoosName={m.zeoosName} sellerId={clientRobot.sellerId} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </AccordionSummary>

                        <AccordionDetails className="width100 p0 m0div">
                            <table className="table-results mheightauto">
                                <thead>
                                    <tr className="table-result-box int-text border0 width100 m0 height30 width100">
                                        <th className="int-set-list-table-active ml4">Round ID</th>
                                        <th className="int-set-list-table-active">Robot round type</th>
                                        <th className="int-set-list-table-inactive">Round products </th>
                                        <th className="int-set-list-table-active">Status</th>
                                        <th className="int-set-list-table-update">Last update</th>
                                        <th className="int-set-list-table-inactive">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {m.processes?.map(p => (
                                        <tr key={p.processId} className="table-result-box int-text border0 width100 m0 height30 width100">
                                            <td className="int-set-list-table-active ml4">{p.processId}</td>
                                            <td className="int-set-list-table-active">{p.type}</td>
                                            <td className="int-set-list-table-inactive">{p.totalProducts}</td>
                                            <td className="int-set-list-table-active">
                                                <AntSwitch checked={p.status} />
                                                <span className="ml2">{p.status ? 'Active' : 'Inactive'}</span>
                                            </td>
                                            <td className="int-set-list-table-update">23/02/2023 - 10:00 AM</td>
                                            <td className="int-set-list-table-inactive">
                                                <NavLink to={`/intelligence/robot/${p.processId}`} className="int-nav-link">
                                                    Setup
                                                </NavLink>
                                                <div className="int-nav-link">Log Report</div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </AccordionDetails>
        </Accordion>
    );
};
