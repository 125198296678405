import React, { useState, useDeferredValue } from 'react';
import "./notifications/noties.css";
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import useNotifications from '../../../hooks/useNotifications';
import { useClickOutside } from '@mantine/hooks';
import { readAllNotifcations } from '../../../actions/notifications';
import { Link } from 'react-router-dom';
import { getNotificationImage } from '../../routes/notifications/Notifications';
import { DateTime } from 'luxon';
import RoleRequired from '../../utils/role-required/RoleRequired';
import { Role } from '../../routes/users-management/UsersManagement';

export const StyledMenu = withStyles({
	paper: {
		background: '#FFFFFF',
		border: '1px solid #E1E1E1',
		boxSizing: 'border-box',
		boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
		width: '400px',
		maxHeight: '100%',
		height: '100%',
		overflowY: 'auto',
		marginTop: '75px',
		paddingTop: '10px',
		paddingBottom: '0',
		marginLeft: "15px",
	  },
})((props: MenuProps) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
		}}
		{...props}
	/>
));

export const NotiesSection = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [config, setConfig] = useState({
		perPage: 10,
		page: 1,
		wasRead: undefined as undefined | false,
		my: true,
	});
	const debouncedConfig = useDeferredValue(config);
	const notifications = useNotifications(debouncedConfig);

	const unread = useNotifications({
		perPage: 1,
		page: 1,
		wasRead: false,
		my: true,
	}, 'unread-notifications');

	const [open, setOpen] = useState<boolean>(false);

	const handleDropDownFocus = (state: boolean) => {
		setOpen(!state);
	};

	const dropdownRef = useClickOutside(() => {
		if (open) {
			setOpen(false);
		}
	});

	return (
		<div className="header-circle" onClick={(e) => setAnchorEl(e.currentTarget)}>
			<img src="/icons/notification-white.svg" alt="" />
			<div className="new-notification">
				<img
					src="/icons/new-notification.svg"
					width="18px"
					alt=""
				/>
				<div className="number-notifications">{unread.data?.total}</div>
			</div>

			<div>
				<StyledMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
					<div style={{ marginTop: "-15px" }} className="notification-title">
						<div className="in-row align-center">
							<img src="/icons/notification.svg" alt="" />
							<div className="pl1">Notifications</div>
						</div>
						<div className="app-drop-down-container justify-end ml2" ref={dropdownRef}>
							<button onClick={(e) => handleDropDownFocus(open)}><img className="pointer not-dotts-btn ml2" src="/icons/menu-settings-dots.svg" alt="" /></button>
							{open && (
								<ul>
									<li className="in-row align-center not-menuitem-cont" onClick={async () => {
										try {
											await readAllNotifcations();
											notifications.invalidate();
											unread.invalidate();
										} catch (error) {
											console.error(error);
										}
									}}>
										<img src="/icons/not-check-dotts.svg" alt="" />
										<span className="not-menuitem-text initial">Mark all as read</span>
									</li>
									<Link to="/notifications-settings">
										<li className="in-row align-center not-menuitem-cont">
											<img src="/icons/not-set-dotts.svg" alt="" />
											<span className="not-menuitem-text initial">Notifications configuration</span>
										</li>
									</Link>
								</ul>
							)}
						</div>
					</div>
					<div className="width100 in-row alugn-center justify-between">
						<div className="in-row align-center ml2">
							<button style={config.wasRead === undefined ? { background: '#FCE8A4' } : {}} onClick={() => setConfig(c => ({ ...c, wasRead: undefined }))} className="not-table-btn">All events</button>
							<button style={config.wasRead === false ? { background: '#FCE8A4' } : {}} onClick={() => setConfig(c => ({ ...c, wasRead: false }))} className="not-table-btn">Unread</button>
						</div>
						<Link to="/notifications">
							<button className="not-table-btn ml4">See all</button>
						</Link>
					</div>
					<div className="table-modal-border"></div>
					<div style={{ maxHeight: "500px", overflow: "visible" }} className="width100 in-column alugn-start justify-start">
						<>
							{notifications.data?.total ? (
								notifications.data?.data.map(notification =>
									<React.Fragment key={notification._id}>
										<div className="not-notifications width100">
											<img src={getNotificationImage(notification.type)} width={48} alt="" />
											<div className="not-table-cont">
												<span className="">{notification.title}</span>
												<span style={{ fontSize: "12px" }} className="opacity5 bold">{DateTime.fromISO(notification.created as any).toFormat("dd/LL/yyyy 'at' HH:mm")}</span>
												<RoleRequired role={Role.admin}>
													{/* @ts-ignore */}
													{notification.user.username && <span style={{ fontSize: "12px" }} className="opacity5 bold">Seller name: {notification.user.username}</span>}
												</RoleRequired>
											</div>
										</div>

										<div className="table-modal-border" />
									</React.Fragment>
								)
							) : (
								<div className="table-result-box in-column justify-center border0 m0 height100px width100">
									<img src="/icons/not-alert-table.svg" alt="" />
									<span>You have no notifications alerts.</span>
								</div>
							)}
						</>
					</div>
				</StyledMenuComponent>
			</div>
		</div>
	);
};

interface INotiesMenu {
	anchorEl: null | HTMLElement;
	setAnchorEl: (anchorEl: null | HTMLElement) => void;
	children: React.ReactNode;
}

export const StyledMenuComponent: React.FC<INotiesMenu> = ({ anchorEl, setAnchorEl, children }) => {
	const onCloseNoties = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<StyledMenu style={{ width: "320px", }}
			id="customized-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={onCloseNoties}
		>
			{children}
		</StyledMenu>
	);
};
