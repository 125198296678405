import React, { memo, useCallback, useMemo, useState } from "react";
import { IPaginatedRequest } from "../../../../interfaces";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import queryString from "query-string";
import { ILogisticWarehouse } from "types";
import { getPaginatedWarehouses } from "../../../../actions/logistic";
import Preloader from "../../../ui/preloader/Preloader";
import Paginator from "../../../ui/pagination";
import StatusWarehouse from "./StatusWarehouse";
import CardInfoWarehouse from "./CardInfoWarehouse";
import CreateEditWarehouse from "./CreateEditWarehouse";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import TooltipIcon from "../TooltipIcon";

interface Props {
	setWarehouseId: (warehouseId: React.SetStateAction<string>) => void;
}

const Warehouses: React.FC<Props> = ({ setWarehouseId }) => {
	const [editModal, setEditModal] = useState<boolean>(false);
	const [cardModal, setCardModal] = useState(false);
	// eslint-disable-next-line
	const [feesModal, setFeesModal] = useState(false);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
	const [selectedWarehouse, setSelectedWarehouse] =
		useState<ILogisticWarehouse>();

	const rqKeyWarehouses = useMemo(() => {
		return `/logistic-warehouses?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const { isLoading, data: warehouses } = useQuery(rqKeyWarehouses, () =>
		getPaginatedWarehouses(debouncedConfig)
	);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	return (
		<>
			{isLoading ? (
				<Preloader />
			) : (
				<div className="table-main-cont">
					<div style={{ padding: "0 40px" }} className="width100">
						<div className="table-main-title-cont">
							<div className="table-main-title-search-value-cont">
								<div className="search-box">
									<div className="search_form">
										<div className="table-search-box">
											<form
												className="table-search-form"
												onSubmit={(e) => e.preventDefault()}
											>
												<input
													className="table-search-input"
													placeholder="Search warehouse"
													onChange={(e) =>
														setCurrentConfig((conf: IPaginatedRequest) => ({
															...conf,
															search: e.target.value,
														}))
													}
													value={currentConfig.search}
												/>
												<img
													className="table-search-image"
													src="/icons/search.svg"
													width="25px;"
													alt=""
												/>
											</form>
										</div>
									</div>
									<div className="table-main-title-text pl2">
										<span className="table-main-title-text">
											{warehouses?.total}{" "}
											{warehouses?.total > 1 ? "warehouses" : "warehouse"}
										</span>
									</div>
								</div>
							</div>
							<div className="marketplace-pagination">
								<Paginator data={warehouses} refetch={onChangePage as any} />
							</div>
						</div>
						<div style={{ height: "max-content" }} className="table-overflow">
							<table
								style={{
									position: "sticky",
									zIndex: 101,
									top: "2px",
									width: "100%",
								}}
							>
								<thead>
									<tr
										style={{ marginTop: "-5px", width: "100%" }}
										className="table-results-title"
									>
										<th className="warehouse-name">Warehouse</th>
										<th className="warehouse-country">Country of operation</th>
										<th className="warehouse-partner">Logistic Partners</th>
										<th className="warehouse-status">Status</th>
										<th className="warehouse-contact">Contact</th>
										<th className="warehouse-fees">Fees</th>
									</tr>
								</thead>
							</table>
							<table
								className="table-results mb2 batch-text"
								style={{ minHeight: "auto" }}
							>
								<tbody>
									{warehouses?.data?.map((warehouse: ILogisticWarehouse) => (
										<tr
											className="table-result-box height100px"
											key={warehouse._id}
										>
											<td
												className="warehouse-name pointer"
												onClick={(e) => {
													e.preventDefault();

													setWarehouseId(warehouse._id);
												}}
											>
												<span>{warehouse.warehouseName}</span>
											</td>
											<td className="warehouse-country">
												<CountryFlag
													country={warehouse.country}
													className="table-search-image"
													width="32px"
												/>
												<span>{warehouse.country}</span>
											</td>
											<td className="warehouse-partner">
												<img
													className="mr2"
													width="50px"
													// height="50px"
													src={warehouse.logisticPartner.logo}
													alt=""
												/>
												<span>{warehouse.logisticPartner.partnerName}</span>
											</td>
											<StatusWarehouse
												warehouse={warehouse}
												rqKeyWarehouses={rqKeyWarehouses}
											/>
											<td className="warehouse-contact">
												<TooltipIcon
													title={"See Contact Info"}
													handleClick={(e: React.MouseEvent) => {
														e.preventDefault();

														setCardModal(true);
														setSelectedWarehouse(warehouse);
													}}
													icon={"/icons/contact-icon.svg"}
													iconHover={"/icons/contact-icon-hover.svg"}
													size={{ w: "60px", h: "40px" }}
												/>
											</td>
											<td className="warehouse-fees">
												<TooltipIcon
													title={"See Logistic Fees"}
													handleClick={(e: React.MouseEvent) => {
														e.preventDefault();

														setFeesModal(true);
														setSelectedWarehouse(warehouse);

														setWarehouseId(warehouse._id);
													}}
													icon={"/icons/tax-fee-icon.svg"}
													iconHover={"/icons/tax-fee-icon-hover.svg"}
													size={{ w: "60px", h: "40px" }}
												/>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							{editModal && (
								<CreateEditWarehouse
									warehouse={selectedWarehouse}
									rqKeyWarehouses={rqKeyWarehouses}
									modal={editModal}
									setModal={setEditModal}
								/>
							)}
							{cardModal && (
								<CardInfoWarehouse
									warehouse={selectedWarehouse || warehouses?.data[0]}
									modal={cardModal}
									setModal={setCardModal}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default memo(Warehouses);
