import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import {
	massUpdateCountryPrice,
	massUpdatePvpFinal,
} from "../../../../actions/vendor";
import { Modal } from "../../../ui/modal/Modal";
import Preloader from "../../../ui/preloader/Preloader";
import "../../pim/mass/mass-import.css";
// import { CountriesContext } from "../../../context";
// import { ICountryM } from "../../country-management/CountryManagement";

interface Props {
	onSuccess: () => void;
	onError: () => void;
	modal: boolean;
	setModal: (modal: boolean) => void;
	mode: string;
}

const MassUpdateCountryPrice: React.FC<Props> = ({
	onSuccess,
	onError,
	modal,
	setModal,
	mode,
}) => {
	// const countries = useContext(CountriesContext) as ICountryM[];

	// const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [report, setReport] = useState<any>(null);
	// const [schemesList, setSchemesList] = useState<any>([]);

	// const [scheme, setScheme] = useState("");
	// const [lang, setLang] = useState("");
	// const [separator, setSeparator] = useState(",");

	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({
			accept: ["application/xls", ".xlsx"],
			onDropRejected: () => {
				toast.error('File type rejected. Only ".xlsx" files are allowed.');
			},
		});

	useEffect(() => {
		async function initializeState() {
			// const productSchemes = await getSchemes();
			// setSchemesList(productSchemes);
			// setScheme(productSchemes[0]._id);
			// setLang(countries[0].langCode);
		}
		initializeState();
		// eslint-disable-next-line
	}, []);

	const dropzone = (
		<form>
			<h2 className="import-title">Mass pricing update</h2>
			<div
				className="width100 import-reviews-dropzone"
				style={{ width: "400px", height: "200px" }}
				{...getRootProps()}
			>
				{/* @ts-ignore */}
				<input {...getInputProps()} />
				{isDragActive ? (
					<h4>Drop the files here or click me to select ...</h4>
				) : (
					<h2 style={{ color: "#000" }}>
						{acceptedFiles.length > 0
							? `Click 'Import' to finish upload ${acceptedFiles[0].name}`
							: "Drop the file here"}
					</h2>
				)}
			</div>
			{/* <div className="import-options mt2">
        <label>
          Product scheme:
          <select
            className="formEdit__input"
            value={scheme}
            onChange={(e) => setScheme(e.target.value)}
          >
            {schemesList.map((scheme: any) => {
              return <option value={scheme._id}>{scheme.name}</option>;
            })}
          </select>
        </label>
        <label>
          Language:
          <select
            className="formEdit__input"
            name="lang"
            value={lang}
            onChange={(e) => setLang(e.target.value)}
          >
            {countries.map((item) => {
              return <option value={item.langCode}>{item.language}</option>;
            })}
          </select>
        </label>
        <label>
          Separator:
          <input
            type="text"
            className="formEdit__input"
            maxLength={1}
            required
            value={separator}
            onChange={(e) => setSeparator(e.target.value)}
          />
        </label>
      </div> */}
			{acceptedFiles.length > 0 && (
				<div className="width100 justify-end mt2">
					<button
						className="singlemarketplace-addproducts-button"
						onClick={async (e) => {
							e.preventDefault();
							setLoading(true);
							try {
								// console.log(acceptedFiles);
								const { report } =
									mode === "pricing"
										? await massUpdateCountryPrice(acceptedFiles[0], 'auto')
										: await massUpdatePvpFinal(acceptedFiles[0]);
								setReport(report);
								onSuccess();
							} catch (error) {
								console.error(error);
								onError();
							} finally {
								setLoading(false);
							}
						}}
					>
						Import
					</button>
				</div>
			)}
		</form>
	);

	return (
		<div className="mass-import-box">
			{/* <div className='create-product-box'>
                <button className='singlemarketplace-addproducts-button m0' onClick={() => setModal(true)}>Mass import</button>
            </div> */}
			{modal && (
				<Modal isOpened={modal} onModalClose={() => setModal(false)}>
					<div className="width100 height100">
						{loading ? (
							<Preloader />
						) : !report ? (
							dropzone
						) : (
							<div className="in-column align-start justify-start ft3 import-result">
								<span className="mb4">Uploaded: {report.uploaded}</span>
								<span className="mb4">Succeded: {report.succeded}</span>
								<span className="mb4">Rejected: {report.rejected}</span>
								Messages:
								<span className="mb4 ft1 import-error">
									{" "}
									{report.messages.map((msg: string) => (
										<>
											{msg}
											<br />
										</>
									))}
								</span>
								<button
									className="singlemarketplace-addproducts-button"
									onClick={() => setReport(null)}
								>
									Try again
								</button>
							</div>
						)}
					</div>
				</Modal>
			)}
		</div>
	);
};

export default MassUpdateCountryPrice;
