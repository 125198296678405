import React, { memo, useMemo, useState } from "react";
import "./batchList.css";
import { IPaginatedRequest, IBatchList } from "../../../../interfaces";
// import SyncNav from "../../../ui/sync-nav/SyncNav";
import FilterSelect from "../../../ui/select/FilterSelect";
import BatchListTable from "./BatchListTable";
import { useDebouncedValue } from "@mantine/hooks";
import { useQuery } from "react-query";
import queryString from "query-string";
import { getPaginatedBatchList } from "../../../../actions/batch";
import * as _ from "lodash";
import InventoryNav from "../../../ui/inv-nav/InventoryNav";

export enum BatchStatus {
	WAITING = "WAITING",
	RUNNING = "RUNNING",
	FINISHED = "FINISHED",
	ERROR = "ERROR",
}

const BatchList: React.FC = () => {
	const [user, setUser] = useState<string[]>([]);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		status: "All statuses",
		user: "All users",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const rqKey = useMemo(() => {
		return `/batch-items?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const { isLoading, data: batchList } = useQuery(rqKey, async () => {
		const batchList = await getPaginatedBatchList(debouncedConfig);
		setUser((prev) =>
			!!prev.length
				? prev
				: [
						"All users",
						..._.uniq(
							_.map(batchList?.data, (item: IBatchList) => item.user.username)
						),
				  ]
		);
		return batchList;
	});

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont p0">
					{/* <SyncNav />
					<div className="mapping-title-border"></div> */}
					<div className="table-title-cont">
						<div className="mplc-filter-cont width100">
						<InventoryNav />
							<div className="mplc-filter">
								<div className="filter-text">Filter by:</div>
								<FilterSelect
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
										setCurrentConfig((conf: IPaginatedRequest) => ({
											...conf,
											status: e.target.value,
										}))
									}
									value={currentConfig.status}
									data={user}
								/>
								<FilterSelect
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
										setCurrentConfig((conf: IPaginatedRequest) => ({
											...conf,
											status: e.target.value,
										}))
									}
									value={currentConfig.status}
									data={["All statuses", ...Object.values(BatchStatus)]}
								/>
							</div>
						</div>
					</div>
					<BatchListTable
						batchList={batchList}
						setCurrentConfig={setCurrentConfig}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</div>
	);
};

export default memo(BatchList);
