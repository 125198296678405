import React, { useContext } from "react";
import { isValidPermission, TPermission } from "../../../actions/user";
import useUser from "../../../hooks/useUser";
import { Role } from "../../routes/users-management/UsersManagement";

interface Props extends React.PropsWithChildren {
  role?: string;
  roles?: string[];
  permission?: TPermission;
  strict?: boolean;
}

const RoleRequired: React.FC<Props> = ({
  children,
  role,
  roles,
  permission,
  strict = false,
}) => {
  const { user } = useUser();

  if (user.role === Role.admin && !strict) {
    return <>{children}</>;
  }

  return (
    <>
      {(role === user.role ||
        roles?.includes(user.role) ||
        (permission && isValidPermission(permission, user.permissions))) &&
        children}
    </>
  );
};

export default RoleRequired;
