import axios from "axios";
import { IPaginatedRequest, SchemeRootObject } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export const postScheme = async (name: string) => {
  const res = await axios.post(
    "/productSchemes",
    { name, details: { tabs: [] } },
    getHeaders()
  );

  return res.data.productScheme;
};

export const getSchemes = async () => {
  const res = await axios.get("/productSchemes", getHeaders());

  return res.data.schemes;
};

export const getSchemeVersions = async (
  name: string,
  pagination: IPaginatedRequest
) => {
  const res = await axios.get(
    `/productSchemes/versions/${name}?${queryString.stringify(pagination)}`,
    getHeaders()
  );

  return res.data;
};

export const getSchemeById = async (id: string) => {
  const res = await axios.get(`/productSchemes/${id}`, getHeaders());

  return res.data.schemes[0] as SchemeRootObject;
};

export const getSchemeVersionByName = async (name: string) => {
  const res = await axios.get(`/schemeVersion/${name}`, getHeaders());

  return res.data.version;
};

export const deleteSchemeById = async (id: string) => {
  const res = await axios.delete(`/productSchemes/${id}`, getHeaders());
  return res;
};

export async function deleteSchemeVersion(id: string) {
  await axios.delete(`/schemeVersions/${id}`, getHeaders());
}

export const setScheme = async (body: any) => {
  const res = await axios.put(
    `/productSchemes/${body._id}`,
    body,
    getHeaders()
  );

  return res;
};
