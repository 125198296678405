import React, { useState, useCallback } from "react";
import Paginator from "../../ui/pagination";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../users-management/UsersManagement";
import useUser from "../../../hooks/useUser";
import { getMarketplaceProductImage } from "../../../actions/pim";
import { IPaginatedRequest, IProductGestao } from "../../../interfaces";
import Tooltip from "@mui/material/Tooltip";
import ConditionalLink from "../../utils/conditional-link";

interface Props {
	products: any;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
	selectedProducts: string[];
	setSelectedProducts: React.Dispatch<React.SetStateAction<string[]>>;
}

export const PimMainTable: React.FC<Props> = ({
	products,
	setCurrentConfig,
	currentConfig,
	selectedProducts,
	setSelectedProducts,
}) => {
	const { user } = useUser();

	const [hide, setHide] = useState(false);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	const handleSelectAll = useCallback(() => {
		setSelectedProducts((prev) =>
			!prev.length ? products?.data.map((x: any) => x.sku) : []
		);
	}, [products?.data, setSelectedProducts]);

	const handleSelectOne = useCallback(
		(item: IProductGestao) => () => {
			setSelectedProducts((prev) => {
				if (prev.find((x) => x === item.sku)) {
					return prev.filter((x) => x !== item.sku);
				}
				return [...prev, item.sku];
			});
		},
		[setSelectedProducts]
	);

	const toggleHide = () => {
		setHide(!hide);
	};

	return (
		<>
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont">
					<div className="table-search-box ml2">
						<form
							className="table-search-form"
							onSubmit={(e) => e.preventDefault()}
						>
							<input
								className="table-search-input"
								placeholder="Search by title or sku"
								value={currentConfig.search}
								onChange={(e) =>
									setCurrentConfig((conf: IPaginatedRequest) => ({
										...conf,
										search: e.target.value,
									}))
								}
								autoFocus
							/>
							<img
								className="table-search-image"
								src="/icons/search.svg"
								width="25px;"
								alt=""
							/>
						</form>
					</div>
					<div className="table-value">
						{["gv@vinuus.com", "pj@vinuus.com"].includes(user.email) && (
							<RoleRequired role={Role.admin}>
								<img
									className="refresh-image"
									src="/icons/refresh.svg"
									width="20px;"
									alt=""
								/>
							</RoleRequired>
						)}
						<span className="table-main-title-text">
							{products?.total} PRODUCTS
						</span>
					</div>
				</div>
				<div className="marketplace-pagination">
					<div className="mr2 in-row align-center">
						<img
							onClick={toggleHide}
							className="mr1 pointer"
							style={{
								width: "30px",
								height: "30px",
								display: hide ? "flex" : "none",
							}}
							src="/icons/pim-main-tab1.svg"
							alt=""
						/>
						<img
							onClick={toggleHide}
							className="mr1 pointer"
							style={{
								width: "30px",
								height: "30px",
								display: hide ? "none" : "flex",
							}}
							src="/icons/pim-main-tab1active.svg"
							alt=""
						/>
						<img
							onClick={toggleHide}
							className="pointer"
							style={{
								width: "30px",
								height: "30px",
								display: hide ? "none" : "flex",
							}}
							src="/icons/pim-main-tab2.svg"
							alt=""
						/>
						<img
							onClick={toggleHide}
							className="mr1 pointer"
							style={{
								width: "30px",
								height: "30px",
								display: hide ? "flex" : "none",
							}}
							src="/icons/pim-main-tab2active.svg"
							alt=""
						/>
					</div>
					<Paginator data={products} refetch={onChangePage as any} />
				</div>
			</div>
			<div
				style={{ display: hide ? "none" : "flex" }}
				className="mt2 mb2 width100 wrap justify-start aligm-start"
			>
				{products?.data.map((item: any, index: number) => (
					<div className="product-main-box">
						<div className="width100 justify-between align-center">
							<div className=""></div>
							{
								<Tooltip title="Preview" placement="left">
									<a
										className="underline"
										target={"_blank"}
										rel="noreferrer"
										href={`${process.env.REACT_APP_PREVIEW_URL}/pt/${item.sku}`}
									>
										<img src="/icons/preview-link-blue.svg" alt="" />
									</a>
								</Tooltip>
							}
						</div>
						<ConditionalLink
							condition={user.role === Role.admin}
							to={`/productInfo/${item.sku}`}
							key={`${item.sku}-${index}`}
							className="in-row"
						>
							<div className="width100 justify-center">
								<img
									className="product-main-box-image"
									src={getMarketplaceProductImage(item)}
									alt=""
								/>
							</div>
						</ConditionalLink>
						<div className="in-column justify-start align-start width100 mheight100px">
							<div className="product-main-box-title-cont">
								<div className="in-column align-start">
									<div>SKU:</div>
									<div className="bold">{item.sku}</div>
								</div>
								<div className="reverse-pim-main-box-title">
									<div>EAN:</div>
									<div className="bold">{item.barcode}</div>
								</div>
							</div>
							<div>{item.name}</div>
						</div>
					</div>
				))}
			</div>
			<div
				style={{
					display: hide ? "block" : "none",
					height: "max-content",
					marginBottom: "20px",
				}}
				className="table-overflow"
			>
				<table
					style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
				>
					<tbody>
						{/* {selectedProducts.length ? (
							<tr
								style={{
									marginTop: "-5px",
									width: "100%",
								}}
								className="table-results-title"
							>
								<th
									className={`${
										user?.role !== Role.admin && "border10"
									} pim-selected-products pointer`}
									onClick={handleSelectAll}
								>
									<img
										className="mr2"
										src={
											selectedProducts.length
												? "/icons/carbon_checkbox.svg"
												: "/icons/check-box.svg"
										}
										alt=""
									/>
									<span>{selectedProducts.length} selected</span>
								</th>
								<RoleRequired role={Role.admin}>
									<th
										className="pim-batch pointer"
										onClick={() => {
											setBatch(true);
											toast.success("Products added to batch");
										}}
									>
										<span>Add to batch</span>
									</th>
								</RoleRequired>
							</tr>
						) : ( */}
						<tr
							style={{ marginTop: "-5px", width: "100%" }}
							className="table-results-title"
						>
							<th className="ml3 mr3">
								<img
									className="pointer"
									src={
										selectedProducts.length
											? "/icons/carbon_checkbox.svg"
											: "/icons/check-box.svg"
									}
									alt=""
									onClick={handleSelectAll}
								/>
							</th>
							<th className="gestao-image-link"></th>
							<th className="gestao-image"></th>
							<th className="pim-main-sku">Sku</th>
							<th className="pim-main-ean">EAN</th>
							<th className="pim-main-title">Title</th>
							<th className="pim-main-producer">Producer</th>
							<th className="pim-main-producer">Preview</th>
						</tr>
						{/* )} */}
					</tbody>
				</table>
				<div>
					<table className="table-results p0">
						<tbody>
							{products?.data.map((item: any, index: number) => (
								<tr className="pim-table-result-box gestao-sku-text">
									<td className="ml3 mr3" onClick={handleSelectOne(item)}>
										<img
											className="pointer"
											src={
												!selectedProducts.find((x) => x === item.sku)
													? "/icons/check-box.svg"
													: "/icons/check-box-checked.svg"
											}
											alt=""
										/>
									</td>
									<ConditionalLink
										condition={user.role === Role.admin}
										to={`/productInfo/${item.sku}`}
										key={`${item.sku}-${index}`}
										className="in-row"
									>
										<td className="gestao-image-link">
											<img
												src={
													!item.components?.length ||
													item.components?.length > 1
														? `/icons/group-p.svg`
														: `/icons/arrow-return-right.svg`
												}
												alt=""
											/>
										</td>
										<td className="gestao-image">
											<img
												src={getMarketplaceProductImage(item)}
												alt=""
												className="gestao-table-image"
											/>
										</td>
									</ConditionalLink>
									<td className="pim-main-sku">{item.sku}</td>
									<td className="pim-main-ean">{item.barcode}</td>
									<td className="pim-main-title">{item.name}</td>
									<td className="pim-main-producer">{item.brand}</td>
									<td className="pim-main-producer">
										{
											<a
												className="underline in-row align-center"
												target={"_blank"}
												style={{ color: "#E51B15" }}
												rel="noreferrer"
												href={`${process.env.REACT_APP_PREVIEW_URL}/pt/${item.sku}`}
											>
												<img
													className="mr2"
													src="/icons/preview-link-blue.svg"
													alt=""
												/>
												Preview
											</a>
										}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};
