import React, { memo, useCallback, useMemo, useState } from "react";
import * as _ from "lodash";
import { Modal } from "../../../ui/modal/Modal";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import { ICountryM } from "../../country-management/CountryManagement";
import useCountries from "../../../../hooks/useCountries";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import type { ILogisticPartner } from "types";
import * as LogisticController from "../../../../actions/logistic";
import RateWindowModal from "./RateWindowModal";

export type ILogisticPartnerWithFile = ILogisticPartner & { file: File; };

interface Props {
	partner?: ILogisticPartner;
	rqKeyPartners?: string;
	modal: boolean;
	setModal: (value: React.SetStateAction<boolean>) => void;
}

const CreateEditPartner: React.FC<Props> = ({
	partner,
	rqKeyPartners,
	modal,
	setModal,
}) => {
	const countries = useCountries() as ICountryM[];

	const [partnerName, setPartnerName] = useState(partner?.partnerName || "");
	const [country, setCountry] = useState(partner?.country || "Select country");
	const [phone, setPhone] = useState(partner?.phone || "");
	const [contactName, setContactName] = useState(partner?.contactName || "");
	const [email, setEmail] = useState(partner?.email || "");
	const [over, setOver] = useState<boolean>(false);
	const [rateWindow, setRateWindow] = useState<boolean>(false);

	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({
			accept: [".svg", "image/png", "image/jpeg"],
			onDropRejected: () => {
				toast.error(
					'File type rejected. Only ".svg" ".png" or ".jpeg" files are allowed.'
				);
			},
			maxFiles: 1,
			// 10mb
			maxSize: 1e7,
		});

	const refetchPartnerConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyPartners);
			toast.success(
				`The partner is successfully ${partner ? "updated" : "created"}!`
			);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const createMutation = useMutation(
		(data: ILogisticPartnerWithFile) => LogisticController.createPartner(data),
		refetchPartnerConfig
	);

	const editMutation = useMutation(
		(data: ILogisticPartnerWithFile) => LogisticController.updatePartner(data),
		refetchPartnerConfig
	);

	const deleteMutation = useMutation(
		(id: string) => LogisticController.deletePartner(id),
		refetchPartnerConfig
	);

	const data = useMemo(
		() => ({
			...partner,
			partnerName,
			country,
			phone,
			contactName,
			email,
		}),
		[partner, partnerName, country, phone, contactName, email]
	) as ILogisticPartnerWithFile;

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			if (acceptedFiles.length) {
				data.file = acceptedFiles[0];
			}

			if (partner) {
				await editMutation.mutateAsync(data);
			} else {
				await createMutation.mutateAsync(data);
			}

			setModal(false);
		},
		[partner, setModal, acceptedFiles, createMutation, data, editMutation]
	);

	const onDeleteHandler = useCallback(
		async (e: React.MouseEvent) => {
			e.preventDefault();

			if (partner) {
				await deleteMutation.mutateAsync(partner?._id);
			}

			setModal(false);
		},
		[deleteMutation, partner, setModal]
	);

	const createCountriesArray = (array: ICountryM[]) => {
		return [
			"Select country",
			..._.uniq(_.map(array, (item: ICountryM) => item.name)),
		];
	};

	const onModalClose = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			setModal(false);
		},
		[setModal]
	);

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="partner-modal-cont">
				<div className="table-modal-title-box mt3">
					<div className="partner-modal-title-text">
						<span>{partner ? "Update" : "New"} Logistic Partner</span>
						<span className="opacity0 pointer" onClick={onDeleteHandler}>
							delete
						</span>
					</div>
					{partner && (
						<img
							onMouseOver={() => setOver(true)}
							onMouseOut={() => setOver(false)}
							onClick={() => setRateWindow(true)}
							className="pointer"
							width="20px"
							height="20px"
							src={
								!over ? "/icons/edit-modal.svg" : "/icons/edit-modal-hover.svg"
							}
							alt=""
						/>
					)}
				</div>
				<form className="table-modal-form-cont" onSubmit={onSubmit}>
					<div className="table-modal-border"></div>
					<div className="in-row align-center justify-between width100 mt3 mb3">
						<div className="width40 p2 pointer">
							{partner?.logo ? (
								<img
									className="width100 partner-modal-dropzone"
									height="150px"
									src={partner?.logo}
									alt=""
								/>
							) : (
								<div
									className="width100 partner-modal-dropzone"
									style={{ height: "150px" }}
									{...getRootProps()}
								>
									{/* @ts-ignore */}
									<input {...getInputProps()} />
									{isDragActive ? (
										<h4>Drop the files here or click me to select ...</h4>
									) : (
										<h2 className="partner-modal-dropzone-text">
											{acceptedFiles.length > 0
												? `Click 'Create' to finish upload ${acceptedFiles[0].name}`
												: "LOGO"}
										</h2>
									)}
								</div>
							)}
							<div
								className="width100 in-row align-center justify-between ft1 opacity5"
								{...getRootProps()}
							>
								{/* @ts-ignore */}
								<input {...getInputProps()} />
								<span>Add 480 x 360 image</span>
								<span>|</span>
								<span className="underline bold pointer">Edit logo</span>
							</div>
						</div>
						<div className="width60 in-column justify-start align-start text-start p2">
							<div className="width100 mb3">
								<div>Logistic Partner Name:</div>
								<input
									className="logistic-modal-input"
									value={partnerName}
									onChange={(e) => setPartnerName(e.target.value)}
									required
								/>
							</div>
							<div className="width100 in-row align-center justify-between">
								<div>
									<div>Country:</div>
									<select
										className="partner-modal-select"
										value={country}
										onChange={(e) => {
											setCountry(e.target.value);
										}}
									>
										{createCountriesArray(countries).map(
											(country: string, index: number) => (
												<option key={index}>{country}</option>
											)
										)}
									</select>
								</div>
								<div className="width50">
									<div>Phone:</div>
									<input
										className="logistic-modal-input"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										required
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="width100 in-row align-center justify-between text-start mb6">
						<div className="width40">
							<div>Contact Name:</div>
							<input
								className="logistic-modal-input"
								value={contactName}
								onChange={(e) => setContactName(e.target.value)}
								required
							/>
						</div>
						<div className="width50">
							<div>Email:</div>
							<input
								className="logistic-modal-input"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
							/>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button className="table-modal-form-button" type="submit">
							{partner ? "Update" : "Create"}
						</button>
					</div>
				</form>
			</div>
			{rateWindow && (
				<RateWindowModal
					partner={partner}
					onModalClose={onModalClose}
					rqKeyPartners={rqKeyPartners}
					modal={rateWindow}
					setModal={setRateWindow}
				/>
			)}
		</Modal>
	);
};

export default memo(CreateEditPartner);
