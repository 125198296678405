import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Preloader from "../../../ui/preloader/Preloader";
import { getSanitizedMarketplaces } from "../../../../actions/marketplace";
import { useQuery } from "react-query";
import { IMarketplace } from "../../../../interfaces";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import * as _ from "lodash";

const integratedPlatforms = ["amazon", "fnac", "kaufland", "shopify"];

interface Props {
	addedPlatforms: string[];
	setAddedPlatforms: React.Dispatch<React.SetStateAction<string[]>>;
}

const StepPlatforms: React.FC<Props> = ({
	addedPlatforms,
	setAddedPlatforms,
}) => {
	const [search, setSearch] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);
	const [marketplaceName, setMarketplaceName] = useState<string>("");
	const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
	const [results, setResults] = useState<string[]>([]);

	const { isLoading: loadSanitizedMarkeplaces, data: sanitizedMarkeplaces } =
		useQuery("sanitizedMarkeplaces", () => getSanitizedMarketplaces());

	useEffect(() => {
		const results =
			search === ""
				? _.uniq(
						_.map(
							sanitizedMarkeplaces,
							(item: IMarketplace) => item.marketplaceName
						)
				  )
				: Object.keys(
						_.groupBy(sanitizedMarkeplaces, "marketplaceName")
				  ).filter((x: string) =>
						x.toLowerCase().includes(search.toLowerCase())
				  );
		setResults(results.filter((x: string) => integratedPlatforms.includes(x)));
		// eslint-disable-next-line
	}, [search]);

	const checkSelectedPlatforms = (marketplaceName: string) => {
		const group = _.groupBy(sanitizedMarkeplaces, "marketplaceName");

		const selected = group[marketplaceName].filter((x: IMarketplace) =>
			addedPlatforms.includes(x.zeoosName)
		);

		return `${selected.length} / ${group[marketplaceName].length} Selected countries`;
	};

	const filterByMplcName = () => {
		return sanitizedMarkeplaces.filter(
			(x: IMarketplace) => x.marketplaceName === marketplaceName
		);
	};

	if (loadSanitizedMarkeplaces) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div
			style={{ background: "#E8EDF2", marginTop: "0", paddingTop: "20px" }}
			className="product-cont"
		>
			<h1 className="product-h">Choose the platforms for this batch</h1>
			<div
				style={{ display: !addedPlatforms.length ? "none" : "flex" }}
				className="batch-platform-search-cont"
			>
				<div className="in-column width100 align-start mb4">
					<div className="in-row align-center">
						<span className="batch-upload-text">
							{addedPlatforms.length} selected platforms
						</span>
						<span
							onClick={() => setAddedPlatforms([])}
							className="batch-search-remove pointer"
						>
							remove all
						</span>
					</div>
					<div className="batch-search-variants">
						{addedPlatforms.map((platform: string, index: number) => (
							<div className="batch-search-variant" key={index}>
								{platform}
								<img
									src="/icons/batch-search-close.svg"
									alt=""
									onClick={(e: React.MouseEvent) => {
										e.preventDefault();
										e.stopPropagation();

										setAddedPlatforms((prev: string[]) =>
											prev.filter((x: string) => x !== platform)
										);
									}}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="table-search-box ml2 mt4">
				<form
					className="table-search-form"
					onSubmit={(e) => e.preventDefault()}
				>
					<input
						className="table-search-input"
						placeholder="Search platforms"
						onChange={(e) => setSearch(e.target.value)}
					/>
					<img
						className="table-search-image"
						src="/icons/search.svg"
						width="25px;"
						alt=""
					/>
				</form>
			</div>
			<div className="product-content">
				<div className="batch-platform-cont">
					{results.map((item: string) => (
						<div
							onClick={() => {
								setMarketplaceName(item);
								setOpen(!open);
							}}
							className="batch-platform-box pointer"
						>
							<img
								style={{ width: "200px", height: "200px", padding: "25px" }}
								src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${item}.svg`}
								alt=""
							/>
							<div className="batch-platform-text-cont">
								<span className="mb2 batch-marketplace-text">{item}</span>
								<span className="mb2 batch-platform-selected">
									{checkSelectedPlatforms(item)}
								</span>
							</div>
						</div>
					))}
				</div>
			</div>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={open}
				className="relative"
			>
				<div className="filter-bg" onClick={() => setOpen(false)} />
				<div className="batch-platform-modal-cont">
					<div className="width100">
						<span className="batch-platform-modal-title">
							Select the countries
						</span>
						<div className="batch-platform-modal-content">
							<img
								style={{
									width: "120px",
									height: "120px",
									padding: "25px",
									border: "1px solid #E1E1E1",
									borderRadius: "10px",
									marginRight: "40px",
								}}
								src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${marketplaceName}.svg`}
								alt=""
							/>
							<div className="width100">
								<div
									className="batch-platform-modal-box"
									onClick={(e: React.MouseEvent) => {
										e.preventDefault();

										setSelectedPlatforms((prev: string[]) =>
											!prev.length
												? filterByMplcName().map(
														(x: IMarketplace) => x.zeoosName
												  )
												: []
										);
									}}
								>
									<img
										className="pointer mr3"
										src={
											selectedPlatforms.length
												? "/icons/carbon_checkbox.svg"
												: "/icons/check-box.svg"
										}
										alt=""
									/>
									<div className="in-row align-center">
										<span className="batch-upload-text">Country</span>
									</div>
								</div>
								{filterByMplcName().map((x: IMarketplace) => (
									<div
										className="batch-platform-modal-box"
										onClick={(e: React.MouseEvent) => {
											e.preventDefault();

											setSelectedPlatforms((prev: string[]) =>
												prev.includes(x.zeoosName)
													? prev.filter((y: string) => y !== x.zeoosName)
													: [...prev, x.zeoosName]
											);
										}}
									>
										<img
											className="pointer mr3"
											src={
												selectedPlatforms.includes(x.zeoosName)
													? "/icons/check-box-checked.svg"
													: "/icons/check-box.svg"
											}
											alt=""
										/>
										<div className="in-row align-center">
											<CountryFlag
												country={x.country}
												className="mr2"
												width="32px"
											/>
											<span style={{ color: "#5A5A5A" }}>{x.zeoosName}</span>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div
						style={{ borderTop: "1px solid #E1E1E1" }}
						className="table-modal-form-button-box pt4 width100"
					>
						<button
							className="table-modal-form-button-cancel"
							onClick={() => {
								setSelectedPlatforms([]);
								setOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							className="table-modal-form-button"
							onClick={(e: React.MouseEvent) => {
								e.preventDefault();

								setAddedPlatforms((prev: string[]) => [
									...prev,
									...selectedPlatforms,
								]);
								setSelectedPlatforms([]);
								setOpen(false);
							}}
						>
							Add
						</button>
					</div>
				</div>
			</Backdrop>
		</div>
	);
};

export default StepPlatforms;
