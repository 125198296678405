import React, { useCallback, useMemo, useState } from "react";
import "./users-management.css";
import { createUser } from "../../../actions/user";
import { Modal } from "../../ui/modal/Modal";
import { toast } from "react-toastify";
import * as UserController from "../../../actions/user";
import { queryClient } from "../../App";
import { useMutation } from "react-query";
import { IUser } from "../../../interfaces";
import useUser from "../../../hooks/useUser";
import { Role } from "./UsersManagement";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
// import { AutocompleteVendors } from "./AutocompleteVendors";
// import useRoles from "../../../hooks/useRoles";
// import useUserGroups from "../../../hooks/useUserGroups";
// import RoleRequired from "../../utils/role-required/RoleRequired";
// import { Select } from "@mui/material";

interface CreateUserFormProps {
	onModalClose: (e: React.MouseEvent) => void;
	mappedUser: IUser;
	modal: boolean;
	vendorOptions: any;
	setModal: (modal: boolean) => void;
	rqKey: string;
}

export const CreateUserForm: React.FC<CreateUserFormProps> = ({
	onModalClose,
	modal,
	setModal,
	rqKey,
	// mappedUser,
	// vendorOptions,
}) => {
	// const roles = useRoles();
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [hide, setHide] = useState(false);
	const [password, setPassword] = useState("");
	const [state, setState] = useState(true);
	// const [role, setRole] = useState(roles.sellerUser);
	// const [vendorPermissions, setVendorPermissions] = useState<string[]>([]);
	// const [permissions, setPermissions] = useState<UserController.TPermission[]>(
	// 	mappedUser.permissions || []
	// );
	// const userGroups = useUserGroups();
	// const [userGroup, setUserGroup] = useState("");

	const validate = () => {
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
			throw new Error("Validation error");
		}
	};

	const createNewUser = useMemo(
		() => ({
			username,
			email,
			password,
			isActive: state,
			role: Role.sellerAdmin,
			// permissions,
			// vendorPermissions,
			// group: userGroup,
		}),
		[
			username,
			email,
			password,
			state,
			// role,
			// vendorPermissions,
			// userGroup,
			// permissions,
		]
	);

	const refetchUserConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKey);
			toast.success(`The user is successfully created!`);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const createMutation = useMutation(
		() => createUser(createNewUser as any),
		refetchUserConfig
	);

	const handleSubmit = useCallback(
		async (e: any) => {
			e.preventDefault();

			validate();
			await createMutation.mutateAsync();
			setModal(false);
		},
		// eslint-disable-next-line
		[createMutation, setModal]
	);

	const toggleHide = () => {
		setHide(!hide);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<form
				id="email-form"
				name="email-form"
				className="formUser"
				onSubmit={handleSubmit}
			>
				<div className="formEdit__container">
					<div className="user-title-sticky">
						<div className="table-modal-title-box m0">
							<div className="mapping-modal-title-text">Create User</div>
						</div>
						<div className="table-modal-border"></div>
					</div>
					<span>Email:</span>
					<input
						type="text"
						className="formEdit__input"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
					<div className="in-row align-start width100">
						<div className="width100">
							<span>Username:</span>
							<input
								type="text"
								className="formEdit__input"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								required
							/>
						</div>
						<div className="ml2 width100">
							<span>Password:</span>
							<div className="width100 in-row align-center justify-center">
								<input
									type={hide ? "text" : "password"}
									className="formEdit__input"
									style={{ marginBottom: "0px" }}
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
								<img
									onClick={toggleHide}
									className="ml2 pointer"
									src="/icons/password-hide.svg"
									alt=""
								/>
							</div>
						</div>
					</div>

					{/* <div className="in-row align-start width100 justify-between mb4">
            <div className="width100">
              <span>Role</span>
              <select
                id="field-3"
                name="field-3"
                required
                className="main-select-bi"
                onChange={(e) => setRole(e.target.value as any)}
                value={role}
              >
                {Object.keys(roles).map((key: string, index: number) => (
                  <option
                    className="option-field-user"
                    value={roles[key]}
                    key={index}
                  >
                    {roles[key]}
                  </option>
                ))}
              </select>
            </div>

            <RoleRequired role={roles.admin}>
              {[roles.sellerAdmin, roles.sellerUser].includes(role) && (
                <div className="width100">
                  <span>User Group</span>
                  <Select
                    required
                    onChange={(e) => setUserGroup(e.target.value)}
                    value={userGroup}
                  >
                    {userGroups?.data?.map((ug) => (
                      <option
                        className="option-field-user"
                        value={ug._id}
                        key={ug._id}
                      >
                        {ug.name}
                      </option>
                    ))}
                  </Select>
                </div>
              )}
            </RoleRequired>

            {role === roles.user && (
              <div className="ml2 width100">
                <span>Vendor Permission:</span>
                <AutocompleteVendors
                  vendorOptions={vendorOptions}
                  setVendorPermissions={setVendorPermissions}
                  vendorPermissions={vendorPermissions}
                />
              </div>
            )}
          </div>
          <PermissionSelect
            role={role}
            permissions={permissions}
            setPermissions={setPermissions}
          /> */}

					<div className="in-row align-center width100 justify-start mt4">
						<div className="in-row align-center">
							<div className="switch-width">
								<AntSwitch
									checked={state}
									onChange={() => setState(!state)}
									name="checkedC"
									required
								/>
							</div>
							<span className="email-text ml2">
								{state ? "Active" : "Inactive"}
							</span>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button
							className="table-modal-form-button"
							type="submit"
							value="Create"
						>
							Create
						</button>
					</div>
				</div>
			</form>
		</Modal>
	);
};

export const PermissionSelect: React.FC<{
	permissions: UserController.TPermission[];
	setPermissions: React.Dispatch<
		React.SetStateAction<UserController.TPermission[]>
	>;
	role: Role;
}> = ({ permissions, setPermissions, role }) => {
	const { user } = useUser();

	if (![Role.permissionBased, Role.sellerUser].includes(role)) {
		return <></>;
	}

	return (
		<>
			{UserController.PermissionOptions.filter(
				(p) =>
					user.role === Role.admin ||
					[
						UserController.PermissionScope.dashboard,
						// UserController.PermissionScope.platforms,
						UserController.PermissionScope.inventory,
						UserController.PermissionScope.orders,
						UserController.PermissionScope.billings,
						UserController.PermissionScope.pim,
					].includes(p.value)
			).map((scope) => (
				<div
					key={scope.key}
					className="in-row width100 mt0 pt0 pb0 align-center"
				>
					<span className="userm-edit-perm-text">{scope.label}</span>
					{Object.values(UserController.PermissionTypeOptions).map((type) => (
						<div key={type.key} className="in-row align-center">
							<label className="userm-edit-perm-type-text">{type.label}</label>
							<input
								type="checkbox"
								style={{
									width: 15,
									border: "1px solid #999999",
									height: 15,
									cursor: "pointer",
									background: "#55718A !important",
									marginRight: 20,
								}}
								checked={
									!!permissions?.find(
										(p) => p.scope === scope.value && p.type === type.value
									)
								}
								onChange={(e) =>
									setPermissions((prev) => {
										const newPermission = {
											scope: scope.value,
											type: e.target.checked && type.value,
										} as UserController.TPermission;
										const indexToEdit = permissions.findIndex(
											(p) => p.scope === newPermission.scope
										);
										if (indexToEdit !== -1) {
											return prev.map((p, index) =>
												index === indexToEdit ? newPermission : p
											);
										}
										return [...prev, newPermission];
									})
								}
							/>
						</div>
					))}
				</div>
			))}
		</>
	);
};
