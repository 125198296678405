import React, { forwardRef, useEffect, useMemo, useState } from "react";
import "./businessIntelligence.css";
import "react-datepicker/dist/react-datepicker.css";
import * as _ from "lodash";
import DatePicker from "react-datepicker";
import Backdrop from "@mui/material/Backdrop";
// import { withStyles } from "@material-ui/core/styles";
// import Menu, { MenuProps } from "@material-ui/core/Menu";
import {
	ICombinedOrder,
	ICombinedOrders,
	IVendor,
	// ICombinedProduct,
} from "../../../interfaces";
import moment from "moment";
import { toast } from "react-toastify";

interface Props {
	vendors: IVendor[];
	from: Date | number;
	setDateFrom: (date: Date | number) => void;
	setDate: (date: string) => void;
	to: Date | number;
	setDateTo: (date: Date | number) => void;
	section: string;
	setCountry: (country: string) => void;
	country: string;
	warehouse: string;
	setWarehouse: (warehouse: string) => void;
	category: string;
	setCategory: (category: string) => void;
	brand: string;
	setBrand: (brand: string) => void;
	setMarketplaceMarket: (marketplaceMarket: string) => void;
	marketplaceMarket: string;
	setMarketplaceAccount: (marketplaceMarket: string) => void;
	seller: string;
	setSeller: (seller: string) => void;
	marketplaceAccount: string;
	orders: ICombinedOrders;
	setflteredOrders: any;
}

export const FilterSection: React.FC<Props> = ({
	// vendors,
	from,
	setDateFrom,
	setDate,
	to,
	setDateTo,
	section,
	setCountry,
	country,
	warehouse,
	setWarehouse,
	category,
	setCategory,
	brand,
	setBrand,
	setMarketplaceMarket,
	marketplaceMarket,
	setMarketplaceAccount,
	marketplaceAccount,
	seller,
	setSeller,
	orders,
	// products,
	setflteredOrders,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [aplicarDateFrom, setAplicarDateFrom] = useState<Date | number>(from);
	const [aplicarDateTo, setAplicarDateTo] = useState<Date | number>(to);
	const [aplicarCountry, setAplicarCountry] = useState<string>(country);
	const [aplicarWarehouse, setAplicarWarehouse] = useState<string>(warehouse);
	const [aplicarCategory, setAplicarCategory] = useState<string>(category);
	const [aplicarSeller, setAplicarSeller] = useState<string>(seller);
	const [open, setOpen] = useState(false);
	const [aplicarBrand, setAplicarBrand] = useState<string>(brand);
	const [aplicarMarketplaceMarket, setAplicarMarketplaceMarket] =
		useState<string>(marketplaceMarket);
	const [aplicarMarketplaceAccount, setAplicarMarketplaceAccount] =
		useState<string>(marketplaceAccount);
	const [aplicarOrders, setAplicarOrders] = useState<any>([]);

	useEffect(() => {
		setCountry("All Countries");
		setAplicarCountry("All Countries");
		setWarehouse("All warehouses");
		setCategory("All Categories");
		setBrand("All brands");
		setSeller("All sellers");
		setMarketplaceMarket("All Webstores");
		setMarketplaceAccount("All Marketplaces");
		// eslint-disable-next-line
	}, [section]);

	useEffect(() => {
		const getOrdersBySection = () => {
			switch (section) {
				case "B2C":
					return orders?.b2c;
				case "FlashSales":
					return orders?.b2b;
				case "B2B":
					return orders?.b2b;
				case "General":
					return [...orders!.b2b, ...orders!.b2c];
			}
		};

		const _orders =
			country === "All Countries"
				? getOrdersBySection()
				: getOrdersBySection()?.filter(
						(x: ICombinedOrder) => x.country === country
				  );
		const _orders2 =
			marketplaceMarket === "All Webstores"
				? _orders
				: _orders?.filter(
						(x: ICombinedOrder) => x.sale_marketplace === marketplaceMarket
				  );
		const _orders3 =
			marketplaceAccount === "All Marketplaces"
				? _orders2
				: _orders2?.filter(
						(x: ICombinedOrder) =>
							x.sale_marketplace_account === marketplaceAccount
				  );
		const filteredOrders =
			category === "All Categories"
				? _orders3
				: _orders3
						?.flatMap((x: ICombinedOrder) => x.sale_line_ids)
						.filter((x: any) => x.category_id === category);
		setflteredOrders(filteredOrders);
		setAplicarOrders(filteredOrders);
		// eslint-disable-next-line
	}, [country, marketplaceMarket, marketplaceAccount, section]);

	const filCount = useMemo(() => {
		let obj = {} as any;

		if (country !== "All Countries") {
			obj.country = country;
		} else {
			delete obj.country;
		}
		if (marketplaceMarket !== "All Webstores") {
			obj.marketplaceMarket = marketplaceMarket;
		} else {
			delete obj.marketplaceMarket;
		}
		if (marketplaceAccount !== "All Marketplaces") {
			obj.marketplaceAccount = marketplaceAccount;
		} else {
			delete obj.marketplaceAccount;
		}

		return Object.keys(obj).length;
	}, [country, marketplaceMarket, marketplaceAccount]);

	const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
		<div className="bi__calendar_input" onClick={onClick} ref={ref}>
			{value}
		</div>
	));

	const renderSelects = (section: string, resetFilters: any) => {
		switch (section) {
			case "General":
				return (
					<>
						<CountrySelects
							country={aplicarCountry}
							setCountry={setAplicarCountry}
							orders={orders}
						/>
						{/* {vendors.length > 1 && (
							<SellersSelect
								sellers={vendors}
								seller={aplicarSeller}
								setSeller={setAplicarSeller}
							/>
						)} */}
					</>
				);
			case "B2B":
				return (
					<CountrySelects
						country={aplicarCountry}
						setCountry={setAplicarCountry}
						orders={orders}
					/>
				);
			// case "S&OP":
			//   return (
			//     <OperationsSelects
			//       warehouse={aplicarWarehouse}
			//       setWarehouse={setAplicarWarehouse}
			//       category={aplicarCategory}
			//       setCategory={setAplicarCategory}
			//       brand={aplicarBrand}
			//       setBrand={setAplicarBrand}
			//       products={products}
			//     />
			//   );
			case "B2C":
				return (
					<B2CSelects
						category={aplicarCategory}
						setCategory={setAplicarCategory}
						country={aplicarCountry}
						setCountry={setAplicarCountry}
						setMarketplaceMarket={setAplicarMarketplaceMarket}
						marketplaceMarket={aplicarMarketplaceMarket}
						setMarketplaceAccount={setAplicarMarketplaceAccount}
						marketplaceAccount={aplicarMarketplaceAccount}
						orders={aplicarOrders}
						resetFilters={resetFilters}
					/>
				);
			default:
				return;
		}
	};

	const onAplicarClick = (e: any) => {
		e.preventDefault();
		setDateFrom(aplicarDateFrom);
		setDateTo(aplicarDateTo);
		setDate("Selecione período");
		setCountry(aplicarCountry);
		setWarehouse(aplicarWarehouse);
		setCategory(aplicarCategory);
		setBrand(aplicarBrand);
		setSeller(aplicarSeller);
		setMarketplaceMarket(aplicarMarketplaceMarket);
		setMarketplaceAccount(aplicarMarketplaceAccount);
		setOpen(false);
	};

	const resetFilters = (e: any) => {
		// e.preventDefault();
		setAplicarDateFrom(Date.parse(`${moment().startOf("months")}`));
		setAplicarDateTo(new Date().valueOf());
		setDate("Selecione período");
		setAplicarCountry("All Countries");
		setAplicarWarehouse("All warehouses");
		setAplicarCategory("All Categories");
		setAplicarBrand("All brands");
		setAplicarMarketplaceMarket("All Webstores");
		setAplicarMarketplaceAccount("All Marketplaces");
	};

	return (
		<>
			<div className="table-title-sort">
				<div className="gestao-select-cont">
					<button
						className="main-select-bi no-shadow"
						onClick={(e) => {
							e.preventDefault();
							setAnchorEl(e.currentTarget);
							setOpen(!open);
						}}
					>
						<img src="/icons/bi-filter.svg" alt="" />
						FILTER <span className="orange-text bold">({filCount})</span>
					</button>
				</div>
			</div>
			{anchorEl !== null && (
				<Backdrop
					sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={open}
					className="relative"
				>
					<div className="filter-bg" onClick={() => setOpen(false)} />
					<div className="filter-cont-position">
						<div className="filter-cont">
							<div className="filter-content justify-start">
								<div className="filter-header pt4">
									<div className="filter-header-title">
										Filter <span className="orange-text">({filCount})</span>
									</div>
									<div className="filter-header-reset" onClick={resetFilters}>
										Clear filters
									</div>
								</div>
								<div className="filter-content-cont mt2">
									<div className="filter-container">
										<div className="filter-line"></div>
										<div className="filter-content justify-start">
											{section !== "S&OP" && (
												<div className="filter-datepicker">
													<div className="filter-dates">
														<div className="filter-dates-box">
															<div className="filter-dates-label">From</div>
															<div className="filter-dates-calendar">
																<div>
																	<DatePicker
																		selected={aplicarDateFrom as Date}
																		dateFormat="dd/MM/yyyy"
																		onChange={(date: any) => {
																			setAplicarDateFrom(Date.parse(date));
																		}}
																		customInput={<ExampleCustomInput />}
																	/>
																</div>
																<div className="filter-calendar-icon">
																	<img
																		src="/icons/calendar.svg"
																		alt="calendar"
																	/>
																</div>
															</div>
														</div>
														<div className="filter-dates-box">
															<div className="filter-dates-label">To</div>
															<div className="filter-dates-calendar">
																<div>
																	<DatePicker
																		selected={aplicarDateTo as Date}
																		dateFormat="dd/MM/yyyy"
																		onChange={(date: any) => {
																			setAplicarDateTo(
																				moment(date).endOf("day").valueOf()
																			);
																		}}
																		customInput={<ExampleCustomInput />}
																	/>
																</div>
																<div className="filter-calendar-icon">
																	<img
																		src="/icons/calendar.svg"
																		alt="calendar"
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
											<div className="filter-selects-cont">
												{renderSelects(section, resetFilters)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="filter-border mt4 mb2"></div>
							<div className="filter-buttons width100 m0">
								<button
									className="filter-apply-btn width80"
									onClick={onAplicarClick}
								>
									APPLY
								</button>
								<button
									className="filter-cancel-btn mb2"
									onClick={() => setAnchorEl(null)}
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</Backdrop>
			)}
		</>
	);
};

// const StyledMenu = withStyles({
//   paper: {
//     background: "#FFFFFF",
//     border: "1px solid #E1E1E1",
//     boxSizing: "border-box",
//     boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
//     width: "360px",
//     height: "500px",
//     overflowY: "auto",
//     marginTop: "10px",
//     paddingTop: "10px",
//     paddingBottom: "10px",
//   },
// })((props: MenuProps) => (
//   <Menu
//     elevation={0}
//     getContentAnchorEl={null}
//     anchorOrigin={{
//       vertical: "bottom",
//       horizontal: "center",
//     }}
//     transformOrigin={{
//       vertical: "top",
//       horizontal: "center",
//     }}
//     {...props}
//   />
// ));

// interface IBIFilter {
//   anchorEl: null | HTMLElement;
//   setAnchorEl: (anchorEl: null | HTMLElement) => void;
//   children: React.ReactNode;
// }

// const StyledMenuComponent: React.FC<IBIFilter> = ({
//   anchorEl,
//   setAnchorEl,
//   children,
// }) => {
//   const onCloseFilter = (e: React.MouseEvent) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setAnchorEl(null);
//   };

//   return (
//     <StyledMenu
//       id="customized-menu"
//       anchorEl={anchorEl}
//       keepMounted
//       open={Boolean(anchorEl)}
//       onClose={onCloseFilter}
//     >
//       {children}
//     </StyledMenu>
//   );
// };

const CountrySelects: React.FC<any> = ({ country, setCountry, orders }) => {
	function createArray(obj: ICombinedOrders) {
		return [...obj.b2b, ...obj.b2c];
	}

	function countriesArray(array: ICombinedOrder[]) {
		return [
			"All Countries",
			..._.compact(
				_.uniq(_.map(array, (item: ICombinedOrder) => item.country))
			),
		];
	}

	return (
		<div>
			<select
				className="filter-select"
				value={country}
				onChange={(e) => {
					setCountry(e.target.value);
				}}
			>
				{countriesArray(createArray(orders)).map(
					(item: string, key: number) => (
						<option key={key}>{item}</option>
					)
				)}
			</select>
		</div>
	);
};

// const SellersSelect: React.FC<any> = ({ sellers, seller, setSeller }) => {
// 	const sellersArray = (array: any) => {
// 		return ["All sellers", ..._.uniq(_.map(array, (item: any) => item.name))];
// 	};

// 	return (
// 		<div>
// 			<select
// 				className="filter-select"
// 				value={seller}
// 				onChange={(e) => {
// 					e.preventDefault();
// 					setSeller(e.target.value);
// 				}}
// 			>
// 				{sellersArray(sellers).map((x: string, index: number) => (
// 					<option className="acompan-option" key={index}>
// 						{x}
// 					</option>
// 				))}
// 			</select>
// 		</div>
// 	);
// };

// const OperationsSelects: React.FC<any> = ({
//   warehouse,
//   setWarehouse,
//   category,
//   setCategory,
//   brand,
//   setBrand,
//   products,
// }) => {
//   const warehouseList = Object.keys(products[0].stock_warehouse);

//   function warehousesArray() {
//     return ["All warehouses", ...warehouseList];
//   }

//   function categoriesArray(array: ICombinedProduct[]) {
//     return [
//       "All Categories",
//       ..._.uniq(_.map(array, (item: ICombinedProduct) => item.category_id)),
//     ];
//   }

//   function brandsArray(array: ICombinedProduct[]) {
//     return [
//       "All brands",
//       ..._.uniq(_.map(array, (item: ICombinedProduct) => item.brand)),
//     ];
//   }

//   return (
//     <>
//       <div>
//         <select
//           className="filter-select"
//           value={warehouse}
//           onChange={(e) => {
//             e.preventDefault();
//             setWarehouse(e.target.value);
//             setCategory("All Categories");
//             setBrand("All brands");
//           }}
//         >
//           {warehousesArray().map((item: string, key: number) => (
//             <option key={key}>{item}</option>
//           ))}
//         </select>
//       </div>
//       <div>
//         <select
//           className="filter-select"
//           value={category}
//           onChange={(e) => {
//             e.preventDefault();
//             setCategory(e.target.value);
//             setWarehouse("All warehouses");
//             setBrand("All brands");
//           }}
//         >
//           {categoriesArray(products).map((item: string, key: number) => (
//             <option key={key}>{item}</option>
//           ))}
//         </select>
//       </div>
//       <div>
//         <select
//           className="filter-select"
//           value={brand}
//           onChange={(e) => {
//             e.preventDefault();
//             setBrand(e.target.value);
//             setWarehouse("All warehouses");
//             setCategory("All Categories");
//           }}
//         >
//           {brandsArray(products).map((item: string, key: number) => (
//             <option key={key}>{item}</option>
//           ))}
//         </select>
//       </div>
//     </>
//   );
// };

const B2CSelects: React.FC<any> = ({
	vendors,
	country,
	setCountry,
	// category,
	// setCategory,
	marketplaceMarket,
	setMarketplaceMarket,
	marketplaceAccount,
	setMarketplaceAccount,
	orders,
	resetFilters,
}) => {
	useEffect(() => {
		if (!orders.length) {
			resetFilters();
			toast.error("No match! Please change the filtering options");
		}
		// eslint-disable-next-line
	}, [orders]);

	const [vendorName, setVendorName] = useState("All vendors");

	const getCountriesArray = useMemo(() => {
		return [
			"All Countries",
			..._.uniq(_.map(orders, (item: ICombinedOrder) => item.country)),
		];
	}, [orders]);

	// const getCategoriesArray = useMemo(() => {
	// 	const products = filteredOrders.flatMap(
	// 		(x: ICombinedOrder) => x.sale_line_ids
	// 	);
	// 	const otherCategories = Object.keys(_.groupBy(products, "category_id"));
	// 	return ["All Categories", ...otherCategories];
	// }, [filteredOrders]);

	const getWebstoresArray = useMemo(() => {
		return [
			"All Webstores",
			..._.uniq(orders.map((x: ICombinedOrder) => x.sale_marketplace)),
		];
	}, [orders]);

	const getMarketplacesArray = useMemo(() => {
		return [
			"All Marketplaces",
			..._.uniq(orders.map((x: ICombinedOrder) => x.sale_marketplace_account)),
		];
	}, [orders]);

	const vendorsArray = (array: any) => {
		return ["All vendors", ..._.uniq(_.map(array, (item: any) => item.name))];
	};

	return (
		<>
			<div>
				<select
					className="filter-select"
					value={country}
					onChange={(e) => {
						setCountry(e.target.value);
					}}
				>
					{getCountriesArray.map((item: string, key: number) => (
						<option key={key}>{item}</option>
					))}
				</select>
			</div>
			{/* <div>
				<select
					className="filter-select"
					value={category}
					onChange={(e) => {
						setCategory(e.target.value);
					}}
				>
					{getCategoriesArray.map((item: string, key: number) => (
						<option key={key}>{item}</option>
					))}
				</select>
			</div> */}
			<div>
				<select
					className="filter-select"
					value={marketplaceMarket}
					onChange={(e) => {
						e.preventDefault();
						setMarketplaceMarket(e.target.value);
					}}
				>
					{_.map(getWebstoresArray, (item: string, index: number) => (
						<option className="" key={index}>
							{item}
						</option>
					))}
				</select>
			</div>
			<div>
				<select
					className="filter-select"
					value={marketplaceAccount}
					onChange={(e) => {
						e.preventDefault();
						setMarketplaceAccount(e.target.value);
					}}
				>
					{_.map(getMarketplacesArray, (item: string, index: number) => (
						<option className="" key={index}>
							{item}
						</option>
					))}
				</select>
			</div>
			<div>
				<select
					className="filter-select"
					value={vendorName}
					onChange={(e) => {
						e.preventDefault();
						setVendorName(e.target.value);
					}}
				>
					{vendorsArray(vendors).map((vendorName: string, index: number) => (
						<option className="acompan-option" key={index}>
							{vendorName}
						</option>
					))}
				</select>
			</div>
		</>
	);
};
