import React, { memo, useState, useCallback } from "react";
import { Modal } from "../../../ui/modal/Modal";
import type { ILogisticPartner } from "types";
import RateWindowModal from "./RateWindowModal";

interface Props {
	partner?: ILogisticPartner;
	rqKeyPartners?: string;
	modal: boolean;
	setModal: (value: React.SetStateAction<boolean>) => void;
}

const CardInfoPartner: React.FC<Props> = ({
	partner,
	rqKeyPartners,
	modal,
	setModal,
}) => {
	const [over, setOver] = useState<boolean>(false);
	const [rateWindow, setRateWindow] = useState<boolean>(false);

	const onModalClose = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			setModal(false);
		},
		[setModal]
	);

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="partner-modal-cont">
				<div className="table-modal-title-box mt3">
					<div className="partner-modal-title-text">Logistic Partner</div>
					<img
						onMouseOver={() => setOver(true)}
						onMouseOut={() => setOver(false)}
						onClick={() => setRateWindow(true)}
						className="pointer"
						width="20px"
						height="20px"
						src={
							!over ? "/icons/edit-modal.svg" : "/icons/edit-modal-hover.svg"
						}
						alt=""
					/>
				</div>
				<form className="table-modal-form-cont">
					<div className="table-modal-border"></div>
					<div className="in-row align-center justify-between width100 mt3 mb3">
						<div className="width40 p2 pointer">
							<img
								className="width100 img-border"
								height="150px"
								src={partner?.logo}
								alt=""
							/>
						</div>
						<div className="width60 in-column justify-start align-start text-start p2">
							<div className="width100 mb3">
								<div className="bold">Logistic Partner Name:</div>
								<div>{partner?.partnerName}</div>
							</div>
							<div className="width100 in-row align-center justify-between">
								<div>
									<div className="bold">Country:</div>
									<div>{partner?.country}</div>
								</div>
								<div className="width50">
									<div className="bold">Phone:</div>
									<div>{partner?.phone}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="width100 in-row align-center justify-between text-start mb6">
						<div className="width40">
							<div className="bold">Contact Name:</div>
							<div>{partner?.contactName}</div>
						</div>
						<div className="width50">
							<div className="bold">Email:</div>
							<div>{partner?.email}</div>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="width100 in-row align-center justify-center pt3 pb3">
						<button className="table-modal-form-button" onClick={onModalClose}>
							Close
						</button>
					</div>
				</form>
			</div>
			{rateWindow && (
				<RateWindowModal
					partner={partner}
					onModalClose={onModalClose}
					rqKeyPartners={rqKeyPartners}
					modal={rateWindow}
					setModal={setRateWindow}
				/>
			)}
		</Modal>
	);
};

export default memo(CardInfoPartner);
