import React, { useState, useCallback, useMemo } from "react";
import "./gestao.css";
import "../tables.css";
import { UpdateGestao } from "./UpdateGestao";
import {
	IProductGestao,
	IMarketplace,
	IVendor,
	IPaginatedRequest,
} from "../../../../interfaces";
import { TooltipText } from "./Tooltip";
import { convertPrice } from "./UpdateGestao";
import Paginator from "../../../ui/pagination";
import { getMarketplaceProductImage } from "../../../../actions/pim";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import ConditionalLink from "../../../utils/conditional-link";
import { Role } from "../../users-management/UsersManagement";
import useUser from "../../../../hooks/useUser";
import { useAtom } from "jotai";
import { ProductResponse, selectedProductsAtom } from "./Gestao";
// import RoleRequired from "../../../utils/role-required/RoleRequired";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import { Modal } from "../../../ui/modal/Modal";
import GestaoGridModal from "./GestaoGridModal";
import _ from "lodash";
import { styled } from "@mui/material/styles";

interface Props {
	marketplaces: IMarketplace[];
	vendors: IVendor[];
	products: ProductResponse;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
	refetchProducts: () => void;
	loadProducts: boolean;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9",
	},
}));

export const rankingZeoosWinner = (data: any, forceSeller?: number) => {
	if (data?.rankingZeoos) {
		const sellers = Object.keys(data.rankingZeoos)?.filter(
			(seller: any) => !isNaN(seller)
		);
		let winner, seller_id;

		if (forceSeller) {
			winner = data.rankingZeoos[forceSeller];
			seller_id = forceSeller;
		} else {
			for (const seller of sellers) {
				if (data.rankingZeoos[seller].ranking === 1) {
					winner = data.rankingZeoos[seller];
					seller_id = seller;
					break;
				}
			}
		}

		return {
			seller: Number(seller_id),
			price:
				winner?.breakdownPromotional ||
				winner?.breakdownCustom ||
				winner?.breakdown,
			ranking: winner?.ranking,
			stock: winner?.stock,
			priceType: winner?.breakdownPromotional
				? "Promotional Price"
				: winner?.breakdownCustom
				? "Custom Price"
				: "Default Price",
		};
	} else {
		return {
			price:
				data?.priceBreakdownPromotional ||
				data?.priceBreakdownCustom ||
				data?.priceBreakdown,
			stock: data?.stock,
			priceType: data?.breakdownPromotional
				? "Promotional Price"
				: data?.breakdownCustom
				? "Custom Price"
				: "Default Price",
		};
	}
};

export const featuredMarketplace = (data: any) => {
	let list = integratedMarketplaces(data);
	if (!list.length) {
		list = allMarketplaces(data);
	}

	let top5: any[] = [];
	list.forEach((m: any) => {
		if (top5.length < 5) {
			if (m.integrated && m.available !== false) {//&& isSelling(m)
				top5.push(m);
			}
		}
	});

	return top5;
};

const rankingZeoosOrdered = (data: any) => {
	let ordered = [];
	if (data?.rankingZeoos) {
		const sellers = Object.keys(data.rankingZeoos)?.filter(
			(seller: any) => !isNaN(seller)
		);

		for (let index = 1; index <= sellers.length; index++) {
			for (const seller of sellers) {
				if (data.rankingZeoos[seller].ranking === index) {
					ordered.push({
						...data.rankingZeoos[seller],
						price:
							data.rankingZeoos[seller].breakdownPromotional ||
							data.rankingZeoos[seller].breakdownCustom ||
							data.rankingZeoos[seller].breakdown,
						seller: Number(seller),
					});
					break;
				}
			}
		}
	}

	return ordered;
};

export const integratedMarketplaces = (item: IProductGestao) => {
	let list: any[] = [];
	Object.keys(item?.marketplaces || {}).forEach((zeoosName: string) => {
		if (item?.marketplaces?.[zeoosName]?.integrated) {
			list.push({
				...item?.marketplaces?.[zeoosName],
				zeoosName,
			});
		}
	});

	const orderIsSelling = (res: any) => isSelling(res);

	return _.orderBy(
		list,
		[orderIsSelling, "buybox.ranking", "stock", "zeoosName"],
		["desc", "asc", "asc", "asc"]
	);
};

export const allMarketplaces = (item: IProductGestao) => {
	let list: any[] = [];
	Object.keys(item?.marketplaces || {}).forEach((zeoosName: string) => {
		list.push({
			...item?.marketplaces?.[zeoosName],
			zeoosName,
		});
	});

	const orderIntegrated = (res: any) =>
		!_.isUndefined(res.integrated) && res.integrated === true;
	const orderIsSelling = (res: any) => isSelling(res);

	return _.orderBy(
		list,
		[orderIntegrated, orderIsSelling, "buybox.ranking", "zeoosName"],
		["desc", "desc", "asc", "asc"]
	);
};

export const isSelling = (marketplace: any, seller?: number) => {
	if (marketplace.available === false || !marketplace.integrated) return false;

	const winner = rankingZeoosWinner(marketplace, seller);
	if (!winner?.stock || !winner?.price?.pvpFinal) return false;

	return true;
};

export const stockTotal = (item: IProductGestao, seller?: number) => {
	let cont = 0;
	const sellers = seller
		? [seller]
		: typeof item?.seller_id === "object"
		? item?.seller_id
		: [item?.seller_id];

	sellers.forEach((seller: any) => {
		let aux = 0;
		for (const zeoosName of Object.keys(item?.marketplaces || {})) {
			if (item?.marketplaces?.[zeoosName]?.rankingZeoos?.[seller]) {
				if (
					item?.marketplaces?.[zeoosName]?.rankingZeoos?.[seller].stock > aux
				) {
					aux = item?.marketplaces?.[zeoosName]?.rankingZeoos?.[seller].stock;
				}
			}
		}
		cont += aux;
	});

	return cont;
};

export const getSellersMarketplace = (data: any) => {
	if (data?.rankingZeoos) {
		return Object.keys(data.rankingZeoos).filter((k: string) => Number(k))
			.length;
	}
};

export const TableGestao: React.FC<Props> = ({
	marketplaces,
	vendors,
	products,
	setCurrentConfig,
	currentConfig,
	refetchProducts,
	loadProducts,
}) => {
	const { user } = useUser();
	const [modal, setModal] = useState<boolean>(false);
	const [product, setProduct] = useState<IProductGestao | undefined>();
	const [marketplace, setMarketplace] = useState<IMarketplace>();
	const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
	const [modal1, setModal1] = useState<boolean>(false);
	const [value, setValue] = React.useState("1");
	const [hide, setHide] = useState(false);
	const [selectedWinner, setSelectedWinner] = useState<any>({});
	const [selectedSeller, setSelectedSeller] = useState<number | undefined>();

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	const handleSelectAll = useCallback(() => {
		setSelectedProducts((prev) =>
			!prev.length ? products?.data.map((x) => x.sku) : []
		);
	}, [products?.data, setSelectedProducts]);

	const handleSelectOne = useCallback(
		(item: IProductGestao) => () => {
			setSelectedProducts((prev) => {
				if (prev.find((x) => x === item.sku)) {
					return prev.filter((x) => x !== item.sku);
				}
				return [...prev, item.sku];
			});
		},
		[setSelectedProducts]
	);

	const isWortenUser = useMemo(
		() => user?.group?._id === "630c87437a3c0a000381f941",
		[user]
	);

	const isAdmin = user.role === Role.admin ? true : false;

	const activeMarketplaces = useMemo(() => {
		if (isWortenUser) {
			return marketplaces.filter((m) =>
				["Rakuten FR", "Kaufland DE", "Cdiscount FR", "Carrefour FR"].includes(
					m.zeoosName
				)
			);
		}

		return marketplaces.filter((marketplace: IMarketplace) => {
			return products?.data.find(
				(product: IProductGestao) =>
					// marketplace.isFeed ||
					product?.marketplaces?.[marketplace.zeoosName]?.integrated
			);
		});
	}, [marketplaces, products?.data, isWortenUser]);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const onModalClose1 = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal1(false);
	};

	const toggleHide = () => {
		setHide(!hide);
	};

	const getSellerName = (id: number) => {
		const vendor = vendors.find((v: any) => v.id === id);
		if (isAdmin || user.seller?.id === vendor?.id) {
			return vendor?.displayName || vendor?.name || "";
		} else {
			return "*****";
		}
	};

	const stockSellers = (item: IProductGestao) => {
		let sellers: any[] = [];
		(typeof item?.seller_id === "object"
			? item?.seller_id
			: [item?.seller_id]
		).forEach((seller: any) => {
			sellers.push({
				seller: getSellerName(seller),
				stock: stockTotal(item, seller),
			});
		});

		return _.orderBy(sellers, ["stock"], ["desc"]).map((s: any, index: number) => (
			<div className="in-row justify-between" key={index}>
				<span>{s.seller}:</span> <span>{s.stock}</span>
			</div>
		));
	};

	return (
		<>
			<div className="table-main-cont">
				<div className="table-main-title-cont">
					<div className="table-main-title-search-value-cont">
						<div className="table-search-box ml2">
							<form
								className="table-search-form"
								onSubmit={(e) => e.preventDefault()}
							>
								<input
									className="table-search-input"
									placeholder="Search by title or sku"
									onChange={(e) =>
										setCurrentConfig((conf: IPaginatedRequest) => ({
											...conf,
											search: e.target.value,
										}))
									}
									value={currentConfig.search}
								/>
								<img
									className="table-search-image"
									src="/icons/search.svg"
									width="25px;"
									alt=""
								/>
							</form>
						</div>
						<div className="table-value">
							{loadProducts ? (
								<CircularPreloader />
							) : (
								<span className="table-main-title-text">
									{products?.total} produtos
								</span>
							)}
						</div>
					</div>
					<div className="marketplace-pagination">
						<div className="mr2 in-row align-center">
							<img
								onClick={toggleHide}
								className="mr1 pointer"
								style={{
									width: "30px",
									height: "30px",
									display: hide ? "flex" : "none",
								}}
								src="/icons/pim-main-tab1.svg"
								alt=""
							/>
							<img
								onClick={toggleHide}
								className="mr1 pointer"
								style={{
									width: "30px",
									height: "30px",
									display: hide ? "none" : "flex",
								}}
								src="/icons/pim-main-tab1active.svg"
								alt=""
							/>
							<img
								onClick={toggleHide}
								className="pointer"
								style={{
									width: "30px",
									height: "30px",
									display: hide ? "none" : "flex",
								}}
								src="/icons/pim-main-tab2.svg"
								alt=""
							/>
							<img
								onClick={toggleHide}
								className="mr1 pointer"
								style={{
									width: "30px",
									height: "30px",
									display: hide ? "flex" : "none",
								}}
								src="/icons/pim-main-tab2active.svg"
								alt=""
							/>
						</div>
						{
							<Paginator
								data={!loadProducts ? products : {}}
								refetch={onChangePage as any}
							/>
						}
					</div>
				</div>
				<div className="table-border-bottom"></div>
				<div
					style={{ display: hide ? "flex" : "none" }}
					className="mt2 mb2 width100 wrap justify-start aligm-start"
				>
					<div className="table-overflow table-gestao-height">
						{loadProducts ? (
							<></>
						) : (
							<table style={{ position: "sticky", zIndex: 101, top: "2px" }}>
								<thead>
									<tr
										style={{ marginTop: "-5px", width: "100%" }}
										className="table-results-title"
									>
										<th className="gestao-sticky-block admin-titlebg title-border">
											<th className="ml3 mr3">
												<img
													className="pointer"
													src={
														selectedProducts.length
															? "/icons/inventory-all-selected.svg"
															: "/icons/check-box.svg"
													}
													alt=""
													onClick={handleSelectAll}
												/>
											</th>
											<th className="gestao-image-link"></th>
											<th className="gestao-image"></th>
											<th className="gestao-ean">
												SKU
												<br />
												EAN
											</th>
											<th className="gestao-image-link"></th>
											<th className="gestao-title">Title</th>
                      {isAdmin && <td className="gestao-sku">Integrated MKTplaces</td>}											
											<th className="gestao-total">
												{isAdmin ? "" : "My "}Stock
											</th>
											<th className="table-border-right" />
										</th>
										{activeMarketplaces.map(
											(marketplace: IMarketplace, index: number) => (
												<React.Fragment key={index}>
													<th
														className="gestao-vinuus"
														style={{
															...(isWortenUser
																? {
																		minWidth: "150px",
																  }
																: {}),
														}}
													>
														<ConditionalLink
															condition={user.role === Role.admin}
															className="align-center height100"
															to={`/platform/${marketplace.zeoosName}`}
														>
															{marketplace.zeoosName}
														</ConditionalLink>
														<div className="in-row width100 height100 gestao-sort">
															{isAdmin && (
																<div className="gestao-stock justify-center">
																	Sellers
																</div>
															)}
															<div
																className="gestao-cost justify-center"
																style={{
																	...(!isAdmin
																		? {
																				width: "100%",
																		  }
																		: {}),
																}}
															>
																Price
															</div>
															{!isAdmin && (
																<div
																	className="gestao-stock justify-start"
																	style={{
																		...(!isAdmin
																			? {
																					width: "100%",
																			  }
																			: {}),
																	}}
																>
																	Ranking
																</div>
															)}
														</div>
													</th>
													<th className="table-border-right" />
												</React.Fragment>
											)
										)}
									</tr>
								</thead>
								<tbody></tbody>
							</table>
						)}
						<div>
							<table className="table-results p0">
								<thead></thead>
								<tbody>
									{products?.data?.map(
										(item: IProductGestao, index: number) => (
											<tr
												className="table-result-box gestao-height-box"
												key={index}
											>
												<td className="gestao-sticky-block">
													<td
														className="ml3 mr3"
														onClick={handleSelectOne(item)}
													>
														<img
															className="pointer"
															src={
																!selectedProducts.find((x) => x === item.sku)
																	? "/icons/check-box.svg"
																	: "/icons/check-box-checked.svg"
															}
															alt=""
														/>
													</td>
													<td className="gestao-image-link">
														<ConditionalTooltip
															condition={isAdmin}
															title={
																<div>
																	{!item.components?.length ||
																	item.components?.length > 1
																		? "Parent Product"
																		: "Variant Product"}
																</div>
															}
														>
															<img
																src={
																	!item.components?.length ||
																	item.components?.length > 1
																		? `/icons/group-p.svg`
																		: `/icons/arrow-return-right.svg`
																}
																className="pointer"
																alt=""
															/>
														</ConditionalTooltip>
													</td>
													<td className="gestao-image">
														<ConditionalLink
															condition={isAdmin}
															to={`/productInfo/${item.sku}`}
														>
															<img
																className="gestao-table-image"
																src={getMarketplaceProductImage(item)}
																alt={item.sku}
															/>
														</ConditionalLink>
													</td>
													<td className="gestao-ean">
														<div className="gestao-sku-text">
															<ConditionalLink
																condition={isAdmin}
																to={`/productInfo/${item.sku}`}
															>
																<TooltipText
																	text={item.sku}
																	items={7}
																	separator={""}
																/>
															</ConditionalLink>
														</div>

														<div className="gestao-ean-text">
															{item.barcode}
														</div>
													</td>
													<td className="gestao-image-link">
														<ConditionalLink
															condition={isAdmin}
															to={`/productInfo/${item.sku}`}
														>
															<img src="/icons/inventory-pim-icon.svg" alt="" />
														</ConditionalLink>
													</td>
													<td className="gestao-title">
														<div className="gestao-title-text">
															<TooltipText
																text={item.name}
																items={20}
																separator={" "}
															/>
														</div>
													</td>
                          {isAdmin && <td className="gestao-sku">
														{integratedMarketplaces(item).length}
													</td>}
													<td className="gestao-total">
														{/* <div className="gestao-total-text" title={isAdmin ? stockSellers(item) : ''}>
														{stockTotal(item)}
													</div> */}
														{isAdmin ? (
															<HtmlTooltip
																title={
																	<React.Fragment>
																		<div className="gestao-tooltip-stock">
																			<div className="in-row width100 bold justify-between">
																				<span>Seller</span>
																				<span>Stock</span>
																			</div>
																			<div className="gestao-tooltip-border" />
																			{isAdmin ? stockSellers(item) : ""}
																		</div>
																	</React.Fragment>
																}
															>
																<button
																	className="no-shadow transparent gestao-total-text"
																	style={{ flexDirection: "row" }}
																>
																	{stockTotal(
																		item,
																		isAdmin ? false : user.seller?.id
																	)}
																	<img
																		width="10px"
																		style={{ opacity: 0.5, marginLeft: 5 }}
																		src="/icons/down-arrow.png"
																		alt=""
																	/>
																</button>
															</HtmlTooltip>
														) : (
															<div className="gestao-total-text">
																{stockTotal(
																	item,
																	isAdmin ? false : user.seller?.id
																)}
															</div>
														)}
													</td>
													<td className="table-border-right"></td>
												</td>
												{activeMarketplaces.map(
													(marketplace: any, index: number) => {
														const data =
															item.marketplaces?.[marketplace.zeoosName] ||
															({} as any);
														data.price = data.price || "0";
														data.stock = data.stock || 0;
														const showSeller = isAdmin
															? false
															: user.seller?.id;
														const winner = rankingZeoosWinner(data, showSeller);

														return (
															<React.Fragment key={index}>
																{/* <ConditionalTooltip
															condition={!isWortenUser}
															title={
																<div>
																	<p>
																		Integrated:{" "}
																		{data?.integrated || isWortenUser
																			? "on"
																			: "off"}
																	</p>
																	<p>
																		Active: { data?.available === false ? "off" : "on" }
																	</p>
																	<p>
																		Disabled: { item?.disabled ? "on" : "off" }
																	</p>
																</div>
															}
														> */}
																<td
																	onClick={() => {
																		//TODO: Temporary blocking worten group to open price breakdown
																		if (
																			user.group?.vendorPermissions.find(
																				(p: any) => p.id === 1439
																			)
																		) {
																			return;
																		}
																		setModal1(true);
																		setProduct(item as any);
																		setMarketplace(marketplace);
																		setSelectedWinner(winner);
																	}}
																	className="gestao-vinuus pointer"
																	style={{
																		...(!isAdmin
																			? {
																					minWidth: "150px",
																					border: "none",
																					// cursor: "inherit",
																			  }
																			: {
																					backgroundColor: data.integrated
																						? "#fff"
																						: "#FFECEC",
																			  }),
																	}}
																>
																	<div className="in-row width100 height100">
																		{isAdmin && (
																			<div className="gestao-stock">
																				<div className="gestao-stock-text">
																					{getSellersMarketplace(data)}
																					{/* {winner?.stock?.toLocaleString(
																				"de-DE",
																				{}
																			) || 0} */}
																					{/* {data.stock.toLocaleString(
																				"de-DE",
																				{}
																			) || 0} */}
																				</div>
																			</div>
																		)}
																		<div
																			className="gestao-cost"
																			style={{
																				...(!isAdmin
																					? {
																							width: "100%",
																							justifyContent: "center",
																					  }
																					: {}),
																			}}
																		>
																			<div className="gestao-cost-text">
																				{`${convertPrice(
																					winner?.price?.pvpFinal || 0
																				)} €`}
																				{/* {convertPrice(
																				data?.priceBreakdownPromotional
																					?.pvpFinal ||
																				data?.priceBreakdownCustom
																					?.pvpFinal ||
																				data?.priceBreakdown?.pvpFinal ||
																				0
																			)}{" "}
																			€ */}
																			</div>
																			{isAdmin &&
																				data.buybox?.ranking === 1 && (
																					<img
																						className="ml1"
																						src="/icons/inv-modal1-rank.svg"
																						alt=""
																						title="Buybox"
																					/>
																				)}
																		</div>
																		{!isAdmin && (
																			<div
																				className="gestao-stock"
																				style={{
																					...(!isAdmin
																						? {
																								width: "100%",
																								justifyContent: "center",
																						  }
																						: {}),
																				}}
																			>
																				<div className="gestao-cost-text align-center justify-start">
																					{winner?.ranking === 1 ? (
																						<img
																							className="ml2"
																							src="/icons/ranking-modal-yellow.svg"
																							alt=""
																							title="1º on Zeoos"
																						/>
																					) : winner?.ranking ? (
																						`${winner?.ranking}º`
																					) : (
																						""
																					)}
																					{data.buybox?.ranking === 1 ? (
																						<img
																							className="ml2"
																							src="/icons/inv-modal1-rank.svg"
																							alt=""
																							title="Buybox"
																						/>
																					) : (
																						""
																					)}
																				</div>
																			</div>
																		)}

																		{/* <div className="gestao-cost-update">update</div> */}
																	</div>
																</td>
																{/* </ConditionalTooltip> */}
																<td className="table-border-right" />
															</React.Fragment>
														);
													}
												)}
											</tr>
										)
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div
					style={{ display: hide ? "none" : "flex", alignItems: "stretch" }}
					className="mt2 mb2 width100 wrap align-start"
				>
					{products?.data?.map((item: IProductGestao, index: number) => {
						const featuredMrktp = featuredMarketplace(item);
						// const winner = rankingZeoosWinner(featuredMrktp[0]);
						return (
							<div className="gestao-product-main-box" key={index}>
								<div>
								<div className="in-row align-center justify-start width100">
									<div className="ml3 mr3" onClick={handleSelectOne(item)}>
										<img
											className="pointer"
											src={
												!selectedProducts.find((x) => x === item.sku)
													? "/icons/check-box.svg"
													: "/icons/check-box-checked.svg"
											}
											alt=""
										/>
									</div>
									<div
										style={{ fontSize: "13px" }}
										className="gestao-title-text"
									>
										<TooltipText text={item.name} items={20} separator={" "} />
									</div>
								</div>
								<div className="border-bottom mt2 mb2" />
								<div className="in-row align-center justify-start width100">
									<ConditionalLink
										condition={isAdmin}
										to={`/productInfo/${item.sku}`}
									>
										<img
											src={getMarketplaceProductImage(item)}
											alt={item.sku}
											width="120px !important"
											height="120px !important"
										/>
									</ConditionalLink>
									<div>
										<div
											style={{ fontSize: "13px" }}
											className="in-row align-center ustify-start width100 gestao-title-text mb1"
										>
											<b className="pr2">SKU: </b>
											<ConditionalLink
												condition={isAdmin}
												to={`/productInfo/${item.sku}`}
											>
												<TooltipText text={item.sku} items={7} separator={""} />
											</ConditionalLink>
										</div>
										<div
											style={{ fontSize: "13px" }}
											className="in-row align-center ustify-start width100 gestao-title-text mb1"
										>
											<b className="pr2">EAN: </b>
											<div className="gestao-ean-text">{item.barcode}</div>
										</div>
										<div
											style={{ fontSize: "13px" }}
											className="in-row align-center ustify-start width100 gestao-title-text mb1"
										>
											<b className="pr2">{!isAdmin ? "My " : ""}Stock: </b>
											{isAdmin ? (
												<HtmlTooltip
													title={
														<React.Fragment>
															<div className="gestao-tooltip-stock">
																<div className="in-row width100 bold justify-between">
																	<span>Seller</span>
																	<span>Stock</span>
																</div>
																<div className="gestao-tooltip-border" />
																{isAdmin ? stockSellers(item) : ""}
															</div>
														</React.Fragment>
													}
												>
													<button className="no-shadow in-row align-center justify-center transparent gestao-total-text">
														{stockTotal(
															item,
															isAdmin ? false : user.seller?.id
														)}
														<img
															width="10px"
															style={{ opacity: 0.5, marginLeft: 5 }}
															src="/icons/down-arrow.png"
															alt=""
														/>
													</button>
												</HtmlTooltip>
											) : (
												<div className="gestao-total-text">
													{stockTotal(item, isAdmin ? false : user.seller?.id)}
												</div>
											)}
										</div>
									</div>
								</div>

								<div className="border-bottom mt2 mb2" />

								<table
									style={{ fontSize: "12px" }}
									className="mapping-modal-table gestao-title-text"
								>
									<thead className="in-column justify-center">
										<tr
											style={{
												width: "91%",
												borderBottom: "1px solid #e1e1e1",
												margin: "auto !important",
											}}
											className="mapping-modal-results-title justify-between m0 pt2 pb2"
										>
											<th
												style={{ flex: "0 1 30%", width: "120px" }}
												className="inv-modal1-table-seller"
											>
												Platform
											</th>
											{isAdmin && (
												<th className="inv-modal1-table-column">Sellers</th>
											)}
											<th className="inv-modal1-table-column">Price</th>
											<th className="inv-modal1-table-column">Ranking</th>
										</tr>
									</thead>
									<tbody className="in-column justify-center">
										{featuredMrktp.map((m: any) => {
                      const seller = isAdmin ? undefined : user.seller?.id; 
											const winner = rankingZeoosWinner(m, seller);
											return (
												<tr
													style={{
														width: "91%",
														borderBottom: "1px solid #e1e1e1",
														margin: "auto !important",
													}}
													className="mapping-modal-result-box justify-between pt1 pb1 pointer platform-list"
													onClick={() => {
														if (winner?.price) {
                              setModal1(true);
                            } else {
                              setModal(true);
                              setSelectedSeller(seller);
                            }							
														setProduct(item as any);
														setMarketplace(m);
														setSelectedWinner(winner);
													}}
												>
													<td
														style={{ flex: "0 1 30%", width: "120px" }}
														className="inv-modal1-table-seller"
														title={m?.zeoosName}
													>
														{m?.zeoosName}
													</td>
													{isAdmin && (
														<td className="inv-modal1-table-column">
															{getSellersMarketplace(m)}
														</td>
													)}
													<td className="inv-modal1-table-column text-end ">
                            {
                              winner?.price 
                                ? `${convertPrice(winner?.price?.pvpFinal || 0)} €`
                                : <div className="blue">add</div>
                            }
													</td>
													{isAdmin && (
														<td className="inv-modal1-table-column">
															{m?.buybox?.ranking === 1 ? (
																<img
																	className="ml2"
																	src="/icons/inv-modal1-rank.svg"
																	alt=""
																	title="Buybox"
																/>
															) : (
																""
															)}
														</td>
													)}
													{!isAdmin && (
														<td className="inv-modal1-table-column">
															<div className="gestao-cost-text align-center justify-start">
																{winner?.ranking === 1 ? (
																	<img
																		className="ml2"
																		src="/icons/ranking-modal-yellow.svg"
																		alt=""
																		title="1º on Zeoos"
																	/>
																) : 
                                  <>{winner?.ranking ? `${winner?.ranking}º` : '' }</>
																}
																{m?.buybox?.ranking === 1 ? (
																	<img
																		className="ml2"
																		src="/icons/inv-modal1-rank.svg"
																		alt=""
																		title="Buybox"
																	/>
																) : (
																	""
																)}
															</div>
														</td>
													)}
												</tr>
											);
										})}
									</tbody>
								</table>
								</div>
								<div className="mt2 mb2" />

								<div className="in-row align-center justify-center">
									<GestaoGridModal
										product={item}
										setModal1={setModal1}
										setProduct={setProduct}
										setMarketplace={setMarketplace}
										setSelectedWinner={setSelectedWinner}
										featured={featuredMrktp.length}
                    setSelectedSeller={setSelectedSeller}
                    setModalUpdate={setModal}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			{modal1 && (
				<Modal onModalClose={onModalClose1} isOpened={modal1}>
					<>
						<div
							style={{ maxWidth: "640px" }}
							className="table-modal-form-cont p4"
						>
							<div className="inv-title-modal1-cont">
								<span className="inv-title-modal1-text">
									{marketplace?.zeoosName}
								</span>
							</div>
							<div className="mb4 in-row align-start">
								<div
									style={{ width: "120px", height: "120px" }}
									className="int-set-match-logo"
								>
									<img
										width="120px"
										height="120px"
										className="pb1"
										src={product?.image}
										alt=""
									/>
								</div>
								<div className="ml2 mt4 int-text in-column text-aligns align-start justify-start">
									<span style={{ lineHeight: "20px" }}>{product?.name}</span>
									<div className="in-row mt2 align-center justify-start width100">
										<span className="int-text mr4">
											<b className="mr1">SKU:</b>
											{product?.sku}
										</span>
										<span className="int-text">
											<b className="mr1">EAN:</b>
											{product?.barcode}
										</span>
									</div>
									<div className="in-row mt2 align-center justify-start width100">
										<span className="int-text mr4">
											<b style={{ fontSize: "24px" }} className="mr1">
												{`${convertPrice(
													selectedWinner?.price?.pvpFinal || 0
												)} €`}
											</b>
											<span className="opacity5">
												by {getSellerName(selectedWinner?.seller)}
											</span>
										</span>
									</div>
									{!product?.marketplaces[marketplace?.zeoosName as any]
										?.integrated && (
										<div className="in-row mt2 align-center justify-start width100">
											<span
												className="int-text mr4"
												style={{ color: "#E80000", fontWeight: "bold" }}
											>
												Product not integrated yet
											</span>
										</div>
									)}
								</div>
							</div>
							<TabContext value={value}>
								<TabList
									className="inv-mod-tabs"
									style={{ borderBottom: "1px solid #E1E1E1" }}
									onChange={handleChange}
									aria-label="lab API tabs example"
								>
									{/* <Tab className="ges-tab-sync ges-tab-sync-active" label="Platform Buybox" value="2" /> */}
									<Tab
										className="ges-tab-sync ges-tab-sync-active"
										label="Zeoos Ranking"
										value="1"
									/>
								</TabList>
								<TabPanel className="m0 p0" value="2">
									<table
										style={{ fontSize: "14px" }}
										className="mapping-modal-table"
									>
										<thead>
											<tr className="mapping-modal-results-title justify-between m0 pt2 pb2">
												<th className="inv-modal1-table-seller">
													Buybox on {marketplace?.zeoosName}
												</th>
												<th className="inv-modal1-table-column">Price</th>
											</tr>
										</thead>
										<tbody>
											{product?.marketplaces?.[
												marketplace?.zeoosName as any
											]?.buybox?.sellers?.map((m: any, index: number) => {
												return (
													<tr
														key={index}
														className="mapping-modal-result-box justify-between pt1 pb1"
													>
														<td className="inv-modal1-table-seller">
															<b className="mr2">{index + 1}</b> {m.name}
														</td>
														<td className="inv-modal1-table-column justify-start bold">
															<span>{`${convertPrice(m.price || 0)} €`}</span>
															{index === 0 &&
															product?.marketplaces?.[
																marketplace?.zeoosName as any
															]?.buybox?.ranking === 1 ? (
																<img
																	className="ml2"
																	src="/icons/inv-modal1-rank.svg"
																	alt=""
																	title="Buybox"
																/>
															) : (
																""
															)}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</TabPanel>
								<TabPanel className="m0 p0" value="1">
									<table
										style={{ fontSize: "14px" }}
										className="mapping-modal-table"
									>
										<thead>
											<tr className="mapping-modal-results-title m0 pt2 pb2">
												<th className="inv-modal1-table-seller">
													Zeoos Ranking on {marketplace?.zeoosName}
												</th>
												<th className="inv-modal1-table-column">Selling</th>
												<th className="inv-modal1-table-column">Stock</th>
												<th className="inv-modal1-table-column price">Price</th>
											</tr>
										</thead>
										<tbody>
											{rankingZeoosOrdered(
												product?.marketplaces?.[marketplace?.zeoosName as any]
											).map((seller: any, index: number) => {
												const clickable =
													getSellerName(seller.seller) === "*****"
														? false
														: true;
												return (
													<tr
														key={index}
														onClick={() => {
															if (clickable) {
																setModal(true);
																setSelectedSeller(seller.seller);
																// setProduct(item as any);
																// setMarketplace(marketplace);
																// setSelectedWinner(winner);
															}
														}}
														className={`mapping-modal-result-box pt1 pb1 ${
															clickable ? "pointer platform-list" : ""
														}`}
													>
														<td className="inv-modal1-table-seller">
															<b className="mr2">{seller.ranking}º</b>
															{getSellerName(seller.seller)}
														</td>
														<td className="inv-modal1-table-column bold">
															{isSelling(
																product?.marketplaces?.[
																	marketplace?.zeoosName as any
																], seller.seller
															) && seller.stock ? (
																<>
																	<img
																		className="mr1"
																		src="/icons/inv-modal1-green-dott.svg"
																		alt=""
																	/>
																	<span
																		style={{
																			color: "#299C00",
																			textTransform: "uppercase",
																		}}
																	>
																		on
																	</span>
																</>
															) : (
																<>
																	<img
																		className="mr1"
																		src="/icons/inv-modal1-red-dott.svg"
																		alt=""
																	/>
																	<span
																		style={{
																			color: "#E80000",
																			textTransform: "uppercase",
																		}}
																	>
																		off
																	</span>
																</>
															)}
														</td>
														<td className="inv-modal1-table-column">
															{getSellerName(seller.seller) === "*****"
																? "*"
																: seller.stock}
														</td>
														<td className="inv-modal1-table-column justify-start price bold">
															<span>{`${convertPrice(
																seller.price?.pvpFinal || 0
															)} €`}</span>
															{seller.ranking === 1 ? (
																<img
																	className="ml1"
																	src="/icons/ranking-modal-yellow.svg"
																	alt=""
																	title="1º on Zeoos"
																/>
															) : (
																""
															)}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</TabPanel>
							</TabContext>
							<div className="in-row width100 align-center justify-center">
								<div onClick={onModalClose1} className="grid-modal-close-btn">
									Close
								</div>
							</div>
						</div>
					</>
				</Modal>
			)}
			{modal && (
				<UpdateGestao
					modal={modal}
					setModal={setModal}
					product={product}
					onUpdateCallback={refetchProducts}
					productMarketplace={marketplace as IMarketplace}
					vendors={vendors}
					seller={selectedSeller}
				/>
			)}
		</>
	);
	return (
		<>
			<div className="table-main-cont">
				<div className="table-main-title-cont">
					<div className="table-main-title-search-value-cont">
						<div className="table-search-box ml2">
							<form
								className="table-search-form"
								onSubmit={(e) => e.preventDefault()}
							>
								<input
									className="table-search-input"
									placeholder="Search by title or sku"
									onChange={(e) =>
										setCurrentConfig((conf: IPaginatedRequest) => ({
											...conf,
											search: e.target.value,
										}))
									}
									value={currentConfig.search}
								/>
								<img
									className="table-search-image"
									src="/icons/search.svg"
									width="25px;"
									alt=""
								/>
							</form>
						</div>
						<div className="table-value">
							{loadProducts ? (
								<CircularPreloader />
							) : (
								<span className="table-main-title-text">
									{products?.total} produtos
								</span>
							)}
						</div>
					</div>
					<div className="marketplace-pagination">
						{
							<Paginator
								data={!loadProducts ? products : {}}
								refetch={onChangePage as any}
							/>
						}
					</div>
				</div>
				<div className="table-border-bottom"></div>
				<div className="table-overflow table-gestao-height">
					{loadProducts ? (
						<></>
					) : (
						<table style={{ position: "sticky", zIndex: 101, top: "2px" }}>
							<thead>
								<tr
									style={{ marginTop: "-5px", width: "100%" }}
									className="table-results-title"
								>
									<th className="gestao-sticky-block admin-titlebg title-border">
										<th className="ml3 mr3">
											<img
												className="pointer"
												src={
													selectedProducts.length
														? "/icons/inventory-all-selected.svg"
														: "/icons/check-box.svg"
												}
												alt=""
												onClick={handleSelectAll}
											/>
										</th>
										<th className="gestao-image-link"></th>
										<th className="gestao-image"></th>
										<th className="gestao-sku">SKU</th>
										<th className="gestao-ean">EAN</th>
										<th className="gestao-image-link"></th>
										<th className="gestao-title">Title</th>
										<th className="gestao-total">Total Stock</th>
										<th className="table-border-right" />
									</th>
									{activeMarketplaces.map(
										(marketplace: IMarketplace, index: number) => (
											<React.Fragment key={index}>
												<th
													className="gestao-vinuus"
													style={{
														...(isWortenUser
															? {
																	minWidth: "100px",
															  }
															: {}),
													}}
												>
													<ConditionalLink
														condition={user.role === Role.admin}
														className="align-center height100"
														to={`/platform/${marketplace.zeoosName}`}
													>
														{marketplace.zeoosName}
													</ConditionalLink>
													<div className="in-row width100 height100 gestao-sort">
														<div
															className="gestao-cost justify-center"
															style={{
																...(isWortenUser
																	? {
																			width: "100%",
																	  }
																	: {}),
															}}
														>
															Price
														</div>
														{!isWortenUser && (
															<div className="gestao-stock justify-center">
																Stock
															</div>
														)}
													</div>
												</th>
												<th className="table-border-right" />
											</React.Fragment>
										)
									)}
								</tr>
							</thead>
							<tbody></tbody>
						</table>
					)}
					<div>
						<table className="table-results p0">
							<thead></thead>
							<tbody>
								{products?.data?.map((item: IProductGestao, index: number) => (
									<tr
										className="table-result-box gestao-height-box"
										key={index}
									>
										<td className="gestao-sticky-block">
											<td className="ml3 mr3" onClick={handleSelectOne(item)}>
												<img
													className="pointer"
													src={
														!selectedProducts.find((x) => x === item.sku)
															? "/icons/check-box.svg"
															: "/icons/check-box-checked.svg"
													}
													alt=""
												/>
											</td>
											<td className="gestao-image-link">
												<ConditionalTooltip
													condition={!isWortenUser}
													title={
														<div>
															{!item.components?.length ||
															item.components?.length > 1
																? "Parent Product"
																: "Variant Product"}
														</div>
													}
												>
													<img
														src={
															!item.components?.length ||
															item.components?.length > 1
																? `/icons/group-p.svg`
																: `/icons/arrow-return-right.svg`
														}
														className="pointer"
														alt=""
													/>
												</ConditionalTooltip>
											</td>
											<td className="gestao-image">
												<ConditionalLink
													condition={user.role === Role.admin}
													to={`/productInfo/${item.sku}`}
												>
													<img
														className="gestao-table-image"
														src={getMarketplaceProductImage(item)}
														alt=""
													/>
												</ConditionalLink>
											</td>
											<td className="gestao-sku">
												<div className="gestao-sku-text">
													<ConditionalLink
														condition={user.role === Role.admin}
														to={`/productInfo/${item.sku}`}
													>
														<TooltipText
															text={item.sku}
															items={7}
															separator={""}
														/>
													</ConditionalLink>
												</div>
											</td>
											<td className="gestao-ean">
												<div className="gestao-ean-text">{item.barcode}</div>
											</td>
											<td className="gestao-image-link">
												<ConditionalLink
													condition={user.role === Role.admin}
													to={`/productInfo/${item.sku}`}
												>
													<img src="/icons/inventory-pim-icon.svg" alt="" />
												</ConditionalLink>
											</td>
											<td className="gestao-title">
												<div className="gestao-title-text">
													<TooltipText
														text={item.name}
														items={20}
														separator={" "}
													/>
												</div>
											</td>
											<td className="gestao-total">
												<div className="gestao-total-text">{item.stock}</div>
											</td>
											<td className="table-border-right"></td>
										</td>
										{activeMarketplaces.map(
											(marketplace: any, index: number) => {
												const data =
													item.marketplaces?.[marketplace.zeoosName] ||
													({} as any);
												data.price = data.price || "0";
												data.stock = data.stock || 0;
												const winner = rankingZeoosWinner(data);

												return (
													<React.Fragment key={index}>
														{/* <ConditionalTooltip
															condition={!isWortenUser}
															title={
																<div>
																	<p>
																		Integrated:{" "}
																		{data?.integrated || isWortenUser
																			? "on"
																			: "off"}
																	</p>
																	<p>
																		Active: {data?.available ? "on" : "off"}
																	</p>
																	<p>
																		Disabled: {data?.disabled ? "on" : "off"}
																	</p>
																</div>
															}
														> */}
														<td
															onClick={() => {
																if (user.role === Role.sellerAdmin) {
																	return;
																}

																setModal(true);
																setProduct(item as any);
																setMarketplace(marketplace);
															}}
															className={`gestao-vinuus ${
																user.role !== Role.sellerAdmin ? "pointer" : ""
															}`}
															style={{
																...(isWortenUser
																	? {
																			minWidth: "100px",
																			border: "none",
																			cursor: "inherit",
																	  }
																	: {
																			backgroundColor: data.integrated
																				? "#E9FAEB"
																				: "#FFECEC",
																	  }),
															}}
														>
															<div className="in-row width100 height100">
																<div
																	className="gestao-cost"
																	style={{
																		...(isWortenUser
																			? {
																					width: "100%",
																					justifyContent: "center",
																			  }
																			: {}),
																	}}
																>
																	<div className="gestao-cost-text">
																		{`${convertPrice(
																			winner?.price?.pvpFinal || 0
																		)} €`}
																		{/* {convertPrice(
																				data?.priceBreakdownPromotional
																					?.pvpFinal ||
																				data?.priceBreakdownCustom
																					?.pvpFinal ||
																				data?.priceBreakdown?.pvpFinal ||
																				0
																			)}{" "}
																			€ */}
																	</div>
																</div>
																{!isWortenUser && (
																	<div className="gestao-stock">
																		<div className="gestao-stock-text">
																			{winner?.stock?.toLocaleString(
																				"de-DE",
																				{}
																			) || 0}
																			{/* {data.stock.toLocaleString(
																					"de-DE",
																					{}
																				) || 0} */}
																		</div>
																	</div>
																)}
																{/* <div className="gestao-cost-update">update</div> */}
															</div>
														</td>
														{/* </ConditionalTooltip> */}
														<td className="table-border-right" />
													</React.Fragment>
												);
											}
										)}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{modal && (
				<UpdateGestao
					modal={modal}
					setModal={setModal}
					product={product}
					onUpdateCallback={refetchProducts}
					productMarketplace={marketplace as IMarketplace}
					vendors={vendors}
				/>
			)}
		</>
	);
};

interface ConditionalTooltipProps extends TooltipProps {
	condition: boolean;
}

const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({
	condition,
	children,
	...props
}) => {
	if (condition) {
		return <Tooltip {...props}>{children}</Tooltip>;
	}

	return <div>{children}</div>;
};
