import axios from "axios";
import {
	IPaginatedRequest,
	IPaginatedResponse,
	IVendor,
	IVendorProducts,
} from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export const getVendors = async () => {
	const res = await axios.get("/vendorsCombined", getHeaders());
	return res.data.vendors as IVendor[];
};

export const getVendorsActive = async () => {
	const res = await axios.get(`/vendor?isActive=1`, getHeaders());

	if (res.status !== 200) {
		throw new Error("Something goes wrong");
	}

	return res.data.vendors as IVendor[];
};

export const getVendor = async (id: string) => {
	const res = await axios.get(`/single-vendor?id=${id}`, getHeaders());

	if (res.status !== 200) {
		throw new Error("Something goes wrong");
	}

	return res.data.vendor as IVendor[];
};

export const getVendorProducts = async (id: string) => {
	const res = await axios.get(`/vendor/${id}/products`, getHeaders());

	if (res.status !== 200) {
		throw new Error("Something goes wrong");
	}

	// return res.data.products as IVendorProducts[];
	return res.data;
};

export const getPaginatedVendorProducts = async (
	id: string,
	pagination: IPaginatedRequest
) => {
	const res = await axios.get(
		`/vendor/${id}/products?${queryString.stringify(pagination)}`,
		getHeaders()
	);

	if (res.status !== 200) {
		throw new Error("Something goes wrong");
	}

	return res.data as IPaginatedResponse<any>;
};

export async function createVendor(body: any) {
	const formData = new FormData();
	formData.append("file", body.file);
	formData.append("name", body.name);
	formData.append("displayName", body.displayName);
	formData.append("phone", body.phone);
	formData.append("country", body.country);
	formData.append("zeoosClient", body.zeoosClient);
	formData.append("contacts", body.contacts);

	const res = await axios.post("/vendor", formData, getHeaders());
	return res.data.createdVendor;
}

export async function updateVendor(body: any) {
	const formData = new FormData();
	formData.append("file", body.file);
	formData.append("name", body.name);
	formData.append("displayName", body.displayName);
	formData.append("city", body.city);
	formData.append("rate", body.rate);
	formData.append("country", body.country);
	formData.append("zeoosClient", body.zeoosClient);
	formData.append("email", body.email);
	formData.append("phone", body.phone);
	formData.append("isActive", body.isActive);
	formData.append("contacts", JSON.stringify(body.contacts));
	formData.append("categories", JSON.stringify(body.categories));

	const res = await axios.put(`/vendor/${body.id}`, formData, getHeaders());
	return res.data.updatedVendor;
}

export async function editVendor(body: any) {
	await axios.put(`/vendor/${body.id}`, body, getHeaders());
}

export async function deleteVendor(vendor: any) {
	await axios.put(`/vendor/${vendor.id}`, getHeaders());
}

export const updateSellerProduct = async (body: any) => {
	const res = await axios.post(`/vendor/product`, body, getHeaders());
	if (res.status !== 200) {
		throw new Error("Something goes wrong");
	}

	return {
		updated: res.data.updated,
		product: res.data.product as IVendorProducts,
	};
};

export const massUpdateCountryPrice = async (file: File, mode: string) => {
	const formData = new FormData();
	formData.append("file", file);
  formData.append("mode", mode);
	const res = await axios.post(
		`/vendor/countryPriceMassUpdate`,
		formData,
		getHeaders()
	);
	return res.data;
};

export const exportPricePointData = async (seller: string) => {
  axios.get(`/marketplaceData/export?seller=${seller}`, getHeaders());  
};

export const massUpdatePvpFinal = async (file: File) => {
	const formData = new FormData();
	formData.append("file", file);
	const res = await axios.post(`/productMassUpdatePVP`, formData, getHeaders());
	return res.data;
};

export function getImageURL(sellerId: number) {
	return `${process.env.REACT_APP_API_URL}/vendor/${sellerId}/image`;
}

export const uploadOboardingProducts = async (
	file: File,
	seller: number,
	type: string
) => {
	const formData = new FormData();
	formData.append("file", file);
	formData.append("seller", `${seller}`);
	formData.append("type", type);
	const res = await axios.post(
		`/vendor/uploadProducts`,
		formData,
		getHeaders()
	);
	return res.data;
};
