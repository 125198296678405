import axios from "axios";
import { IPaginatedRequest } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";

export async function getShipments() {
  const res = await axios.get("/shipments", getHeaders());
  return res.data.shipments;
}

export async function getAllShipments() {
  const res = await axios.get("/billings/allShipments", getHeaders());
  return res.data.shipments;
}

export const getPaginatedShipments = async (pagination: IPaginatedRequest) => {
  const res = await axios.get(
    // `/billings/paginatedShipments?${queryString.stringify(pagination)}`,
    `/payouts/shipments?${queryString.stringify(pagination)}`,
    getHeaders()
  );

  return res.data;
};

export const getPaginatedTreasury = async (pagination: IPaginatedRequest) => {
  const res = await axios.get(
    // `/billings/paginatedShipments?${queryString.stringify(pagination)}`,
    `/payouts/treasury?${queryString.stringify(pagination)}`,
    getHeaders()
  );

  return res.data;
};

export async function getShipmentsPerVendor() {
  const res = await axios.get("/billings/shipmentsPerVendor", getHeaders());
  return res.data.shipments;
}

export async function getShipment(id: number) {
  // const res = await axios.get(`/billings/shipment/${id}`, getHeaders());
  const res = await axios.get(`/payouts/shipment/${id}`, getHeaders());
  return res.data.shipment[0];
}

export async function getBilling(id: number) {
  // const res = await axios.get(`/billings/billing/${id}`, getHeaders());
  const res = await axios.get(`/payouts/shipment/${id}/billings`, getHeaders());
  return res.data.billing;
}

export async function getInvoice(ref: number) {
  // const res = await axios.post("/billings/invoice", { ref }, getHeaders());
  const res = await axios.get(`/payouts/invoice/${ref}`, getHeaders());
  return res.data.invoice[0];
}

export async function getBillingPrice(id: number) {
  const res = await axios.get(`/billings/billingPrice/${id}`, getHeaders());
  return res.data.billing[0].bill_line_ids[0];
}

export async function getSingleBillingCSV(id: number) {
  await axios.get(`/billings/billing/${id}/export`, getHeaders());
}

export async function getBillingsCSV() {
  await axios.get(`/billings/export`, getHeaders());
}
