import React, { useContext, useEffect, useMemo, useState } from "react";
import { PimMainTable } from "./PimMainTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { CreateProductModal } from "./CreateProductModal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Link, NavLink, useHistory } from "react-router-dom";
import {
  getOpenSchemeTabs,
  getOpenTabs,
  getSchemes,
  removeOpenTab,
  removeOpenSchemeTab,
  exportPriceStockData,
  getOdooCategories,
} from "../../../actions/pim";
import { useQuery } from "react-query";
import Preloader from "../../ui/preloader/Preloader";
import { CreateSchemeForm } from "./scheme/CreateScheme";
import { toast } from "react-toastify";
import { Role } from "../users-management/UsersManagement";
import MassImport from "./mass/MassImport";
import MassUpdate from "./mass/MassUpdate";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { ExportAllProductInfo } from "./ExportAllProductInfo";
import { CountriesContext } from "../../context";
import { ICountryM } from "../country-management/CountryManagement";
import { saveProductInfo } from "../../../actions/marketplace";
import * as _ from "lodash";
import { getTaxMappings } from "../../../actions/taxMapping";
import useVendors from "../../../hooks/useVendors";
import useUser from "../../../hooks/useUser";
import { useAtom } from "jotai";
import { selectedProductsAtom } from "../tables/gestao/Gestao";
import { useDebouncedValue } from "@mantine/hooks";
import queryString from "query-string";
import { getPaginatedMarketplaceProducts } from "../../../actions/marketplace";
import { IPaginatedRequest, IPaginatedResponse } from "../../../interfaces";
import BatchDropDown from "../../ui/batch-dropdown/BatchDropDown";

export type PimProductResponse = IPaginatedResponse<
  any[],
  {
    all: { sku: string }[];
  }
>;

export const PimMain = () => {
  const { user } = useUser();

  const countries = useContext(CountriesContext) as ICountryM[];

  const [modal, setModal] = useState<boolean>(false);
  const [showExportForm, setShowExportForm] = useState(false);
  const [modalMassImport, setModalMassImport] = useState(false);
  const [modalMassUpdate, setModalMassUpdate] = useState(false);
  const [createProductModal, setCreateProductModal] = useState(false);
  const [batch, setBatch] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const { isLoading: loadProducts, data: products } = useQuery(
    `pimProducts?${queryString.stringify(debouncedConfig)}`,
    () =>
      getPaginatedMarketplaceProducts(
        debouncedConfig
      ) as never as PimProductResponse,
    { refetchOnWindowFocus: false }
  );
  const { isLoading, data: schemes } = useQuery("schemes", () => getSchemes(), {
    enabled: user.role === Role.admin,
    refetchOnWindowFocus: false,
  });
  const vendors = useVendors();
  const categories = useQuery("categories", () => getOdooCategories(), {
    enabled: user.role === Role.admin,
    refetchOnWindowFocus: false,
  });
  const taxes = useQuery("taxes", () => getTaxMappings(), {
    enabled: user.role === Role.admin,
    refetchOnWindowFocus: false,
  });

  const isDataLoading = useMemo(
    () =>
      isLoading || vendors.isLoading || categories.isLoading || taxes.isLoading,
    [isLoading, vendors.isLoading, categories.isLoading, taxes.isLoading]
  );

  const selectData = useMemo(
    () => ({
      vendors: vendors.data,
      categories: categories.data?.data.categories,
      taxes: taxes.data,
    }),
    [vendors.data, categories.data?.data.categories, taxes.data]
  );

  const header = useMemo(
    () =>
      user.role === Role.admin ? (
        <RoleRequired role={Role.admin}>
          <BatchDropDown
            products={products!}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            batch={batch}
            setBatch={setBatch}
            productsFrom={"products"}
          />
          <div className="pim-main-tabs">
            <PimOpenTabs />
          </div>
          <div
            className="main-table-title-cont m0"
            style={{ paddingLeft: "25px", paddingRight: "40px" }}
          >
            <div className="mplc-filter">
              <button
                onClick={() => {
                  setCreateProductModal(true);
                }}
                className="pim-title-button pim-green"
              >
                Create Product
              </button>
              {createProductModal && (
                <CreateProductModal
                  modal={createProductModal}
                  setModal={setCreateProductModal}
                  schemes={schemes}
                  selectData={selectData}
                  sendRequest={async (data) => {
                    await Promise.all(
                      _.uniq(countries.map((x) => x.langCode)).map(
                        async (lang: any) => {
                          try {
                            await saveProductInfo({
                              ...data,
                              lang,
                            });
                          } catch (error) {
                            console.error(error);
                          }
                        }
                      )
                    );
                  }}
                />
              )}
              <Link to="/pim-management" className="filter-text">
                Schemes
              </Link>
              <div className="ml2">
                <FormControl>
                  <InputLabel
                    className="pim-main-scheme-select-title"
                    id="demo-simple-select-label"
                  >
                    Select scheme
                  </InputLabel>
                  <Select
                    className="pim-main-schemes-select"
                    id="select"
                    value={""}
                  >
                    {schemes?.map((scheme: any, index: number) => (
                      <NavLink to={`/pim/scheme/${scheme.name}`} key={index}>
                        <MenuItem>{scheme.name}</MenuItem>
                      </NavLink>
                    ))}
                    <MenuItem onClick={() => setModal(true)}>
                      + New Scheme
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              {modal && (
                <CreateSchemeForm
                  modal={modal}
                  setModal={setModal}
                  onModalClose={onModalClose}
                />
              )}
            </div>
            <div className="in-row align-center">
              {/* {batch && (
								<div className="in-row align-center">
									<div className="pim-batch-figure">
										<img
											width="45px"
											height="45px"
											src="/icons/comment-cloud.svg"
											alt=""
										/>
										<span className="pim-batch-text">
											{selectedProducts.length}
										</span>
									</div>
									<NavLink to="/batch-products?from=products">
										<div className="upload-batch-button in-row align-center ml1">
											<img
												className="mr1"
												width="15px"
												height="15px"
												src="/icons/upload.svg"
												alt=""
											/>
											<div>Upload batch</div>
										</div>
									</NavLink>
								</div>
							)} */}
              <div className="ml2">
                <FormControl>
                  <InputLabel
                    className="pim-main-scheme-select-title"
                    id="select-action"
                  >
                    Select action
                  </InputLabel>
                  <Select
                    className="pim-main-schemes-select"
                    id="select-actions"
                    value={""}
                  >
                    <MenuItem onClick={() => setModalMassImport(true)}>
                      <RoleRequired role={Role.admin}>Mass import</RoleRequired>
                    </MenuItem>
                    <MenuItem onClick={() => setModalMassUpdate(true)}>
                      <RoleRequired role={Role.admin}>
                        Mass update price/stock
                      </RoleRequired>
                    </MenuItem>
                    <MenuItem onClick={() => setShowExportForm(true)}>
                      Export products
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        exportPriceStockData().then(() =>
                          toast.success(
                            "Success! Check your mailbox, it should be there in seconds."
                          )
                        )
                      }
                    >
                      Export products price/stock
                    </MenuItem>
                  </Select>
                </FormControl>
                {showExportForm && (
                  <ExportAllProductInfo
                    modal={showExportForm}
                    setModal={setShowExportForm}
                  />
                )}
                {modalMassImport && (
                  <MassImport
                    onSuccess={() => toast.success("Successful import!")}
                    onError={() => toast.error("Import failed...")}
                    modal={modalMassImport}
                    setModal={setModalMassImport}
                  />
                )}
                {modalMassUpdate && (
                  <MassUpdate
                    onSuccess={() => toast.success("Successful import!")}
                    onError={() => toast.error("Import failed...")}
                    modal={modalMassUpdate}
                    setModal={setModalMassUpdate}
                  />
                )}
              </div>
            </div>
          </div>
        </RoleRequired>
      ) : (
        <div
          className="main-table-title-cont table-main-cont m0"
          style={{ paddingLeft: 42, paddingBottom: 0, paddingTop: 20 }}
        >
          <div className="in-row align-center justify-between">
            <div className="billing-modal-text bold pb1">
              Product Information Management
            </div>
            <div
              className="req-popup-button in-row align-center"
              onClick={() => setShowExportForm(true)}
            >
              <div>Export</div>
            </div>
          </div>
          {showExportForm && (
            <ExportAllProductInfo
              modal={showExportForm}
              setModal={setShowExportForm}
            />
          )}
        </div>
      ),
    [
      user.role,
      countries,
      createProductModal,
      modal,
      modalMassImport,
      modalMassUpdate,
      schemes,
      selectData,
      showExportForm,
      batch,
      products,
      selectedProducts,
      setSelectedProducts,
    ]
  );

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <>
      <div className="main-body">
        <div className="main-container">
          {header}
          {!isDataLoading && !loadProducts ? (
            <div className="table-main-cont pt4">
              <PimMainTable
                products={products!}
                setCurrentConfig={setCurrentConfig}
                currentConfig={currentConfig}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
              />
            </div>
          ) : (
            <Preloader />
          )}
        </div>
      </div>
    </>
  );
};

export const PimOpenTabs: React.FC<any> = () => {
  const [tabs, setTabs] = useState([] as string[]);
  const [schemeTabs, setSchemeTabs] = useState([] as string[]);

  const history = useHistory();

  useEffect(() => {
    let count = 0;
    const handle = setInterval(() => {
      if (++count === 3) {
        clearInterval(handle);
      }

      setTabs(getOpenTabs());
      setSchemeTabs(getOpenSchemeTabs());
    }, 100);
  }, [history.location]);

  return (
    <Box sx={{ maxWidth: 2560, background: "#E51B15" }}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        value={0}
      >
        <Tab
          style={{
            padding: "0 10px",
            minHeight: "50px",
            minWidth: "auto",
            display: "flex",
            alignItems: "center",
          }}
          iconPosition="end"
          className={`pim-main-tab ${
            history.location.pathname === "/products"
              ? "pim-main-tab-active"
              : ""
          }`}
          label="Products"
          to="/products"
          component={Link}
        />
        {tabs.map((sku: string) => {
          const productPath = `/productInfo/${sku}`;
          return (
            <Tab
              key={sku}
              style={{
                padding: "0px 10px 0px 25px",
                minHeight: "50px",
                minWidth: "auto",
                display: "flex",
                alignItems: "center",
              }}
              icon={
                <CancelRoundedIcon
                  className="pointer"
                  style={{ width: "20px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    removeOpenTab(sku);

                    const newTabs = tabs.filter((x) => x !== sku);
                    setTabs(newTabs);

                    if (history.location.pathname === productPath) {
                      history.push("/products");
                    }
                  }}
                />
              }
              iconPosition="end"
              className={`pim-main-tab ${
                history.location.pathname === productPath
                  ? "pim-main-tab-active"
                  : ""
              }`}
              label={sku}
              to={productPath}
              component={Link}
            />
          );
        })}
        {schemeTabs.map((id: string, index: number) => {
          const schemePath = `/pim/scheme/${id}`;
          return (
            <Tab
              style={{
                padding: "0px 10px 0px 25px",
                minHeight: "50px",
                minWidth: "auto",
                display: "flex",
                alignItems: "center",
              }}
              icon={
                <CancelRoundedIcon
                  className="pointer"
                  style={{ width: "20px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    removeOpenSchemeTab(id);

                    const newTabs = schemeTabs.filter((x) => x !== id);
                    setSchemeTabs(newTabs);

                    if (history.location.pathname === schemePath) {
                      history.push("/products");
                    }
                  }}
                />
              }
              iconPosition="end"
              className={`pim-main-tab ${
                history.location.pathname === schemePath
                  ? "pim-main-tab-active"
                  : ""
              }`}
              label={id}
              to={schemePath}
              component={Link}
              key={index}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};
