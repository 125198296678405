import React, { useCallback, useState } from "react";
import * as _ from "lodash";
import { ICategoryField } from "types";
import { Modal } from "../../../ui/modal/Modal";
import { ICountryM } from "../../country-management/CountryManagement";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import { toast } from "react-toastify";
import { IRate } from "./PRatesTable";
import { updateMarketplacesByMplcName } from "../../../../actions/marketplace";
import * as PlatformRates from "../../../../actions/categoryField";
import { queryClient } from "../../../App";
import { useMutation } from "react-query";

interface Props {
	modal: boolean;
	setModal: (modal: boolean) => void;
	category: ICategoryField;
	countries: ICountryM[];
}

const PRatesModal: React.FC<Props> = ({
	modal,
	setModal,
	category,
	countries,
}) => {
	const [newCat, setNewCat] = useState<ICategoryField>(category);

	const onInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, country: string) => {
			e.preventDefault();

			const updRates = newCat.rates.map((rate: IRate) => {
				if (rate.country === country) {
					rate.rate = Number(e.target.value);
					return rate;
				}
				return rate;
			});

			setNewCat({
				...category,
				rates: updRates,
			});
		},
		[category, newCat.rates]
	);

	const updateRatesConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries("paginated-category-fields");
			toast.success("Platform rates updated successfully!");
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const updateRatesMutation = useMutation(
		(newCat: ICategoryField) => PlatformRates.update(newCat._id, newCat),
		updateRatesConfig
	);

	const updatePlatformRates = async (e: React.MouseEvent) => {
		e.preventDefault();

		const minRate = _.minBy(newCat.rates, "rate")?.rate;
		const maxRate = _.maxBy(newCat.rates, "rate")?.rate;

		await updateRatesMutation.mutateAsync(newCat);
		await updateMarketplacesByMplcName(newCat.marketplaceName, {
			from: minRate,
			to: maxRate,
		});
		setModal(false);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="table-modal-cont">
				<div className="table-modal-title-box">
					<div className="table-modal-title-pricing">
						<div>Edit Platform Rates</div>
					</div>
				</div>
				<div className="table-modal-border"></div>
				<form className="table-modal-form-cont">
					<div className="table-req-modal-cont">
						<div className="in-row">
							<div className="mr4">
								<img
									src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${category.marketplaceName}.svg`}
									alt=""
									width="70px"
									height="70px"
									className="rates-img-border p1"
								/>
							</div>
							<div className="table-req-modal-elems">
								<div className="mb1 capital">{category.marketplaceName}</div>
								<div className="mb1">
									<span className="bold">Category:</span>{" "}
									<span>{category.name}</span>
								</div>
							</div>
						</div>
						<div className="table-modal-border"></div>
						<div className="width100 height300 in-column wrap justify-start">
							{countries.map((c: ICountryM) => (
								<div className="in-row align-center mb2 width50" key={c._id}>
									<div className="in-row align-center width60">
										<CountryFlag country={c.name} width="28px;" />
										<span className="ml2 mr2">{c.name}:</span>
									</div>
									<input
										className="rates-modal-input rates-modal-input-value"
										value={
											newCat.rates.find(
												(rate: IRate) => rate.country === c.name
											)?.rate
										}
										onChange={(e) => onInputChange(e, c.name)}
									/>
								</div>
							))}
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box">
						<button
							onClick={onModalClose}
							className="table-modal-form-button-cancel"
						>
							Cancel
						</button>
						<button
							className="table-modal-form-button"
							onClick={updatePlatformRates}
						>
							Save
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default PRatesModal;
