import React, { useEffect, useState, useContext, useCallback } from "react";
import { getProductsFromPlatform } from "../../../actions/marketplace";
import { IPaginatedRequest, IProductGestao } from "../../../interfaces";
import "./change.css";
import Preloader from "../../ui/preloader/Preloader";
import { TooltipText } from "../tables/gestao/Tooltip";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../users-management/UsersManagement";
import { useDebouncedValue } from "@mantine/hooks";
import { useQuery } from "react-query";
import Paginator from "../../ui/pagination";
import { getMarketplaceProductImage } from "../../../actions/pim";

interface Props {
	marketplace: any;
}

export const TableSection: React.FC<Props> = ({ marketplace }) => {
	const { user } = useUser();

	const [search, setSearch] = useState<string>("");
	const [debouncedSearch] = useDebouncedValue(search, 200);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: debouncedSearch,
	});

	const {
		isLoading,
		data: products,
		refetch,
	} = useQuery("products", () =>
		getProductsFromPlatform(marketplace.zeoosName, currentConfig)
	);

	useEffect(() => {
		refetch();
		// eslint-disable-next-line
	}, [currentConfig]);

	useEffect(() => {
		setCurrentConfig((currentConfig) => ({
			...currentConfig,
			search: debouncedSearch,
		}));
	}, [debouncedSearch]);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			config.search = debouncedSearch;
			setCurrentConfig(config);
		},
		[debouncedSearch]
	);

	if (isLoading) {
		return <Preloader />;
	}

	return (
		<>
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont">
					<div className="table-search-box ml2">
						<form
							className="table-search-form"
							onSubmit={(e) => e.preventDefault()}
						>
							<input
								className="table-search-input"
								placeholder="Search product or sku"
								onChange={(e) => setSearch(e.target.value)}
							/>
							<img
								className="table-search-image"
								src="/icons/search.svg"
								width="25px;"
								alt=""
							/>
						</form>
					</div>
					<div className="table-value">
						{["gv@vinuus.com", "pj@vinuus.com"].includes(user.email) && (
							<RoleRequired role={Role.admin}>
								<img
									className="refresh-image"
									src="/icons/refresh.svg"
									width="20px;"
									alt=""
								/>
							</RoleRequired>
						)}
						<span className="table-main-title-text">
							{products.total > 1
								? `${products.total} PRODUCTS`
								: `${products.total} PRODUCT`}
						</span>
					</div>
				</div>
				<div className="marketplace-pagination">
					<Paginator data={products} refetch={onChangePage as any} />
				</div>
			</div>
			<div style={{ height: "max-content" }} className="table-overflow">
				<table
					style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
				>
					<tr
						style={{ marginTop: "-5px", width: "100%" }}
						className="table-results-title"
					>
						<th className="gestao-image-link"></th>
						<th className="gestao-image"></th>
						<th className="singlemarketplace-sku">Sku</th>
						<th className="singlemarketplace-ean">EAN</th>
						<th className="singlemarketplace-title">Title</th>
						<th className="singlemarketplace-vendors">Brands</th>
						<td className="table-border-right"></td>
						<th className="singlemarketplace-price align-center">Price</th>
						<th className="singlemarketplace-stock">Stock</th>
					</tr>
				</table>
				<div>
					<table className="table-results p0">
						<tbody>
							{products.data.map((item: IProductGestao, index: number) => (
								<tr className="table-result-box" key={index}>
									<td className="gestao-image-link">
										<img
											src={
												!item.components?.length || item.components?.length > 1
													? `/icons/group-p.svg`
													: `/icons/arrow-return-right.svg`
											}
											alt=""
										/>
									</td>
									<td className="gestao-image">
										<Link to={`/productInfo/${item.sku}`}>
											<img
												className="gestao-table-image"
												src={getMarketplaceProductImage(item)}
												alt=""
											/>
										</Link>
									</td>
									<td className="singlemarketplace-sku">
										<div className="singlemarketplace-text">
											<Link to={`/productInfo/${item.sku}`}>{item.sku}</Link>
										</div>
									</td>
									<td className="singlemarketplace-ean">{item.barcode}</td>
									<td className="singlemarketplace-title">
										<div className="singlemarketplace-text">
											<TooltipText text={item.name} items={8} separator={" "} />
										</div>
									</td>
									<td className="singlemarketplace-vendors">{item.brand}</td>
									<td className="table-border-right"></td>
									<td className="singlemarketplace-price">
										<div className="singlemarketplace-text">
											{getPriceOrDefault(
												item.marketplaces?.[marketplace.zeoosName]
											)}{" "}
											€
										</div>
									</td>
									<td className="singlemarketplace-stock">
										<div className="singlemarketplace-text">
											{item.marketplaces?.[marketplace.zeoosName]?.stock || 0}
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

const getPriceOrDefault = (marketplaceData: any, defaultPrice = 0) =>
	String(marketplaceData?.price || defaultPrice).replace(".", ",");
