import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import querystring from "query-string";
import { getOrdersZeoos } from "../actions/bi";
import { IPaginatedRequest, ReactQueryConfig } from "../interfaces";
import { listOrders, getOrder } from "../actions/order";
import * as _ from "lodash";

export function useOrders(
	from: number | Date,
	to: number | Date,
	config?: ReactQueryConfig
) {
	const key = useMemo(
		() => `orders?${querystring.stringify({ from, to })}`,
		[from, to]
	);
	const data = useQuery(
		key,
		async () => await getOrdersZeoos({ from, to }),
		// async () => await listOrders({ from, to }),
		config
	);

	return data;
}

export function useListOrders(
	body: IPaginatedRequest,
	config?: ReactQueryConfig
) {
	const [statuses, setStatuses] = useState<string[]>([]);
	const [countries, setCountries] = useState<string[]>([]);
	const [channels, setChannels] = useState<string[]>([]);

	const key = useMemo(
		() => `listOrders?${querystring.stringify(body)}`,
		[body]
	);

	const data = useQuery(
		key,
		async () => {
			const data = await listOrders(body);
			setStatuses((prev) =>
				!!prev.length
					? prev
					: [
							"All statuses",
							..._.uniq(_.map(data, (item: any) => item.status)).filter(
								(x: any) => x !== "" && x !== false
							),
					  ]
			);
			setCountries((prev) =>
				!!prev.length
					? prev
					: [
							"All countries",
							..._.uniq(
								_.map(data, (item: any) => item.customer?.country)
							).filter((x: any) => x && x.trim()),
					  ]
			);
			setChannels((prev) =>
				!!prev.length
					? prev
					: [
							"All channels",
							..._.uniq(_.map(data, (item: any) => item.zeoosName)),
					  ]
			);

			return data;
		},
		config
	);

	return { data, statuses, countries, channels };
}

export function useSingleOrder(id: string, config?: ReactQueryConfig) {
	const key = useMemo(() => `singleOrder/${id}`, [id]);
	const data = useQuery(key, async () => await getOrder(id), config);

	return data;
}

export default useOrders;
