import React, { useState, useContext } from "react";
import "./freight.css";
import { FreightModal } from "./FreightModal";
import { CountriesContext } from "../../../context";
import { ICountryM } from "../../country-management/CountryManagement";
import { getFreights, getWeights } from "../../../../actions/freight";
import { useQuery } from "react-query";

export const FreightTable: React.FC = () => {
  const countries = useContext(CountriesContext) as ICountryM[];

  const [modal, setModal] = useState<boolean>(false);
  const [selectedFreight, setSelectedFreight] = useState("");
  const freights = useQuery("freights", () => getFreights());
  const weights = useQuery("weights", () => getWeights());

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  const getWeightPrice = (weight: number, mapping: any[]) => {
    const mappingWeight = mapping.find(
      (mapping: any) => mapping.weight === weight
    );
    if (mappingWeight && mappingWeight.price) {
      return `€ ${mappingWeight.price}`;
    } else {
      return "-";
    }
  };

  return (
    <>
      <div className="table-main-cont">
      <div style={{padding: "0 40px"}} className="width100">
        <div className="table-main-title-cont">
          <div className="table-main-title-search-value-cont">
            <div className="table-main-title-text pl2">15 freight maps</div>
          </div>
        </div>
        <div
          style={{ height: "max-content", minHeight: "80vh" }}
          className="table-overflow"
        >
          <table
            style={{
              position: "sticky",
              zIndex: 101,
              top: "2px",
              width: "100%",
            }}
          >
            <tr
              style={{ marginTop: "-5px", width: "100%" }}
              className="table-results-title"
            >
              <th className="gestao-sticky-block admin-titlebg">
                <th className="freight-pais">País</th>
                <th className="freight-reg">Região</th>
                <th className="freight-prazo">Prazo</th>
                <th className="freight-vat">IVA <br /> VAT</th>
                <th className="table-border-right"></th>
              </th>
              <th className="table-border-right"></th>
              {weights.data?.map((value: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <th className="freight-column justify-center">{value}</th>
                  </React.Fragment>
                );
              })}
            </tr>
          </table>
          <table className="table-results">
            {/* <thead>
                            <tr className="table-results-title">
                            <th className="gestao-sticky-block admin-titlebg">
                                <th className="freight-pais">País</th>
                                <th className="freight-reg">Região</th>
                                <th className="freight-prazo">Prazo</th>
                                <th className="table-border-right"></th>
                                </th>
                                <th className="table-border-right"></th>
                                {weights.map((value: any, index: any) => {
                                    return <React.Fragment key={index}>
                                        <th className="freight-column">{value}</th>
                                    </React.Fragment>
                                })}
                            </tr>
                        </thead> */}
            <tbody>
              {freights.data?.map((freight: any) => {
                return (
                  <React.Fragment key={freight._id}>
                    <tr className="table-result-box freight-body">
                      <td className="gestao-sticky-block">
                        <td
                          onClick={() => {
                            setModal(true);
                            setSelectedFreight(freight);
                          }}
                          className="freight-pais pointer"
                        >
                          {countries.find(
                            (x: any) => freight.country === x.name
                          ) ? (
                            <>
                              <img
                                width="30px;"
                                alt=""
                                src={
                                  countries.find(
                                    (x: any) => freight.country === x.name
                                  )?.iconUrl
                                }
                              />
                              <div className="ml2">{freight.country}</div>
                            </>
                          ) : (
                            <div>{freight.country}</div>
                          )}
                        </td>
                        <td className="freight-reg">{freight.region}</td>
                        <td className="freight-prazo">
                          {freight.timeMin}h - {freight.timeMax}h
                        </td>
                        <td className="freight-vat">{freight.vat || 0} %</td>
                        <td className="table-border-right"></td>
                      </td>
                      <td className="table-border-right"></td>
                      {weights.data?.map((value: any, index: any) => {
                        return (
                          <React.Fragment key={index}>
                            <td className="freight-column">
                              {getWeightPrice(value, freight.mapping)}
                            </td>
                          </React.Fragment>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        </div>
      </div>
      {modal && (
        <FreightModal
          onModalClose={onModalClose}
          isOpened={modal}
          freight={selectedFreight}
        />
      )}
    </>
  );
};
