import React, { useState } from "react";
import PricingPlan from "./PricingPlan";
import UploadProductsModal from "./UploadProductsModal";
import { toast } from "react-toastify";
import { queryClient } from "../../../App";
// @ts-ignore
import { DateTime } from "luxon-business-days";

interface Props {
	onboardingSellers: any;
	setModalNewOnboarding: (
		modalNewOnboarding: React.SetStateAction<boolean>
	) => void;
	setSellerId: (sellerId: React.SetStateAction<string>) => void;
	rqKeyOnboardingSellers: string;
}

const OnboardingTable: React.FC<Props> = ({
	onboardingSellers,
	setModalNewOnboarding,
	setSellerId,
	rqKeyOnboardingSellers,
}) => {
	const [modalProductUpload, setModalProductUpload] = useState<boolean>(false);
	const [selectedSellerId, setSelectedSellerId] = useState<number>(0);
	// eslint-disable-next-line
	const [fileName, setFileName] = useState<string>("");
	// eslint-disable-next-line
	const [productsFromExcel, setProductsFromExcel] = useState<any>([]);

	const timeToRelease = (created: string) => {
		if (!created) {
			return <span className="table-subtext">Na</span>;
		}

		const timeObj = DateTime.fromISO(created)
			.plusBusiness({ days: 4 })
			.diff(DateTime.now(), ["days", "hours", "minutes"])
			.toObject();

		// @ts-ignore
		const isExpired = Object.values(timeObj).some((x: number) => x < 0);

		const days = Math.abs(timeObj.days as number);
		const hours = Math.abs(timeObj.hours as number);
		const minutes = Math.abs(timeObj.minutes as number);

		return `${days > 0 ? `${days}d` : ""} ${hours > 0 ? `${hours}h` : ""} ${
			minutes > 0 ? `${minutes?.toFixed(0)}m` : ""
		} ${isExpired ? "late" : "left"}`;
	};

	return (
		<div
			style={{ height: "max-content", marginBottom: "20px" }}
			className="table-overflow"
		>
			<table
				style={{
					zIndex: 101,

					width: "100%",
				}}
			>
				<thead>
					<tr
						style={{ marginTop: "-5px", width: "100%" }}
						className="table-results-title"
					>
						<th className="onborading-name">Seller name</th>
						<th className="onborading-column">
							Seller
							<br />
							information
						</th>
						<th className="">
							<img src="/icons/orange-arrow.svg" alt="" />
						</th>
						<th className="onborading-column">Products</th>
						<th className="">
							<img src="/icons/orange-arrow.svg" alt="" />
						</th>
						<th className="onborading-column">Pricing plan</th>
						<th className="">
							<img src="/icons/orange-arrow.svg" alt="" />
						</th>
						<th className="onborading-column">Contract</th>
						<th className="">
							<img src="/icons/orange-arrow.svg" alt="" />
						</th>
						<th className="onborading-column">Payment</th>
						<th className="">
							<img src="/icons/orange-arrow.svg" alt="" />
						</th>
						<th className="onborading-column">Price/Stock</th>
						<th className="">
							<img src="/icons/orange-arrow.svg" alt="" />
						</th>
						<th className="onborading-column">
							Release
							<br />
							process
						</th>
					</tr>
				</thead>
			</table>
			<table className="table-results">
				<tbody>
					{onboardingSellers?.data.map((seller: any) => (
						<tr
							key={seller._id}
							style={
								seller.releaseProcess
									? { background: "rgba(108, 217, 70, 0.1)" }
									: { marginTop: "-5px" }
							}
							className="table-result-box height120px email-text border0 width100"
						>
							<td className="onborading-name">
								{seller.releaseProcess ? (
									<b style={{ color: "#299C00" }}>
										{seller.contactInfo?.sellerName}
									</b>
								) : (
									<span>{seller.contactInfo?.sellerName}</span>
								)}
							</td>
							<td className="onborading-column">
								<div className="in-row align-center">
									<div
										className="pointer"
										onClick={() => {
											setSellerId(seller._id);
											setModalNewOnboarding(true);
										}}
									>
										<img src="/icons/sel-info-icon.svg" alt="" />
									</div>
								</div>
							</td>
							<td className="">
								<img width="13px" src="" alt="" />
							</td>
							<td className="onborading-column">
								{!seller.onBoardingProducts ? (
									<button
										style={{ zIndex: "100" }}
										className="sel-set-actions-modal"
										onClick={() => {
											setSelectedSellerId(seller.id);
											setModalProductUpload(true);
										}}
									>
										Upload file
									</button>
								) : (
									<img
										className="pointer"
										src="/icons/sel-green-done.svg"
										alt=""
										onClick={() => {
											setSelectedSellerId(seller.id);
											setModalProductUpload(true);
										}}
									/>
								)}

								{/* {fileName === "" ? (
									<button
										style={{ zIndex: "100" }}
										className="sel-set-actions-modal"
										onClick={() => {
											setSelectedSellerId(seller.id);
											setModalProductUpload(true);
										}}
									>
										Upload file
									</button>
								) : (
									<OnboardingProducts
										productsFromExcel={productsFromExcel}
										setModalProductUpload={setModalProductUpload}
									/>
								)} */}
							</td>
							<td className="">
								<img width="13px" src="" alt="" />
							</td>
							<td className="onborading-column">
								<PricingPlan
									seller={seller}
									rqKeyOnboardingSellers={rqKeyOnboardingSellers}
								/>
							</td>
							<td className="">
								<img width="13px" src="" alt="" />
							</td>
							<td className="onborading-column">
								{seller.contractStatus === "Accepted" ? (
									<img src="/icons/sel-green-done.svg" alt="" />
								) : (
									<span className="table-subtext">{seller.contractStatus}</span>
								)}
							</td>
							<td className="">
								<img width="13px" src="" alt="" />
							</td>
							<td className="onborading-column">
								{seller.paymentStatus === "Accepted" ? (
									<img src="/icons/sel-green-done.svg" alt="" />
								) : (
									<span className="table-subtext">{seller.paymentStatus}</span>
								)}
							</td>
							<td className="">
								<img width="13px" src="" alt="" />
							</td>
							<td className="onborading-column">
								{!seller.onBoardingPriceStock ? (
									<span className="table-subtext">Na</span>
								) : (
									<img src="/icons/sel-green-done.svg" alt="" />
								)}
							</td>
							<td className="">
								<img width="13px" src="" alt="" />
							</td>
							<td className="onborading-column">
								{!seller.releaseProcess &&
								!seller?.onBoardingPriceStockDate &&
								seller.paymentStatus !== "Accepted" ? (
									<span className="table-subtext">Na</span>
								) : (
									<>
										{seller.releaseProcess ? (
											<>
												<span
													style={{ color: "#299C00" }}
													className="uppercase-text bold"
												>
													released
												</span>
												{DateTime.fromISO(seller.releaseDate).toFormat(
													"yyyy LLL dd"
												)}
											</>
										) : (
											<span
												className={
													!seller?.onBoardingPriceStockDate
														? ""
														: "sel-red-text"
												}
											>
												{timeToRelease(seller?.onBoardingPriceStockDate)}
											</span>
										)}
										{seller.paymentStatus !== "Accepted" && (
											<span className="table-subtext">Na</span>
										)}
									</>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{modalProductUpload && (
				<UploadProductsModal
					onSuccess={() => {
						toast.success("Successful import!");
						queryClient.invalidateQueries(rqKeyOnboardingSellers);
					}}
					onError={() => toast.error("Import failed...")}
					modal={modalProductUpload}
					setModal={setModalProductUpload}
					seller={selectedSellerId}
					type={"products"} //"pricestock" to reuse for price upload
					setFileName={setFileName}
					setProductsFromExcel={setProductsFromExcel}
				/>
			)}
		</div>
	);
};

export default OnboardingTable;
