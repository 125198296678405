import React from "react";
// import "./gestao.css";
// import "../tables.css";
import { Modal } from "../../../ui/modal/Modal";
import { TooltipInfoText } from "../../../ui/tooltip/TooltipInfoText";
import { toStringConverter } from "../../tables/gestao/UpdateGestao";
import { DateTime } from "luxon";



interface Props {
	modal: boolean;
	setModal: (modal: boolean) => void;
	product: any;
	// onUpdateCallback: (data: any) => void;
	// productMarketplace: IMarketplace;
	// vendors?: any;
}



const formatMoney = (number: number) => {
	return new Intl.NumberFormat("de", {
		style: "currency",
		currency: "EUR",
	}).format(!number ? 0 : number);
};

const formatPercent = (number: number) => {
	return new Intl.NumberFormat("de", {
		style: "percent",
		maximumFractionDigits: 2,
	})
		.format(!number ? 0 : number / 100)
		.replace(/\s/g, "");
};




export const PriceBreakdown: React.FC<Props> = ({
	modal,
	setModal,
	product,
	// onUpdateCallback,
	// productMarketplace,
	// vendors,
}) => {
  console.log(product);
  const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div style={{ maxWidth: "520px" }} className="table-modal-cont">
				<div className="table-modal-title-box width100 pb1 m0">
					<div className="mapping-modal-title-text">
						Pricing breakdown
					</div>          
				</div>  
        <div className="in-row mt2 align-center justify-between width100">
          <span className="int-text mr4">
            <b className="mr1">SKU:</b>
            { product.sku }
          </span>
          <span className="int-text">
            <b className="mr1">Date:</b>
            { DateTime.fromISO(product.billingDate).toLocaleString(DateTime.DATETIME_SHORT) }
          </span>
				</div> 
				<div className="table-modal-border mb1"></div>
        <form className="table-modal-form-cont">
							<div className="width100">
								<div className="width100 in-row align-center ft2">
									<div className="width60 justify-end bold uppercase-text opacity5">
										
									</div>
									<div className="newPriceButton in-row align-center justify-end bold">
									
									</div>
								</div>
								{product?.pricingType === "fullBreakdown" ? (
									<FullBreakdown breakdown={product?.price_breakdown} />
								) : product?.pricingType === "wholesaler" ? (
									<WholesalerBreakdown breakdown={product?.price_breakdown} />
								) : product?.pricingType === "wortenSeller" ? (
									<WortenSellerBreakdown breakdown={product?.price_breakdown} />
								) : product?.pricingType === "pvpAndCost" ? (
									<PvpAndCostBreakdown breakdown={product?.price_breakdown} />
								) : product?.pricingType === "d2c" ? (
									<PvpAndCostBreakdown breakdown={product?.price_breakdown} />
								) : (
									<DefaultBreakdown breakdown={product} />
								)}
							</div>
									
							<div className="table-modal-border"></div>
							<div
								className="table-modal-form-button-box"
								style={{ marginBottom: 0, justifyContent: "center" }}
							>
								<button
									onClick={onModalClose}
									className="table-modal-form-button"
								>
									Cancel
								</button>
								{/* <button
							className="table-modal-form-button"
							type="submit"
							disabled={stateButton}
							onClick={onSubmitHandler}
						>
							update
						</button> */}
							</div>
						</form>

			</div>
		</Modal>
	);
};

//TODO: split this component in new file
const DefaultBreakdown: React.FC<{ breakdown: any; }> = ({ breakdown }) => {
	return (
		<>
			<div className="table-modal-form-box ft3 pb5 pt5 bold green-bg">
				<div className="width100">PVP VISÃO POR:</div>
				<div className="table-modal-input-box">
					{formatMoney(breakdown?.pvpFinal)}
				</div>
			</div>
			<div className="ft2 mt4">
				<div className="table-modal-form-box">
					<div className="width100">IVA ({formatPercent(breakdown?.iva)}):</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.ivaValue)}
					</div>
				</div>
				<div className="table-modal-form-box">
					<div className="width100">IEC:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.iec)}
					</div>
				</div>
			</div>
			<div className="ft2">
				<div className="table-modal-form-box">
					<div className="width100">Freight:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.deliveryPrice)}
					</div>
				</div>
				<div className="table-modal-form-box">
					<div className="width100">PVP Base:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.basePrice)}
					</div>
				</div>
			</div>
			<div className="ft2">
				<div className="table-modal-form-box">
					<div className="width100 defaultBreakdown">
						<TooltipInfoText
							text={
								<div className="pricingTooltop">
									<div className="service">
										Vinuus Services:
										<span>
											{toStringConverter(
												breakdown?.services + breakdown?.vinuusMargin
											)}{" "}
											€
										</span>
									</div>
									<ul>
										<li>Marketing and Advertisement</li>
										<li>Marketplace Platforms Comissions</li>
										<li>Payment Services Fees</li>
										<li>Catalogue Data Management</li>
										<li>Pricing Management</li>
										<li>Warehousing</li>
										<li>Order management</li>
										<li>Picking</li>
										<li>Packing</li>
										<li>Invoicing</li>
										<li>Shipping & Order Monitoring</li>
										<li>Customer Services</li>
									</ul>
								</div>
							}
						/>
						Vinuus Services:
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.vinuusPrice)}
					</div>
				</div>
			</div>

			<div className="ft2">
				<div className="table-modal-form-box bold">
					<div className="width100">Vendor Price:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.vendorPrice)}
					</div>
				</div>
			</div>
		</>
	);
};

//TODO: split this component in new file
const FullBreakdown: React.FC<{ breakdown: any; }> = ({ breakdown }) => {
	return (
		<>
			<div className="table-modal-cont-box table-modal-cont-text ft3 pb5 pt5 bold green-bg">
				<div className="width100">PVP:</div>
				<div className="table-modal-input-box">
					{formatMoney(breakdown?.pvpFinal)}
				</div>
			</div>
			<div className="ft2 mt4">
        <div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Product VAT ({formatPercent(breakdown?.productVAT)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.productVATValue)}
					</div>
				</div>
        <div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">Excise Duty:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.duty)}
					</div>
				</div>
				
			</div>
			<div className="table-modal-border"></div>
			<div className="ft2">
				<div className="table-modal-cont-box table-modal-cont-text bold">
					<div className="width100">PVP Base:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.pvpBase)}
					</div>
				</div>
        <div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Platform Rate ({formatPercent(breakdown?.platformRate)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.platformValue)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">Freight:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.freight)}
					</div>
				</div>
				{/* <div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Freight VAT ({formatPercent(breakdown?.freightVAT)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.freightVATValue)}
					</div>
				</div> */}
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">Fulfilment Cost:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.fulfillCost)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Zeoos Rate ({formatPercent(breakdown?.zeoosRate)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.zeoosValue)}
					</div>
				</div>
			</div>
			<div className="table-modal-border"></div>
			<div className="height80px ft2">
				<div className="table-modal-cont-box table-modal-cont-text bold">
					<div className="width100">Purchase Price:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.purchasePrice)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">Markup:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.markup)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">Cost:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.cost)}
					</div>
				</div>
			</div>
		</>
	);
};

//TODO: split this component in new file
const WholesalerBreakdown: React.FC<{ breakdown: any; }> = ({ breakdown }) => {
	return (
		<>
			<div className="table-modal-cont-box table-modal-cont-text ft3 pb5 pt5 bold green-bg">
				<div className="width100">PVP:</div>
				<div className="table-modal-input-box">
					{formatMoney(breakdown?.pvpFinal)}
				</div>
			</div>
			<div className="ft2 mt4">
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Product VAT ({formatPercent(breakdown?.productVAT)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.productVATValue)}
					</div>
				</div>
			</div>
			<div className="table-modal-border"></div>
			<div className="ft2">
				<div className="table-modal-cont-box table-modal-cont-text bold">
					<div className="width100">PVP Base:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.pvpBase)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Zeoos Rate ({formatPercent(breakdown?.zeoosRate)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.zeoosValue)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Platform Rate ({formatPercent(breakdown?.platformRate)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.platformValue)}
					</div>
				</div>
			</div>
			<div className="table-modal-border"></div>
			<div className="ft2">
				<div className="table-modal-cont-box table-modal-cont-text bold">
					<div className="width100">Purchase Price:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.purchasePrice)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						VAT ({formatPercent(breakdown?.costVAT)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.costVATValue)}
					</div>
				</div>
				{!breakdown?.freight &&
					<div className="table-modal-cont-box table-modal-cont-text">
						<div className="width100">Transport:</div>
						<div className="table-modal-input-box">
							{formatMoney(breakdown?.transport)}
						</div>
					</div>
				}
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">Payment Cost:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.paymentCosts)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">Cost:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.cost)}
					</div>
				</div>
			</div>
		</>
	);
};

//TODO: split this component in new file
const WortenSellerBreakdown: React.FC<{ breakdown: any; }> = ({ breakdown }) => {
	return (
		<>
			<div className="table-modal-cont-box table-modal-cont-text ft3 pb5 pt5 bold green-bg">
				<div className="width100">PVP:</div>
				<div className="table-modal-input-box">
					{formatMoney(breakdown?.pvpFinal)}
				</div>
			</div>
			<div className="ft2 mt4">
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Product VAT ({formatPercent(breakdown?.productVAT)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.productVATValue)}
					</div>
				</div>
			</div>
			<div className="table-modal-border"></div>
			<div className="ft2">
				<div className="table-modal-cont-box table-modal-cont-text bold">
					<div className="width100">PVP Base:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.pvpBase)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Zeoos Rate ({formatPercent(breakdown?.zeoosRate)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.zeoosValue)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Platform Rate ({formatPercent(breakdown?.platformRate)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.platformValue)}
					</div>
				</div>
			</div>
			<div className="table-modal-border"></div>
			<div className="ft2">
				<div className="table-modal-cont-box table-modal-cont-text bold">
					<div className="width100">Purchase Price:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.purchasePrice)}
					</div>
				</div>
			</div>
		</>
	);
};

const PvpAndCostBreakdown: React.FC<{ breakdown: any; }> = ({ breakdown }) => {
	return (
		<>
			<div className="table-modal-cont-box table-modal-cont-text ft3 pb5 pt5 bold green-bg">
				<div className="width100">PVP:</div>
				<div className="table-modal-input-box">
					{formatMoney(breakdown?.pvpFinal)}
				</div>
			</div>
			<div className="ft2 mt4">
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Product VAT ({formatPercent(breakdown?.productVAT)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.productVATValue)}
					</div>
				</div>
			</div>
			<div className="table-modal-border"></div>
			<div className="ft2">
				<div className="table-modal-cont-box table-modal-cont-text bold">
					<div className="width100">PVP Base:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.pvpBase)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Zeoos Rate ({formatPercent(breakdown?.zeoosRate)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.zeoosValue)}
					</div>
				</div>
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">
						Platform Rate ({formatPercent(breakdown?.platformRate)}):
					</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.platformValue)}
					</div>
				</div>
			</div>
			<div className="table-modal-border"></div>
			<div className="ft2">
				<div className="table-modal-cont-box table-modal-cont-text bold">
					<div className="width100">Purchase Price:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.purchasePrice)}
					</div>
				</div>
				{!breakdown?.freight &&
					<div className="table-modal-cont-box table-modal-cont-text">
						<div className="width100">Transport:</div>
						<div className="table-modal-input-box">
							{formatMoney(breakdown?.transport)}
						</div>
					</div>
				}
				<div className="table-modal-cont-box table-modal-cont-text">
					<div className="width100">Cost:</div>
					<div className="table-modal-input-box">
						{formatMoney(breakdown?.cost)}
					</div>
				</div>
			</div>
		</>
	);
};
