import React, { useState } from "react";
import {
	getBillingsCSV,
	getPaginatedShipments,
} from "../../../actions/billings";
import Preloader from "../../ui/preloader/Preloader";
import "./billing.css";
import "./BillingTable";
import { BillingTable } from "./BillingTable";
import { SingleBilling } from "./single-billing/SingleBilling";
import { useQuery } from "react-query";
import useActiveVendors from "../../../hooks/useActiveVendors";
import FilterSelect from "../../ui/select/FilterSelect";
import { useDebouncedValue } from "@mantine/hooks";
import { IPaginatedRequest } from "../../../interfaces";
import queryString from "query-string";
import * as _ from "lodash";
import { Performance } from "./Performance";
import { Quantidade } from "./Quantidade";
import { toast } from "react-toastify";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../users-management/UsersManagement";
import TreasuryTable from "./treasury/TreasuryTable";
import { atom } from "jotai";

export enum BillingStatus {
	ALL_STATUSES = "All statuses",
	OPEN = "Aberto",
	CLOSED = "Fechado",
}

export const allBillingsAtom = atom(0);

export default function Billing() {
	const [billingsWindow, setBillingsWindow] = useState<boolean>(false);
	const [billingsId, setBillingsId] = useState<any>([]);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
		status: BillingStatus.ALL_STATUSES,
		vendorName: "All vendors",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const { isLoading: loadShipments, data: shipments } = useQuery(
		// `/billings/paginatedShipments?${queryString.stringify(debouncedConfig)}`,
		`/payouts/shipments?${queryString.stringify(debouncedConfig)}`,
		() => getPaginatedShipments(debouncedConfig),
		{ refetchOnWindowFocus: false }
	);

	const { isLoading, data } = useActiveVendors();

	if (loadShipments || isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<>
					{billingsWindow ? (
						<SingleBilling
							setBillingsWindow={setBillingsWindow}
							billingsId={billingsId}
						/>
					) : (
						<>
							<div className="table-main-cont">
								{/* <div className="in-row justify-between align-center mt6">
									<div className="billing-modal-text bold">
										Fulfilment Billings
									</div>
									<div className="fw1 ft2 blue-color">View Drop Shipping</div>
								</div> */}
								{/* <div className="billing-graph-cont">
									<Performance data={shipments?.data} />
									<Quantidade data={shipments?.data} />
								</div> */}
								<div className="sb-filter-box">
									<div className="sb-filter-cont">
										<div className="sb-filter">
											<div className="in-row align-center">
												<div className="filter-text">Filter by:</div>
												<FilterSelect
													onChangeHandler={(
														e: React.ChangeEvent<HTMLInputElement>
													) =>
														setCurrentConfig((conf: IPaginatedRequest) => ({
															...conf,
															status: e.target.value,
														}))
													}
													value={currentConfig?.status}
													options={Object.keys(BillingStatus).map((x) => ({
														label: _.capitalize(x.replace("_", " ")),
														value: x,
													}))}
												/>
												<RoleRequired
													roles={[
														Role.admin,
														Role.sellerAdmin,
														Role.sellerUser,
													]}
												>
													<FilterSelect
														onChangeHandler={(
															e: React.ChangeEvent<HTMLInputElement>
														) =>
															setCurrentConfig((conf: IPaginatedRequest) => ({
																...conf,
																vendorName:
																	data.find(
																		(x: any) => x.name === e.target.value
																	)?.id || "All vendors",
															}))
														}
														value={
															data.find(
																(x: any) => x.id === currentConfig.vendorName
															)?.name
														}
														data={[
															"All vendors",
															..._.uniq(_.map(data, (item: any) => item.name)),
														]}
													/>
												</RoleRequired>
											</div>
											{shipments.data.length ? (
												<div
													className="export-btn pointer"
													onClick={() => {
														getBillingsCSV().catch(console.error);
														toast.success(
															"You will receive an email with the file"
														);
													}}
												>
													Export
												</div>
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
								{/* <BillingTable
									shipments={shipments}
									setBillingsWindow={setBillingsWindow}
									setBillingsId={setBillingsId}
									setCurrentConfig={setCurrentConfig}
									currentConfig={currentConfig}
								/> */}
								<TreasuryTable />
							</div>
						</>
					)}
				</>
			</div>
		</div>
	);
}
