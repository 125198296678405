import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import { useQuery } from "react-query";
import { AvailableProduct, createRobot, getAvailableProducts } from "../../../../actions/intelligence";
import { getImageURL } from "../../../../actions/pim";
import { TooltipText } from "../../tables/gestao/Tooltip";
import { RobotToCreate } from "types/intelligence";
import { toast } from "react-toastify";
import { queryClient } from "../../../App";
import { useAtom } from "jotai";
import { searchAtom } from "./IntelligenceSettings";


const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        minHeight: "auto",
        boxShadow: "none",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "16px",
        marginRight: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.85)',
        background: "#F2F2F2",
        border: "2px solid #E1E1E",
        borderRadius: "6px",
        padding: "10px 17px",
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            // color: '#40a9ff',
            // opacity: 1,
        },
        '&.Mui-selected': {
            color: '#F2F2F2',
            background: "#006DD2",
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    }),
);
interface StyledTabProps {
    label: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type Props = {
    zeoosName: string;
    sellerId: number | string;
    processId?: number | string;
};

const TABS = [
    {
        label: 'Initial Setup',
        value: 0,

        state: {
            onlyBuybox: false,
            setup: true
        }
    },
    {
        label: 'Regular Process',
        value: 1,

        state: {
            onlyBuybox: false,
            setup: false
        }
    },
    {
        label: 'Only Buybox',
        value: 2,

        state: {
            onlyBuybox: true,
            setup: false
        }
    },
];

const IntelligenceSettingsActions: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false);
    const [productsSetupModal, setProductsSetupModal] = useState<boolean>(false);
    const [products, setProducts] = useState<AvailableProduct[]>([]);
    const [search] = useAtom(searchAtom);

    const [robot, setRobot] = useState<RobotToCreate>({
        zeoosName: props.zeoosName,
        sellerId: +props.sellerId,
        setup: true,
        onlyBuybox: false,
        zeoosRate: 0,
        margin: 0,
        markupDiscount: 0,
    });

    const handleRobotEdit = useCallback(<T extends keyof RobotToCreate,>(property: T, value: RobotToCreate[T]) => {
        setRobot(prev => ({
            ...prev,
            [property]: value
        }));
    }, []);

    const onModalClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setModal(false);
    };

    const [value, setValue] = useState(0);

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setRobot(prev => ({ ...prev, ...TABS[newValue].state }));
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            await createRobot(robot, products.map(p => ({
                sku: p.sku,
                barcode: p.barcode,
                name: p.name,
                link: p.link,
                price: p.priceStandard,
                odooPrice: p.price,
                stock: p.stock,
                enabled: p.enabled,
            })));

            toast.success('Process created!');
            setModal(false);

            queryClient.invalidateQueries(`/intelligence/overview?${search}`);
        } catch (error) {
            console.error(error);
            toast.error('Oops... Something went wrong');
        }
    }, [robot, products, search]);

    return (
        <>
            <button
                onClick={() => {
                    setModal(true);
                    setProductsSetupModal(false);
                }}
                style={{ zIndex: "100" }}
                className="int-set-actions-modal"
            >
                Initial Setup
            </button>
            {modal && (
                <Modal onModalClose={onModalClose} isOpened={modal}>
                    <>
                        <div style={{ maxWidth: "480px", width: "90vh", textAlign: "left" }} className="table-modal-form-cont p4">
                            <div className="table-modal-title-box width100 m0">
                                <div className="mapping-modal-title-text">
                                    New Round
                                </div>
                            </div>
                            <div className="table-modal-border"></div>
                            <div className="mb4 in-row align-center">
                                <div style={{ width: "80px", height: "60px" }} className="int-set-match-logo">
                                    <img width="80px" height="60px" className="pb1" src="/icons/int-worten.svg" alt="" />
                                </div>
                                <div className="ml2 int-text in-column align-start justify-start">
                                    <b style={{ fontSize: "16px" }}>Worten Equipamentos Para O Lar SA</b>
                                    <br />
                                    <span>Products on CDiscount FR</span>
                                </div>
                            </div>
                            <div className="table-modal-border"></div>
                            <span className="int-text mb2 mt2">Select the robot round type:</span>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                {TABS.map(t => (
                                    <AntTab key={t.value} label={t.label} {...a11yProps(t.value)} />
                                ))}
                            </Tabs>
                            <div className="in-row align-center justify-start pt2 pb4">
                                <div className="in-column align-start justify-start">
                                    <span className="int-text mb2 mt4">Default reducer:</span>
                                    <div className="in-row align-center justify-start">
                                        <input className="int-input" placeholder="6.0" value={robot.markupDiscount} onChange={e => handleRobotEdit('markupDiscount', +e.target.value)} />
                                        <select
                                            id="compa-channels"
                                            name="compa-channels"
                                            style={{ maxWidth: "200px" }}
                                            className="int-product-select"
                                        >
                                            <option className="select-option" value="">
                                                centimus
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="in-column align-start justify-start ml2 mr2">
                                    <span className="int-text mb2 mt4">Min. margin:</span>
                                    <div className="in-row align-center justify-start">
                                        <input className="int-input" placeholder="0.4" value={robot.margin} onChange={e => handleRobotEdit('margin', +e.target.value)} />
                                        <span className="int-subtext">%</span>
                                    </div>
                                </div>
                                <div className="in-column align-start justify-start">
                                    <span className="int-text mb2 mt4">Min. Zeoos rate:</span>
                                    <div className="in-row align-center justify-start">
                                        <input className="int-input" placeholder="0.07" value={robot.zeoosRate} onChange={e => handleRobotEdit('zeoosRate', +e.target.value)} />
                                        <span className="int-subtext">%</span>
                                    </div>
                                </div>
                            </div>
                            <div className="in-row align-center justify-start pb4">
                                <select
                                    id="compa-channels"
                                    name="compa-channels"
                                    style={{ maxWidth: "200px" }}
                                    className="int-product-select"
                                >
                                    <option className="select-option" value="">
                                        All buybox products
                                    </option>
                                    <option className="select-option" value="First">
                                        Custom collection
                                    </option>
                                </select>
                                <div
                                    onClick={() => {
                                        setProductsSetupModal(true);
                                        setModal(false);
                                    }}
                                    className="int-nav-link pointer"
                                >
                                    Select products
                                </div>
                            </div>

                            <div className="table-modal-border mt4" />

                            <div
                                className="table-modal-form-button-box width100 justify-between"
                                style={{ marginBottom: 0, justifyContent: "center" }}
                            >
                                <button onClick={onModalClose} className="int-set-modal-cancel">
                                    Cancel
                                </button>

                                <button
                                    className="int-set-modal-done"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </>
                </Modal>
            )}

            {productsSetupModal && (
                <SetupProducts
                    {...props}
                    open={productsSetupModal}
                    setOpen={setProductsSetupModal}
                    onSave={products => {
                        setProducts(products);

                        setProductsSetupModal(false);
                        setModal(true);
                    }}
                />
            )}
        </>
    );
};

const SetupProducts: React.FC<{
    open: boolean,
    setOpen: (state: boolean) => void,
    onSave: (data: AvailableProduct[]) => void,
} & Props> = ({
    open,
    setOpen,
    onSave,
    zeoosName,
    sellerId,
    processId,
}) => {
        // const [search, setSearch] = useState<any>("");
        const _products = useQuery(`/intelligence/available-products?${zeoosName}&${sellerId}`, () => getAvailableProducts({ zeoosName, sellerId, processId }), {
            refetchOnWindowFocus: false
        });
        const [products, setProducts] = useState<AvailableProduct[]>([]);

        useEffect(() => {
            setProducts(_products.data || []);
        }, [_products.data]);

        const enabledProductsCount = useMemo(() => {
            return products.filter(p => p.enabled).length;
        }, [products]);

        return (
            <Modal
                onModalClose={(e: any) => {
                    e.stopPropagation();
                    setOpen(false);
                }}
                isOpened={open}
            >
                <>
                    <div style={{ minWidth: "860px", height: "80vh", textAlign: "left" }} className="table-modal-form-cont p4">
                        <div className="table-modal-title-box width100 m0">
                            <div className="mapping-modal-title-text">
                                {enabledProductsCount} out of {products.length} selected products
                            </div>
                            <div className="tax-title-button pointer">
                                <div className="pim-avaliacoes-title-button-text p0" onClick={() => onSave(products)}>Save</div>
                            </div>
                        </div>
                        <div className="table-modal-border"></div>
                        <div className="table-main-title-cont">
                            <div className="table-main-title-search-value-cont">
                                <div className="search-box m0">
                                    {/* <div className="search_form">
                                        <div className="table-search-box">
                                            <form
                                                className="table-search-form"
                                                onSubmit={(e) => e.preventDefault()}
                                            >
                                                <input
                                                    className="table-search-input"
                                                    placeholder="Search seller"
                                                // onChange={(e) => setSearch(e.target.value)}
                                                />
                                                <img
                                                    className="table-search-image"
                                                    src="/icons/search.svg"
                                                    width="25px;"
                                                    alt=""
                                                />
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="table-value">
                                        <span className="table-main-title-text">
                                            {products.length} products
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="marketplace-pagination">
                                {/* Paginator */}
                            </div>
                        </div>
                        <div style={{ height: "max-content" }} className="table-overflow">
                            <table
                                style={{
                                    position: "sticky",
                                    zIndex: 101,
                                    top: "2px",
                                    width: "100%",
                                }}
                            >
                                <thead>
                                    <tr
                                        style={{ marginTop: "-5px", width: "100%" }}
                                        className="table-results-title"
                                    >
                                        <th className="int-set-list-active-prod-modal-switch"></th>
                                        <th className="int-set-list-active-prod-modal-image"></th>
                                        <th className="int-set-list-active-prod-modal-sku">SKU <br /> EAN</th>
                                        <th className="int-set-list-active-prod-modal-title">Title</th>
                                        <th className="int-set-list-active-prod-modal-id">Product URL</th>
                                    </tr>
                                </thead>
                            </table>
                            <table className="table-results">
                                <tbody>
                                    {products?.map(item => (
                                        <ProductItem
                                            {...item}
                                            key={item.sku}
                                            onLinkChange={(key, value) => {
                                                setProducts(prev => prev.map(p => {
                                                    if (p.sku === item.sku) {
                                                        p[key] = value;
                                                    }

                                                    return p;
                                                }));
                                            }}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            </Modal>
        );
    };

const ProductItem: React.FC<AvailableProduct & {
    onLinkChange: <T extends keyof AvailableProduct, >(property: T, value: AvailableProduct[T]) => void;
}> = ({ sku, barcode, name, onLinkChange, ...product }) => {

    const handleChangeLink = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onLinkChange('link', e.target.value);
    }, [onLinkChange]);

    const handleChangeEnabled = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onLinkChange('enabled', e.target.checked);
    }, [onLinkChange]);

    return (
        <tr className="table-result-box int-text">
            <td className="int-set-list-active-prod-modal-switch">
                <AntSwitch checked={product.enabled} onChange={handleChangeEnabled} />
            </td>
            <td className="int-set-list-active-prod-modal-image">
                <img width="60px" src={getImageURL(sku)} alt="" />
            </td>
            <td className="int-set-list-active-prod-modal-sku text-aligns">
                {sku} <br /> {barcode}
            </td>
            <td className="int-set-list-active-prod-modal-title text-aligns">
                <TooltipText text={name} items={7} separator=" " />
            </td>
            <td className="int-set-list-active-prod-modal-id text-aligns">
                <input value={product.link} onChange={handleChangeLink} placeholder='Product URL...' />
            </td>
        </tr>
    );
};

export default IntelligenceSettingsActions;
