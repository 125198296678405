import React, { useEffect, useState } from "react";
import "./pim-management.css";
import { Modal } from "../../../ui/modal/Modal";
import { postScheme, setScheme } from "../../../../actions/scheme";
import { TreeList } from "./Tree";
import { CreateTabForm, TabBlock } from "./TabBlock";
import { FieldsetBlock } from "./FieldsetBlock";
import { ItemBlock } from "./ItemBlock";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { addOpenSchemeTab } from "../../../../actions/pim";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import Preloader from "../../../ui/preloader/Preloader";

type TParams = { name: string };

const CreateScheme: React.FC<RouteComponentProps<TParams>> = (props) => {
  const name = props.match.params.name as string;
  const history = useHistory();

  const [createdScheme, setCreatedScheme] = useState<any>({});
  const [modalScheme, setModalScheme] = useState<boolean>(false);

  useEffect(() => {
    addOpenSchemeTab(name);
  }, [name]);

  // const onDeleteScheme = async () => {
  //   const result = confirm("Are you sure?");
  //   if (result) {
  //     await deleteSchemeById(createdScheme.id);
  //     history.push("/pim-management");
  //   }
  // }

  const backToList = async () => {
    history.push("/products");
  };

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalScheme(false);
  };

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="pim-scheme-container">
          <div className="pim-scheme-title">
            <h1>Pim scheme</h1>
          </div>
          <div className="pim-scheme-header">
            <div>
              {!createdScheme.name ? (
                <button
                  className="pim-create-button"
                  onClick={() => {
                    setModalScheme(true);
                  }}
                >
                  New scheme
                </button>
              ) : (
                <button className="pim-create-button" onClick={backToList}>
                  Back
                </button>
              )}
            </div>
            {Object.keys(createdScheme).length > 0 && (
              <div>{createdScheme.name}</div>
            )}
            {/* <div className="pim-scheme-name-cont">
             <button
                  className="pim-delete-button"
                  onClick={onDeleteScheme}>
                  Delete scheme
                </button>
                </div> */}
          </div>
          <div className="pim-scheme-line"></div>
          {Object.keys(createdScheme).length > 0 && (
            <CreateSection data={createdScheme} />
          )}
          {modalScheme && (
            <CreateSchemeForm
              onModalClose={onModalClose}
              modal={modalScheme}
              setModal={setModalScheme}
              setCreatedScheme={setCreatedScheme}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const CreateSection: React.FC<{
  data: any;
  setSchemeById?: React.Dispatch<any>;
}> = ({ data, setSchemeById }) => {
  const [modalTab, setModalTab] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<any>({});

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalTab(false);
  };

  const deleteItem = (e: any, node: any) => {
    e.preventDefault();
    if (!window.confirm("Are you sure?")) {
      return;
    }

    const tabsArr = data.details.tabs;
    if (node.type === "tab") {
      tabsArr.forEach((tab: any, index: number) => {
        if (tab.label === node.label) {
          tabsArr.splice(index, 1);
        }
      });
    }
    if (node.type === "fieldset") {
      tabsArr.forEach((tab: any) => {
        tab.fields.forEach((field: any, index: number) => {
          if (field.label === node.label) {
            tab.fields.splice(index, 1);
          }
        });
      });
    }
    if (node.type !== "fieldset" && node.type !== "tab") {
      tabsArr.forEach((tab: any) => {
        tab.fields.forEach((field: any) => {
          field.fields.forEach((item: any, index: number) => {
            if (item.label === node.label) {
              field.fields.splice(index, 1);
            }
          });
        });
      });
    }
    setSelectedNode({});
    setScheme(data).catch((error) => {
      console.error(error);
      toast.error("Something went wrong... Reload the page");
    });

    if (setSchemeById) {
      setSchemeById({ ...data, version: data.version + 1 });
    }
    toast.success(`The ${node.label} has been deleted!`);
  };

  if (!data?.details?.tabs) {
    return <Preloader />;
  }

  return (
    <>
      <div className="pim-scheme-content">
        <div className="pim-scheme-item-content">
          <div className="pim-scheme-item-title">
            <div className="pim-scheme-item-create-buttons">
              <div className="primary-color mr2 bold">Tabs and fields</div>
              <button
                className="pim-create-button"
                onClick={() => {
                  setModalTab(true);
                }}
              >
                New tab
              </button>
            </div>
            <TreeList
              data={data.details.tabs}
              version={data}
              setSelectedNode={setSelectedNode}
              setUpdatedData={setSchemeById}
            />
          </div>
          {Object.keys(selectedNode).length > 0 && (
            <>
              <div className="pim-scheme-item-block">
                <div className="width100">
                  <div>
                    <div className="pim-scheme-tree-node-img">
                      <div className="pim-scheme-item-header">
                        <div className="item-blocks-title bold primary-color">
                          {selectedNode.label}
                        </div>
                      </div>
                      <img
                        onClick={(e) => deleteItem(e, selectedNode)}
                        className="pim-image"
                        src="/icons/trash-bin.svg"
                        alt=""
                        width="20px;"
                      />
                      <div className="ml1 mr1">delete</div>
                    </div>
                  </div>
                  <div className="pim-scheme-line"></div>
                  {selectedNode.type === "tab" && (
                    <TabBlock
                      selectedNode={selectedNode}
                      data={data}
                      setUpdatedData={setSchemeById}
                      setSelectedNode={setSelectedNode}
                    />
                  )}
                  {selectedNode.type === "fieldset" && (
                    <FieldsetBlock
                      selectedNode={selectedNode}
                      data={data}
                      setUpdatedData={setSchemeById}
                      setSelectedNode={setSelectedNode}
                    />
                  )}
                  {selectedNode.type !== "tab" &&
                    selectedNode.type !== "fieldset" && (
                      <ItemBlock
                        selectedNode={selectedNode}
                        data={data}
                        setSelectedNode={setSelectedNode}
                        setUpdatedData={setSchemeById}
                      />
                    )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {modalTab && (
        <CreateTabForm
          onModalClose={onModalClose}
          modal={modalTab}
          setModal={setModalTab}
          data={data}
          setUpdatedData={setSelectedNode}
          setSelectedNode={setSelectedNode}
        />
      )}
    </>
  );
};

interface CreateSchemeProps {
  onModalClose: (e: React.MouseEvent) => void;
  modal: boolean;
  setModal: (modal: boolean) => void;
  setCreatedScheme?: (createdScheme: any) => void;
}

export const CreateSchemeForm: React.FC<CreateSchemeProps> = ({
  onModalClose,
  modal,
  setModal,
  setCreatedScheme,
}) => {
  const history = useHistory();

  const [schemeLabel, setSchemeLabel] = useState<string>("");

  const createMutation = useMutation(() => postScheme(schemeLabel), {
    onSuccess: (res) => {
      queryClient.invalidateQueries("schemes");
      addOpenSchemeTab(res.name);
      setCreatedScheme && setCreatedScheme(res);
      setModal(false);
      history.push(`/pim/scheme/${res.name}`);
    },
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await createMutation.mutateAsync();
  };

  return (
    <Modal onModalClose={onModalClose} isOpened={modal}>
      <form className="pim-modal-form-container" onSubmit={handleSubmit}>
        <div className="pim-modal-form-content">
          <div className="pim-modal-form-section">
            <span>Please, type a scheme label you want to create</span>
          </div>
          <div className="pim-modal-form-section">
            <label className="pim-modal-form-title">Scheme label:</label>
            <input
              type="text"
              className="pim-modal-input"
              value={schemeLabel}
              onChange={(e) => setSchemeLabel(e.target.value)}
            />
          </div>
          <div className="pim-modal-form-button-box width100">
            <button className="pim-modal-form-button-cancel">Cancel</button>
            <input
              type="submit"
              value={"Create"}
              className="pim-modal-form-button"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default withRouter(CreateScheme);
