import "../vendors.css";
import React from "react";
import { useQuery } from "react-query";
import {
	getPaginatedInventoryProducts,
	getSanitizedMarketplaces,
} from "../../../../actions/marketplace";
import { IPaginatedRequest, IVendor } from "../../../../interfaces";
import queryString from "query-string";
import { TableGestao } from "../../tables/gestao/TableGestao";
import { atom } from "jotai";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";

interface Props {
	vendors: IVendor[];
	vendor: IVendor;
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
}

export const selectedProductsAtom = atom([]);

const SellerProductInventory: React.FC<Props> = ({
	vendor,
	vendors,
	setCurrentConfig,
	currentConfig,
}) => {
	// const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
	const config = { ...currentConfig, vendorName: vendor?.id?.toString() };

	const { isLoading: loadMarketplaces, data: marketplaces } = useQuery(
		"sanitizedMarkeplaces",
		() => getSanitizedMarketplaces()
	);

	const {
		isLoading: loadProducts,
		data: products,
		refetch: refetchProducts,
	} = useQuery(`inventoryProducts?${queryString.stringify(config)}`, () =>
		getPaginatedInventoryProducts(config)
	);

	return loadMarketplaces ? (
		<CircularPreloader />
	) : (
		<TableGestao
			marketplaces={marketplaces}
			vendors={vendors}
			products={products!}
			setCurrentConfig={setCurrentConfig}
			currentConfig={currentConfig}
			refetchProducts={refetchProducts}
			loadProducts={loadProducts}
		/>
	);
};

export default SellerProductInventory;
