import axios from "axios";
import { ICategoryField } from "types";
import { getHeaders } from "./user";
import { stringify } from "query-string";
import { IPaginatedRequest } from "../interfaces";

export async function find(search: { marketplaceName?: string; id?: string }) {
	const query = stringify(search);
	const res = await axios.get(`/category-fields?${query}`, getHeaders());
	return res.data as ICategoryField[];
}

export async function create(item: Partial<ICategoryField>) {
	await axios.post("/category-field", item, getHeaders());
}

export async function update(id: string, item: Partial<ICategoryField>) {
	await axios.put(`/category-field/${id}`, item, getHeaders());
}

export async function remove(id: string) {
	await axios.delete(`/category-field/${id}`, getHeaders());
}

export async function getPaginatedCategories(pagination: IPaginatedRequest) {
	const res = await axios.get(
		`/paginated-category-fields?${stringify(pagination)}`,
		getHeaders()
	);
	return res.data;
}
