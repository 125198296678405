import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { uploadOboardingProducts } from "../../../../actions/vendor";
import { Modal } from "../../../ui/modal/Modal";
import Preloader from "../../../ui/preloader/Preloader";
import "../../pim/mass/mass-import.css";
import * as XLSX from "xlsx";
import { uploadPriceAndStockSpreadsheet } from "../../../../actions/onboardingSeller";

interface Props {
	onSuccess: () => void;
	onError: () => void;
	modal: boolean;
	setModal: (modal: boolean) => void;
	seller: number;
	type: "products" | "pricestock";
	setFileName: (fileName: React.SetStateAction<string>) => void;
	setProductsFromExcel: (
		productsFromExcel: React.SetStateAction<any[]>
	) => void;
}

const label = {
	products: "Products",
	pricestock: "Price/Stock",
};

const UploadProductsModal: React.FC<Props> = ({
	onSuccess,
	onError,
	modal,
	setModal,
	seller,
	type,
	setFileName,
	setProductsFromExcel,
}) => {
	const [loading, setLoading] = useState(false);
	const [report, setReport] = useState<any>(null);

	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({
			accept: ["application/xls", ".xlsx"],
			onDropRejected: () => {
				toast.error('File type rejected. Only ".xlsx" files are allowed.');
			},
		});

	useEffect(() => {
		async function initializeState() { }
		initializeState();
		// eslint-disable-next-line
	}, []);

	const importHandler = async (e: React.MouseEvent) => {
		e.preventDefault();
		setLoading(true);

		try {
			if (type === 'products') {
				const res = await uploadOboardingProducts(
					acceptedFiles[0],
					seller,
					type
				);

				setReport(res.report);

				const file = acceptedFiles[0];
				setFileName(file.name);

				const data = await file.arrayBuffer();
				const workbook = XLSX.read(data);
				const worksheet = workbook.Sheets[workbook.SheetNames[0]];
				const jsonData = XLSX.utils.sheet_to_json(worksheet);

				setProductsFromExcel(jsonData);
				onSuccess();
			} else {
				const res = await uploadPriceAndStockSpreadsheet(acceptedFiles[0]);
				setReport(res.report);

				if (!res.report.messages.length) {
					onSuccess();
				}
			}

		} catch (error) {
			console.error(error);
			onError();
		} finally {
			setLoading(false);
		}
	};

	const dropzone = (
		<form>
			<h2 className="import-title">{label[type]} upload</h2>
			<div
				className="width100 import-reviews-dropzone"
				style={{ width: "400px", height: "200px" }}
				{...getRootProps()}
			>
				{/* @ts-ignore */}
				<input {...getInputProps()} />
				{isDragActive ? (
					<h4>Drop the files here or click me to select ...</h4>
				) : (
					<h2 style={{ color: "#000" }}>
						{acceptedFiles.length > 0
							? `Click 'Import' to finish upload ${acceptedFiles[0].name}`
							: "Drop the file here"}
					</h2>
				)}
			</div>
			{acceptedFiles.length > 0 && (
				<div className="width100 justify-end mt2">
					<button
						className="singlemarketplace-addproducts-button"
						onClick={importHandler}
					>
						Import
					</button>
				</div>
			)}
		</form>
	);

	return (
		<div className="mass-import-box">
			{modal && (
				<Modal isOpened={modal} onModalClose={() => setModal(false)}>
					<div className="width100 height100">
						{loading ? (
							<Preloader />
						) : !report ? (
							dropzone
						) : (
							<div className="in-column align-start justify-start ft3 import-result">
								<span className="mb4">Uploaded: {report.uploaded}</span>
								<span className="mb4">Succeded: {report.succeded}</span>
								<span className="mb4">Rejected: {report.rejected}</span>
								Messages:
								<span className="mb4 ft1 import-error">
									{" "}
									{report.messages.map((msg: string) => (
										<>
											{msg}
											<br />
										</>
									))}
								</span>
								<button
									className="singlemarketplace-addproducts-button"
									onClick={() => setReport(null)}
								>
									Try again
								</button>
							</div>
						)}
					</div>
				</Modal>
			)}
		</div>
	);
};

export default UploadProductsModal;
