import { useMemo } from "react";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { queryClient } from "../components/App";
import { UserGroupController } from "../actions";
import { UserGroup } from "../actions/userGroup";
import useUser from "./useUser";
import { Role } from "../components/routes/users-management/UsersManagement";

type Config = Omit<
  UseQueryOptions<UserGroup[], unknown, UserGroup[], QueryKey>,
  "queryKey" | "queryFn"
>;

function useUserGroups(config?: Config) {
  const { user } = useUser();
  const key = useMemo(() => `userGroups`, []);
  const data = useQuery<UserGroup[]>(key, UserGroupController.find, {
    enabled: user.role === Role.admin,
    ...config,
  });

  return useMemo(
    () => ({
      ...data,
      invalidate: () => queryClient.invalidateQueries(key),
    }),
    [data, key]
  );
}

export default useUserGroups;
