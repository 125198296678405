import React, { useContext, useEffect, useState } from "react";
import "./tax.css";
import TaxTable from "./TaxTable";
import { Modal } from "../../../ui/modal/Modal";
import { v4 } from "uuid";
import * as TaxMapping from "../../../../actions/taxMapping";
import { toast } from "react-toastify";
import { CountriesContext } from "../../../context";
import { ICountryM } from "../../country-management/CountryManagement";
import MappingsNav from "../../../ui/mappings-nav/MappingsNav";

export default function Tax() {
	const [modal, setModal] = useState<boolean>(false);
	const [taxMappings, setTaxMappings] = useState<ITaxMapping[]>([]);

	useEffect(() => {
		TaxMapping.getTaxMappings().then(setTaxMappings).catch(console.error);
	}, []);

	console.log("taxMappings: ", taxMappings);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont p0">
					<div className="table-value-search-cont">
						<MappingsNav />
						<button
							className="tax-title-button"
							onClick={(e) => {
								e.preventDefault();

								TaxMapping.pushShopifyTaxCollections()
									.then(() => toast.success("Collections pushed!"))
									.catch(() => toast.error("Something went wrong"));
							}}
						>
							Push collections to shopify
						</button>
						<div
							onClick={() => {
								setModal(true);
							}}
							className="tax-title-button pointer"
						>
							<img src="/icons/pim-plus.svg" alt="" />
							<div className="pim-avaliacoes-title-button-text">
								ADD tax mapping
							</div>
						</div>
					</div>
					<div className="mapping-title-border"></div>
					<div className="table-border-bottom"></div>
					<TaxTable taxMappings={taxMappings} setTaxMappings={setTaxMappings} />
				</div>
			</div>
			{modal && (
				<CreateEditTaxMappingForm
					onModalClose={onModalClose}
					modal={modal}
					onAction={async (newTaxMapping: ITaxMapping) => {
						const taxMapping = await TaxMapping.createTaxMapping(
							newTaxMapping.name,
							newTaxMapping.countries
						);
						toast.success("Category created!");
						setTaxMappings((prevState: any) => [...prevState, taxMapping]);
					}}
				/>
			)}
		</div>
	);
}

export interface ITaxMapping {
	_id?: string;
	name: string;
	countries: ITaxMappingCountry[];
	created?: Date;
}

export interface ITaxMappingCountry {
	id: string;
	name: string;
	iec: number;
	vat: number;
}

interface ICreateEditTaxMappingFormProps {
	onModalClose: (e: React.MouseEvent) => void;
	modal: boolean;
	onAction: (taxMapping: ITaxMapping) => Promise<void>;
	taxMapping?: ITaxMapping;
}

export const CreateEditTaxMappingForm: React.FC<
	ICreateEditTaxMappingFormProps
> = ({ onModalClose, modal, taxMapping, onAction }) => {
	const availableCountries = useContext(CountriesContext) as ICountryM[];

	const [name, setName] = useState(taxMapping?.name || "");
	const [countries, setCountries] = useState<ITaxMappingCountry[]>(
		taxMapping?.countries || []
	);

	const handleAddCountry = (e: React.MouseEvent) => {
		e.preventDefault();

		setCountries((prevState) => [
			...prevState,
			{
				id: v4(),
				name: availableCountries[0].name,
				iec: 0,
				vat: 0,
			},
		]);
	};

	const handleUpdateCountry = (e: any, id: string, key: string) => {
		setCountries((prevState) =>
			prevState.map((country: any) => {
				if (country.id === id) {
					country[key] = e.target.value;
				}

				return country;
			})
		);
	};

	const handleRemoveCountry = (e: React.MouseEvent, id: string) => {
		e.preventDefault();

		setCountries((prevState) => prevState.filter((x) => x.id !== id));
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="mapping-modal-cont">
				<div className="table-modal-title-box m0">
					<div className="mapping-modal-title-text">
						Editar categoria de imposto
					</div>
				</div>
				<form
					className="table-modal-form-cont"
					onSubmit={async (e) => {
						e.preventDefault();

						try {
							await onAction({ name, countries });
							setTimeout(() => {
								onModalClose(e as any);
							}, 1000);
						} catch (error) {
							console.error(error);
							toast.error("Something went wrong");
						}
					}}
				>
					<div className="table-modal-border"></div>
					<div className="tax-modal-cat">
						<div className="mapping-modal-text">Nome da categoria:</div>
						<input
							className="tax-modal-input"
							placeholder=""
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<table className="mapping-modal-table">
						<thead>
							<tr className="mapping-modal-results-title">
								<th className="tax-modal-pais">País:</th>
								<th className="tax-modal-iva">IVA:</th>
								<th className="tax-modal-iec">IEC:</th>
								<td className="tax-modal-del"> </td>
							</tr>
						</thead>
						<tbody>
							{countries.map((country) => (
								<tr className="mapping-modal-result-box" key={country.id}>
									<td className="tax-modal-pais">
										<select
											className="tax-modal-select"
											value={country.name}
											onChange={(e) =>
												handleUpdateCountry(e, country.id, "name")
											}
										>
											{availableCountries.map((country, index) => (
												<option key={index} value={country.name}>
													{country.name}
												</option>
											))}
										</select>
									</td>
									<td className="tax-modal-iva">
										<input
											className="mapping-modal-table-input"
											value={country.vat}
											onChange={(e) =>
												handleUpdateCountry(e, country.id, "vat")
											}
										/>
										<div className="mapping-modal-subtext">%</div>
									</td>
									<td className="tax-modal-iec">
										<input
											className="mapping-modal-table-input mapping-modal-widthx2"
											value={country.iec}
											onChange={(e) =>
												handleUpdateCountry(e, country.id, "iec")
											}
										/>
										<div className="mapping-modal-subtext">€</div>
									</td>
									<td className="tax-modal-del">
										<button
											className="tax-modal-table-trash"
											onClick={(e) => handleRemoveCountry(e, country.id)}
										>
											<img
												src="/icons/testemunhos-trash.svg"
												alt=""
												width="30;"
											/>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div
						className="tax-modal-adicionar pointer"
						onClick={handleAddCountry}
					>
						<img src="/icons/gray-plus.svg" alt="" width="20px;" />
						<div>Adicionar novo país</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box width100">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button className="table-modal-form-button" type="submit">
							CONCLUIR
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};
