import React, { memo, useState } from "react";
// import { Paginator } from "../../../ui/pagination/Paginator";
import LogReportModal from "../LogReportModal";
import JobsRun from "./JobsRun";
import JobsActivity from "./JobsActivity";
import { getAsset } from "../../../../utils";
import * as JobController from "../../../../actions/job";
import CreateEditJob from "./CreateEditJob";
interface Props {
  jobs: JobController.IJob[];
}
const JobsPanelTable: React.FC<Props> = ({ jobs }) => {
  const [job, setJob] = useState<null | JobController.IJob>(null);
  // const [limit, setLimit] = useState<number>(10);
  // const [results, setResults] = useState<any>([]);
  return (
    <div className="table-main-cont">
      <div style={{padding: "0 40px"}} className="width100">
      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search marketplace"
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-main-title-text pl2">{jobs.length} Jobs</div>
          </div>
        </div>
        <div className="marketplace-pagination">
          {/* <Paginator
            data=""
            rowsPerPage={limit}
            setPaginatedData={setResults}
            setLimit={setLimit}
            limit={limit}
          /> */}
        </div>
      </div>
      <div style={{ height: "max-content" }} className="table-overflow">
        <table
          style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
        >
          <tr
            style={{ marginTop: "-5px", width: "100%" }}
            className="table-results-title"
          >
            <th className="jobs-run"></th>
            <th className="jobs-title">Job Name</th>
            <th className="jobs-platform">Platform</th>
            <th className="jobs-desc">Job Description</th>
            <th className="jobs-freequency">Frequency</th>
            <th className="jobs-status">Status</th>
            <th className="jobs-action justify-center">Actions</th>
          </tr>
        </table>
        <table className="table-results mb2" style={{ minHeight: "auto" }}>
          <tbody>
            {jobs.map((job) => (
              <tr className="table-result-box height100px" key={job._id}>
                <JobsRun job={job} />
                <td className="jobs-title">{job.name}</td>
                <td className="jobs-platform">
                  <img
                    src={getAsset(
                      `${
                        job.input?.marketplace || job.input?.marketplaceName
                      }.svg`
                    )}
                    alt=""
                    width="60px"
                  />
                </td>
                <td className="jobs-desc pr4">{job.description}</td>
                <td className="jobs-freequency">{job.frequency}</td>
                <JobsActivity job={job} />
                <td className="jobs-action">
                  <div
                    className="pointer underline"
                    onClick={(e) => setJob(job)}
                  >
                    Edit
                  </div>
                  <div className="in-row align-center">
                    {job.latestReport ? (
                      <LogReportModal
                        jobName={job.name}
                        report={job.latestReport}
                      />
                    ) : (
                      <div>No report</div>
                    )}
                    <div
                      className={`jobs-dott jobs-${
                        job.latestStatus !== JobController.jobItemStatus.ERROR
                          ? "green"
                          : "red"
                      } ml2`}
                    ></div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {job && (
        <CreateEditJob
          open={!!job}
          job={job}
          setOpen={(open) => setJob(null)}
        />
      )}
      </div>
    </div>
  );
};
export default memo(JobsPanelTable);
