import React, { useEffect, useState } from "react";
import { Screen } from "../../ui/screen/Screen";
import "./businessIntelligence.css";
import { Cards } from "./Cards";
import Preloader from "../../ui/preloader/Preloader";
import { ICombinedOrders, IVendor } from "../../../interfaces";
import moment from "moment";
import { FilterSection } from "./FilterSection";
import { SelectSection } from "./SelectSection";
import { DateSelect } from "./DateSelect";
import { useOrders } from "../../../hooks/useOrders";
import useActiveVendors from "../../../hooks/useActiveVendors";

const BusinessIntelligence = () => {
	const [section, setSection] = useState<string>("General");
	const [country, setCountry] = useState<string>("All Countries");
	const [warehouse, setWarehouse] = useState<string>("All warehouses");
	const [category, setCategory] = useState<string>("All Categories");
	const [brand, setBrand] = useState<string>("All brands");
	const [seller, setSeller] = useState<string>("All sellers");
	const [marketplaceMarket, setMarketplaceMarket] =
		useState<string>("All Webstores");
	const [marketplaceAccount, setMarketplaceAccount] =
		useState<string>("All Marketplaces");

	const [date, setDate] = useState<string>("This Month");
	const [from, setDateFrom] = useState<Date | number>(
		Date.parse(`${moment().startOf("months")}`)
	);
	const [to, setDateTo] = useState<Date | number>(new Date().valueOf());
	const [filteredOrdersB2C, setflteredOrdersB2C] = useState<ICombinedOrders[]>(
		[]
	);

	const orders = useOrders(from, to);
	const activeVendors = useActiveVendors();

	return (
		<div className="main-body">
			<div className="main-container">
				{!activeVendors.isLoading && !orders.isLoading && !!orders.data ? (
					<>
						<div className="bi-header-cont">
							<div className="bi-title-cont">
								<SelectSection section={section} setSection={setSection} />
								<div className="bi-filters-cont">
									{section !== "S&OP" && (
										<DateSelect
											setDateFrom={setDateFrom}
											setDateTo={setDateTo}
											date={date}
											setDate={setDate}
										/>
									)}
									<FilterSection
										vendors={activeVendors.data}
										from={from}
										to={to}
										section={section}
										setDateFrom={setDateFrom}
										setDateTo={setDateTo}
										setDate={setDate}
										setCountry={setCountry}
										country={country}
										warehouse={warehouse}
										setWarehouse={setWarehouse}
										category={category}
										setCategory={setCategory}
										brand={brand}
										setBrand={setBrand}
										seller={seller}
										setSeller={setSeller}
										setMarketplaceMarket={setMarketplaceMarket}
										marketplaceMarket={marketplaceMarket}
										setMarketplaceAccount={setMarketplaceAccount}
										marketplaceAccount={marketplaceAccount}
										orders={orders.data!}
										setflteredOrders={setflteredOrdersB2C}
									/>
								</div>
							</div>
						</div>
						<Cards
							orders={orders.data!}
							ordersB2C={filteredOrdersB2C}
							section={section}
							country={country}
							warehouse={warehouse}
							category={category}
							brand={brand}
							seller={
								seller !== "All sellers"
									? activeVendors.data.find((x: IVendor) => x.name === seller)
											.id
									: "All sellers"
							}
							marketplaceMarket={marketplaceMarket}
							marketplaceAccount={marketplaceAccount}
						/>
					</>
				) : (
					<Preloader />
				)}
			</div>
		</div>
	);
};

export default function WrapBI() {
	const [timeInterval, setTimeInterval] = useState<number>(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeInterval((timeInterval) => timeInterval + 1);
		}, 900000);
		return () => {
			setTimeout(() => {
				setTimeInterval(0);
			}, 15000);
			clearInterval(interval);
		};
	}, [timeInterval]);

	return timeInterval ? <Screen /> : <BusinessIntelligence />;
}
