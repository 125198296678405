import React from "react";
import { NavLink } from "react-router-dom";
import { Role } from "../../routes/users-management/UsersManagement";
import RoleRequired from "../../utils/role-required/RoleRequired";

export default function IntelligenceNav() {
	return (
		<>
			<div className="table-value-search-cont mt2">
				<div className="table-title-nav-cont">
					<NavLink
						to="/intelligence/overview"
						className="table-tab-sync"
						activeClassName="table-tab-sync-active"
					>
						Overview
					</NavLink>
					<RoleRequired role={Role.admin}>
						<NavLink
							to="/intelligence/products"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							Products
						</NavLink>
						<NavLink
							to="/intelligence/settings"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							Settings
						</NavLink>
					</RoleRequired>
				</div>
			</div>
			<div className="mapping-title-border"></div>
		</>
	);
}
