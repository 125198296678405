import React, { useState, useMemo } from "react";
import "./gestao.css";
import "../tables.css";
import { getPaginatedInventoryProducts } from "../../../../actions/marketplace";
import { TableGestao } from "./TableGestao";
import Preloader from "../../../ui/preloader/Preloader";
import {
  IPaginatedRequest,
  IPaginatedResponse,
  IProductGestao,
} from "../../../../interfaces";
import { GestaoFilter } from "./GestaoFilter";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import useActiveVendors from "../../../../hooks/useActiveVendors";
import queryString from "query-string";
import { Modal } from "../../../ui/modal/Modal";
import ActivateWindow from "./ActivateWindow";
import { atom, useAtom } from "jotai";
import { STOCK_CONDITIONS } from "types";
import BatchDropDown from "../../../ui/batch-dropdown/BatchDropDown";
import InventoryNav from "../../../ui/inv-nav/InventoryNav";
import { exportPriceStockData } from "../../../../actions/pim";
import { toast } from "react-toastify";
import { MassUpdateSellerModal } from "../../pim/mass/MassUpdate";
import useSanitizedMarketplaces from "../../../../hooks/useSanitizedMarketplaces";

// import Box from "@mui/material/Box";
// import ClickAwayListener from "@mui/material/ClickAwayListener";
// import { SxProps } from "@mui/system";

// const styles: SxProps = {
// 	position: "absolute",
// 	top: 50,
// 	right: 40,
// 	left: 0,
// 	zIndex: 102,
// 	border: "1px solid",
// 	p: 1,
// 	bgcolor: "background.paper",
// 	marginLeft: -20,
// 	borderRadius: "5px !important",
// 	boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.5) !important",
// 	padding: "2px",
// };

export type ProductResponse = IPaginatedResponse<
  IProductGestao[],
  {
    all: { sku: string }[];
  }
>;

// Object ids
export const selectedProductsAtom = atom([] as string[]);

export default function Gestao() {
  const [openActive, setOpenActive] = useState<boolean>(false);
  const [batch, setBatch] = useState<boolean>(false);
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
    country: "All Countries",
    vendorName: "All Sellers",
    brand: "All Brands",
    category: "All Categories",
    stockCondition: STOCK_CONDITIONS[0],
    platformName: "All Marketplaces",
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
  const [filteredMarketplaces, setFilteredMarketplaces] = useState([]);
  const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);

  const { isLoading: loadMarketplaces, data: marketplaces } =
    useSanitizedMarketplaces();

  const vendors = useActiveVendors() as any;

  const key = useMemo(() => {
    return `inventoryProducts?${queryString.stringify(debouncedConfig)}`;
  }, [debouncedConfig]);

  const {
    isLoading: loadProducts,
    data: products,
    refetch: refetchProducts,
  } = useQuery(
    `inventoryProducts?${queryString.stringify(debouncedConfig)}`,
    () =>
      getPaginatedInventoryProducts(
        debouncedConfig
      ) as never as ProductResponse,
    { refetchOnWindowFocus: false }
  );

  const [sellerModal, setSellerModal] = useState(false);

  // const [showExportForm, setShowExportForm] = useState(false);

  if (loadMarketplaces || vendors.isLoading) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  return (
    <div className="main-body">
      <div className="main-container">
        <BatchDropDown
          products={products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          batch={batch}
          setBatch={setBatch}
          productsFrom={"inventory"}
          setOpenWindow={setOpenActive}
        />
        <div className="table-title-cont">
          <div className="mplc-filter-cont width100 mt1">
            {/* <InventoryNav /> */}
            <span className="pim-language-text pl4">Offers</span>
            <div className="gestao-select-cont justify-end">
              <div
                onClick={() => setSellerModal(true)}
                className="req-popup-button in-row align-center mr1"
              >
                <div>Import</div>
              </div>

              <MassUpdateSellerModal
                modal={sellerModal}
                setModal={setSellerModal}
                onSuccess={console.log}
                onError={console.error}
              />

              <div
                className="req-popup-button in-row align-center"
                onClick={() => {
                  exportPriceStockData();
                  toast.success(
                    "Success! Check your mailbox, it should be there in seconds."
                  );
                }}
              >
                <div>Export</div>
              </div>
              <GestaoFilter
                marketplaces={marketplaces as any}
                vendors={vendors.data!}
                currentConfig={currentConfig}
                setCurrentConfig={setCurrentConfig}
                setFilteredMarketplaces={setFilteredMarketplaces}
                filterFrom={"inventory"}
              />
            </div>
            {/* <div className="mr2">
							<ClickAwayListener onClickAway={() => setOpen(false)}>
								<Box sx={{ position: "relative" }}>
									<button
										type="button"
										className="mass-import-button"
										onClick={() => setOpen((prev) => !prev)}
									>
										<img src="/icons/inv-settings-line.svg" alt="" />
										More
										<img width="11px" src="/icons/down-arrow.png" alt="" />
									</button>
									{open ? (
										<Box sx={styles}>
											<button className="mass-import-option">
												Mass update price/stock
											</button>
											<button className="mass-import-option">
												Export product price/stock
											</button>
										</Box>
									) : null}
								</Box>
							</ClickAwayListener>
						</div> */}
          </div>
        </div>
        <TableGestao
          marketplaces={
            filteredMarketplaces.length
              ? filteredMarketplaces
              : (marketplaces as any)
          }
          vendors={vendors.data!}
          products={products!}
          setCurrentConfig={setCurrentConfig}
          currentConfig={currentConfig}
          refetchProducts={refetchProducts}
          loadProducts={loadProducts}
        />
        <div className="table-title-cont" />
      </div>
      {openActive && (
        <Modal onModalClose={() => setOpenActive(false)} isOpened={openActive}>
          <ActivateWindow
            setOpenActive={setOpenActive}
            selectedProducts={selectedProducts}
            key={key}
          />
        </Modal>
      )}

      {/* {showExportForm && (
				<ExportAllProductInfo
					modal={showExportForm}
					setModal={setShowExportForm}
					skus={selectedProducts}
				/>
			)} */}
    </div>
  );
}
