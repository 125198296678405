import React, { useCallback, useState } from "react";
import './sellerinfo.css';
import SettingsNav from "../../ui/settings-nav/SettingsNav";
// import { Checkbox } from "@mui/material";
import useUser from "../../../hooks/useUser";
import { IVendor } from "../../../interfaces";
import { AllCountrySelect, InputComponent } from "../vendors-management/SellerCreateEditModal";
import { editOnBoardingSeller } from "../../../actions/onboardingSeller";
import { toast } from "react-toastify";

export default function SellerInfo() {
	const { user } = useUser();
	const [sellerData, setSellerData] = useState<Partial<IVendor>>(user.seller);

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			try {
				await editOnBoardingSeller(sellerData);
				toast.success('Data confirmed!');
			} catch (error) {
				console.error(error);
				toast.error('Something went wrong...');
			}

		}, [sellerData]
	);

	return (
		<>
			<div className="main-body">
				<div className="main-container">
					<div className="table-main-cont p0">
						<SettingsNav />
						<form
							style={{
								height: "max-content",
								paddingBottom: "20px",
								minHeight: "85vh",
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "flex-start",
							}}
							className="in-column align-center justify-start p4"
							onSubmit={onSubmit}
						>
							<div style={{ maxWidth: "100%" }} className="sel-newon-cont">
								<div className="sel-newon-title">Contact information</div>
								<div style={{ justifyContent: "flex-start" }} className="sel-newon-box">
									<div className="sel-newon-content">
										<span>Seller Name: </span>
										<InputComponent
											value={sellerData?.contactInfo?.sellerName}
											setValue={setSellerData}
											inputField={"sellerName"}
											section={"contactInfo"}
										/>
									</div>
									<div className="sel-newon-content">
										<span>Company Name: </span>
										<InputComponent
											value={sellerData?.contactInfo?.companyName}
											setValue={setSellerData}
											inputField={"companyName"}
											section={"contactInfo"}
											addClass="ml1"
										/>
									</div>
									<div className="sel-newon-content">
										<span>VAT number (NIF): </span>
										<InputComponent
											value={sellerData?.contactInfo?.vatNumber}
											setValue={setSellerData}
											inputField={"vatNumber"}
											section={"contactInfo"}
										/>
									</div>
									<div className="sel-newon-content">
										<span>Telephone: </span>
										<InputComponent
											value={sellerData?.contactInfo?.phone}
											setValue={setSellerData}
											inputField={"phone"}
											section={"contactInfo"}
											addClass={"maxwidth150 ml1"}
										/>
									</div>
									<div className="sel-newon-content">
										<span>Main Email (registration): </span>
										<input
											className="sel-newon-input"
											value={sellerData.email}
											onChange={e => setSellerData(d => ({ ...d, email: e.target.value }))}
											required
											type="email"
										/>
									</div>

									<div className="sel-newon-content ml1"></div>
									<div className="sel-newon-content">
										<span>Email for orders: </span>
										<InputComponent
											value={sellerData?.contactInfo?.emailForOrders}
											setValue={setSellerData}
											inputField={"emailForOrders"}
											section={"contactInfo"}
											type="email"
										/>
									</div>

									<div className="sel-newon-content ml1">
										<span>Email for billings: </span>
										<InputComponent
											value={sellerData?.contactInfo?.emailForBillings}
											setValue={setSellerData}
											inputField={"emailForBillings"}
											section={"contactInfo"}
											type="email"
										/>
									</div>
								</div>
							</div>
							<div style={{ maxWidth: "100%" }} className="sel-newon-cont">
								<div className="sel-newon-title">Fiscal address</div>
								<div style={{ justifyContent: "flex-start" }} className="sel-newon-box">
									<div className="sel-newon-content">
										<span>Address: </span>
										<InputComponent
											value={sellerData?.fiscalAddress?.address}
											setValue={setSellerData}
											inputField={"address"}
											section={"fiscalAddress"}
										/>
									</div>
									<div className="sel-newon-content ml1">
										<span>Complement: </span>
										<InputComponent
											value={sellerData?.fiscalAddress?.complement}
											setValue={setSellerData}
											inputField={"complement"}
											section={"fiscalAddress"}
											required={false}
										/>
									</div>
									<div className="sel-newon-content">
										<span>City: </span>
										<InputComponent
											value={sellerData?.fiscalAddress?.city}
											setValue={setSellerData}
											inputField={"city"}
											section={"fiscalAddress"}
										/>
									</div>
									<div className="sel-newon-content ml1">
										<span>Country: </span>
										<AllCountrySelect
											value={sellerData?.fiscalAddress?.country}
											setValue={(newValue) => {
												setSellerData((data: any) => ({
													...data,
													fiscalAddress: {
														...data.fiscalAddress,
														country: newValue,
													},
												}));
											}}
										/>
									</div>
									<div className="sel-newon-content">
										<span>Postal Code: </span>
										<InputComponent
											value={sellerData?.fiscalAddress?.postalCode}
											setValue={setSellerData}
											inputField={"postalCode"}
											section={"fiscalAddress"}
											addClass={"maxwidth150"}
										/>
									</div>
									<div className="sel-newon-content ml1">
										<span>Region: </span>
										<InputComponent
											value={sellerData?.fiscalAddress?.region}
											setValue={setSellerData}
											inputField={"region"}
											section={"fiscalAddress"}
										/>
									</div>
								</div>
							</div>
							<div style={{ maxWidth: "100%" }} className="sel-newon-cont">
								<div className="sel-newon-title">Warehouse address</div>
								<div style={{ justifyContent: "flex-start" }} className="sel-newon-box">
									<>
										<div className="sel-newon-content">
											<span>Address: </span>
											<InputComponent
												value={sellerData?.warehouseAddress?.wAddress}
												setValue={setSellerData}
												inputField={"wAddress"}
												section={"warehouseAddress"}
											/>
										</div>
										<div className="sel-newon-content ml1">
											<span>Complement: </span>
											<InputComponent
												value={sellerData?.warehouseAddress?.wComplement}
												setValue={setSellerData}
												inputField={"wComplement"}
												section={"warehouseAddress"}
												required={false}
											/>
										</div>
										<div className="sel-newon-content">
											<span>City: </span>
											<InputComponent
												value={sellerData?.warehouseAddress?.wCity}
												setValue={setSellerData}
												inputField={"wCity"}
												section={"warehouseAddress"}
											/>
										</div>
										<div className="sel-newon-content ml1">
											<span>Country: </span>
											<AllCountrySelect
												value={sellerData?.warehouseAddress?.wCountry}
												setValue={(newValue) => {
													setSellerData((data: any) => ({
														...data,
														warehouseAddress: {
															...data.fiscalAddress,
															wCountry: newValue,
														},
													}));
												}}
											/>
										</div>
										<div className="sel-newon-content">
											<span>Postal Code: </span>
											<InputComponent
												value={sellerData?.warehouseAddress?.wPostalCode}
												setValue={setSellerData}
												inputField={"wPostalCode"}
												section={"warehouseAddress"}
												addClass={"maxwidth150"}
											/>
										</div>
										<div className="sel-newon-content ml1">
											<span>Region: </span>
											<InputComponent
												value={sellerData?.warehouseAddress?.wRegion}
												setValue={setSellerData}
												inputField={"wRegion"}
												section={"warehouseAddress"}
											/>
										</div>
									</>
									<div className="sel-newon-content">
										<span>Warehouse contact: </span>
										<InputComponent
											value={sellerData?.warehouseAddress?.wContact}
											setValue={setSellerData}
											inputField={"wContact"}
											section={"warehouseAddress"}
										/>
									</div>
									<div className="sel-newon-content ml1">
										<span>Warehouse phone: </span>
										<InputComponent
											value={sellerData?.warehouseAddress?.wPhone}
											setValue={setSellerData}
											inputField={"wPhone"}
											section={"warehouseAddress"}
										/>
									</div>
								</div>
							</div>
							<div style={{ maxWidth: "100%" }} className="sel-newon-cont">
								<div className="sel-newon-title">Bank information</div>
								<div style={{ justifyContent: "flex-start" }} className="sel-newon-box">
									<div className="sel-newon-content">
										<span>Bank name: </span>
										<InputComponent
											value={sellerData?.bankInfo?.bankName}
											setValue={setSellerData}
											inputField={"bankName"}
											section={"bankInfo"}
										/>
									</div>
									<div className="sel-newon-content ml1"></div>
									<div className="sel-newon-content">
										<span>IBAN: </span>
										<InputComponent
											value={sellerData?.bankInfo?.iban}
											setValue={setSellerData}
											inputField={"iban"}
											section={"bankInfo"}
										/>
									</div>
									<div className="sel-newon-content ml1">
										<span>Swift code: </span>
										<InputComponent
											value={sellerData?.bankInfo?.swiftCode}
											setValue={setSellerData}
											inputField={"swiftCode"}
											section={"bankInfo"}
										/>
									</div>
								</div>
							</div>
							<div
								style={{ maxWidth: "100%" }}
								className="width100 in-row align-center justify-between"
							>
								<button className="table-modal-form-button" type="submit">
									SAVE
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}
