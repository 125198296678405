import React, { useCallback, useState } from "react";
import "./acompanhamento.css";
import "../tables.css";
import * as _ from "lodash";
import Backdrop from "@mui/material/Backdrop";
import {
	IMarketplace,
	IVendor,
	IPaginatedRequest
} from "../../../../interfaces";
import { useMemo } from "react";
import { StockCondition, STOCK_CONDITIONS } from "types";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { Role } from "../../users-management/UsersManagement";
import { isEqual } from "lodash";
import { MultiSelect, stringsToOptions } from "../gestao/GestaoFilter";
import DatePicker from "react-datepicker";
import { ExampleCustomInput } from "../../business-intelligence/Datepickers";
import moment from "moment";

interface Props {
	marketplaces: IMarketplace[];
	vendors: IVendor[];
	setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
	currentConfig: IPaginatedRequest;
	setFilteredMarketplaces?: any;
	filterFrom?: string;
}

export enum OrderStatus {
	RESERVED = "Reserved",
	APPROVED = "Approved",
	PURCHASE = "Purchase order",
	CONFIRM_PURCHASE = "Confirmation purchase order",
	PICKUP = "Pickup scheduling",
	INVOICE = "Customer invoice",
	SAVE_INVOICE = "Save customer invoice",
	WAITING_SHIPMENT = "Waiting shipment",
	SHIPPING = "Shipping",
	DELIVERED = "Delivered",
	CANCELED = "Canceled",
	CLOSED = "Closed",
	REFUSED = "Refused",
	RETURNED = "Returned",
	REFUNDED = "Refunded",
	ACTION = "Action required",
	PROCESSING = "Processing",
}

export enum OrderStatusValue {
	RESERVED = "reserved",
	APPROVED = "approved",
	PURCHASE = "purchase_order",
	CONFIRM_PURCHASE = "confirmation_purchase_order",
	PICKUP = "pickup_scheduling",
	INVOICE = "customer_invoice",
	SAVE_INVOICE = "save_customer_invoice",
	WAITING_SHIPMENT = "waiting_shipment",
	SHIPPING = "shipping",
	DELIVERED = "delivered",
	CANCELED = "canceled",
	CLOSED = "closed",
	REFUSED = "refused",
	RETURNED = "returned",
	REFUNDED = "refunded",
	ACTION = "action_required",
	PROCESSING = "processing",
}

export const Selects: React.FC<Props> = ({
	marketplaces,
	vendors,
	currentConfig,
	setCurrentConfig,
	setFilteredMarketplaces,
	filterFrom,
}) => {
	const defaultFromDate = Date.parse(`${moment().startOf("months")}`);
	const defaultToDate = new Date().valueOf();
	const [open, setOpen] = useState(false);
	const [counter, setCounter] = useState(0);
	const [platform, setPlatform] = useState("All marketplaces");
	const [status, setStatus] = useState("All statuses");
	const [webstore, setWebstore] = useState("All webstores");
	const [country, setCountry] = useState(["All countries"]);
	const [vendorName, setVendorName] = useState("All vendors");
	const [stockCondition, setStockCondition] = useState<StockCondition>("ALL");
	const [from, setDateFrom] = useState<Date | number>(defaultFromDate);
	const [to, setDateTo] = useState<Date | number>(defaultToDate);

	const countriesArray = (array: any) => {
		return [
			"All countries",
			..._.uniq(_.map(array, (item: any) => item.country)),
		];
	};

	const statusesArray = () => {
		return [
			"All statuses",
			..._.uniq(Object.values(OrderStatus)).filter((x: any) => x !== "" && x !== false),
		];
	};

	const platformNamesArray = (array: any) => {
		return [
			"All marketplaces",
			..._.uniq(_.map(array, (item: any) => item.zeoosName)),
		];
	};

	const vendorOptions = useMemo(() => [
		{
			label: "All vendors",
			value: "All vendors",
		},
		...vendors.map((x) => ({
			label: x.name,
			value: x.id as any,
		}))
	], [vendors]);

	const clearFilterHandle = (e: React.MouseEvent) => {
		e.preventDefault();
		setCountry(["All countries"]);
		setStatus("All statuses");
		setPlatform("All marketplaces");
		setDateFrom(defaultFromDate);
		setDateTo(defaultToDate);
		setWebstore("All webstores");
		setVendorName("All vendors");
		setStockCondition(STOCK_CONDITIONS[0]);
		setCounter(0);
	};

	const filterCounter = (
		value: string | string[],
		flag: string | string[],
		defaultValue: string | string[]
	) => {
		if (!isEqual(value, defaultValue) && !isEqual(defaultValue, flag)) {
			setCounter(counter);
		} else if (!isEqual(value, defaultValue)) {
			setCounter(counter + 1);
		} else {
			setCounter(counter - 1);
		}
	};

	const applyFilter = (e: React.MouseEvent) => {
		e.preventDefault();

		const _filteredResults =
			platform === "All marketplaces"
				? marketplaces
				: marketplaces.filter((x: any) => x.zeoosName === platform);
		const filteredResults =
			webstore === "All webstores"
				? _filteredResults
				: _filteredResults.filter((x: any) => x.marketplaceName === webstore);

		if (setFilteredMarketplaces) {
			setFilteredMarketplaces(filteredResults);
		}

		setCurrentConfig({
			...currentConfig,
			platform,
			page: 1,
			country,
			status,
			vendorId: vendorName,
			stockCondition,
			from,
			to
		});

		setOpen(false);
	};

	return (
		<>
			<button
				onClick={() => setOpen(!open)}
				className="main-select-bi no-shadow ml2"
			>
				<img src="/icons/bi-filter.svg" alt="" />
				FILTER <span className="orange-text bold">({counter})</span>
			</button>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={open}
				className="relative"
			>
				<div className="filter-bg" onClick={() => setOpen(false)} />
				<div className="filter-cont-position">
					<div className="filter-cont">
						<div className="filter-content justify-start">
							<div className="filter-header pt4">
								<div className="filter-header-title">
									Filter <span className="orange-text">({counter})</span>
								</div>
								<div
									className="filter-header-reset"
									onClick={clearFilterHandle}
								>
									Clear filters
								</div>
							</div>
							<div className="filter-content-cont mt2">
								<MultiSelect
									multiple={false}
									resetFilter={(newValue, oldValue) =>
										filterCounter(newValue, oldValue, "All statuses")
									}
									// @ts-ignore
									value={OrderStatus[Object.entries(OrderStatusValue).find(entry => entry[1] === status)?.[0]] || "All statuses"}
									setValue={((val: any) => {
										// @ts-ignore
										const status = OrderStatusValue[Object.entries(OrderStatus).find(entry => entry[1] === val)?.[0]] || "All statuses";
										status && setStatus(status);
									})}
									options={stringsToOptions(statusesArray())}
								/>
								<MultiSelect
									resetFilter={(newValue, oldValue) =>
										filterCounter(newValue, oldValue, ["All countries"])
									}
									value={country}
									setValue={(value: any) =>
										setCountry(
											value[value.length - 1] === "All countries"
												? ["All countries"]
												: value.filter((x: string) => x !== "All countries")
										)
									}
									options={stringsToOptions(countriesArray(marketplaces))}
								/>

								<MultiSelect
									multiple={false}
									resetFilter={(newValue, oldValue) =>
										filterCounter(newValue, oldValue, "All marketplaces")
									}
									value={platform}
									setValue={setPlatform}
									options={stringsToOptions(platformNamesArray(marketplaces))}
								/>

								{filterFrom === "inventory" && (
									<RoleRequired role={Role.admin}>
										<MultiSelect
											resetFilter={(newValue, oldValue) =>
												filterCounter(newValue, oldValue, "All vendors")
											}
											value={vendorName}
											setValue={setVendorName}
											options={vendorOptions}
											multiple={false}
										/>
									</RoleRequired>
								)}

								<div className="acompan-dates" style={{ width: "100%" }}>
									<div className="acompan-calendar" style={{ width: "100%" }}>
										<DatePicker
											customInput={<ExampleCustomInput />}
											selectsRange={true}
											startDate={from as Date}
											endDate={to as Date}
											onChange={(update: [Date, Date]) => {
												const newFrom = update?.[0];
												const newTo = update?.[1];

												setDateFrom(moment(newFrom).utcOffset("+0000").valueOf());
												filterCounter(newFrom.toString(), from.toString(), Date.parse(`${moment().startOf("months")}`).toString());
												setDateTo(moment(newTo).utcOffset("+0000").endOf("day").valueOf());
												filterCounter(newTo.toString(), to.toString(), new Date().valueOf().toString());
											}}
										/>
										<div className="acompan-calendar-icon">
											<img src="/icons/calendar.svg" alt="calendar" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="filter-border mt4 mb2"></div>
						<div className="filter-buttons width100 m0">
							<button
								className="filter-apply-btn width80"
								onClick={applyFilter}
							>
								APPLY
							</button>
							<button
								className="filter-cancel-btn mb2"
								onClick={() => setOpen(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</Backdrop>
		</>
	);
};
