import React from "react";
import { General } from "./General";
import { B2CMarketplace } from "./B2CMarketplace";
import { B2BMarketplace } from "./B2BMarketplace";
// import { Vendor } from "./Vendor";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../users-management/UsersManagement";
import {
	ICombinedOrders,
	// ICombinedProduct,
	ICombinedOrder,
	ISaleLineIds,
} from "../../../interfaces";
import { FlashSales } from "./FlashSales";

interface Props {
	orders: ICombinedOrders;
	ordersB2C: any;
	// products: ICombinedProduct[];
	section: string;
	country: string;
	warehouse: string;
	category: string;
	brand: string;
	seller: string | number;
	marketplaceMarket: string;
	marketplaceAccount: string;
}

export const Cards: React.FC<Props> = ({
	orders,
	ordersB2C,
	// products,
	section,
	country,
	warehouse,
	category,
	brand,
	seller,
	marketplaceMarket,
	marketplaceAccount,
}) => {
	function addTotalQty(array: ICombinedOrder[]) {
		array.forEach(
			(x: any) =>
				(x.totalQuantity = x.sale_line_ids.reduce(
					(acc: number, cv: ISaleLineIds) => {
						return acc + cv.quantity;
					},
					0
				))
		);
		return array;
	}
	addTotalQty(orders.b2c);
	addTotalQty(orders.b2b);

	function avgOrderCounter(array: ICombinedOrder[]) {
		if (array.length === 0) return 0;
		return (
			array.reduce(
				(prev: number, next: ICombinedOrder) => prev + next.sale_amount,
				0
			) / array.length
		).toLocaleString("de-DE", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		});
	}

	function grossValueCounter(array: ICombinedOrder[]) {
		if (array.length === 0) return 0;
		return array
			.reduce(
				(prev: number, next: ICombinedOrder) => prev + next.sale_amount,
				0
			)
			.toLocaleString("de-DE", {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
	}

	const renderSection = (section: string) => {
		switch (section) {
			case "General":
				return (
					// <RoleRequired roles={[Role.admin, Role.sellerAdmin, Role.sellerUser]}>
					<General
						orders={orders}
						ordersWaiting={
							orders["b2c_wait"].length + orders["b2b_wait"].length
						}
						country={country}
						marketplaceMarket={marketplaceMarket}
						marketplaceAccount={marketplaceAccount}
						category={category}
						seller={seller}
						avgOrderCounter={avgOrderCounter}
						grossValueCounter={grossValueCounter}
					/>
					// </RoleRequired>
				);
			case "B2C":
				return (
					<B2CMarketplace
						orders={ordersB2C}
						ordersWaiting={orders["b2c_wait"].length}
						avgOrderCounter={avgOrderCounter}
						grossValueCounter={grossValueCounter}
						category={category}
					/>
				);
			case "FlashSales":
				return (
					<FlashSales
						orders={orders}
						avgOrderCounter={avgOrderCounter}
						grossValueCounter={grossValueCounter}
						country={country}
						category={category}
					/>
				);
			case "B2B":
				return (
					<RoleRequired roles={[Role.admin, Role.sellerAdmin, Role.sellerUser]}>
						<B2BMarketplace
							orders={orders}
							ordersWaiting={orders["b2b_wait"].length}
							avgOrderCounter={avgOrderCounter}
							grossValueCounter={grossValueCounter}
							countryb2b={country}
							category={category}
						/>
					</RoleRequired>
				);
			// case "S&OP":
			//   return (
			//     <RoleRequired roles={[Role.admin, Role.sellerAdmin, Role.sellerUser]}>
			//       <Operations
			//         products={products}
			//         warehouse={warehouse}
			//         category={category}
			//         brand={brand}
			//       />
			//     </RoleRequired>
			//   );
			default:
				return;
		}
	};

	return (
		<div className="bi__cards">
			{renderSection(section)}
			{/*
			<RoleRequired roles={[Role.admin, Role.sellerAdmin, Role.sellerUser]}>
				<Vendor products={products} />
			</RoleRequired>
			*/}
		</div>
	);
};
