import React, { useCallback, useState } from "react";
import "./uploading.css";
import LogoutWrap from "../../utils/logout-wrap/LogoutWrap";
import Avatar from "@mui/material/Avatar";
import { Modal } from "../../ui/modal/Modal";
import useUser from "../../../hooks/useUser";
import { toast } from "react-toastify";
import { confirmBankInfo, downloadPriceAndStockSpreadsheet } from "../../../actions/onboardingSeller";
import UploadProductsModal from "../vendors-management/onboarding/UploadProductsModal";

export default function Uploading() {
    const { user } = useUser();
    const [modal, setModal] = useState(!user.seller.bankInfoConfirmed);
    const [loading, setLoading] = useState(false);

    const onModalClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setModal(false);
    };

    const [bankInfo, setBankInfo] = useState(user.seller.bankInfo);

    const handleConfirm = useCallback(async () => {
        try {
            await confirmBankInfo(bankInfo);
            toast.success('Banking details confirmed!');

            setModal(false);
        } catch (error) {
            toast.error('Something went wrong...');
        }
    }, [bankInfo]);

    return (
        <>
            <div className="contrato-bg">
                <div className="uploading-header">
                    <div className="uploading-header-cont">
                        <img className="ml4" src="/icons/worten.svg" alt="" />
                        <div className="in-row align-center mr4">
                            <div

                                className="in-row align-center justify-between"
                            >
                                <Avatar
                                    alt={user.username}
                                    className="profile-bg-image user-avatar"
                                    src={
                                        user.profileImage === undefined ? "df" : user.profileImage
                                    }
                                    sx={{ width: 40, height: 40, borderRadius: "50%" }}
                                />
                                <div className="hide-mob">
                                    <div className="profile-box-header pointer">
                                        Hey, {user.username}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <LogoutWrap>
                                    <button className="log-in-out-btn-header">
                                        <div>Logout</div>
                                    </button>
                                </LogoutWrap>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="upload-cont-main upload-text">
                    <span>Hello,</span>
                    <br />
                    <span>Our team is working on your catalogue. In less than 4 working days the platform will be ready for your management. We will notify you by email.</span>
                    <br />
                    <span className="policy-text">While we check the available platforms, you can prepare and send us the stock and price per country sheet for your products.</span>
                    <br />
                    <span
                        style={{ color: "#E51B15", opacity: loading ? 0.7 : 1 }}
                        className="policy-text pointer"
                        onClick={async () => {
                            if (loading) {
                                return;
                            }

                            setLoading(true);

                            try {
                                await downloadPriceAndStockSpreadsheet();
                            } catch (error) {
                                console.error(error);
                            }

                            setLoading(false);
                        }}
                    >
                        Download template worksheet
                    </span>
                    <br />
                    <SellerUploadFile />
                </div>
            </div>
            {modal && (
                <Modal onModalClose={onModalClose} isOpened={modal}>
                    <>
                        <div style={{ width: "480px", zIndex: "10001 !important" }} className="table-modal-form-cont p4">
                            <div className="payment-container p0">
                                <div className="width100 in-row align-center justify-between">
                                    <span className="payment-text">Bank information</span>
                                </div>
                                <div className="payment-border"></div>
                                <div className="in-column align-start width100 justify-center mb2">
                                    <span className="policy-text mb1">Bank name:</span>
                                    <input className="payment-input" value={bankInfo.bankName} onChange={e => setBankInfo((prev: any) => ({ ...prev, bankName: e.target.value }))} />
                                </div>
                                <div className="in-column align-start width100 justify-center mb2">
                                    <span className="policy-text mb1">IBAN:</span>
                                    <input className="payment-input" value={bankInfo.iban} onChange={e => setBankInfo((prev: any) => ({ ...prev, iban: e.target.value }))} />
                                </div>
                                <div className="in-column align-start width50 justify-center mb2">
                                    <span className="policy-text mb1">Swift code:</span>
                                    <input className="payment-input" value={bankInfo.swiftCode} onChange={e => setBankInfo((prev: any) => ({ ...prev, swiftCode: e.target.value }))} />
                                </div>
                                <div className="payment-border"></div>
                                <div className="in-row width100 align-center justify-end">
                                    <div>
                                        <button
                                            className="log-in-login mt4 p2"
                                            onClick={handleConfirm}
                                        >
                                            SAVE
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            )}
        </>
    );
}

export const SellerUploadFile: React.FC = () => {
    const { user } = useUser();
    const [modal, setModal] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(!!user.seller.onBoardingPriceStock);

    return (
        <>
            <div>
                <button
                    className="log-in-login p2"
                    onClick={() => setModal(true)}
                    disabled={!!isFileUploaded}
                >
                    Send stock and price sheet
                </button>
            </div>
            <br />

            {!!isFileUploaded && (
                <div className="in-row align-center">
                    {/* <span className="bold mr1" style={{ color: "#299C00" }}>name-file.xlsx </span> */}
                    <span style={{ color: "#299C00" }}>{user.seller.onBoardingPriceStockFilename ? `${user.seller.onBoardingPriceStockFilename} sent.` : 'Your xlsx file has been sent'}</span>
                </div>
            )}

            {modal && (
                <UploadProductsModal
                    onSuccess={() => {
                        setIsFileUploaded(true);
                        toast.success("Successful import!");

                        // @ts-ignore
                        window.location.reload();
                    }}
                    onError={() => toast.error("Import failed...")}
                    modal={modal}
                    setModal={setModal}
                    seller={user.seller.id}
                    type={"pricestock"}
                    setFileName={() => { }}
                    setProductsFromExcel={() => { }}
                />
            )}
        </>
    );
};
