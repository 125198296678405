import { useMemo } from "react";
import { useQuery } from "react-query";
import { queryClient } from "../components/App";
import { getMarketplaceCategory } from "../actions/marketplace";
import { ReactQueryConfig } from "../interfaces";
import { MarketplaceCategory } from "types";

function useMarketplaceCategory(
	zeoosName: string,
	body: any,
	config?: ReactQueryConfig
) {
	const key = useMemo(
		() => `/marketplace/${zeoosName}/category`,
		[zeoosName, body]
	);
	const data = useQuery<MarketplaceCategory>(
		key,
		async () => await getMarketplaceCategory(zeoosName, body),
		config as any
	);

	return useMemo(
		() => ({
			...data,
			invalidate: () => queryClient.invalidateQueries(key),
		}),
		[data, key]
	);
}

export default useMarketplaceCategory;
