import axios from "axios";
import {
	IMarketplace,
	INewMarketplace,
	IPaginatedRequest,
	IPaginatedResponse,
	IProductGestao,
} from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";
import { executeJob } from "./job";
import { O } from "ts-toolbelt";
import { MarketplaceCategory } from "types";

export const getMarketplacesAdmin = async () => {
	const res = await axios.get(`/getAllMarketplaces/admin`, getHeaders());

	return res.data.data.data;
};

export const getPaginatedMarketplaces = async (
	pagination: IPaginatedRequest
) => {
	const res = await axios.get(
		`/getAllMarketplaces/admin?${queryString.stringify(pagination)}`,
		getHeaders()
	);

	return res.data;
};

export async function getPaginatedUsers(pagination: IPaginatedRequest) {
	const res = await axios.get(
		`/user?${queryString.stringify(pagination)}`,
		getHeaders()
	);
	return res.data;
}

export const getSanitizedMarketplaces = async () => {
	const res = await axios.get("/getMarketplaces", getHeaders());

	return res.data.data.data;
};

export const findMarketplace = async (zeoosName: string) => {
	const res = await axios.get(`/marketplace/${zeoosName}`, getHeaders());

	const { data, success } = res.data;

	if (!success) {
		throw new Error("Something goes wrong");
	}

	return data as IMarketplace;
};

export async function findById(id: string) {
	const res = await axios.get(`/v2/marketplace/${id}`, getHeaders());

	return res.data as INewMarketplace;
}

export const createMarketplace = async (body: any) => {
	const res = await axios.post("/createMarketplace", body, getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const editMarketplace = async (body: any) => {
	const res = await axios.put(
		`/marketplace/${body.zeoosName}`,
		body,
		getHeaders()
	);

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const updateMarketplacesByMplcName = async (
	marketplaceName: string,
	body: any
) => {
	await axios.put(`/marketplaces/${marketplaceName}`, body, getHeaders());
};

export const deleteMarketplace = async (name: string) => {
	const res = await axios.delete(`/marketplace/${name}`, getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const generateDottFile = async () => {
	const res = await axios.get("/getDottFeed", getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const generateFnacFile = async () => {
	const res = await axios.get("/getFnacFeed", getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const generateAmazonFile = async () => {
	const res = await axios.get("/getAmazonFeed", getHeaders());

	const { data, success } = res.data;

	if (!success) {
		return new Error("Something goes wrong");
	}

	return data;
};

export const sendDataToFnac = async () => {
	const res = await axios.post("/send-data-to-fnac");
	return res.data;
};

export const getCombinedProductsLong = async () => {
	const res = await axios.get("/combinedProducts/long", getHeaders());
	return res.data;
};

export const getCombinedProducts = async () => {
	const res = await axios.get("/combinedProducts", getHeaders());
	return res.data.data.products;
};

export const generateProducts = async () => {
	const res = await axios.post("/combinedProducts", {}, getHeaders());
	return res.data;
};

export const updateProduct = async (toUpdate: any, zeoosName: string) => {
	await axios.put(
		`/combinedProducts/${zeoosName}`,
		{
			toUpdate,
		},
		getHeaders()
	);
};
export async function getPricingBreakdownData(data: any) {
	const res = await axios.get(
		`/productPriceBreakdownData/${data.zeoosName}/${data.sku}`,
		getHeaders()
	);

	return res.data;
}

export const updateProductPrice = async (toUpdate: any) => {
  const { seller, sku } = toUpdate;
	const res = await axios.put(`/product/${seller}/${sku}`, toUpdate, getHeaders());

  return res.data;
};

export const getPreviewBreakdown = async (body: any) => {
	const res = await axios.post(`/product/previewBreakdown`, body, getHeaders());

  return res.data;
};

export const getAttributesBreakdown = async (body: any) => {
	const res = await axios.post(`/product/breakdownAttrs`, body, getHeaders());

  return res.data;
};

export async function getSupportedMarketplaces() {
	return (await axios.get("supportedMarketplaces", getHeaders())).data
		.supportedMarketplaces;
}

export async function getMarketplaceSetupConfig() {
	return (await axios.get("marketplaceSetupConfig", getHeaders())).data
		.marketplaceSetupConfig;
}

export async function postProductDetails(data: any) {
	const res = await axios.post("/productDetails", data, getHeaders());
	return res.data;
}
export async function getProductDetails(data: any) {
	const res = await axios.get(
		`/productDetails/${data.zeoosName}/${data.sku}`,
		getHeaders()
	);

	return res.data;
}
export async function updateProductDetails(data: any) {
	const res = await axios.put(
		`/productDetails/${data.zeoosName}/${data.sku}`,
		data,
		getHeaders()
	);

	return res.data;
}

export async function uploadCsvSeed(file: File, zeoosName: string) {
	const formData = new FormData();
	formData.append("file", file);
	const res = await axios.post(
		`/marketplace/${zeoosName}/seedManually`,
		formData,
		getHeaders()
	);
	return res.data;
}

export const getProductsFromPlatform = async (
	zeoosName: string,
	pagination: IPaginatedRequest
) => {
	const res = await axios.get(
		`/getProductsFromMarketplace/${zeoosName}?${queryString.stringify(
			pagination
		)}`,
		getHeaders()
	);

	return res.data.products;
};

export const updateProductOnAPlatform = async (body: any) => {
	const res = await axios.post(
		"/updateProductOnMarketplace",
		body,
		getHeaders()
	);

	const data = res.data.status;

	return data.toString();
};

export const setSchedule = async (body: any) => {
	const res = await axios.post("/marketplace/setCronJob", body, getHeaders());
	return res.data;
};

export const removeSchedule = async (body: any) => {
	const res = await axios.post(
		"/marketplace/removeCronJob",
		body,
		getHeaders()
	);

	return res.data;
};

export const uploadImage = async (body: any) => {
	const formData = new FormData();
	formData.append("file", body.imageFile);
	formData.append("zeoosName", body.zeoosName);
	formData.append("id", body.id);

	const res = await axios.post(
		"/marketplace/upload-file",
		formData,
		getHeaders({ "Content-Type": "multipart/form-data" })
	);

	return res.data;
};

export const getJSONScheme = async () => {
	const res = await axios.get("/scheme", getHeaders());

	return res.data.wineScheme.tabs;
};

export const saveProductInfo = async (body: any) => {
	const res = await axios.post(`/productInfo`, body, getHeaders());

	return res.data.productInfo;
};

export const updateProductMetaInfo = async (body: any) => {
	const res = await axios.put(`/productInfo/${body.sku}`, body, getHeaders());

	return res.data.productInfo;
};

export const getProductInfo = async (sku: string, lang: string) => {
	const res = await axios.get(`/productInfo/${sku}?lang=${lang}`, getHeaders());

	return res.data;
};

export const checkProductInfo = async (sku: string) => {
	const res = await axios.get(`/checkProductInfo/${sku}`, getHeaders());

	return res.data;
};

export const getCombinedProductBySku = async (sku: string) => {
	const res = await axios.get(`/combinedProducts/${sku}`, getHeaders());

	return res.data.data.products?.[0];
};

export async function getMarketplaceProduct(
	search: O.Either<{ sku: string; id: string }, "sku" | "id">
) {
	const query = queryString.stringify(search);
	const res = await axios.get(`/marketplace-product?${query}`, getHeaders());

	return res.data as IProductGestao;
}

export const getReviews = async (
	sku: string,
	pagination: IPaginatedRequest
) => {
	const res = await axios.get(
		`/reviews/${sku}?${queryString.stringify(pagination)}`,
		getHeaders()
	);

	return res.data;
};

export const createPaginatedRequest =
	(url: string) => async (pagination: IPaginatedRequest) => {
		const res = await axios.get(
			`${url}?${queryString.stringify(pagination)}`,
			getHeaders()
		);

		return res.data as IPaginatedResponse<any>;
	};

export const getPaginatedMarketplaceProducts =
	createPaginatedRequest("/pim/products");
export const getPaginatedInventoryProducts = createPaginatedRequest(
	"/inventory/products"
);

export async function syncOrders(marketplace: any) {
	const { zeoosName, marketplaceName } = marketplace;
	//this is the id of "Create Orders Custom" Job
	await executeJob("62ceb949ba441306244f2fe8", { marketplaceName, zeoosName });
}

export async function getPlatformPriceRange(zeoosName: string) {
	const res = await axios.get(
		`/platform/${zeoosName}/price-range`,
		getHeaders()
	);

	return res.data as { min: number; max: number };
}

export const updateMarketplaceProductMarketplaces = async (
	body: any,
	sku: string
) => {
	await axios.put(`/product-activation/${sku}`, body, getHeaders());
};

export const updateProductsMarketplaces = async (
	skus: string[],
	marketplaces: any
) => {
	await axios.put(
		`/products-activation/marketplaces`,
		{ skus, marketplaces },
		getHeaders()
	);
};

export async function getMarketplaceCategories(zeoosName: string) {
	const res = await axios.get(
		`/marketplace/${zeoosName}/categories`,
		getHeaders()
	);
	return res.data;
}

export async function getMarketplaceCategory(zeoosName: string, body: any) {
	const res = await axios.post(
		`/marketplace/${zeoosName}/category`,
		body,
		getHeaders()
	);
	return res.data as MarketplaceCategory;
}
