import React, { useMemo } from 'react';
import Contrato from '../contrato/Contrato';
import useUser from '../../../hooks/useUser';
import Payment from '../payment/Payment';
import Uploading from '../uploading/Uploading';
import ChoosePlatforms from '../uploading/choose-platforms.tsx/ChoosePlatforms';

export enum Status {
    na = "Na",
    awaiting = "Awaiting acceptance",
    accepted = "Accepted",
}

const Onboarding: React.FC = () => {
    const { user } = useUser();

    const component = useMemo(() => {
        if (user.seller?.contractStatus !== Status.accepted) {
            return <Contrato />;
        }

        if (user.seller?.paymentStatus !== Status.accepted) {
            return <Payment />;
        }

        if (!user.seller?.releaseProcess) {
            return <Uploading />;
        }


        if (!user.seller?.didUploadFile) {
            return <ChoosePlatforms />;
        }
    }, [user]);

    return (
        <main style={{ marginLeft: "0" }} className='content'>
            {component}
        </main>
    );
};

export default Onboarding;
