import React from 'react';
import { Link } from 'react-router-dom';
import useMarketplace from '../../../hooks/useMarketplace';
import CountryFlag from '../../ui/country-flag/CountryFlag';

interface Props extends React.PropsWithChildren {
    zeoosName: string;
}

const ModalHeader: React.FC<Props> = ({ zeoosName, children }) => {
    const marketplace = useMarketplace(zeoosName);

    return (
        <div className="product-header-cont">
            <div className="in-row align-cener height100">
                <Link to={`/platform/${zeoosName}`} className="product-exit-btn">
                    <img className="mr2" src="/icons/product-exit.svg" alt="" />
                    Exit
                </Link>
                <div className="in-row align-center ml2">
                    {marketplace.data && (
                        <CountryFlag
                            country={marketplace.data.country}
                            width="32px"
                            height="32px"
                        />
                    )}
                    <span className="product-header-text ml1">{zeoosName}</span>
                </div>
            </div>

            {children}
        </div>
    );
};

export default ModalHeader;
