import axios from "axios";
import { getHeaders } from "./user";
import queryString from "query-string";
import { IPaginatedRequest } from "../interfaces";
import { ILogisticPartnerWithFile } from "../components/routes/logistic/partners/CreateEditPartner";
import { ILogisticWarehouse } from "types";

export async function getPaginatedWarehouses(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/logistic-warehouses?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export async function getWarehouse(id: string) {
  const res = await axios.get(`/logistic-warehouse/${id}`, getHeaders());
  return res.data.warehouse;
}

export async function createWarehouse(body: ILogisticWarehouse) {
  await axios.post("/logistic-warehouse", body, getHeaders());
}

export async function updateWarehouse(body: ILogisticWarehouse) {
  await axios.put(`/logistic-warehouse/${body._id}`, body, getHeaders());
}

export async function deleteWarehouse(id: string) {
  await axios.delete(`/logistic-warehouse/${id}`, getHeaders());
}

export async function getPaginatedPartners(pagination: IPaginatedRequest) {
  const res = await axios.get(
    `/logistic-partners?${queryString.stringify(pagination)}`,
    getHeaders()
  );
  return res.data;
}

export async function getPartner(id: string) {
  const res = await axios.get(`/logistic-partner/${id}`, getHeaders());
  return res.data.logisticPartner;
}

export async function createPartner(body: ILogisticPartnerWithFile) {
  const formData = new FormData();
  formData.append("file", body.file);
  formData.append("fileProps", JSON.stringify(body));

  await axios.post("/logistic-partner", formData, getHeaders());
}

export async function updatePartner(body: ILogisticPartnerWithFile) {
  const formData = new FormData();
  formData.append("file", body.file);
  formData.append("fileProps", JSON.stringify(body));

  await axios.put(`/logistic-partner/${body._id}`, formData, getHeaders());
}

export async function getActivePartners() {
  const res = await axios.get(`/logistic-active-partners`, getHeaders());
  return res.data.activePartners;
}

export async function deletePartner(id: string) {
  await axios.delete(`/logistic-partner/${id}`, getHeaders());
}
