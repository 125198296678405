import React, { useCallback, useEffect, useState } from "react";
import "./payment.css";
import useUser from "../../../hooks/useUser";
import { subscribe, updateBillingData, confirmSubscription3DS } from "../../../actions/onboardingSeller";
import { usePricingPlan } from "../../../actions/stripeProduct";
import { toast } from "react-toastify";
import LogoutWrap from "../../utils/logout-wrap/LogoutWrap";

export default function Payment() {
    const { user, invalidate } = useUser();
    const [loading, setLoading] = useState(false);

    const [confirmed, setConfirmed] = useState(user.seller.billingDataConfirmed);

    const [billingData, setBillingData] = useState({
        address: user.seller.fiscalAddress.address,
        city: user.seller.fiscalAddress.city,
        country: user.seller.fiscalAddress.country,
        postalCode: user.seller.fiscalAddress.postalCode,
        region: user.seller.fiscalAddress.region,
        email: user.seller.contactInfo.emailForBillings,
    });

    const handleConfirm = useCallback(async () => {
        if (confirmed) {
            return;
        }

        try {
            await updateBillingData(billingData);

            setConfirmed(true);

            toast.success('Data confimed. Now add your credit card');
        } catch (error) {
            toast.error('Something went wrong...');
        }
    }, [billingData, confirmed]);

    const currentPlan = usePricingPlan(user.seller.stripePriceId);

    const [card, setCard] = useState({
        number: "",
        exp: '',
        cvc: "",
        name: "",
    });

    const handleSubscribe = useCallback(async () => {
        if (!confirmed) {
            return;
        }

        try {
            const [exp_month, exp_year] = card.exp.split('/');

            setLoading(true);
            const data = await subscribe({
                number: card.number.replaceAll(' ', ''),
                cvc: card.cvc,
                exp_month,
                exp_year
            });
            setLoading(false);

            if (data.url) {
                window.location.replace(data.url);
            }

            toast.success("Perfect! Now let's finish setting up your catalog...");

            invalidate();
        } catch (error) {
            toast.error('Something went wrong...');
        }
    }, [card, confirmed, invalidate]);

    useEffect(() => {
        if (!user.seller.stripeCardId) {
            return;
        }

        async function main() {
            setLoading(true);
            try {
                await confirmSubscription3DS();
                invalidate();
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        }

        main();
    }, [user.seller, invalidate]);

    const formatCardNum = useCallback((v: string) => {
        setCard(
            prev => ({
                ...prev, number:
                    v
                        .replace(/\D/g, '')
                        .replaceAll(" ", "")
                        .split(/(\d{4})/)
                        .filter((w: string) => w.length > 0)
                        .join(" ")
            }));
    }, [setCard]);

    const formatExpDate = useCallback((v: string) => {
        setCard(
            prev => ({
                ...prev, exp:
                    v
                        .replaceAll("/", "")
                        .split(/(\d{2})/)
                        .filter((w: string) => w.length > 0)
                        .join("/")
            }));
    }, [setCard]);

    return (
        <div className="contrato-bg">
            <div className="payment-header">
                <img className="ml4" src="/icons/worten-logo.svg" width="129px;" alt="" />
                {/* <div className="in-row align-center mr4">
                    <span className="powered-text">Powered by</span>
                    <img width="96px" height="28px" src="/icons/zeoos-logo.svg" alt="" />
                </div> */}
                <LogoutWrap>
                    <button style={{ background: "#fff" }} className="logout-btn-onboarding mr4"><div>Logout</div></button>
                </LogoutWrap>
            </div>
            <div className="payment-cont">
                <div className="payment-box">
                    <span className="payment-text">Billing information</span>
                    <div className="payment-container">
                        <div className="in-column align-start width100 justify-center mb2">
                            <span className="policy-text mb1">Company Name:</span>
                            <input className="payment-input" value={user.seller.contactInfo.companyName} disabled />
                        </div>
                        <div className="in-column align-start width100 justify-center mb2">
                            <span className="policy-text mb1">VAT number (NIF):</span>
                            <input className="payment-input" value={user.seller.contactInfo.vatNumber} disabled />
                        </div>
                        <div className="in-column align-start width100 justify-center mb2">
                            <span className="policy-text mb1">Email for Billings:</span>
                            <input className="payment-input" value={billingData.email} onChange={e => setBillingData(prev => ({ ...prev, email: e.target.value }))} />
                        </div>
                        <div className="in-row width100 align-center justify-between">
                            <div className="in-column align-start width45 justify-center mb2">
                                <span className="policy-text mb1">Country:</span>
                                <input className="payment-input" value={billingData.country} onChange={e => setBillingData(prev => ({ ...prev, country: e.target.value }))} />
                            </div>
                            <div className="in-column align-start width45 justify-center mb2">
                                <span className="policy-text mb1">Postal Code:</span>
                                <input className="payment-input" value={billingData.postalCode} onChange={e => setBillingData(prev => ({ ...prev, postalCode: e.target.value }))} />
                            </div>
                        </div>
                        <div className="in-row width100 align-center justify-between">
                            <div className="in-column align-start width45 justify-center mb2">
                                <span className="policy-text mb1">State / Region:</span>
                                <input className="payment-input" value={billingData.region} onChange={e => setBillingData(prev => ({ ...prev, region: e.target.value }))} />
                            </div>
                            <div className="in-column align-start width45 justify-center mb2">
                                <span className="policy-text mb1">City:</span>
                                <input className="payment-input" value={billingData.city} onChange={e => setBillingData(prev => ({ ...prev, city: e.target.value }))} />
                            </div>
                        </div>
                        <div className="in-column align-start width100 justify-center mb2">
                            <span className="policy-text mb1">Address:</span>
                            <input className="payment-input" value={billingData.address} onChange={e => setBillingData(prev => ({ ...prev, address: e.target.value }))} />
                        </div>
                        <button
                            onClick={handleConfirm}
                            className="log-in-login mt4"
                            disabled={confirmed}
                        >
                            Confirm
                        </button>
                    </div>
                </div>

                <div className="payment-box">
                    <span className="payment-text">Payment method</span>
                    <div className="payment-container">
                        <div className="width100 in-row align-center justify-between">
                            <span className="payment-text">Credit card</span>
                            <img src="/icons/payment-icon.svg" alt="" />
                        </div>
                        <div className="payment-border"></div>
                        <div className="in-column align-start width100 justify-center mb2">
                            <span className="policy-text mb1">Card number:</span>
                            <input maxLength={19} disabled={!confirmed} className="payment-input" value={card.number} onChange={e => formatCardNum(e.target.value)} />
                        </div>
                        <div className="in-row width100 align-center justify-between">
                            <div className="in-column align-start width45 justify-center mb2">
                                <span className="policy-text mb1">Expiration date:</span>
                                <input maxLength={5} disabled={!confirmed} className="payment-input" placeholder="12/24" value={card.exp} onChange={e => formatExpDate(e.target.value)} />
                            </div>
                            <div className="in-column align-start width45 justify-center mb2">
                                <span className="policy-text mb1">Security code:</span>
                                <input maxLength={3} disabled={!confirmed} className="payment-input" placeholder="123" value={card.cvc} onChange={e => setCard(prev => ({ ...prev, 'cvc': e.target.value.replace(/\D/g, '') }))} />
                            </div>
                        </div>
                        <div className="in-column align-start width100 justify-center mb2">
                            <span className="policy-text mb1">Name of the card owner</span>
                            <input disabled={!confirmed} className="payment-input" placeholder="John Doe" value={card.name} onChange={e => setCard(prev => ({ ...prev, 'name': e.target.value.replace(/[^A-Za-z\s!?]/g, '') }))} />
                        </div>
                    </div>
                </div>

                <div className="payment-box">
                    <span className="payment-text">Plan pricing details</span>
                    <div className="payment-container">
                        <div className="width100 in-row align-center justify-between">
                            <span className="payment-text"><span style={{ color: "#F1692E" }}>{currentPlan?.name}</span> plan</span>
                        </div>
                        <div className="payment-border"></div>
                        <div className="width100 in-row align-center justify-between mb4">
                            <span className="policy-text" style={{ textTransform: 'capitalize' }}>{`${currentPlan?.interval}ly`}{' '}subscription</span>
                            <span className="payment-cost">{currentPlan?.price}</span>
                        </div>
                        <button
                            className="log-in-login mt4"
                            onClick={handleSubscribe}
                            disabled={loading}
                        >
                            Confirm and Pay
                        </button>
                        <span className="mt4 policy-text">
                            Your subscription will automatically renew every {currentPlan?.interval} with a payment of {currentPlan?.price}. You can cancel your subscription at any time by contacting your account manager.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
