import React, { useState, memo } from "react";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import type { ILogisticPartner } from "types";
import * as LogisticController from "../../../../actions/logistic";
import { queryClient } from "../../../App";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { ILogisticPartnerWithFile } from "./CreateEditPartner";

interface Props {
	partner: ILogisticPartner;
	rqKeyPartners: string;
}

const StatusPartner: React.FC<Props> = ({ partner, rqKeyPartners }) => {
	const [state, setState] = useState({
		checkedC: partner.isActive,
	});

	const refetchPartnerConfig = {
		onSuccess: () => {
			queryClient.invalidateQueries(rqKeyPartners);
		},
		onError: () => {
			toast.error("Something went wrong!");
		},
	};

	const editMutation = useMutation(
		(data: ILogisticPartnerWithFile) => LogisticController.updatePartner(data),
		refetchPartnerConfig
	);

	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, [event.target.name]: event.target.checked });
		await editMutation.mutateAsync({
			...partner,
			isActive: event.target.checked,
		} as ILogisticPartnerWithFile);
	};

	return (
		<td className="partner-status">
			<AntSwitch
				checked={state.checkedC}
				onChange={handleChange}
				name="checkedC"
				required
			/>
			<span className="email-textm ml2">
				{partner.isActive ? "Active" : "Inactive"}
			</span>
		</td>
	);
};

export default memo(StatusPartner);
