import React, { forwardRef, useState } from "react";
import "./acompanhamento.css";
import "../tables.css";
import { Selects } from "./Selects";
import { TableAcompanhamento } from "./TableAcomp";
import Preloader from "../../../ui/preloader/Preloader";
import { IPaginatedRequest } from "../../../../interfaces";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useListOrders } from "../../../../hooks/useOrders";
import { useDebouncedValue } from "@mantine/hooks";
import useSanitizedMarketplaces from "../../../../hooks/useSanitizedMarketplaces";
import useActiveVendors from "../../../../hooks/useActiveVendors";

export default function Acompanhamento() {
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
		vendorId: "All vendors",
		status: "All statuses",
		country: "All countries",
		platform: "All channels",
		from: Date.parse(`${moment().startOf("years")}`),
		to: new Date().valueOf(),
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const { isLoading: loadMarketplaces, data: marketplaces } =
		useSanitizedMarketplaces();

	const vendors = useActiveVendors() as any;

	const {
		data: orders,
		statuses,
		countries,
		channels,
	} = useListOrders(debouncedConfig);

	const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
		<div className="acompan-calendar-input" onClick={onClick} ref={ref}>
			{value}
		</div>
	));

	if (loadMarketplaces || vendors.isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<>
			<div className="main-body">
				<div className="main-container">
					<div className="table-title-cont">
						<div className="main-table-title-cont">
							<div className="acompan-select-cont justify-between" style={{ width: "100%" }}>
								<div className="in-row align-center">
									<span className="pim-language-text pl4" style={{ textTransform: 'capitalize' }}>
										All my orders
									</span>
								</div>
									<Selects
										marketplaces={marketplaces as any}
										vendors={vendors.data!}
										currentConfig={currentConfig}
										setCurrentConfig={setCurrentConfig}
										filterFrom={"inventory"}
									/>
							</div>
						</div>
					</div>
					{orders && !orders.isLoading ? (
						<>
							{orders.data.length ? (
								<TableAcompanhamento orders={orders.data} />
							) : (
								<div className="in-column width100 height80vh align-center justify-center">
									<span>No orders yet</span>
								</div>
							)}
						</>
					) : (
						<Preloader />
					)}
				</div>
			</div>
		</>
	);
}
