import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { InfoSection } from "./InfoSection";
import { TableSection } from "./TableSection";
import { CreateEditMarketplaceForm } from "../marketplaces/CreateEditMplc";
import Preloader from "../../ui/preloader/Preloader";
import "./singlemarketplace.css";
import useActiveVendors from "../../../hooks/useActiveVendors";
import { useMemo } from "react";
import useMarketplace from "../../../hooks/useMarketplace";

type TParams = { zeoosName: string; };

const Singlemarketplace: React.FC<RouteComponentProps<TParams>> = (props) => {
  const { zeoosName } = props.match.params;
  const [modal, setModal] = useState(false);

  const vendors = useActiveVendors();
  const marketplace = useMarketplace(zeoosName);
  const loading = useMemo(() => vendors.isLoading || marketplace.isLoading, [vendors.isLoading, marketplace.isLoading]);

  return loading ? (
    <div className="center-loader">
      <Preloader />
    </div>
  ) : (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont">
          <InfoSection
            marketplace={marketplace.data!}
            setOpenEdit={setModal}
            vendors={vendors.data}
          />
          <TableSection marketplace={marketplace} />
        </div>
      </div>
      {modal && (
        <CreateEditMarketplaceForm
          setModal={setModal}
          modal={modal}
          marketplace={marketplace.data!}
          action={"edit"}
        />
      )}
    </div>
  );
};

export default withRouter(Singlemarketplace);
