import React, { useState, useEffect } from "react";
import { getCookie, setCookie } from "../../../utils/cookie";
import { countryUser } from "../../../actions/user";

const Contato: React.FC<any> = () => {
  const [linkVinuus, setLinkVinuus] = useState("");

  useEffect(() => {
    async function initializeState() {
      let link = getCookie("site-vinuus") || "";
      if (!link) {
        const country = (await countryUser()) as any;
        setCookie("site-vinuus", country.target);
        link = country.target;
      }
      setLinkVinuus(link);
    }
    initializeState();
  }, []);

  return (
    <div className="contato">
      <div>
        {/* <a href="mailto:vendor@vinuus.com">Dúvidas? Entre em contato</a> */}
        {/* <a href={linkVinuus}>Dúvidas? Entre em contato</a> */}
        <a href="https://www.zeoos.com/">Doubts? Contact us</a>
      </div>
      {/* <div>
                <a href={linkVinuus} target="_blank" rel="noreferrer">Ver loja Vinuus</a>
            </div> */}
    </div>
  );
};

export default Contato;
