import React, { useState, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { getSchemes, massImport } from "../../../../actions/pim";
import { Modal } from "../../../ui/modal/Modal";
import Preloader from "../../../ui/preloader/Preloader";
import "./mass-import.css";
import { CountriesContext } from "../../../context";
import { ICountryM } from "../../country-management/CountryManagement";
import { exportPriceStockData } from "../../../../actions/pim";

interface Props {
  onSuccess: () => void;
  onError: () => void;
  modal: boolean;
  setModal: (modal: boolean) => void;
}

const MassImport: React.FC<Props> = ({
  onSuccess,
  onError,
  modal,
  setModal,
}) => {
  const countries = useContext(CountriesContext) as ICountryM[];
  const modes = ["import", "update"] as string[];

  // const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<any>(null);
  const [schemesList, setSchemesList] = useState<any>([]);

  const [scheme, setScheme] = useState("");
  const [lang, setLang] = useState("");
  const [separator, setSeparator] = useState(",");
  const [mode, setMode] = useState("import");

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: ["text/csv", ".csv", '.xlsx'],
      onDropRejected: () => {
        toast.error('File type rejected. Only ".csv" or ".xlsx" files are allowed.');
      },
    });

  useEffect(() => {
    async function initializeState() {
      const productSchemes = await getSchemes();
      setSchemesList(productSchemes);
      setScheme(productSchemes[0]._id);
      setLang(countries[0].langCode);
    }
    initializeState();
    // eslint-disable-next-line
  }, []);

  const dropzone = (
    <div className="table-modal-form-cont p4">
      <div className="table-modal-title-box m0">
          <div className="import-title">Update prices & stock</div>
        </div>
        <div className="table-modal-border"></div>
        <span className="mt2 in-row width100 align-center">
          <b className="mr1">Upload the file</b> 
           (Allowed file format .xlsx)
        </span>
      <div
        className="width100 pointer import-reviews-dropzone"
        style={{ width: "400px", height: "150px" }}
        {...getRootProps()}
      >
        {/* @ts-ignore */}
        <input {...getInputProps()} />
        {isDragActive ? (
          // <h4>Drop the files here or click me to select ...</h4>
          <></>
        ) : (
          <div className="in-row align-center justify-center" style={{ background: "#e2e2e2", height: "150px" }}>
          <h2 className="import-title" style={{ opacity: "0.5", fontWeight: "normal" }} >
            {acceptedFiles.length > 0
              ? `Click 'Import' to finish upload ${acceptedFiles[0].name}`
              : "Drop the file here"}
          </h2>
          </div>
        )}
      </div>
      <div
									className="bold mt2 in-row width100 align-center" style={{ color: "#E51B15" }}
									onClick={() =>
										exportPriceStockData().then(() =>
											toast.success(
												"Success! Check your mailbox, it should be there in seconds."
											)
										)
									}
								>
									<div className="pointer">Download Template Spreadsheet</div>
								</div>
      {/* <div className="import-selects-cont">
        <div className="import-options mt2">
          <label>
            Product scheme:
            <select
              className="formEdit__input"
              value={scheme}
              onChange={(e) => setScheme(e.target.value)}
            >
              {schemesList.map((scheme: any) => {
                return <option value={scheme._id}>{scheme.name}</option>;
              })}
            </select>
          </label>
          <label>
            Country:
            <select
              className="formEdit__input"
              name="lang"
              value={lang}
              onChange={(e) => setLang(e.target.value)}
            >
              {countries.map((item) => {
                return <option value={item.langCode}>{item.language}</option>;
              })}
            </select>
          </label>
        </div>
        <div className="import-options mt2">
          <label>
            Separator:
            <input
              type="text"
              className="formEdit__input"
              maxLength={1}
              required
              value={separator}
              onChange={(e) => setSeparator(e.target.value)}
            />
          </label>
          <label>
            Mode:
            <select
              className="formEdit__input"
              name="mode"
              value={mode}
              onChange={(e) => setMode(e.target.value)}
            >
              {modes.map((mode) => {
                return <option value={mode}>{mode}</option>;
              })}
            </select>
          </label>
        </div>
      </div> */}
      {acceptedFiles.length > 0 && (
        <div className="width100 justify-end mt2">
          <button
            className="singlemarketplace-addproducts-button"
            onClick={async (e) => {
              e.preventDefault();
              setLoading(true);
              try {
                const { report } = await massImport(
                  acceptedFiles[0],
                  lang,
                  scheme,
                  separator,
                  mode
                );
                setReport(report);
                onSuccess();
              } catch (error) {
                console.error(error);
                onError();
              } finally {
                setLoading(false);
              }
            }}
          >
            Import
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="mass-import-box">
      {/* <div className='create-product-box'>
                <button className='singlemarketplace-addproducts-button m0' onClick={() => setModal(true)}>Mass import</button>
            </div> */}
      {modal && (
        <Modal isOpened={modal} onModalClose={() => setModal(false)}>
          <div className="width100 height100">
            {loading ? (
              <Preloader />
            ) : !report ? (
              dropzone
            ) : (
              <div className="in-column align-start justify-start ft3 import-result">
                <span className="mb4">Uploaded: {report.uploaded}</span>
                <span className="mb4">Succeded: {report.succeded}</span>
                <span className="mb4">Rejected: {report.rejected}</span>
                Messages:
                <span className="mb4 ft1 import-error">
                  {" "}
                  {report.messages.map((msg: string) => (
                    <>
                      {msg}
                      <br />
                    </>
                  ))}
                </span>
                <button
                  className="singlemarketplace-addproducts-button"
                  onClick={() => setReport(null)}
                >
                  Try again
                </button>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MassImport;
