import React, { useState } from "react";
import { exportAllProductInfo, exportMyProducts, getEssentialProductSchemeData } from "../../../actions/pim";
import { Modal } from "../../ui/modal/Modal";
import { toast } from "react-toastify";
import { Checkbox } from "@mui/material";
import useSanitizedMarketplaces from "../../../hooks/useSanitizedMarketplaces";
import useSchemes from "../../../hooks/useSchemes";
import { useEffect } from "react";
import { useQuery } from "react-query";
import useCountries from "../../../hooks/useCountries";
import { useLanguages } from "./tabs/Tabs";

interface Props {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  modal: boolean;
  skus?: string[];
}

export const ExportAllProductInfo: React.FC<Props> = ({
  modal,
  setModal,
  skus,
}) => {
  const languages = useLanguages();
  const [lang, setLang] = useState('');

  useEffect(() => {
    setLang(languages[0].langCode);
  }, [languages]);

  return (
    <Modal
      onModalClose={(e: React.MouseEvent) => {
        e.stopPropagation();
        setModal(false);
      }}
      isOpened={modal}
    >
      <div className="modal-export-cont">
        <div>
          <label className="mapping-modal-text pr2" htmlFor="">
            Language:
          </label>
          <select
            className="freight-modal-input"
            onChange={(e) => setLang(e.target.value)}
          >
            {languages.map(x => (
              <option value={x.langCode}>{x.name}</option>
            ))}
          </select>
        </div>

        <div className="width100">
          <button
            className="table-modal-form-button"
            onClick={(e) => {
              e.preventDefault();

              exportMyProducts(lang, skus);
              toast.success(
                "It will land in your email inbox once finished processing (usually takes less than a minute)"
              );
            }}
          >
            Export
          </button>
        </div>
      </div>
    </Modal>
  );
};
