import React, { memo, useCallback } from "react";
import { Modal } from "../../../ui/modal/Modal";
import type { ILogisticPartner } from "types";
import CountryFlag from "../../../ui/country-flag/CountryFlag";

interface Props {
	partner?: ILogisticPartner;
	onModalClose: (e: React.MouseEvent) => void;
	rqKeyPartners?: string;
	modal: boolean;
	setModal: (value: React.SetStateAction<boolean>) => void;
}

const RateWindowModal: React.FC<Props> = ({
	partner,
	onModalClose,
	// rqKeyPartners,
	modal,
	setModal,
}) => {
	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			if (partner) {
				// await editMutation.mutateAsync(data);
			} else {
				// await createMutation.mutateAsync(data);
			}

			setModal(false);
		},
		[partner, setModal]
	);

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="rate-window-cont">
				<div className="table-modal-title-box mt1">
					<div className="partner-modal-title-text">Set Zeoos Rate</div>
					<div className="width25 in-row align-center justify-between">
						<CountryFlag
							country={partner?.country}
							className="table-search-image"
							width="35px"
						/>
						<div className="primary-color ft2 bold">{partner?.country}</div>
					</div>
				</div>
				<form className="table-modal-form-cont" onSubmit={onSubmit}>
					<div className="table-modal-border"></div>
					<div className="in-row align-center justify-between width100 mt1 mb5">
						<div className="width30 p2 img-border flex align-center justify-center">
							<img width="60px" height="50px" src={partner?.logo} alt="" />
						</div>
						<div className="width70 pl3 text-start">
							<div className="line-height-medium">
								Lorem ipsum dolor sit amet consectetur adipisicing elit.
								Expedita numquam
							</div>
							<div>
								<span className="bold mr2">Category:</span>
								<span>Wine</span>
							</div>
						</div>
					</div>
					<div className="in-row align-center justify-between width100 mt3 bold ft2">
						<div>Delivery Type</div>
						<div>Zeoos Rate</div>
					</div>
					<div className="table-modal-border mn"></div>
					{["Drop Shipping"].map((type: string, index: number) => (
						<div
							className="width100 in-row align-center justify-between pt1 pb1"
							key={index}
						>
							<div className="in-row align-center">
								<img
									className="pointer scale"
									src="/icons/nav-close.svg"
									width="20px"
									height="20px"
									alt=""
								/>
								<div className="ml2">{type}</div>
							</div>
							<input
								className="table-modal-form-value table-modal-border-value"
								onChange={() => { }}
								value={""}
							/>
						</div>
					))}
					<div className="table-modal-border mn"></div>
					<div className="in-row align-center mt2">
						{/* <select className="rate-window-select">
							{["Drop Shipping", "Delivery Type 1"].map(
								(type: string, index: number) => (
									<option className="" key={index}>
										{type}
									</option>
								)
							)}
						</select> */}
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-form-button-box width100 mt4">
						<button
							className="table-modal-form-button-cancel"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button className="table-modal-form-button" type="submit">
							Save
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(RateWindowModal);
