import React, { useMemo, useState } from "react";
import queryString from "query-string";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import { IPaginatedRequest } from "../../../interfaces";
import SettingsNav from "../../ui/settings-nav/SettingsNav";
import { HLFilter } from "./HLFilter";
import { HLTable } from "./HLTable";
import { getPaginatedUserHistory } from "../../../actions/user";
import { dates } from "../../ui/date/DateSelect";

export default function HistoryLog() {
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: "",
    user: "All users",
    type: "All types",
    created: dates[4].formatDate,
  });
  const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

  const rqKey = useMemo(() => {
    return `user/history?${queryString.stringify(debouncedConfig)}`;
  }, [debouncedConfig]);

  const { isLoading: loadUserHistory, data: userHistory } = useQuery(
    rqKey,
    () => getPaginatedUserHistory(debouncedConfig),
    { refetchOnWindowFocus: false }
  );

  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-main-cont p0">
          <SettingsNav />
          <HLFilter
            setCurrentHistoryConfig={setCurrentConfig}
            currentHistoryConfig={currentConfig}
          />
          <HLTable
            userHistory={userHistory}
            loadUserHistory={loadUserHistory}
            setCurrentConfig={setCurrentConfig}
            currentConfig={currentConfig}
          />
        </div>
      </div>
    </div>
  );
}
