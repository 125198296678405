import React from "react";
import { IZeoosOrder } from "../../../../../interfaces";

interface Props {
	order: IZeoosOrder;
}

const SingleOrderAdresses: React.FC<Props> = ({ order }) => {
	return (
		<div className="so-adresse-cont">
			<div className="width100">
				<div className="single-order-title-cont">
					<h2 className="single-order-title2">Delivery Address</h2>
					<div className="in-row align-center">
						{/* TODO */}
						{/* <img className='mr2 pointer' src='/icons/order-edit.svg' alt='' /> */}
					</div>
				</div>
				<div className="order-border"></div>
				<div className="mt4 single-order-text">
					<b>{order.shipping_address.name}</b>
					<p>
						{order.shipping_address.address}
						{order.shipping_address.address2
							? `, ${order.shipping_address.address2}`
							: ""}<br />
						{order.shipping_address.city}<br />
						phone: {order.shipping_address.phone}<br />
            mobile: {order.shipping_address.mobile}<br />
						postal code: {order.shipping_address.zipcode}<br />
						{order.shipping_address.email}
					</p>
				</div>
			</div>
			<div className="width100">
				<div className="single-order-title-cont">
					<h2 className="single-order-title2">Billing Address </h2>
					<div className="in-row align-center">
						{/* TODO */}
						{/* <img className='mr2 pointer' src='/icons/order-edit.svg' alt='' /> */}
					</div>
				</div>
				<div className="order-border"></div>
				<div className="mt4 single-order-text">
					<b>{order.billing_address.name}</b>
					<p>
						{order.billing_address.address}
						{order.billing_address.address2
							? `, ${order.billing_address.address2}`
							: ""}<br />
						{order.billing_address.city}<br />
						phone: {order.billing_address.phone}<br />
            mobile: {order.billing_address.mobile}<br />
						postal code: {order.billing_address.zipcode}<br />
						{order.billing_address.email}
					</p>
				</div>
			</div>
		</div>
	);
};

export default SingleOrderAdresses;
