import React from "react";
import "./profile.css";
import { Link } from "react-router-dom";
import { Role } from "../users-management/UsersManagement";
import RoleRequired from "../../utils/role-required/RoleRequired";

export default function Profile() {
  return (
    <div className="main-body">
      <div className="main-container">
        <div className="table-title-cont">
          <div className="profile-box-image"></div>
          <div className="profile-text-box">
            <div className="profile-username">Username</div>
            <div className="profile-email">Email</div>
            <RoleRequired role={Role.admin}>
              <div>
                <Link
                  to="/users-management"
                  className="users-management-button"
                >
                  Users-management
                </Link>
              </div>
            </RoleRequired>
          </div>
        </div>
        <div className="table-main-cont">
          <form className="change-form">
            <span className="profile-form-text">New username:</span>
            <input className="profile-form-input" required />
            <button className="profile-change-button">Change username</button>
          </form>
          <form className="change-form" name="email">
            <span className="profile-form-text">New email:</span>
            <input className="profile-form-input" required />
            <button className="profile-change-button">Change email</button>
          </form>
          <form className="change-form">
            <span className="profile-form-text">Old password:</span>
            <input className="profile-form-input" required />
            <span className="profile-form-text">New password:</span>
            <input className="profile-form-input" required />
            <span className="profile-form-text">Repeat password:</span>
            <input className="profile-form-input" required />
            <button className="profile-change-button">Change password</button>
          </form>
        </div>
      </div>
    </div>
  );
}
