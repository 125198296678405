import React from "react";
import { ICountryM } from "../../country-management/CountryManagement";
import CountryFlag from "../../../ui/country-flag/CountryFlag";
import useCountries from "../../../../hooks/useCountries";
import { useAtomValue } from "jotai";
import { selectedProductsAtom } from "./Gestao";

interface Props {
	addedPlatforms: string[];
	setBatchName: (batchName: string) => void;
}

const StepUpload: React.FC<Props> = ({
	addedPlatforms,
	setBatchName,
}) => {
	const countries = useCountries() as ICountryM[];
	const selectedProducts = useAtomValue(selectedProductsAtom);

	const getCountry = (platform: string) => {
		const platformCode = platform.split(" ")[1];

		return platformCode === "BE"
			? "Belgium"
			: countries.find(
				(country: ICountryM) =>
					country.langCode.toUpperCase() === platformCode
			)?.name;
	};

	return (
		<div
			style={{ background: "#E8EDF2", marginTop: "0", paddingTop: "20px" }}
			className="product-cont"
		>
			<h1 className="product-h">Create and Upload batch</h1>
			<div className="product-content">
				<div className="batch-upload-content-cont">
					<div className="in-column width100 align-start mb4">
						<span className="batch-upload-text mb2">Upload Batch Name:</span>
						<input
							className="product-input"
							placeholder="Batch name..."
							onChange={(e) => setBatchName(e.target.value)}
						/>
					</div>
					<div className="in-column width100 align-start">
						<span className="batch-upload-text mb4">
							{selectedProducts.length} products selected for{" "}
							{addedPlatforms.length} platforms
						</span>
						<div className="batch-upload-border"></div>
						{addedPlatforms.map((platform: string, index: number) => (
							<div className="batch-upload-box" key={index}>
								<div className="in-row align-center">
									<CountryFlag
										country={getCountry(platform)}
										className="mr2"
										width="32px"
									/>
									<span style={{ color: "#5A5A5A" }}>{platform}</span>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default StepUpload;
