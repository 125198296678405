import React, { useState } from "react";
import "./marketplaces.css";
import {
	getPaginatedMarketplaces,
	getSanitizedMarketplaces,
} from "../../../actions/marketplace";
import { IMarketplace, IPaginatedRequest } from "../../../interfaces";
import { PlatformFilter } from "./PlatformFilter";
import { CreateEditMarketplaceForm } from "./CreateEditMplc";
import Preloader from "../../ui/preloader/Preloader";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import { PlatformTable } from "./PlatformTable";
import queryString from "query-string";

export const getLogoSvg = (marketplace: IMarketplace) => {
	if (marketplace.marketplaceName === "mirakl") {
		return marketplace?.zeoosName?.split(" ")[0].toLowerCase();
	} else {
		return marketplace?.marketplaceName?.toLowerCase();
	}
};

export const MarketplacesList: React.FC<any> = () => {
	const [createModal, setCreateModal] = useState<boolean>(false);
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
		platformName: "All platforms",
		type: "All types",
		country: "All countries",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const { isLoading: loadMarketplaces, data: marketplaces } = useQuery(
		`marketplaces/admin?${queryString.stringify(debouncedConfig)}`,
		() => getPaginatedMarketplaces(debouncedConfig)
	);

	const { isLoading: loadSanitizedMarkeplaces, data: sanitizedMarkeplaces } =
		useQuery("sanitizedMarkeplaces", () => getSanitizedMarketplaces(),
			{ refetchOnWindowFocus: false });

	if (loadSanitizedMarkeplaces) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont">
					<PlatformFilter
						setOpenCreate={setCreateModal}
						marketplaces={sanitizedMarkeplaces}
						setCurrentConfig={setCurrentConfig}
						currentConfig={currentConfig}
					/>
					<PlatformTable
						marketplaces={marketplaces}
						setCurrentConfig={setCurrentConfig}
						currentConfig={currentConfig}
						loadMarketplaces={loadMarketplaces}
					/>
					{createModal && (
						<CreateEditMarketplaceForm
							setModal={setCreateModal}
							modal={createModal}
							action={"create"}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
