import React, { useCallback, useEffect, useState } from "react";
import "../pim.css";
import { Modal } from "../../../ui/modal/Modal";
import Preloader from "../../../ui/preloader/Preloader";
import { IPaginatedRequest } from "../../../../interfaces";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import { getReviews } from "../../../../actions/marketplace";
import Paginator from "../../../ui/pagination";
import { IReview } from "../../../../actions/reviews";
import { CreateOrEditReviewForm } from "../../reviews/create-review/CreateReview";

export const ReviewsPim: React.FC<any> = ({ sku }) => {
  const [modal, setModal] = useState<boolean>(false);
  const [review, setReview] = useState<IReview | null>();

  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebouncedValue(search, 200);
  const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
    perPage: 50,
    page: 1,
    search: debouncedSearch,
  });

  const {
    isLoading,
    data: reviews,
    refetch,
  } = useQuery("reviews", () => getReviews(sku, currentConfig),
    { refetchOnWindowFocus: false });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [currentConfig]);

  useEffect(() => {
    setCurrentConfig((currentConfig) => ({
      ...currentConfig,
      page: 1,
      search: debouncedSearch,
    }));
  }, [debouncedSearch]);

  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      config.search = debouncedSearch;
      setCurrentConfig(config);
    },
    [debouncedSearch]
  );

  if (isLoading) {
    return (
      <div className="center-loader">
        <Preloader />
      </div>
    );
  }

  return (
    <div>
      <form>
        <div className="pim-main">
          <div className="pim-avaliacoes-title-cont">
            <div className="pim-title-legend p0">
              {reviews?.data.length} Avaliações de clientes
            </div>
            <div
              onClick={() => {
                setReview(null);
                setModal(true);
              }}
              className="pim-avaliacoes-title-button pointer"
            >
              <img src="/icons/pim-plus.svg" alt="" />
              <div className="pim-avaliacoes-title-button-text">
                Nova avaliação
              </div>
            </div>
          </div>
          <div className="table-main-title-cont">
            <div className="table-main-title-search-value-cont">
              <div className="search-box">
                <div className="search_form">
                  <div className="table-search-box">
                    <form
                      className="table-search-form"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        className="table-search-input"
                        placeholder="Search review"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <img
                        className="table-search-image"
                        src="/icons/search.svg"
                        width="25px;"
                        alt=""
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="marketplace-pagination">
              <Paginator data={reviews} refetch={onChangePage as any} />
            </div>
          </div>
          <div className="table-overflow p0">
            <table className="table-results p0">
              <thead>
                <tr className="table-results-title">
                  <th className="pim-avaliacoes-origem">Origem</th>
                  <th className="table-border-right"></th>
                  <th className="pim-avaliacoes-pais">País</th>
                  <th className="table-border-right"></th>
                  <th className="pim-avaliacoes-avaliador">Avaliador</th>
                  <th className="table-border-right"></th>
                  <th className="pim-avaliacoes-titulo">Título</th>
                  <th className="table-border-right"></th>
                  <th className="pim-avaliacoes-descricao">Descrição</th>
                  <th className="table-border-right"></th>
                  <th className="pim-avaliacoes-rating">Rating</th>
                  <th className="table-border-right"></th>
                  <th className="pim-avaliacoes-actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {reviews?.data.map((review: any, index: number) => (
                  <tr className="table-result-box" key={index}>
                    <td className="pim-avaliacoes-origem">
                      <div className="pim-avaliacoes-text">{review.origin}</div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="pim-avaliacoes-pais">
                      <div className="pim-avaliacoes-text">
                        {review.country}
                      </div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="pim-avaliacoes-avaliador">
                      <div className="pim-avaliacoes-text">{review.name}</div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="pim-avaliacoes-titulo">
                      <div className="pim-avaliacoes-text-title">
                        {review.title}
                      </div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="pim-avaliacoes-descricao">
                      <div
                        onClick={() => {
                          setReview(review);
                          setModal(true);
                        }}
                        className="pim-avaliacoes-text-desc pointer"
                      >
                        Ver
                      </div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="pim-avaliacoes-rating">
                      <div className="pim-avaliacoes-text">{review.rating}</div>
                    </td>
                    <td className="table-border-right"></td>
                    <td className="pim-avaliacoes-actions">
                      <img
                        onClick={() => {
                          setReview(review);
                          setModal(true);
                        }}
                        className="pointer"
                        src="/icons/pim-avaliacoes-edit.svg"
                        alt=""
                        width="25px;"
                      />
                      <img
                        className="pointer"
                        src="/icons/pim-avaliacoes-trash.svg"
                        alt=""
                        width="25px;"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {modal && (
          <Modal onModalClose={() => setModal(false)} isOpened={modal}>
            <CreateOrEditReviewForm review={review} setModal={setModal} />
          </Modal>
        )}
      </form>
    </div>
  );
};
