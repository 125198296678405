import React, { useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import SellerProductInventory from "./SellerProductInventory";
import SellerProductContryPrice from "./broken_SellerProductContryPrice";
// import SellerProductContryPrice from "./SellerProductContryPrice";
import { withStyles } from "@material-ui/core/styles";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
	usePaginatedVendorProducts,
	useSingleVendor,
	// useVendorProducts,
} from "../../../../hooks/useVendors";
import Preloader from "../../../ui/preloader/Preloader";
import { countProducts } from "../VendorsManagement";
import { IPaginatedRequest, IVendor } from "../../../../interfaces";
import useTaxes from "../../../../hooks/useTaxes";
import { CreateEditVendor } from "../CreateEditVendor";
import { updateVendor } from "../../../../actions/vendor";
import MassUpdateCountryPrice from "./MassUpdateCountryPrice";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { toast } from "react-toastify";
import { Role } from "../../users-management/UsersManagement";
import { exportPricePointData } from "../../../../actions/vendor";
import { GestaoFilter } from "../../tables/gestao/GestaoFilter";
import useSanitizedMarketplaces from "../../../../hooks/useSanitizedMarketplaces";
import useActiveVendors from "../../../../hooks/useActiveVendors";
import { STOCK_CONDITIONS } from "types";
import { useDebouncedValue } from "@mantine/hooks";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";

const AntTabPanel = withStyles({
	root: {
		background: "#e8edf2 !important",
		padding: "0 !important",
	},
})(TabPanel);

const SellerProduct: React.FC<RouteComponentProps<{ id: string }>> = (
	props
) => {
	const [modal, setModal] = useState<boolean>(false);
	const [modalEditSeller, setModalEditSeller] = useState<boolean>(false);
	const [modalMassUpdate, setModalMassUpdate] = useState<boolean>(false);
	const [massUpdateMode, setMassUpdateMode] = useState<string>("");
	const [value, setValue] = React.useState("1");
	const [action, setAction] = useState<string>("edit");
	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
		country: "All Countries",
		brand: "All Brands",
		category: "All Categories",
		stockCondition: STOCK_CONDITIONS[0],
		platformName: "All Marketplaces",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const vendor = useSingleVendor(props.match.params.id);

	// const products = useVendorProducts(props.match.params.id) as any;
	//Lucio comment here

	const { isLoading: productsLoading, data: products } =
		usePaginatedVendorProducts(props.match.params.id, debouncedConfig);

	const taxes = useTaxes();
	const marketplaces = useSanitizedMarketplaces();
	const vendors = useActiveVendors() as any;

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	const listCategories = (vendor: IVendor) => {
		const lista: string[] = [];
		const show = 3;
		vendor?.categories.map((c: any) => lista.push(c.scheme));

		const plus =
			lista.length > show ? ` + ${lista.length - show} categories` : "";

		return lista.slice(0, show).join(", ") + plus;
	};

	return (
		<>
			<div className="main-body">
				<div className="main-container">
					{!vendor.isLoading && !taxes.isLoading && !marketplaces.isLoading ? (
						<>
							<div className="s-users-management-container">
								<div className="table-main-cont">
									<div className="single-billing-nav pointer m0">
										<Link className="in-row align-center" to={`/sellers`}>
											<img
												src="/icons/billing-arrow-back.svg"
												alt=""
												width="25px;"
											/>
											<div className="table-subtext underline pl2">
												back to Sellers
											</div>
										</Link>
									</div>
									<div className="table-title-cont p0 mt4 mb4">
										<div className="mplc-filter-cont width100">
											<div className="in-row align-center">
												<img
													width="150px"
													className="mr4"
													src={vendor.data?.vendorIcon}
													alt=""
												/>
												<div className="in-column align-start justify-start pl4">
													<h2 className="seller-product-text">
														{vendor.data?.name}
													</h2>
													<div className="seller-product-subtext">
														{countProducts(vendor.data)} products in
														<b
															onClick={(e) => {
																setModal(true);
															}}
															className="seller-product-subtext-blue pointer"
														>
															{` ${listCategories(vendor.data)}`}
														</b>
													</div>
												</div>
											</div>
											<div className="in-column mr4 mt4 mb1 align-end">
												<button
													className="tax-title-button pointer pim-avaliacoes-title-button-text mb4"
													onClick={(e) => {
														setAction("edit");
														setModalEditSeller(true);
													}}
												>
													EDIT
												</button>
												<img
													style={{ cursor: "pointer" }}
													src="/icons/sellers-contact.svg"
													alt=""
													onClick={(e) => {
														setAction("view");
														setModalEditSeller(true);
													}}
												/>
											</div>
										</div>
									</div>
									<div className="table-title-cont p0 mt4 mb4">
										<div className="mplc-filter-cont width100">
											<div className="in-row align-center">
												<TabContext value={value}>
													<TabList
														onChange={handleChange}
														aria-label="lab API tabs example"
													>
														<Tab
															className={`seller-product-tab ${
																value === "1" && "active-tab-default"
															}`}
															label="Country Price Point"
															value="1"
														/>
														<Tab
															className={`seller-product-tab ${
																value === "2" && "active-tab-default"
															}`}
															label="Inventory"
															value="2"
														/>
													</TabList>
												</TabContext>
											</div>
											<div className="in-row align-center">
												<div className="">
													{/* <SellerProductFilter /> */}
													<GestaoFilter
														marketplaces={marketplaces?.data || []}
														vendors={[]}
														currentConfig={currentConfig}
														setCurrentConfig={setCurrentConfig}
													/>
												</div>
												<div className="single-main-title-end">
													<div className="in-row">
														<Select
															className="more-cont"
															labelId="label"
															id="select"
															value="first"
														>
															<MenuItem className="hide" value="first">
																<img src="/icons/edit-sm.svg" alt="" />
																More
															</MenuItem>
															<MenuItem
																onClick={() => {
																	setMassUpdateMode("pricing");
																	setModalMassUpdate(true);
																}}
															>
																<RoleRequired role={Role.admin}>
																	Mass update cost
																</RoleRequired>
															</MenuItem>
															<MenuItem
																onClick={() => {
																	setMassUpdateMode("pvp");
																	setModalMassUpdate(true);
																}}
															>
																<RoleRequired role={Role.admin}>
																	Mass update pvpFinal
																</RoleRequired>
															</MenuItem>
															<MenuItem
																value=""
																onClick={() =>
																	exportPricePointData(
																		props.match.params.id
																	).then(() =>
																		toast.success(
																			"Success! Check your mailbox, it should be there in seconds."
																		)
																	)
																}
															>
																Export product pricing
															</MenuItem>
														</Select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="table-main-cont">
								{/* {!products.isLoading ? ( */}
								{!productsLoading ? (
									<TabContext value={value}>
										<AntTabPanel
											style={{ background: "#e8edf2 !important" }}
											value="1"
										>
											{/* <SellerProductContryPrice
												vendor={vendor.data}
												products={products.data.data!}
												taxes={taxes.data}
												// Lucio comment here
												// currentConfig={currentConfig}
												// setCurrentConfig={setCurrentConfig}
											/> */}

											<SellerProductContryPrice
												vendor={vendor.data}
												products={products!}
												taxes={taxes.data}
												currentConfig={currentConfig}
												setCurrentConfig={setCurrentConfig}
											/>
										</AntTabPanel>
										<AntTabPanel value="2">
											<SellerProductInventory
												vendor={vendor.data}
												vendors={vendors.data}
												currentConfig={currentConfig}
												setCurrentConfig={setCurrentConfig}
											/>
										</AntTabPanel>
									</TabContext>
								) : (
									<CircularPreloader />
								)}
							</div>
						</>
					) : (
						<Preloader />
					)}
					{modal && (
						<Modal onModalClose={onModalClose} isOpened={modal}>
							<div className="mapping-modal-cont">
								<div className="table-modal-form-cont">
									<div className="freight-modal-table-box">
										<table className="mapping-modal-table">
											<thead>
												<tr className="mapping-modal-results-title justify-between">
													<th
														style={{ flex: "0 1 100%" }}
														className="freight-modal-column"
													>
														Category
													</th>
													<th
														style={{ flex: "0 1 100%" }}
														className="freight-modal-column"
													>
														Products
													</th>
												</tr>
											</thead>
											<tbody>
												{vendor.data.categories.map((cat: any) => (
													<tr className="mapping-modal-result-box justify-between">
														<td
															style={{ flex: "0 1 100%" }}
															className="freight-modal-column"
														>
															{cat.scheme}
														</td>
														<td
															style={{ flex: "0 1 100%" }}
															className="freight-modal-column"
														>
															{cat.productCount}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</Modal>
					)}
					{modalEditSeller && (
						<CreateEditVendor
							onSuccess={(newVendor: any) => {
								vendor.invalidate();
								setModalEditSeller(false);
							}}
							sendRequest={updateVendor}
							modal={modalEditSeller}
							setModal={setModalEditSeller}
							action={action}
							vendor={vendor.data}
						/>
					)}
					{modalMassUpdate && (
						<MassUpdateCountryPrice
							onSuccess={() => toast.success("Successful import!")}
							onError={() => toast.error("Import failed...")}
							modal={modalMassUpdate}
							setModal={setModalMassUpdate}
							mode={massUpdateMode}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default withRouter(SellerProduct);
