import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./pim-management.css";
import {
  deleteSchemeById,
  getSchemeVersionByName,
  setScheme as updateScheme,
} from "../../../../actions/scheme";
import Preloader from "../../../ui/preloader/Preloader";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import { CreateSection } from "./CreateScheme";
import { addOpenSchemeTab, removeOpenSchemeTab } from "../../../../actions/pim";
import { PimOpenTabs } from "../PimMain";
import { Modal } from "../../../ui/modal/Modal";
import { SchemeVersions } from "./SchemeVersions";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../../App";
import { toast } from "react-toastify";

type TParams = { name: string };

const PimEditScheme: React.FC<RouteComponentProps<TParams>> = (props) => {
  const { name } = props.match.params;
  const history = useHistory();

  const [schemeVersionsForm, setSchemeVersionsForm] = useState<boolean>(false);
  const [sku, setSku] = useState("");

  // TODO: add types
  const key = useMemo(() => `/version/${name}`, [name]);
  const version = useQuery(key, () => getSchemeVersionByName(name));

  const mutation = useMutation((data) => updateScheme(data), {
    onSuccess: () => {
      toast.success(`The scheme has been modified!`);
    },
    onError: () => {
      toast.error(`Something went wrong...`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(key);
    },
  });

  const handleMutate = useCallback(
    (newVersion: any) => {
      queryClient.setQueryData(key, newVersion);
      mutation.mutate(newVersion);
    },
    [key, mutation]
  );

  useEffect(() => {
    addOpenSchemeTab(name);
  }, [name]);

  const onDeleteScheme = useCallback(async () => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    await deleteSchemeById(version.data?.name);
    removeOpenSchemeTab(version.data?.name);
    history.push("/products");
  }, [version, history]);

  const backToList = async () => {
    history.push("/products");
  };

  if (version.isLoading || version.isError || !version.data) {
    return <Preloader />;
  }

  return (
    <div className="main-body">
      <div className="main-container">
        <PimOpenTabs />
        <div className="bluebg-admin ">
          <div className="pim-scheme-container">
            <div className="pim-scheme-title">{/* <h1>Pim scheme</h1> */}</div>
            <div className="pim-scheme-header">
              <div className="pim-scheme-header-cont">
                <div>
                  <button className="pim-create-button" onClick={backToList}>
                    Back
                  </button>
                </div>
                {/* <div className="pim-scheme-name-cont"> */}
                {/* <div>{schemeById.name}</div> */}
                <button
                  className="pim-title-button ml4"
                  onClick={() => setSchemeVersionsForm(true)}
                >
                  <img src="/icons/pim-history-line.svg" alt="" />
                  <div className="pim-title-button-text normal">Versions</div>
                </button>
                <div
                  className="in-row align-center"
                  style={{ width: 250, justifyContent: "space-evenly" }}
                >
                  <input
                    className="item-blocks-input"
                    style={{ width: 120, margin: 0 }}
                    placeholder="Type sku..."
                    type="text"
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                  />
                  <Link to={`/productInfo/${sku}`}>
                    <button className="pim-create-button">Open</button>
                  </Link>
                </div>
                {schemeVersionsForm && (
                  <Modal
                    onModalClose={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      setSchemeVersionsForm(false);
                    }}
                    isOpened={schemeVersionsForm}
                  >
                    <div className="variants-modal-cont">
                      <SchemeVersions
                        schemeById={version.data}
                        setSchemeById={handleMutate}
                      />
                    </div>
                  </Modal>
                )}
              </div>
              <button className="pim-delete-button" onClick={onDeleteScheme}>
                Delete scheme
              </button>
            </div>
            <div className="pim-scheme-line"></div>
            <CreateSection data={version.data} setSchemeById={handleMutate} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PimEditScheme);
