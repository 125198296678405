import React, { useState } from "react";
import { deleteMarketplace, syncOrders } from "../../../actions/marketplace";
import { IMarketplace } from "../../../interfaces";
import "./singlemarketplace.css";
import CountryFlag from "../../ui/country-flag/CountryFlag";
import { NavLink } from "react-router-dom";
import { getLogoSvg } from "../marketplaces/MarketplacesList";
import * as _ from "lodash";
import { toast } from "react-toastify";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface Props {
	vendors: any;
	marketplace: IMarketplace;
	setOpenEdit: (openEdit: boolean) => void;
}

export const InfoSection: React.FC<Props> = ({
	marketplace,
	setOpenEdit,
	vendors,
}) => {
	const [vendorName, setVendorName] = useState("All vendors");

	const vendorsArray = (array: any) => {
		return ["All vendors", ..._.uniq(_.map(array, (item: any) => item.name))];
	};

	return (
		<>
			<NavLink to="/platforms" className="single-billing-nav pointer">
				<img src="/icons/billing-arrow-back.svg" alt="" width="25px;" />
				<div className="billing-text underline pl2">back to Platforms</div>
			</NavLink>

			<div className="table-title-cont p0 mb2">
				<div className="main-table-title-cont p0">
					<img
						src={`https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getLogoSvg(
							marketplace
						)}.svg`}
						alt=""
						style={{
							width: "120px",
							maxHeight: "120px",
							borderRadius: "5px 0px 0px 5px",
							margin: "5px 0px 5px 10px",
						}}
					/>
					<div className="single-main-title-cont">
						<div className="single-main-title-start">
							<div className="singlemarketplace-title-image">
								<CountryFlag
									country={marketplace.country as string}
									width="32px"
									height="32px"
								/>
								<b className="primary-color ft3 ml2">{marketplace.zeoosName}</b>
							</div>
							<div className="singlemarketplace-title-marketplace-box">
								<div>Sell at:&nbsp;</div>
								<div className="bold capital">
									{marketplace.marketplaceName}
								</div>
							</div>
							<div className="singlemarketplace-title-marketplace-box">
								<div>Platform Rate:&nbsp;</div>
								<div className="bold">
									{marketplace?.rate
										? `${marketplace?.rate.from}% - ${marketplace?.rate.to}%`
										: "-"}
								</div>
							</div>
						</div>
						<div className="single-main-title-end">
							<div className="in-row">
								<Select
									className="more-cont"
									labelId="label"
									id="select"
									value="first"
								>
									<MenuItem className="hide" value="first">
										<img src="/icons/edit-sm.svg" alt="" />
										More
									</MenuItem>
									<MenuItem value="">
										<NavLink
											to={`/product-attributes/${marketplace.zeoosName}`}
										>
											Match Products Attributes
										</NavLink>
									</MenuItem>
									<MenuItem value="">
										<NavLink to={`/status-order/${marketplace.zeoosName}`}>
											Match Status Order
										</NavLink>
									</MenuItem>
									<MenuItem
										value=""
										onClick={() => {
											syncOrders(marketplace).catch(() =>
												toast.error("Something went wrong")
											);
											toast.success(
												"Orders are syncying... Check odoo shortly"
											);
										}}
									>
										Sync Orders
									</MenuItem>
									{/* <MenuItem value="">Platform Rates</MenuItem> */}
								</Select>
								<button
									className="singlemarketplace-edit-button"
									onClick={() => setOpenEdit(true)}
								>
									edit
								</button>
							</div>
							<div className="in-row align-center">
								<div className="filter-text">Filter by:</div>
								<div className="">
									<select
										className="main-select-bi"
										value={vendorName}
										onChange={(e) => {
											e.preventDefault();
											setVendorName(e.target.value);
										}}
									>
										{vendorsArray(vendors).map(
											(vendorName: string, index: number) => (
												<option className="acompan-option" key={index}>
													{vendorName}
												</option>
											)
										)}
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
